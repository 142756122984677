import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'style/douglas-materialui-theme'

const useStyles = makeStyles((theme) => ({
  table: { backgroundColor: colors.white },
  header: { backgroundColor: colors.brightMint },
  row: {
    '&:nth-child(even)': {
      backgroundColor: colors.white,
    },
    '&:nth-child(odd)': {
      backgroundColor: colors.lightMint,
    },
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.tableHover,
      color: colors.nearBlack,
    },
  },
  deactivated: {
    color: colors.darkGrey,
  },
  cell: {
    borderBottom: 'none',
  },
}))

export default useStyles
