import React from 'react'
import classnames from 'classnames'

import { ImageDto, ImagePosition, imagePositions } from 'api/types'
import { TenantGallery } from 'types'
import { ImageGalleries, ImageGalleryEntry } from 'types/image'
import DraggableImageSlot from './DraggableImageSlot'
import classes from './ImageGallery.module.scss'

export interface ImageGalleryProps {
  tenantGallery: TenantGallery
  galleryName: string
  mediathekEnabled?: boolean
  rejectAllSuggestions?: boolean
  visibleGalleries?: (keyof ImageGalleries)[]
  canDrop?:
    | boolean
    | ((droppedItem: ImageGalleryEntry, targetGallery: string, targetImage?: ImageDto) => boolean)
  canUpdateGallery?: boolean
  disableDownload?: boolean
  draggableType?: string
  droppableType?: string
  disableMenu?: boolean
  'data-testid'?: string
  className?: string
  onImageDelete?: (image: ImageDto, galleries: ImageGalleries) => void
  onGalleryChange?: (galleries: ImageGalleries, droppedItem?: ImageGalleryEntry) => void
  onMediathekClick?: (imagePosition: ImagePosition) => void
}

// eslint-disable-next-line react/display-name
const ImageGallery: React.FC<ImageGalleryProps> = React.memo((props) => {
  return (
    <div
      data-testid={props['data-testid']}
      className={classnames(classes.imageGallery, props.className)}
    >
      {imagePositions.map((imagePosition) => (
        <DraggableImageSlot {...props} key={imagePosition} imagePosition={imagePosition} />
      ))}
    </div>
  )
})

export default ImageGallery
