import type { MediathekState } from './initialState'

import { createReducer } from 'store/utils'
import * as types from './actionTypes'
import initialState from './initialState'

const convertToErrorArray = (e: string | string[]): string[] => {
  return Array.isArray(e) ? [...e] : [e]
}

type Handler<T = void> = (state: MediathekState, payload: T) => MediathekState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.INITIATE_REQUEST]: ((state) => ({
    ...initialState,
    requestInProgress: true,
  })) as HandlerWithoutPayload,

  [types.SET_MEDIATHEK]: ((state, payload) => ({
    ...payload,
    requestInProgress: false,
  })) as Handler<MediathekState>,

  [types.SET_ERRORS]: ((state, errors) => {
    return {
      ...state,
      requestInProgress: false,
      errors: convertToErrorArray(errors),
    }
  }) as Handler<string | string[]>,

  [types.RESET_MEDIATHEK]: (() => ({
    ...initialState,
  })) as HandlerWithoutPayload,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type MediathekAction = Actions[keyof Actions]

export default createReducer<MediathekState, MediathekAction>(initialState, handlers)
