import React from 'react'
import { t } from '@lingui/macro'
import { makeStyles, TextFieldProps } from '@material-ui/core'
import { TextField } from 'mui-rff'

import { ComponentCommonProps } from 'components/types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(-2, 0, 0, -2),
    width: `calc(100% + ${theme.spacing(2)}px)`,

    '& > div': {
      flexGrow: '1',
      margin: theme.spacing(2, 0, 0, 2),
    },
  },
}))

export type FullNameTextFieldsProps = ComponentCommonProps & TextFieldProps

export const FullNameTextFields: React.FC<FullNameTextFieldsProps> = ({
  'data-testid': dataTest = 'fullNameTextFields',
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid={dataTest}>
      <TextField
        {...restProps}
        type="text"
        name="firstName"
        label={t`admin.account.fullname.label.first_name`}
        data-testid="first-name"
      />
      <TextField
        {...restProps}
        type="text"
        name="lastName"
        label={t`admin.account.fullname.label.last_name`}
        data-testid="last-name"
      />
    </div>
  )
}

export default FullNameTextFields
