import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorMessageList } from 'components/AlertMessageList'
import NoFilterWarning from 'components/NoFilterWarning'
import { actions, selectors } from 'store/galleryPage'
import ArticleList from './ArticleList'
import BulkCopyGallery from './BulkCopyGallery'
import GalleryPageFilter from './GalleryPageFilter'
import { useInitialRequest } from './hooks'
import TotalCountLabel from './TotalCountLabel'
import classes from './GalleryPage.module.scss'

const GalleryPage: React.FC = (props) => {
  const dispatch = useDispatch()
  const errorNotification = useSelector(selectors.errorNotification)
  const canCopyGalleryImages = useSelector(selectors.canCopyImageGalleries)
  const showFilterWarning = useSelector(selectors.showFilterWarning)
  const handleFilterWarningClick = () => {
    dispatch(actions.ignoreFilterWarning(true))
    dispatch(actions.fetchGalleryPage())
  }

  useInitialRequest()

  return (
    <div className={classes.root} data-testid="galleryPage">
      <GalleryPageFilter data-testid="galleryPage.filter" />
      <div className={classes.bulkActions}>
        {canCopyGalleryImages ? <BulkCopyGallery /> : <div />}
        <TotalCountLabel />
      </div>
      {errorNotification ? <ErrorMessageList errors={errorNotification} /> : null}
      {showFilterWarning ? (
        <NoFilterWarning
          onClick={handleFilterWarningClick}
          data-testid="galleryPage.filterWarning"
        />
      ) : (
        <ArticleList />
      )}
    </div>
  )
}

export default GalleryPage
