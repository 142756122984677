import classes from './DividerWithLabel.module.scss'

export default function DividerWithLabel(props: { label: string }) {
  const { label = '' } = props
  return (
    <div className={classes.separator} data-testid="dividerWithLabel">
      {label}
    </div>
  )
}
