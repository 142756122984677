import React from 'react'
import { Tooltip } from '@material-ui/core'

export interface TenantFlagProps {
  tenantId: string
  shopName?: string
  tooltipText?: string
  className?: string
  size?: Sizes
  useMuiTooltip?: boolean
  'data-testid'?: string
}

export enum Sizes {
  Small = 16,
  Medium = 20,
  Large = 24,
}

const TenantFlag: React.FC<TenantFlagProps> = ({
  tenantId,
  shopName,
  tooltipText,
  size,
  className,
  useMuiTooltip,
  'data-testid': dataTest = 'tenantFlag',
}) => {
  return useMuiTooltip ? (
    <Tooltip title={tooltipText ?? `flag of ${shopName}`} placement="top">
      <img
        className={className}
        alt={shopName ? `flag of ${shopName}` : tooltipText}
        src={`/images/flags/flag-${tenantId ? tenantId.toLocaleLowerCase() : 'unknown'}.png`}
        height={size || Sizes.Medium}
        data-testid={dataTest}
        data-tenantid={tenantId}
      />
    </Tooltip>
  ) : (
    <img
      className={className}
      alt={shopName ? `flag of ${shopName}` : tooltipText}
      title={tooltipText ?? `flag of ${shopName}`}
      src={`/images/flags/flag-${tenantId ? tenantId.toLocaleLowerCase() : 'unknown'}.png`}
      height={size || Sizes.Medium}
      data-testid={dataTest}
      data-tenantid={tenantId}
    />
  )
}

export default TenantFlag
