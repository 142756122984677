import { ReactElement } from 'react'
import { Box } from '@material-ui/core'

import DataLoadingIndicator from 'components/DataLoadingIndicator'
import classes from './AppLoadingIndicator.module.scss'

export default function AppLoadingIndicator(): ReactElement {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.loading}
      flexDirection="column"
    >
      <span className={classes.loadingInfoText}>check authentication ...</span>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <DataLoadingIndicator />
      </Box>
    </Box>
  )
}
