import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Fade, LinearProgress } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import useTimedMessage from 'hooks/useTimedMessage'
import { actions, selectors } from 'store/variantDetail'
import classes from './VariantDetailMessages.module.scss'

interface VariantDetailMessageProps {
  type: 'success' | 'info' | 'warning' | 'error'
  title: string
  message: string
  progress?: number
  style?: React.CSSProperties
}

const messageClasses = {
  root: classes.alert,
  message: classes.message,
}

const VariantDetailMessage: React.FC<VariantDetailMessageProps> = (props) => (
  <Alert
    severity={props.type}
    classes={messageClasses}
    style={props.style}
    data-testid="variant-detail-page-message"
  >
    <AlertTitle>{props.title}</AlertTitle>
    {props.message}
    {props.progress !== undefined ? (
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={100 - props.progress}
        className={classes.progress}
      />
    ) : null}
  </Alert>
)

const VariantDetailInfoMessage: React.FC = () => {
  const dispatch = useDispatch()
  const successMessage = useSelector(selectors.infoMessage)
  const showSubmitNotification = useSelector(selectors.showSubmitNotification)
  const showSuccessMessage = Boolean(successMessage && !showSubmitNotification)
  const [showMessage, progress] = useTimedMessage(showSuccessMessage)
  const handleTransitionEnd = useCallback(() => {
    dispatch(actions.setInfoMessage(null))
  }, [dispatch])

  return showSuccessMessage ? (
    <Fade in={showMessage} timeout={500} onExited={handleTransitionEnd}>
      <VariantDetailMessage
        type="success"
        title={t`variantdetails.images.info.notify.title`}
        message={successMessage || ''}
        progress={progress}
      />
    </Fade>
  ) : null
}

const VariantDetailErrorMessage: React.FC<{ message: string }> = (props) => (
  <VariantDetailMessage
    type="error"
    title={t`variantdetails.images.error.notify.title`}
    message={props.message}
  />
)

const VariantDetailMessages: React.FC = () => {
  const errors = useSelector(selectors.errors)

  return (
    <React.Fragment>
      {errors.length > 0 &&
        errors.map((e, idx) => <VariantDetailErrorMessage message={e} key={idx} />)}
      <VariantDetailInfoMessage />
    </React.Fragment>
  )
}

export default VariantDetailMessages
