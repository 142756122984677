import { useMemo } from 'react'

import initialState, { ArticleOverviewFilter } from 'store/articleOverview/initialState'
import DateUtils from 'utils/converter/DateUtils'

export interface ArticleOverviewUrlParameter {
  subscribedBrands?: boolean
  inStock?: boolean
  onlineReady?: boolean
  prioGoLive?: boolean
  hasSuggestions?: boolean
}

const prioGoLiveDates: Partial<ArticleOverviewFilter> = {
  goLiveFrom: DateUtils.todayAddWeeks(-2),
  goLiveTo: DateUtils.todayAddWeeks(6),
}

export default function (subscribedBrandIds: string[], params: ArticleOverviewUrlParameter) {
  return useMemo(() => {
    const filterConfig: [
      keyof ArticleOverviewUrlParameter,
      (isTrue: boolean) => Partial<ArticleOverviewFilter>
    ][] = [
      ['inStock', (isTrue) => ({ inStock: isTrue })],
      ['onlineReady', (isTrue) => ({ onlineReady: isTrue })],
      ['hasSuggestions', (isTrue) => ({ hasSuggestions: isTrue })],
      ['prioGoLive', (isTrue) => (isTrue ? prioGoLiveDates : { goLiveFrom: '', goLiveTo: '' })],
      ['subscribedBrands', (isTrue) => ({ brandIds: isTrue ? subscribedBrandIds : [] })],
    ]

    return filterConfig.reduce(
      (prev: ArticleOverviewFilter, current) => {
        const [paramName, getFilter] = current
        const paramValue = params[paramName]
        return typeof paramValue === 'boolean' ? Object.assign(prev, getFilter(paramValue)) : prev
      },
      { ...initialState.filter, brandIds: subscribedBrandIds }
    )
  }, [subscribedBrandIds, params])
}
