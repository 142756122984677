import { useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core'

import ProfileMenu from '../ProfileMenu'
import classes from './PageMenuBar.module.scss'

const getHeadlineOfPath = (routePath: string) => {
  if (!routePath) {
    return ''
  }

  if (routePath.indexOf('/variant/') >= 0) {
    return t({ id: 'general.menu.headline.variant_details', message: 'Variant Details' })
  }

  return ''
}

export default function AppTopBar() {
  const location = useLocation()

  return (
    <AppBar position="fixed" className={classes.appTopBar}>
      <Toolbar className={classes.toolbarWrapper}>
        <Container className={classes.toolbarHeadline}>
          <Typography data-testid="pageTitle" variant="h4" className={classes.pageTitle}>
            {getHeadlineOfPath(location.pathname)}
          </Typography>
        </Container>
        <div className={classes.profileBadge}>
          <ProfileMenu />
        </div>
      </Toolbar>
    </AppBar>
  )
}
