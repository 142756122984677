import { useCallback, useState } from 'react'

/**
 * An array with 4 elements:
 * - the message list as TypedMessage[]
 * - a function to add one or more errors
 * - a function to add one or more messages with certain level (default INFO)
 * - a function to clean the message list
 */
type ErrorHookResult = [
  messages: TypedMessage[],
  addErrors: (errMsg: string | string[]) => void,
  addMessages: (infMsg: string | string[], level?: MessageLevel) => void,
  cleanMessages: () => void
]

export enum MessageLevel {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export interface TypedMessage {
  message: string
  msgType: MessageLevel
}

/**
 * A React hook to manage simple messages for user. Returns an array with 4 elements:
 * - the message list as TypedMessage[]
 * - a function to add one or more errors
 * - a function to add one or more messages with certain level (default INFO)
 * - a function to clean the message list
 * <br>
 * Sample usage:
 * ```jsx
 * const [alertMessages, addErrors, addMessages, cleanAlertMessages] = useMessageTextCollection()
 * ```
 * @returns
 */
export default function useMessageTextCollection(): ErrorHookResult {
  const [messages, setMessages] = useState<TypedMessage[]>([])

  const cleanMessages = useCallback(() => setMessages([]), [])

  const addMessages = useCallback((newMessageText: string | string[], level?: MessageLevel) => {
    const newArray = Array.isArray(newMessageText) ? newMessageText : [newMessageText]
    setMessages((prevMsg) => [
      ...prevMsg,
      ...newArray.map((m) => ({ msgType: level ?? MessageLevel.Info, message: m })),
    ])
  }, [])

  const addErrors = useCallback((newErrorText: string | string[]) => {
    const newArray = Array.isArray(newErrorText) ? newErrorText : [newErrorText]
    setMessages((prevMsg) => [
      ...prevMsg,
      ...newArray.map((m) => ({ msgType: MessageLevel.Error, message: m })),
    ])
  }, [])

  return [messages, addErrors, addMessages, cleanMessages]
}
