import { useEffect, useMemo, useState } from 'react'

import { TransferItem } from './ItemTransferListWithState'

export interface ItemTransferListState {
  selected: string[]
  unselected: string[]
  itemMap: Map<string, TransferItem>
  handleSelect: (state: string) => void
  handleUnselect: (state: string) => void
  handleClear: () => void
  transferLeft: (items: string[]) => void
  transferRight: (items: string[]) => void
}

export const useItemTransferListState = (
  selected: string[],
  available: TransferItem[]
): ItemTransferListState => {
  const [availableSorted, itemMap] = useMemo(
    () => [
      available.sort((a, b) =>
        a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
      ),
      new Map(available.map((item) => [item.id, item])),
    ],
    [available]
  )
  const [selectedItems, setSelectedItems] = useState(selected)
  const unselectedItems = useMemo(
    () => availableSorted.map((item) => item.id).filter((item) => !selectedItems.includes(item)),
    [availableSorted, selectedItems]
  )

  useEffect(() => setSelectedItems(selected), [selected])

  return useMemo(
    () => ({
      selected: selectedItems,
      unselected: unselectedItems,
      itemMap,
      handleSelect: (value: string) => {
        const newSelectedItems = [...selectedItems, value].sort((a, b) => {
          const aLabel = itemMap.get(a)?.label || a
          const bLabel = itemMap.get(b)?.label || b
          return aLabel.localeCompare(bLabel)
        })
        setSelectedItems(newSelectedItems)
      },
      handleUnselect: (value: string) => {
        setSelectedItems(selectedItems.filter((current) => current !== value))
      },
      handleClear: () => setSelectedItems([]),
      transferLeft: (transferedItems: string[]) => {
        const items = new Set([...selectedItems, ...transferedItems])
        setSelectedItems([...items])
      },
      transferRight: (transferedItems: string[]) => {
        const items = new Set(selectedItems)
        for (const item of transferedItems) {
          items.delete(item)
        }
        setSelectedItems([...items])
      },
    }),
    [selectedItems, unselectedItems, itemMap]
  )
}
