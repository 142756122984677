import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import { selectors } from 'store/galleryPage'
import classes from './GalleryPage.module.scss'

const TotalCountLabel: React.FC = (props) => {
  const articles = useSelector(selectors.articles)
  const totalElements = useSelector(selectors.totalElements)

  return (
    <div className={classes.totalCount} data-testid="total-article-count-label">
      {totalElements > 0 ? (
        <span>
          {t({
            id: 'gallerypage.total.count.label',
            values: { 0: articles.length, 1: totalElements },
          })}
        </span>
      ) : null}
    </div>
  )
}

export default TotalCountLabel
