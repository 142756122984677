import backendRequest from 'api/backendRequest'
import {
  ArticleGalleryFilterDto,
  ArticleGalleryOverviewDto,
  ArticleGalleryOverviewSearchForm,
} from './galleryPageTypes'

export const fetchGalleryPage = (filter: ArticleGalleryOverviewSearchForm) => {
  return backendRequest().post<ArticleGalleryOverviewDto>(
    '/article/articles-with-approved-galleries',
    filter
  )
}

export function fetchGalleryPageFilters() {
  return backendRequest().get<ArticleGalleryFilterDto>('image/reviews/filter')
}
