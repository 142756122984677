import React from 'react'
import { t } from '@lingui/macro'

import { isArticleNumber } from 'utils/validation'
import { TextFilter } from './TextFilter'

interface FilterProps {
  value: string
  onChange: (value: string) => void
  onSubmit: () => void
  disabled?: boolean
}

function validateArticleNumberOrEan(value: string) {
  if (!isArticleNumber(value)) {
    return t`filter.invalid_article_number_or_ean`
  }
}

export function EanArticleNumbersFilter(props: FilterProps) {
  return (
    <TextFilter
      {...props}
      label={t`filter.label.ean_article_numbers`}
      validateInput={validateArticleNumberOrEan}
      data-testid="filter-article-number"
    />
  )
}
