import { createSelector } from 'reselect'

import { createBasicSelectors } from 'store/utils'
import initialState from './initialState'

const selectors = createBasicSelectors(initialState, 'imageReviews')

const allTenants = createSelector(selectors.articles, (articles) =>
  articles.flatMap((article) => article.tenants)
)

const selectedTenants = createSelector(allTenants, (tenants) =>
  tenants.filter((tenant) => tenant.isSelected)
)

const areAllTenantsSelected = createSelector(
  selectedTenants,
  allTenants,
  (selectedTenants, allTenants) => selectedTenants.length === allTenants.length
)

const isBulkApprovalInProgress = createSelector(
  selectors.bulkApproval,
  (bulkApproval) => bulkApproval.totalCount > 0 && bulkApproval.count < bulkApproval.totalCount
)

const galleryCount = createSelector(selectors.articles, (articles) =>
  articles.reduce((prev, current) => prev + current.tenants.length, 0)
)

export default {
  ...selectors,
  allTenants,
  selectedTenants,
  areAllTenantsSelected,
  isBulkApprovalInProgress,
  galleryCount,
}
