import { __ } from 'ramda'
import { ThunkAction } from 'redux-thunk'

import { translateErrorResponse } from 'api/errors'
import galleryPageApi from 'api/galleryPage'
import {
  ArticleGalleryOverviewDto,
  ArticleGalleryOverviewSearchForm,
} from 'api/galleryPage/galleryPageTypes'
import imageReviewsApi from 'api/imageReviews'
import { ImageFeedback } from 'api/imageReviews/imageReviewsTypes'
import { ArticleIdentifier, ImageDto } from 'api/types'
import { RootState } from 'store/reducers'
import { selectors as profileSelectors } from 'store/userProfile'
import { ArticleGalleries, FetchState, TenantGallery } from 'types'
import { ImageGalleries } from 'types/image'
import articleGalleries from 'utils/articleGalleries'
import tenantGallery from 'utils/tenantGallery'
import { compareTenantIds } from 'utils/tenants'
import * as types from './actionTypes'
import { GalleryPageFilter } from './initialState'
import { GalleryPageAction } from './reducer'
import selectors from './selectors'

type GalleryPageThunkAction<T = void> = ThunkAction<T, RootState, null, GalleryPageAction>

const initiatedRequest = (fetchMore: boolean): GalleryPageAction => ({
  type: types.INITIATED_REQUEST,
  payload: fetchMore,
})

const finishedRequest = (errorNotification: string[] | null): GalleryPageAction => ({
  type: types.FINISHED_REQUEST,
  payload: errorNotification,
})

const parseInputValues = (value: string) =>
  value
    ? value
        .split(/[\s,]+/)
        .map((x) => x.trim())
        .filter(Boolean)
    : []

function fetchArguments(state: RootState): ArticleGalleryOverviewSearchForm {
  const filter = selectors.filter(state)
  const page = selectors.page(state)

  return {
    brandIds: filter.brandIds,
    supplierIds: filter.supplierIds,
    tenantIds: filter.tenantIds,
    articleNumbersAndEans: parseInputValues(filter.articleNumbersAndEans),
    page,
    size: 10,
  }
}

export interface ResetAllFiltersPayload {
  subscribedBrandIds: string[]
}

export interface AddArticlesPayload {
  articles: ArticleGalleries[]
  isLastRequest: boolean
}

export interface SetArticlesAndFilterPayload extends AddArticlesPayload {
  allowedBrands: string[]
  allowedSuppliers: string[]
  allowedTenants: string[]
}

export interface UpdateArticleTenantPayload {
  article: ArticleIdentifier
  update: Partial<TenantGallery>
}

const articlesPayload = (galleryPage: ArticleGalleryOverviewDto, state: RootState) => {
  const brandLabels = profileSelectors.brandLabels(state)
  const mapArticle = articleGalleries.fromArticleDto(__, brandLabels, ['approved'])

  return {
    articles: galleryPage.articles.map(mapArticle),
    isLastRequest: galleryPage.last,
    totalElements: galleryPage.totalElements,
  }
}

export const fetchGalleryPage = (fetchMore = false): GalleryPageThunkAction => {
  return (dispatch, getState) => {
    dispatch(checkAvailableFilter())

    const showFilterWarning = selectors.showFilterWarning(getState())
    if (showFilterWarning) {
      return
    }

    if (fetchMore) {
      dispatch(initiatedRequest(true))
      galleryPageApi.fetchGalleryPage(fetchArguments(getState())).then(
        (response) => {
          dispatch({
            type: types.ADD_ARTICLES,
            payload: articlesPayload(response.data, getState()),
          })
        },
        (error) => dispatch(finishedRequest(translateErrorResponse(error)))
      )
    } else {
      dispatch(initiatedRequest(false))
      Promise.all([
        galleryPageApi.fetchGalleryPageFilters(),
        galleryPageApi.fetchGalleryPage(fetchArguments(getState())),
      ]).then(
        ([filtersResponse, galleryPageResponse]) => {
          const { brands, suppliers, tenants } = filtersResponse.data

          dispatch({
            type: types.SET_ARTICLES_AND_FILTER,
            payload: {
              allowedBrands: brands,
              allowedSuppliers: suppliers,
              allowedTenants: tenants.sort(compareTenantIds),
              ...articlesPayload(galleryPageResponse.data, getState()),
            },
          })
        },
        (error) => dispatch(finishedRequest(translateErrorResponse(error)))
      )
    }
  }
}

const checkAvailableFilter = (): GalleryPageThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const filtersFetched = [selectors.allowedBrands(state), selectors.allowedSuppliers(state)].some(
      (value) => value.length > 0
    )

    if (!filtersFetched) {
      galleryPageApi
        .fetchGalleryPageFilters()
        .then((filtersResponse) => {
          const { brands, suppliers, tenants } = filtersResponse.data

          dispatch({
            type: types.SET_ARTICLES_AND_FILTER,
            payload: {
              allowedBrands: brands,
              allowedSuppliers: suppliers,
              allowedTenants: tenants.sort(compareTenantIds),
              articles: [],
              isLastRequest: true,
            },
          })
        })
        .catch((error) => dispatch(finishedRequest(translateErrorResponse(error))))
    }
  }
}

export const fetchMoreGalleryPage = (): GalleryPageThunkAction => {
  return fetchGalleryPage(true)
}

export const reloadArticle = (articleNumber: string): GalleryPageThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const state = getState()
    return galleryPageApi
      .fetchGalleryPage({
        ...fetchArguments(state),
        articleNumbersAndEans: [articleNumber],
        page: 0,
        size: 1,
      })
      .then((response) => {
        if (response?.status === 200 && response.data) {
          const { articles } = response.data
          if (articles.length > 0) {
            const payload = articlesPayload(response.data, getState())
            dispatch({ type: types.REPLACE_ARTICLE, payload: payload.articles[0] })
          }
        }
      })
  }
}

export const setFilter = (filter: Partial<GalleryPageFilter>): GalleryPageAction => ({
  type: types.SET_FILTER,
  payload: filter,
})
export const resetFilter = (subscribedBrands: string[]): GalleryPageAction => ({
  type: types.RESET_FILTER,
  payload: { subscribedBrandIds: subscribedBrands },
})

export const setVisibleStartIndex = (index: number): GalleryPageThunkAction => {
  return (dispatch, getState) => {
    const visibleStartIndex = selectors.visibleStartIndex(getState())
    if (index !== visibleStartIndex) {
      dispatch({
        type: types.SET_VISIBLE_START_INDEX,
        payload: index,
      })
    }
  }
}

export const toggleSelectedArticle = (articleNumber: string): GalleryPageAction => ({
  type: types.TOGGLE_SELECTED_ARTICLE,
  payload: articleNumber,
})

export const toggleAllSelectedArticles = (): GalleryPageThunkAction => {
  return (dispatch, getState): void => {
    const areAllArticlesSelected = selectors.areAllArticlesSelected(getState())

    dispatch({ type: types.TOGGLE_ALL_SELECTED_ARTICLES, payload: !areAllArticlesSelected })
  }
}

export const resetSelectedArticles = (): GalleryPageAction => ({
  type: types.TOGGLE_ALL_SELECTED_ARTICLES,
  payload: false,
})

export const updateTenant = (
  article: ArticleIdentifier,
  update: Partial<TenantGallery>
): GalleryPageAction => ({
  type: types.UPDATE_TENANT,
  payload: { article, update },
})

export const updateImageGallery = (article: ArticleIdentifier, galleries: ImageGalleries) =>
  updateTenant(article, { galleries, hasChanged: true })

export const setFetchState = (article: ArticleIdentifier, fetchState: FetchState) =>
  updateTenant(article, { fetchState })

export const setTenantError = (article: ArticleIdentifier, errors?: string[]) =>
  updateTenant(article, { error: errors && errors.length ? errors.join(', ') : undefined })

export const showSuccessNotification = (article: ArticleIdentifier) =>
  updateTenant(article, { showSuccessNotification: true })

export const hideSuccessNotification = (article: ArticleIdentifier) =>
  updateTenant(article, { showSuccessNotification: false })

export interface AddToPaperBinPayload {
  article: ArticleIdentifier
  image: ImageDto
  galleries: ImageGalleries
}

export const addToPaperBin = (
  article: ArticleIdentifier,
  image: ImageDto,
  galleries: ImageGalleries
): GalleryPageAction => ({
  type: types.ADD_TO_PAPERBIN,
  payload: { article, image, galleries },
})

export interface RemoveFromPaperBinPayload {
  article: ArticleIdentifier
  image: ImageDto
}

export const removeFromPaperBin = (
  article: ArticleIdentifier,
  image: ImageDto
): GalleryPageAction => ({
  type: types.REMOVE_FROM_PAPERBIN,
  payload: { article, image },
})

export const saveImageSuggestions = (
  article: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean,
  rejectAllSuggestions: boolean
): GalleryPageThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const currentArticle = selectors
      .articles(state)
      .find((current) => current.articleNumber === article.articleNumber)
    const tenant = currentArticle?.tenants?.find((tenant) => tenant.tenantId === article.tenantId)

    if (tenant) {
      dispatch(setFetchState(article, 'FETCHING'))
      const entries = tenantGallery.toReviewImageGalleryEntryForm(tenant, rejectAllSuggestions)
      imageReviewsApi
        .updateReviewImageGallery(article.articleNumber, article.tenantId, {
          entries,
          feedback,
          newGalleryRequested,
        })
        .then(
          async () => {
            await dispatch(reloadArticle(article.articleNumber))
            dispatch(showSuccessNotification(article))
            dispatch(setFetchState(article, 'SUCCESS'))
          },
          (reason) => {
            dispatch(setTenantError(article, translateErrorResponse(reason)))
            dispatch(setFetchState(article, 'ERROR'))
          }
        )
    }
  }
}

export const rejectGallery = (
  article: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean
): GalleryPageThunkAction => {
  return saveImageSuggestions(article, feedback, newGalleryRequested, true)
}

export const approveGallery = (article: ArticleIdentifier): GalleryPageThunkAction => {
  return saveImageSuggestions(article, [], false, false)
}

export const ignoreFilterWarning = (ignore: boolean): GalleryPageAction => ({
  type: types.IGNORE_FILTER_WARNING,
  payload: ignore,
})
