import { Fragment, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Menu } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { environment } from 'config'
import { selectors as profileSelectors } from 'store/userProfile'
import Logout from './Logout'
import ResetDatabase from './ResetDatabase'
import UserProfile from './UserProfile'
import classes from './ProfileMenu.module.scss'

export default function ProfileMenu() {
  const username = useSelector(profileSelectors.username)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const openProfileMenu = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const closeProfileMenu = () => setAnchorEl(null)

  return (
    <Fragment>
      <div>
        <Button
          className={classes.profileButton}
          data-testid="profile-menu-button"
          aria-controls="simple-menu"
          aria-haspopup="true"
          startIcon={<Person className={classes.menuIcon} />}
          endIcon={<ArrowDropDownIcon />}
          onClick={openProfileMenu}
          variant="outlined"
          size="small"
        >
          {username}
        </Button>
      </div>
      <Menu
        data-testid="profile-menu-options"
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeProfileMenu}
      >
        {/* wrap in <div> tag for bugfix: https://github.com/mui-org/material-ui/issues/15903#issuecomment-723807594*/}
        <div>
          <UserProfile onOpen={closeProfileMenu} />
        </div>
        {environment === 'dev' ? <ResetDatabase onOpen={closeProfileMenu} /> : null}
        <hr className={classes.dividerLine} />
        <Logout />
      </Menu>
    </Fragment>
  )
}
