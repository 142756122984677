import React from 'react'

import { useImageStateClasses } from './hooks'
import { ImageThumbnailProps } from './ImageThumbnail'
import { getImageUrl } from './misc'
import classes from './ImageGallery.module.scss'

const TenantImage: React.FC<ImageThumbnailProps> = (props) => {
  const { image } = props
  const imageClasses = useImageStateClasses(image, props.rejectAllSuggestions, classes.image)

  return (
    <img
      draggable="false"
      src={getImageUrl(image)}
      alt={image.imageId}
      className={imageClasses}
      data-testid="galleryimage"
      data-imagestate={String(image.state).toLowerCase()}
    />
  )
}

export default TenantImage
