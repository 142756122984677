import { de, en, es, fr, it, pl } from 'make-plural'

import { Locale } from './Locale'

const localeEnglish: Locale = { key: 'en', label: 'English', plurals: en }
const localeGerman: Locale = { key: 'de', label: 'Deutsch', plurals: de }
const localeFrance: Locale = { key: 'fr', label: 'Français', plurals: fr }
const localePoland: Locale = { key: 'pl', label: 'Polskie', plurals: pl }
const localeSpanish: Locale = { key: 'es', label: 'Español', plurals: es }
const localeItaly: Locale = { key: 'it', label: 'Italiano', plurals: it }

export const Locales: Locale[] = [
  localeEnglish,
  localeGerman,
  localeFrance,
  localePoland,
  localeSpanish,
  localeItaly,
]

export const isValidLocale = (locale: string): boolean => {
  return Locales.find((value) => value.key === locale) !== undefined
}

export const DefaultLocale = localeEnglish
