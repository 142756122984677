import React, { ChangeEvent, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useSharedFilterStyle from './hooks/useSharedFilterStyle'

export type BooleanValue = boolean | null
export type BooleanOptionKey = 'optionNotSet' | 'optionTrue' | 'optionFalse'
export type BooleanOptionMenuValue = '' | 'isTrue' | 'isFalse'

export interface BooleanFilterProps {
  value: BooleanValue
  label: string
  optionLabels: { [K in BooleanOptionKey]: string }
  disabled?: boolean
  onChange: (newValue: boolean | null) => void
  'data-testid'?: string
}

interface Option {
  value: BooleanValue
  menuValue: BooleanOptionMenuValue
  optionKey: BooleanOptionKey
}

const availableOptions: Option[] = [
  {
    value: null,
    menuValue: '',
    optionKey: 'optionNotSet',
  },
  {
    value: true,
    menuValue: 'isTrue',
    optionKey: 'optionTrue',
  },
  {
    value: false,
    menuValue: 'isFalse',
    optionKey: 'optionFalse',
  },
]

const useStyles = makeStyles({
  selectRoot: {
    textAlign: 'start',
  },
  filterTrigger: {
    display: 'none',
  },
})

const convertBooleanToMenuValue = (value: BooleanValue): BooleanOptionMenuValue =>
  (availableOptions.find((o) => o.value === value) as Option).menuValue

const convertMenuValueToBoolean = (menuValue: BooleanOptionMenuValue): BooleanValue =>
  (availableOptions.find((o) => o.menuValue === menuValue) as Option).value

export const BooleanFilter: React.FC<BooleanFilterProps> = ({
  value,
  label,
  disabled,
  optionLabels,
  onChange,
  'data-testid': dataTest = 'booleanFilter',
}) => {
  const classes = useStyles()
  const globalClasses = useSharedFilterStyle()
  const selectedValue = convertBooleanToMenuValue(value)
  const [open, setOpen] = useState(false)

  const handleChange = (ev: ChangeEvent<{ value: unknown }>) => {
    const selectedMenuOption = ev.target.value as BooleanOptionMenuValue
    onChange(convertMenuValueToBoolean(selectedMenuOption))
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)

    // due to a bug in MUI that selection element stay focused when close:
    setTimeout(() => (document.activeElement as HTMLInputElement)?.blur(), 0)
  }

  return (
    <div>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        margin="dense"
        disabled={disabled}
        className={globalClasses.filterFieldRoot}
        data-testid={dataTest}
      >
        <InputLabel id="boolean-filter-label">{label}</InputLabel>
        <Select
          labelId="boolean-filter-label"
          id="boolean-filter-select"
          label={label}
          value={selectedValue}
          className={classes.selectRoot}
          disabled={disabled}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          autoWidth
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {availableOptions.map((o) => (
            <MenuItem
              key={o.optionKey}
              value={o.menuValue}
              data-testid={`${dataTest}.${o.menuValue}`}
            >
              {optionLabels[o.optionKey]}
            </MenuItem>
          ))}
        </Select>
        <button
          onClick={handleOpen}
          data-testid={`${dataTest}.triggerOpen`}
          className={classes.filterTrigger}
        />
        <button
          onClick={handleClose}
          data-testid={`${dataTest}.triggerClose`}
          className={classes.filterTrigger}
        />
      </FormControl>
    </div>
  )
}
