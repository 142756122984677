import React from 'react'
import { t } from '@lingui/macro'
import { TextFieldProps } from '@material-ui/core'
import { TextField } from 'mui-rff'

export default function EmailTextField(props: TextFieldProps) {
  return (
    <div>
      <TextField
        {...props}
        fullWidth
        type="email"
        name="email"
        label={t`admin.edit.account.user.label.mail`}
        data-testid="email"
      />
    </div>
  )
}
