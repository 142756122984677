import { ReactElement, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { getApplicationLocale, loadAndActivateLocale } from 'utils/localizations/i18nUtil'
import AppRoutes from './components/AppRoutes'
import ImageLightbox from './components/ImageLightbox'
import PageMenuBar from './components/PageMenuBar'
import SentryProvider from './SentryProvider'
import UserProfileLoader from './UserProfileLoader'
import classes from './App.module.scss'

const App = (): ReactElement => {
  useEffect(() => {
    loadAndActivateLocale(getApplicationLocale())
  }, [])

  return (
    <Router>
      <SentryProvider>
        <UserProfileLoader>
          <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
            <div className={classes.appRoot}>
              <PageMenuBar />
              <div className={classes.appContent}>
                <AppRoutes />
              </div>
              <ImageLightbox />
            </div>
          </I18nProvider>
        </UserProfileLoader>
      </SentryProvider>
    </Router>
  )
}

export default App
