import { DialogTitle as MuiDialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import classes from './DialogTitle.module.scss'

export interface DialogTitleProps {
  onClose: () => void
  className?: string
}

export const DialogTitle: React.FC<DialogTitleProps> = ({ className, onClose, children }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.title} data-testid="dialogTitle">
      <div className={classes.headline}>
        <div className={className}>{children}</div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeDialogIcon}
          data-testid="dialogTitle.close"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </MuiDialogTitle>
  )
}
