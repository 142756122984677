import React, { Fragment, useState } from 'react'
import { t } from '@lingui/macro'
import { MenuItem, Typography } from '@material-ui/core'

import UserProfileDialog from '../UserProfile'

interface UserProfileProps {
  onOpen: () => void
}

export default function UserProfile(props: UserProfileProps) {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false)
  const handleClickOpenProfileDialog = () => {
    props.onOpen()
    setProfileDialogOpen(true)
  }

  return (
    <Fragment>
      <MenuItem
        onClick={handleClickOpenProfileDialog}
        data-testid="userData-openprofile"
        title={t`general.profile.menu.title.profile`}
      >
        <Typography variant="inherit" noWrap>
          {t`general.profile.menu.label.profile`}
        </Typography>
      </MenuItem>
      <UserProfileDialog open={profileDialogOpen} onClose={() => setProfileDialogOpen(false)} />
    </Fragment>
  )
}
