import { any } from 'ramda'
import { createSelector } from 'reselect'

import { createBasicSelectors } from 'store/utils'
import initialState from './initialState'

const selectors = createBasicSelectors(initialState, 'adminUsers')

const activeSearchTerm = createSelector(selectors.searchTerm, (searchTerm) => {
  return searchTerm.length >= 2 ? searchTerm : ''
})

const searchWords = createSelector(activeSearchTerm, (searchTerm) => {
  return searchTerm.split(/\s+/).filter(Boolean)
})

const filteredUsers = createSelector(selectors.users, searchWords, (users, searchWords) => {
  const lowerCaseSearchWords = searchWords.map((word) => word.toLocaleLowerCase())
  const containsSearchTerm = (text: string) => {
    for (const word of lowerCaseSearchWords) {
      if (!text || text.toLocaleLowerCase().indexOf(word) < 0) {
        return false
      }
    }
    return true
  }

  return lowerCaseSearchWords.length > 0
    ? users.filter((user) =>
        any(containsSearchTerm)([user.username, user.email, `${user.firstName} ${user.lastName}`])
      )
    : users
})

const totalPages = createSelector(filteredUsers, selectors.size, (users, size) => {
  return Math.ceil(users.length / size)
})

const currentPage = createSelector(
  filteredUsers,
  selectors.size,
  selectors.page,
  (users, size, page) => {
    return users.slice((page - 1) * size, page * size)
  }
)

export default {
  ...selectors,
  activeSearchTerm,
  filteredUsers,
  totalPages,
  currentPage,
  searchWords,
}
