import React from 'react'
import { t } from '@lingui/macro'

import { BooleanFilter, BooleanOptionKey, BooleanValue } from './BooleanFilter'

export interface InStockFilterProps {
  value: BooleanValue
  disabled?: boolean
  onChange: (newValue: boolean | null) => void
  'data-testid'?: string
}

export function InStockFilter(props: InStockFilterProps) {
  const optionLabels = {
    optionNotSet: t`filter.options.in_stock_unfiltered`,
    optionTrue: t`filter.options.is_in_stock`,
    optionFalse: t`filter.options.out_of_stock`,
  } as { [K in BooleanOptionKey]: string }

  return (
    <BooleanFilter {...props} label={t`filter.label.is_in_stock`} optionLabels={optionLabels} />
  )
}
