import type { MessageDto } from 'api/types'
import type { BrandsDto, ExportImageGalleryForBrandsForm } from './serviceTypes'

import { trackPromise } from 'react-promise-tracker'

import backendRequest from 'api/backendRequest'

export const fetchAllAvailableBrands = (waitingProgressArea?: string) => {
  return trackPromise(backendRequest().get<BrandsDto>('/brand/all'), waitingProgressArea)
}

export const resyncArticleNumbers = (
  articleNumbers: string[],
  tenantId: string,
  waitingProgressArea?: string
) => {
  return trackPromise(
    backendRequest().post<MessageDto>(`/article/export-approved-image-gallery`, {
      articleNumbers,
      tenantId,
    }),
    waitingProgressArea
  )
}

export const resyncBrand = (
  resyncBrandRequest: ExportImageGalleryForBrandsForm,
  waitingProgressArea?: string
) => {
  return trackPromise(
    backendRequest().post<MessageDto>(
      '/article/export-approved-galleries-for-multiple-brands',
      resyncBrandRequest
    ),
    waitingProgressArea
  )
}
