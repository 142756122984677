export const ADD_UPLOADED_IMAGES = 'imageupload/ADD_UPLOADED_IMAGES'
export const SET_IMAGE_DATA_URL = 'imageupload/SET_IMAGE_DATA_URL'
export const SET_ALLOWED_TENANTS = 'imageupload/SET_AVAILABLE_TENANTS'
export const SET_SELECTED_TENANTS = 'imageupload/SET_SELECTED_TENANTS'
export const START_FILE_UPLOAD = 'imageupload/START_FILE_UPLOAD'
export const SET_UPLOAD_PROGRESS = 'imageupload/SET_UPLOAD_PROGRESS'
export const SET_UPLOAD_RESULT = 'imageupload/SET_UPLOAD_RESULT'
export const SET_UPLOAD_ERROR = 'imageupload/SET_UPLOAD_ERROR'
export const REMOVE_IMAGE = 'imageupload/REMOVE_IMAGE'
export const REMOVE_ALL_IMAGES = 'imageupload/REMOVE_ALL_IMAGES'
export const TOGGLE_SELECTED_TENANT = 'imageupload/TOGGLE_SELECTED_TENANT'
export const BULK_UPLOAD_STARTED = 'imageupload/BULK_UPLOAD_STARTED'
