import type { MessageDto, RawImageGalleryEntry, VariantImagesDto } from 'api/types'
import type { ArticleDetailDto } from './variantDetailTypes'

import { trackPromise } from 'react-promise-tracker'

import backendRequest from 'api/backendRequest'

export const fetchVariantDetails = (articleNumber: string, waitingProgressArea?: string) => {
  return trackPromise(
    backendRequest().get<ArticleDetailDto>(`/article/${articleNumber}`),
    waitingProgressArea
  )
}

export const exportImages = (articleNumber: string, tenantId: string) => {
  return backendRequest().post<unknown>(`/article/export-approved-image-gallery`, {
    articleNumbers: [articleNumber],
    tenantId,
  })
}

export const updateImageGallery = (articleNumber: string, approvals: RawImageGalleryEntry[]) => {
  return backendRequest().post<MessageDto>(`/article/${articleNumber}/update-image-galleries`, {
    approvals: approvals,
  })
}

export function fetchMediathek(articleNumber: string) {
  return backendRequest().get<VariantImagesDto>(`/variant/${articleNumber}/images`)
}

export function deleteMediathekImage(articleNumber: string, imageId: string) {
  return backendRequest().post(`/article/${articleNumber}/${imageId}/block-image-for-all-tenants`)
}
