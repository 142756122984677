import { ThunkAction } from 'redux-thunk'

import adminUserApi from 'api/adminUser'
import { translateErrorResponse } from 'api/errors'
import { RootState } from 'store/reducers'
import { compareTenantIds } from 'utils/tenants'
import * as types from './actionTypes'
import { AdminUsersAction } from './reducer'

type AdminUsersThunkAction<T = void> = ThunkAction<T, RootState, null, AdminUsersAction>

export const cleanAlertMessages = () => ({
  type: types.CLEAN_ALERT_MESSAGES,
  payload: {},
})

export const fetchUsers = (): AdminUsersThunkAction<void> => {
  return (dispatch) => {
    adminUserApi.fetchUsers().then(
      (response) => {
        const users = response.data
        users.sort((a, b) => {
          if (a.role.name === b.role.name) {
            return a.username.toLocaleLowerCase().localeCompare(b.username.toLocaleLowerCase())
          }

          return a.role.name.localeCompare(b.role.name)
        })
        for (const user of users) {
          if (user.tenantIds) {
            user.tenantIds.sort(compareTenantIds)
          }
        }
        dispatch({
          type: types.SET_USERS,
          payload: users,
        })
      },
      (error) => {
        dispatch({ type: types.ADD_ERRORS, payload: translateErrorResponse(error) })
      }
    )
  }
}

export const fetchUserOptions = (): AdminUsersThunkAction<void> => {
  return (dispatch) => {
    adminUserApi.fetchUserOptions().then(
      (response) => {
        const { data } = response

        data.brands.sort((a, b) => {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        })

        dispatch({
          type: types.SET_USER_OPTIONS,
          payload: {
            ...data,
            roles: data.roles.filter(
              (role) => role.id !== 'ADMIN' && role.id !== 'SUPPLIER_SERVICE_ACCOUNT'
            ),
          },
        })
      },
      (error) => {
        dispatch({ type: types.ADD_ERRORS, payload: translateErrorResponse(error) })
      }
    )
  }
}

export const changePage = (pageNumber: number) => ({
  type: types.CHANGE_PAGE,
  payload: pageNumber,
})

export const setSearchTerm = (searchTerm: string) => ({
  type: types.SET_SEARCH_TERM,
  payload: searchTerm,
})
