import { Paper as MuiPaper } from '@mui/material'
import clsx from 'clsx'

import { ComponentCommonProps } from 'components/types'
import style from './Paper.module.scss'

export type PaperProps = ComponentCommonProps & {
  children?: React.ReactNode
}

export const Paper: React.FC<PaperProps> = ({
  children,
  className,
  'data-testid': dataTest = 'paper',
}) => {
  return (
    <MuiPaper className={clsx(style.paper, className)} data-testid={dataTest}>
      {children}
    </MuiPaper>
  )
}

export default Paper
