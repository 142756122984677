import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as QueryString from 'querystring'
import { pick } from 'ramda'

import { actions, selectors } from 'store/imageReviews'
import { selectors as profileSelectors } from 'store/userProfile'

interface RequestParams {
  a?: string
  b?: string
  t?: string
}

let isInitialRequest = true
export const useInitialRequest = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const filter = useSelector(selectors.filter)
  const totalSubscribedBrands = useSelector(profileSelectors.totalSubscribedBrands)

  useEffect(() => {
    if (location.state?.fromDashboard) {
      const { filter } = location.state
      dispatch(actions.setFilter(filter))
      dispatch(actions.fetchImageReviews())
      history.replace(pick(['pathname', 'search'], location))
    } else if (isInitialRequest) {
      const params = QueryString.parse(window.location.search.replace('?', '')) as RequestParams
      const convertUrlParameter = (param: string): string[] => (param ? param.split(',') : [])
      dispatch(
        actions.setFilter(
          Object.assign(
            {},
            filter,
            params.a ? { articleNumbers: params.a } : null,
            { brandIds: params.b ? convertUrlParameter(params.b) : totalSubscribedBrands },
            params.t ? { tenants: convertUrlParameter(params.t) } : null
          )
        )
      )
      dispatch(actions.fetchImageReviews())
    }

    isInitialRequest = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
