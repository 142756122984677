import React, { Fragment, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { TenantDto } from 'api/types'
import { DividerWithLabel } from 'components/Divider'
import TenantFlag from 'components/TenantFlag'
import { actions, selectors } from 'store/imageUpload'
import { isGlobalTenant } from 'utils/tenants'
import classes from './TenantSelectionContainer.module.scss'

export default function TenantSelectionContainer(): ReactElement {
  const dispatch = useDispatch()
  const globalTenant = useSelector(selectors.globalTenant)
  const allowedTenants = useSelector(selectors.allowedTenants)
  const selectedTenants = useSelector(selectors.selectedTenants)

  const onChangeTenantSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.toggleSelectedTenant(event.target.value))
  }

  const isTenantSelected = (tenant: TenantDto): boolean => {
    return Boolean(selectedTenants.find((t) => t.key === tenant.key))
  }

  const getCheckbox = (tenant: TenantDto) => {
    return (
      <Checkbox
        checked={isTenantSelected(tenant)}
        disableRipple
        checkedIcon={<CheckCircleIcon className={classes.checkedIcon} />}
        icon={<span className={classes.selectionIcon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        onChange={onChangeTenantSelection}
        name="tenant"
        value={tenant.key}
        key={tenant.key}
      />
    )
  }

  return (
    <div>
      {globalTenant && (
        <Fragment>
          <div className={classes.selectionEntry}>
            <FormControlLabel
              control={getCheckbox(globalTenant)}
              label={t`upload.selection.tenant.label.global`}
              data-testid="upload-option-tenant-dgl-GLOBAL"
            />
            <TenantFlag tenantId={globalTenant.key} shopName={globalTenant.label} />
          </div>
          {allowedTenants.length > 1 && (
            <DividerWithLabel label={t`upload.selection.tenant.divider.label`} />
          )}
        </Fragment>
      )}
      <div>
        {allowedTenants.map(
          (tenant) =>
            !isGlobalTenant(tenant.key) && (
              <Fragment key={tenant.key}>
                <div className={classes.selectionEntry}>
                  <FormControlLabel
                    control={getCheckbox(tenant)}
                    label={tenant.label}
                    data-testid={'upload-option-tenant-' + tenant.key}
                  />
                  <TenantFlag tenantId={tenant.key} shopName={tenant.label} />
                </div>
                <div className={classes.selectionDivider} />
              </Fragment>
            )
        )}
      </div>
    </div>
  )
}
