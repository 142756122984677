import { TenantDto } from 'api/types'

export enum UploadStatus {
  INVALID = -1,
  READY = 0,
  UPLOADING = 202,
  UPLOADED_OK = 200,
  UPLOADED_INVALID = 500,
}

export interface ImageFile {
  id: string
  file: File
  percent: number
  uploadStatus: UploadStatus
  isImageProcessed: boolean
  messages: string[]
  url?: string
  dataUrl?: string
  updatedTenants?: string
}

export interface ImageUploadState {
  images: ImageFile[]
  totalUploadCount: number
  uploadCount: number
  selectedTenants: TenantDto[]
  allowedTenants: TenantDto[]
}

const initialState: ImageUploadState = {
  images: [],
  selectedTenants: [],
  allowedTenants: [],
  totalUploadCount: 0,
  uploadCount: 0,
}

export default initialState
