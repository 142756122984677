import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ListOnItemsRenderedProps, VariableSizeList } from 'react-window'

import useEntryHeight, { GetEntryHeight, SetEntryHeight } from './useEntryHeight'

type ListRefCallback = (node: VariableSizeList | null) => void

type ListRenderedCallback = (props: ListOnItemsRenderedProps) => void

export type ListEntry<EntryType> = EntryType | 'empty list'

export interface ListEntries<EntryType, EntryProps, ReactComponent> {
  entries: ListEntry<EntryType>[]
  Component: ReactComponent
  margin: number
  setSize: SetEntryHeight<EntryType>
  entryProps: EntryProps
  emptyListMessage?: string
}

export interface UseInfiniteScrolling<EntryType, EntryProps, ReactComponent> {
  entries: EntryType[]
  entryProps: EntryProps
  Component: ReactComponent
  margin: number
  isLastRequest?: boolean
  requestInProgress?: boolean
  visibleStartIndex?: number
  emptyListMessage?: string
  itemKey: (entry: EntryType) => string
  estimateHeight: ((entry: EntryType) => number) | number
  onEndReached?: () => void
  onVisibleStartIndexChanged?: (index: number) => void
}

export function useInfiniteScrolling<EntryType, EntryProps, ReactComponent>({
  entries: list,
  entryProps,
  Component,
  margin,
  isLastRequest,
  requestInProgress,
  visibleStartIndex,
  emptyListMessage,
  estimateHeight,
  itemKey,
  onEndReached,
  onVisibleStartIndexChanged,
}: UseInfiniteScrolling<EntryType, EntryProps, ReactComponent>): [
  ListRefCallback,
  ListEntries<EntryType, EntryProps, ReactComponent>,
  GetEntryHeight,
  ListRenderedCallback,
  () => void
] {
  const listRef = useRef<VariableSizeList | null>(null)
  const refCallback: ListRefCallback = useCallback((node) => {
    if (node) {
      listRef.current = node
      if (typeof visibleStartIndex === 'number' && visibleStartIndex > 0) {
        node.scrollToItem(visibleStartIndex, 'start')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const entries = useMemo(() => {
    const entries: ListEntry<EntryType>[] =
      list.length === 0 && (isLastRequest === undefined || isLastRequest) && !requestInProgress
        ? ['empty list']
        : [...list]

    return entries
  }, [isLastRequest, list, requestInProgress])

  const [getSize, setSize] = useEntryHeight<EntryType>(
    entries,
    listRef,
    margin,
    estimateHeight,
    itemKey
  )

  const listEntries = useMemo(
    (): ListEntries<EntryType, EntryProps, ReactComponent> => ({
      entries,
      Component,
      margin,
      emptyListMessage,
      setSize,
      entryProps,
    }),
    [entries, Component, margin, emptyListMessage, setSize, entryProps]
  )

  const handleItemsRendered: ListRenderedCallback = useCallback(
    (props) => {
      if (
        !requestInProgress &&
        !isLastRequest &&
        props.overscanStopIndex === entries.length - 1 &&
        typeof onEndReached === 'function'
      ) {
        onEndReached()
      }
      if (typeof onVisibleStartIndexChanged === 'function') {
        onVisibleStartIndexChanged(props.visibleStartIndex)
      }
    },
    [entries.length, isLastRequest, onEndReached, onVisibleStartIndexChanged, requestInProgress]
  )

  const jumpToTop = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0)
    }
  }, [])

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0)
    }
  }, [listEntries])

  return [refCallback, listEntries, getSize, handleItemsRendered, jumpToTop]
}

export default useInfiniteScrolling
