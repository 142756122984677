import React, { useState } from 'react'
import { t } from '@lingui/macro'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { ArrowDropDownCircle } from '@material-ui/icons'

import { UserDto } from 'api/adminUser'

export type UserMenuOption = 'disable' | 'reset password'

interface UserMenuProps {
  user: UserDto
  currentUsername: string
  onMenuClick: (user: UserDto, menuOption: UserMenuOption) => void
}

const UserMenu: React.FC<UserMenuProps> = ({ user, currentUsername, onMenuClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const handleMenuClick = (menuOption: UserMenuOption) => () => {
    onMenuClick(user, menuOption)
    setAnchorEl(null)
  }
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  return user.role.id !== 'SUPPLIER_SERVICE_ACCOUNT' ? (
    <>
      <IconButton onClick={handleButtonClick} data-testid="admin-user-menu">
        <ArrowDropDownCircle data-option-icon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClick('disable')} disabled={user.username === currentUsername}>
          {!user.enabled
            ? t`admin.accounts.list.action.reactivate_user`
            : t`admin.accounts.list.action.deactivate_user`}
        </MenuItem>
        <MenuItem
          onClick={handleMenuClick('reset password')}
        >{t`admin.accounts.reset.password`}</MenuItem>
      </Menu>
    </>
  ) : null
}

export default UserMenu
