import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { ReactComponent as PageNotFoundIcon } from 'style/icons/404Icon.svg'
import classes from './NotFoundError.module.scss'

export default function NotFoundError(): ReactElement {
  const errorCode = 404
  const pathname = window.location.pathname

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item xs={1} />
      <Grid item xs={4}>
        <div className={classes.textWrapper}>
          <div className={classes.headerLabel}>
            <Trans id="errorpanel.text.header">Oops!</Trans>
          </div>
          <div className={classes.errorMessage}>
            <Trans id="errorpanel.text.message">
              We can't find the page
              <br />
              you're looking for.
            </Trans>
          </div>
          <div className={classes.errorHint}>
            <Trans id="errorpanel.text.hint">Not everything is PRIMA.</Trans>
          </div>

          <Box m="3rem" />

          <div>
            <Trans id="errorpanel.label.error_code">
              <b>Error code:</b> {errorCode}
            </Trans>
          </div>
          <div>
            <Trans id="errorpanel.label.error_path">
              <b>Path:</b> {pathname}
            </Trans>
          </div>

          <Box m="1.5rem" />
        </div>

        <a href={'/'} className={classes.redirectLink}>
          <Button
            className={classes.redirectHomeButton}
            variant="contained"
            title={'Return to overview'}
            color="primary"
          >
            <Trans id="errorpanel.button.return_to_start">Return to PRIMA Start Page</Trans>
          </Button>
        </a>
      </Grid>
      <Grid item xs={7}>
        <PageNotFoundIcon />
      </Grid>
    </Grid>
  )
}
