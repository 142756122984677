import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { ComponentCommonProps } from '../types'

export type ResetAllFilterButtonProps = ComponentCommonProps & {
  hidden: boolean
  onResetAll: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}))

export const ResetAllFilterButton: React.FC<ResetAllFilterButtonProps> = ({
  hidden,
  onResetAll,
  'data-testid': dataTest = 'button-reset-all',
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid={dataTest}>
      {!hidden ? (
        <Button
          variant="outlined"
          color="default"
          className={classes.button}
          endIcon={<CloseIcon />}
          onClick={onResetAll}
        >
          {t`image.review.filter.button.reset_all`}
        </Button>
      ) : null}
    </div>
  )
}

export default ResetAllFilterButton
