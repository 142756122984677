import { useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Grid } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import StyleIcon from '@material-ui/icons/Style'

import { UserPermission } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import { selectors } from 'store/variantDetail'
import classes from './VariantMasterData.module.scss'

const VariantMasterData: React.FC = () => {
  const articleNumber = useSelector(selectors.articleNumber)
  const sample = useSelector(selectors.sample)
  const blocked = useSelector(selectors.blocked)
  const permissions = useSelector(profileSelectors.permissions)

  return articleNumber ? (
    <Grid container className={classes.masterDataContainer}>
      <Grid item xs={3}>
        <span className={classes.label}>
          <Trans id="variantdetails.masterdata.label.article_number">Douglas Article Number:</Trans>
        </span>
      </Grid>
      <Grid item xs={3}>
        <span className={classes.articleNumber} data-testid="value-dgl-article-number">
          {articleNumber}
        </span>
      </Grid>
      {sample ? (
        <Grid item xs={3} className={classes.articleMarking}>
          <StyleIcon className={classes.articleMarkingIcon} />
          <div data-testid="variantMasterData.sample">{t`general.sample`} </div>
        </Grid>
      ) : null}
      {blocked && permissions.has(UserPermission.BLOCK_ARTICLE) ? (
        <Grid item xs={3} className={classes.articleMarking}>
          <BlockIcon className={classes.articleMarkingIcon} />
          <div data-testid="variantMasterData.blocked">{t`general.blocked`} </div>
        </Grid>
      ) : null}
    </Grid>
  ) : null
}

export default VariantMasterData
