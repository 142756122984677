import { MouseEventHandler, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import DialogTitle from 'components/DialogTitle'
import ImageGallery from 'components/ImageGallery'
import TenantLabel from 'components/TenantLabel'
import TenantSelection from 'components/TenantSelection'
import { selectors } from 'store/variantDetail'
import { TenantGallery } from 'types'
import { isEmptyGallery } from 'utils/imageGallery'
import { ImportGalleryVerifyDialog } from './ImportGalleryVerifyDialog'
import classes from './ImportGalleryDialog.module.scss'

export interface ImportGalleryDialogProps {
  open: boolean
  tenantGallery: TenantGallery
  onClose: () => void
  onApply: (selectedTenantId: string) => void
}

const ImportGalleryDialog: React.FC<ImportGalleryDialogProps> = (props) => {
  const { tenantGallery } = props
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const tenants = useSelector(selectors.tenants)
  const [showImportGalleryVerifyDlg, setShowImportGalleryVerifyDlg] = useState(false)
  const allowedTenants = useMemo(() => {
    return new Set(
      tenants.filter((t) => !isEmptyGallery(t.galleries, 'approved')).map((t) => t.tenantId)
    )
  }, [tenants])
  const selectedTenantGallery = useMemo(
    () => tenants.find((tenantGallery) => tenantGallery.tenantId === selectedTenantId),
    [tenants, selectedTenantId]
  )

  const handleClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation()
  }
  const handleClose = () => {
    props.onClose()
    setSelectedTenantId('')
  }
  const handleApply = () => {
    handleClose()
    props.onApply(selectedTenantId)
  }

  function handleTryToApply() {
    if (!isEmptyGallery(props.tenantGallery.galleries, 'approved')) {
      setShowImportGalleryVerifyDlg(true)
    } else {
      handleApply()
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="xl"
      onClick={handleClick}
      data-testid="importGalleryDialog"
    >
      <DialogTitle onClose={handleClose}>{t`variantdetails.import_gallery`}</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <div className={classes.row}>
          <div className={classes.tenantColumn}>
            <FormControl variant="outlined" margin="dense">
              <InputLabel
                id="import-tenant-selection"
                classes={{ shrink: classes.tenantLabelShrink }}
              >
                {t`variantdetails.import_from_tenant`}
              </InputLabel>
              <TenantSelection
                labelId="import-tenant-selection"
                className={classes.tenantSelection}
                value={selectedTenantId}
                onChange={setSelectedTenantId}
                tenants={tenants.map((tenant) => tenant.tenantId)}
                isDisabled={(tenantId) => !allowedTenants.has(tenantId)}
                data-testid="importGalleryDialog.srcTenantSelection"
              />
            </FormControl>
          </div>
          <div className={classes.gallery}>
            <ImageGallery
              disableMenu
              tenantGallery={selectedTenantGallery ?? tenantGallery}
              galleryName={`variantdetail_tenant_${tenantGallery.tenantId}`}
              visibleGalleries={['approved']}
              canDrop={false}
              data-testid="tenantGallery"
            />
          </div>
        </div>
        <div className={classes.arrowContainer}>
          <ArrowDownwardIcon className={classes.arrowIcon} />
        </div>
        <div className={classes.row}>
          <div className={classes.tenantColumn}>
            <TenantLabel tenantId={tenantGallery.tenantId} className={classes.targetTenant} />
          </div>
          <div className={classes.gallery}>
            <ImageGallery
              disableMenu
              tenantGallery={tenantGallery}
              galleryName={'variantdetail_tenant_' + tenantGallery.tenantId}
              visibleGalleries={['approved', 'global']}
              canDrop={false}
              data-testid="tenantGallery"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t`general.cancel`}
        </Button>
        <Button
          color="primary"
          onClick={handleTryToApply}
          disabled={!allowedTenants.has(selectedTenantId)}
          data-testid="importGalleryDialog.apply"
        >{t`general.apply`}</Button>
      </DialogActions>
      <ImportGalleryVerifyDialog
        open={showImportGalleryVerifyDlg}
        onClose={() => setShowImportGalleryVerifyDlg(false)}
        onApply={handleApply}
      />
    </Dialog>
  )
}

export default ImportGalleryDialog
