import type { MediathekImage } from 'store/mediathek'

import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import MediathekImageCard from './MediathekImageCard'
import classes from './Mediathek.module.scss'

export interface ConfirmDeleteInfo {
  image: MediathekImage
}

interface ConfirmDeleteDialogProps {
  open: boolean
  info: ConfirmDeleteInfo
  onClose: () => void
  onConfirm: (image: MediathekImage) => void
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const { info } = props
  const handleConfirm = () => {
    if (props?.info?.image) {
      props.onConfirm(props.info.image)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">{t`mediathek.card.delete_image_permanently`}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t`mediathek.card.delete_image_confirmation`}</DialogContentText>
        <Alert severity="warning">{t`mediathek.card.delete_image_warning`}</Alert>
        <div className={classes.confirmDialogImageContainer}>
          <MediathekImageCard hideMenu image={info.image} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t`general.cancel`}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t`mediathek.card.block`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteDialog
