import { useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import Button from '@material-ui/core/Button'
import { Alert } from '@material-ui/lab'
import classnames from 'classnames'

import { ImageFeedback } from 'api/imageReviews'
import { UserPermission } from 'api/types'
import RejectionDetailDialog from 'components/RejectionDetailDialog'
import useImageCount from 'hooks/useImageCount'
import { selectors as profileSelectors } from 'store/userProfile'
import { TenantGallery } from 'types'
import { hasImageInFirstPosition } from 'utils/imageGallery'
import classes from './ApprovalButtons.module.scss'

export interface ApprovalButtonsProps {
  tenant: TenantGallery
  onMouseEnterReject: () => void
  onMouseLeaveReject: () => void
  onApproveGallery: () => void
  onRejectGallery: (feedback: ImageFeedback[], newGalleryRequested: boolean) => void
}

const ApprovalButtons: React.FC<ApprovalButtonsProps> = (props) => {
  const { tenant } = props
  const permissions = useSelector(profileSelectors.permissions)
  const tenants = useSelector(profileSelectors.tenants)
  const [showRejectionDialog, setShowRejectionDialog] = useState(false)
  const positionZeroMissing = !hasImageInFirstPosition(tenant.galleries)
  const imageCount = useImageCount(tenant)
  const isOneOfUserTenants = tenants.includes(tenant.tenantId)
  const canApproveImage =
    imageCount.suggested > 0 && permissions.has(UserPermission.APPROVE_IMAGE) && isOneOfUserTenants

  if (!canApproveImage && !tenant.hasChanged) {
    return null
  }

  const handleRejectClick = () => {
    props.onMouseEnterReject()
    setShowRejectionDialog(true)
  }
  const handleRejectionDialogClose = () => {
    props.onMouseLeaveReject()
    setShowRejectionDialog(false)
  }

  return (
    <div
      className={classnames(classes.buttons, {
        [classes.hasErrorMessage]: positionZeroMissing || tenant.error,
      })}
      data-testid="approvalButtons"
    >
      {tenant.error ? (
        <Alert severity="error" className={classes.alert}>
          {tenant.error}
        </Alert>
      ) : null}
      {positionZeroMissing && !tenant.error ? (
        <Alert severity="warning" className={classes.alert}>
          {t`image.review.messages.position_0_required`}
        </Alert>
      ) : null}
      <div>
        {canApproveImage ? (
          <Button
            size="large"
            variant="outlined"
            color="primary"
            disabled={tenant.fetchState === 'FETCHING'}
            title={t`image.review.button.title.reject_image_suggestions`}
            className={classnames(classes.button, classes.rejectAll)}
            data-testid="image-gallery-reject-all"
            onClick={handleRejectClick}
            onMouseEnter={props.onMouseEnterReject}
            onMouseLeave={props.onMouseLeaveReject}
          >
            {t`image.review.button.reject_all`}
          </Button>
        ) : null}
        <Button
          size="large"
          color="primary"
          variant="contained"
          disabled={tenant.fetchState === 'FETCHING' || positionZeroMissing}
          title={t`image.review.button.title.save`}
          className={classes.button}
          data-testid="image-gallery-apply"
          onClick={props.onApproveGallery}
        >
          {t`image.review.button.apply`}
        </Button>
      </div>
      <RejectionDetailDialog
        mode="gallery"
        open={showRejectionDialog}
        onReject={props.onRejectGallery}
        onClose={handleRejectionDialogClose}
      />
    </div>
  )
}

export default ApprovalButtons
