import { useState } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core'

import { tenantLabel } from 'utils/tenants'
import classes from './VariantFilter.module.scss'

interface Props {
  label: string
  tenants: string[]
  availableTenants: string[]
  disabled?: boolean
  'data-testid'?: string
  onFilterChanged?: (newFilter: string[]) => void
  onClose?: () => void
}

export const TenantFilter: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.onFilterChanged?.(event.target.value as string[])
  }

  const isFilterChecked = (filterKey: string) => {
    if (props.availableTenants.length > 0) {
      return props.availableTenants.indexOf(filterKey) !== -1
    }
    return false
  }

  const getSelectionItem = (tenantId: string) => {
    return (
      <MenuItem key={tenantId} value={tenantId} className={classes.multiFilterItem}>
        <Checkbox
          color="primary"
          checked={isFilterChecked(tenantId)}
          data-testid={props['data-testid'] ? `${props['data-testid']}-${tenantId}` : undefined}
        />
        <ListItemText primary={tenantLabel(tenantId)} />
      </MenuItem>
    )
  }

  const getSelectionTextRenderValue = (selected: SelectProps['value']) => {
    return (selected as string[]).map(tenantLabel).join(', ')
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      margin="dense"
      data-testid={props['data-testid']}
    >
      <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
      <Select
        label={props.label}
        multiple
        autoWidth
        fullWidth
        value={props.availableTenants}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        renderValue={getSelectionTextRenderValue}
        disabled={props.disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {props.tenants.map(getSelectionItem)}
      </Select>
      <button
        onClick={handleOpen}
        data-testid="trigger-open"
        className={classes.multiFilterTrigger}
      />
      <button
        onClick={handleClose}
        data-testid="trigger-close"
        className={classes.multiFilterTrigger}
      />
    </FormControl>
  )
}
