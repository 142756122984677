import { useDispatch, useSelector } from 'react-redux'

import { ErrorMessageList } from 'components/AlertMessageList'
import NoFilterWarning from 'components/NoFilterWarning'
import { actions, selectors } from 'store/articleOverview'
import ArticleOverviewFilter from './ArticleOverviewFilter'
import ArticleOverviewTable from './ArticleOverviewTable'
import { useInitialLoad } from './initialLoadHook'

const ArticleOverviewPage: React.FC = () => {
  const dispatch = useDispatch()
  const errorNotification = useSelector(selectors.errorNotification)
  const showFilterWarning = useSelector(selectors.showFilterWarning)
  const handleFilterWarningClick = () => {
    dispatch(actions.ignoreFilterWarning(true))
    dispatch(actions.fetchArticleOverview(0))
  }

  useInitialLoad()

  return (
    <div data-testid="articleOverview">
      {errorNotification && <ErrorMessageList errors={errorNotification} />}
      <ArticleOverviewFilter data-testid="articleOverview.filter" />
      {showFilterWarning ? (
        <NoFilterWarning
          onClick={handleFilterWarningClick}
          data-testid="articleOverview.filterWarning"
        />
      ) : (
        <ArticleOverviewTable />
      )}
    </div>
  )
}

export default ArticleOverviewPage
