import { t } from '@lingui/macro'
import { Fade, LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'

import { ArticleIdentifier } from 'api/types'
import useTimedMessage from 'hooks/useTimedMessage'
import { TenantGallery } from 'types'
import style from './TenantNotification.module.scss'

export type TenantNotificationProps = {
  tenant: TenantGallery
  articleNumber: string
  onTenantNotificationExpired?: (article: ArticleIdentifier) => void
}

export const TenantNotification: React.FC<TenantNotificationProps> = ({
  articleNumber,
  tenant,
  onTenantNotificationExpired,
}) => {
  const [showMessage, progress] = useTimedMessage(Boolean(tenant.showSuccessNotification))
  const closeNotification = () => {
    if (onTenantNotificationExpired && tenant.showSuccessNotification) {
      onTenantNotificationExpired({ articleNumber, tenantId: tenant.tenantId })
    }
  }

  return (
    <Fade in={showMessage} timeout={500} onExited={closeNotification}>
      <div className={style.root} data-testid="tenantNotification">
        <Alert className={style.alert} onClose={closeNotification}>
          {t`general.changes_have_been_saved`}
          {progress !== undefined ? (
            <LinearProgress
              variant="determinate"
              color="primary"
              value={100 - progress}
              classes={{
                root: style.progress,
                bar1Determinate: clsx({ [style.progressBar]: progress === 0 }),
              }}
            />
          ) : null}
        </Alert>
      </div>
    </Fade>
  )
}

export default TenantNotification
