import React from 'react'

import { ArticleGalleries } from 'types'

interface FirstBrandNameProps {
  article: ArticleGalleries
}

export default function FirstBrandName({ article }: FirstBrandNameProps) {
  const firstBrandName = article.tenants.find((tenant) => tenant.brand)?.brand

  return firstBrandName ? (
    <>
      <span>-&nbsp;</span>
      <span data-testid="brand-name">{firstBrandName}</span>
    </>
  ) : null
}
