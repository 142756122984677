import { TransitionEvent, useCallback, useRef, useState } from 'react'

import { guid } from 'utils/generator/RandomGenerator'

interface ClosingElementProps {
  id: string
  onTransitionEnd: (ev: TransitionEvent<HTMLDivElement>) => void
}

type UseCloseAnimationResult = [boolean, () => void, ClosingElementProps]

export default function useCloseAnimation(onClose?: () => void): UseCloseAnimationResult {
  const containerId = useRef(guid())
  const [isClosing, setIsClosing] = useState(false)
  const initiateClosing = useCallback(() => setIsClosing(true), [])
  const onTransitionEnd = useCallback(
    (ev: TransitionEvent<HTMLDivElement>) => {
      if (
        onClose &&
        ev.propertyName === 'transform' &&
        (ev.target as HTMLElement).id === containerId.current
      ) {
        onClose()
      }
    },
    [onClose]
  )
  const elementProps = {
    id: containerId.current,
    onTransitionEnd,
  }

  return [isClosing, initiateClosing, elementProps]
}
