import type { ErrorCodeDto } from 'api/types'
import type { AxiosError } from 'axios'

function instanceOfApiErrorType(object: Record<string, unknown>): boolean {
  return 'status' in object && 'errors' in object && 'timestamp' in object
}
function cleanupErrorMessage(errorMessage: string): string | undefined {
  if (!errorMessage || errorMessage === 'No message available') {
    return undefined
  }

  return errorMessage
}

export class ApiError {
  readonly internalStatusCode: number
  readonly internalStatusText: string
  readonly errorMessage: string | undefined
  readonly errorList: ErrorCodeDto[]
  readonly timestamp: number

  constructor(apiError: AxiosError) {
    const errResp = apiError.response
    const errData = errResp?.data
    if (!errResp) {
      throw Error('no response object in AxiosError')
    }

    if (!errData) {
      this.internalStatusCode = errResp.status
      this.internalStatusText = errResp.statusText
      this.errorMessage = apiError.message
      this.errorList = []
      this.timestamp = new Date().getTime()
    } else if (instanceOfApiErrorType(errData)) {
      this.internalStatusCode = errData.status
      this.internalStatusText = errData.error
      this.errorMessage = cleanupErrorMessage(errData.message)
      this.errorList = errData.errors
      this.timestamp = errData.timestamp
    } else {
      this.internalStatusCode = errData.status
      this.internalStatusText = errData.error
      this.errorMessage = cleanupErrorMessage(errData.message)
      this.errorList = []
      this.timestamp = new Date().getTime()
    }
  }
}

export default ApiError
