import React, { Fragment } from 'react'
import { DialogContentText } from '@material-ui/core'

import ModalDialogTemplate, { ModalDialogProps } from './ModalDialogTemplate'

export default function ModalDialog(
  props: ModalDialogProps & {
    contextText?: string
  }
) {
  return (
    <ModalDialogTemplate {...props}>
      <Fragment>
        {props.contextText && <DialogContentText>{props.contextText}</DialogContentText>}
        {props.children}
      </Fragment>
    </ModalDialogTemplate>
  )
}
