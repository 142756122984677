import React from 'react'
import { Field } from 'react-final-form'
import { ListChildComponentProps } from 'react-window'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const MuiCheckbox = React.memo(Checkbox)

const BrandCheckbox: React.FC<ListChildComponentProps> = (props) => {
  const item = props.data.filteredBrand[props.index]

  return (
    <FormControlLabel
      name="brands"
      label={item.label}
      value={item.value}
      style={props.style}
      control={
        <Field
          type="checkbox"
          name={props.data.formValueName}
          render={({ input: { name, value, onChange, checked } }) => (
            <MuiCheckbox
              name={name}
              value={value}
              onChange={onChange}
              checked={checked}
              color="primary"
              disableRipple
            />
          )}
        />
      }
    />
  )
}

export default BrandCheckbox
