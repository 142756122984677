import type { FileRejection } from 'react-dropzone'

import { ReactElement, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Trans } from '@lingui/macro'
import { Box, Grid } from '@material-ui/core'

import { ReactComponent as AddPhotoIcon } from 'style/icons/addPhoto.svg'
import style from './FileUploadPanel.module.scss'

interface Props {
  acceptFilter: string
  onAddSelectedFile: (newFiles: File[]) => void
}

export default function FileUploadPanel({ onAddSelectedFile, acceptFilter }: Props): ReactElement {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      onAddSelectedFile([
        ...acceptedFiles,
        ...fileRejections.map((invalidFile) => invalidFile.file),
      ])
    },
    [onAddSelectedFile]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFilter,
  })

  return (
    <Grid
      container
      data-testid="bulkupload-dropzone"
      {...getRootProps({
        className: style.rootContainer,
      })}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        style={{ position: 'relative' }}
      >
        <Grid item style={{ textAlign: 'center' }}>
          <Box className={style.uploadContainer}>
            <AddPhotoIcon className={style.uploadIcon} />
          </Box>

          <input {...getInputProps({ name: 'file' })} />
        </Grid>
        <div className={style.dropAndDropHint}>
          <Trans id="upload.dnd.text.hint">Drag & drop or click to browse your computer</Trans>
        </div>
      </Grid>
    </Grid>
  )
}
