import { createTheme } from '@material-ui/core/styles'

import AvenirNextBoldTtf from './fonts/AvenirNextW04-Bold.ttf'
import AvenirNextBoldWoff from './fonts/AvenirNextW04-Bold.woff'
import AvenirNextBoldWoff2 from './fonts/AvenirNextW04-Bold.woff2'
import AvenirNextDemiTtf from './fonts/AvenirNextW04-Demi.ttf'
import AvenirNextDemiWoff from './fonts/AvenirNextW04-Demi.woff'
import AvenirNextDemiWoff2 from './fonts/AvenirNextW04-Demi.woff2'
import AvenirNextRegularTtf from './fonts/AvenirNextW04-Regular.ttf'
import AvenirNextRegularWoff from './fonts/AvenirNextW04-Regular.woff'
import AvenirNextRegularWoff2 from './fonts/AvenirNextW04-Regular.woff2'
import AvenirNextUltraLightTtf from './fonts/AvenirNextW10-UltraLight.ttf'
import AvenirNextUltraLightWoff from './fonts/AvenirNextW10-UltraLight.woff'
import AvenirNextUltraLightWoff2 from './fonts/AvenirNextW10-UltraLight.woff2'
import MontserratRegular_Ttf from './fonts/Montserrat/montserrat-v15-latin-regular.ttf'
import MontserratRegular_Woff from './fonts/Montserrat/montserrat-v15-latin-regular.woff'
import ZahrahSemiBoldTtf from './fonts/Zahrah/ZahrahforDouglasW04-SmBd.ttf'
import ZahrahSemiBoldWoff from './fonts/Zahrah/ZahrahforDouglasW04-SmBd.woff'
import ZahrahSemiBoldWoff2 from './fonts/Zahrah/ZahrahforDouglasW04-SmBd.woff2'
import './_douglas-colors.scss'

const zahrah = {
  fontFamily: 'Zahrah',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url('${ZahrahSemiBoldWoff}') format('woff'),
    url('${ZahrahSemiBoldWoff2}') format('woff2'),
    url('${ZahrahSemiBoldTtf}') format('ttf')
    `,
}

const montserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url('${MontserratRegular_Woff}') format('woff'),
    url('${MontserratRegular_Ttf}') format('truetype'),
    `,
}

const avenirNextRegular = {
  fontFamily: 'AvenirNext',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${AvenirNextRegularWoff}) format('woff'),
    url(${AvenirNextRegularWoff2}) format('woff2'),
    url(${AvenirNextRegularTtf}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
const avenirNextUltraLight = {
  fontFamily: 'AvenirNext',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    url(${AvenirNextUltraLightWoff}) format('woff'),
    url(${AvenirNextUltraLightWoff2}) format('woff2'),
    url(${AvenirNextUltraLightTtf}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
const avenirNextDemi = {
  fontFamily: 'AvenirNext',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${AvenirNextDemiWoff}) format('woff'),
    url(${AvenirNextDemiWoff2}) format('woff2'),
    url(${AvenirNextDemiTtf}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
const avenirNextBold = {
  fontFamily: 'AvenirNext',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${AvenirNextBoldWoff}) format('woff'),
    url(${AvenirNextBoldWoff2}) format('woff2'),
    url(${AvenirNextBoldTtf}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const colors = {
  // douglas colors
  originalMint: '#3cbeaf',
  veryDarkMint: '#0b2e2a',
  brightMint: '#9bdcd2',
  mediumMint: '#c3eae5',
  lightMint: '#e6f6f4',
  shinyMint: '#f0faf8',
  white: '#fff',

  // colorgroup "black to grey":
  black: '#000',
  nearBlack: '#2a2a2a',
  veryDarkGrey: '#bababa',
  darkGrey: '#bfbfbf',
  mediumGrey: '#e6e6e6',
  lightGrey: '#e2e2e2',
  veryLightGrey: '#f2f2f2',

  // colorgroup "red to rose":
  pink: '#ea46bf',
  saleRed: '#ac145a',
  beautyCardBrightRose: '#f0c3dc',
  beautyCardLightRose: '#ffebf0',
  beautyCardDarkRose: '#e17db4',

  // color-group "alerts":
  successGreen: '#5fc33c',
  infoBlue: '#e8f4fd',
  //'--info-border-blue': '#e8f4fd',
  notificationOrange: '#ff9b14',
  errorRed: '#e6373c',

  // color-group other fonts
  labelTextColor: '#8a8a8a',

  // color-group components
  checkboxBorder: '#bfbfbf',

  tableHover: '#ff9',
}

// A custom theme for this app
const theme = createTheme({
  props: {
    disableRipple: true, // No more ripple, on the whole application !
  },
  components: {
    FilterField: {
      marginLeft: -10,
      marginRight: 10,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: colors.originalMint,
      // 'light' and 'dark': will be calculated from palette.primary.main
      contrastText: colors.white,
    },
    secondary: {
      main: colors.lightMint,
      //contrastText: colors.nearBlack
    },
    background: {
      default: colors.white,
    },
    text: {
      default: colors.black,
      primary: colors.black,
      secondary: colors.veryDarkMint,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: colors.labelTextColor,
        '&$focused': {
          '&$focused': {
            color: colors.labelTextColor,
          },
        },
      },
    },
    MuiButton: {
      root: {
        color: colors.originalMint,
        margin: '5px',
        boxShadow: 'none',
        textTransform: 'none',
      },
      contained: {
        backgroundColor: colors.originalMint,
        color: colors.white,
        borderRadius: '6px',
        fontSize: '1rem',
        '&$disabled': {
          backgroundColor: colors.originalMint,
          color: colors.white,
          opacity: 0.25,
        },
      },
      startIcon: {
        marginRight: 0,
      },
      endIcon: {
        marginLeft: 0,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: colors.checkboxBorder,
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    MuiListItem: {
      root: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: colors.white,
      },
    },
    MuiTab: {
      root: {
        color: colors.labelTextColor,
        '&$selected': {
          color: colors.originalMint,
        },
      },
      textColorPrimary: {
        color: colors.labelTextColor,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          avenirNextRegular,
          avenirNextUltraLight,
          avenirNextDemi,
          avenirNextBold,
          montserratRegular,
          zahrah,
        ],
      },
    },
  },
  typography: {
    fontSize: 12,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontFamily: ['AvenirNext', 'Arial', 'sans-serif', 'Montserrat', 'Zahrah'].join(','),
  },
})
export default theme
