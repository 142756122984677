import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreatorsMapObject, bindActionCreators } from 'redux'

const useBoundActions = <M extends ActionCreatorsMapObject>(map: M): M => {
  const dispatch = useDispatch()
  return useMemo(() => {
    return bindActionCreators(map, dispatch)
  }, [dispatch, map])
}

export default useBoundActions
