import { ReactElement } from 'react'
import { Box, CircularProgress, CircularProgressProps, SvgIcon } from '@material-ui/core'

const mainColor = '#3CBEAF'

export default function UploadProgressStateSuccess(props: CircularProgressProps): ReactElement {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} value={100} style={{ color: mainColor }} />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SvgIcon
          className="MuiCircularProgress-svg"
          viewBox="0 0 24 24"
          style={{ width: props.size, height: props.size }}
          htmlColor={mainColor}
        >
          <circle cx="12" cy="12" r="10.6" fill="none" strokeWidth="2" color="#F2F2F2" />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="11.6"
            fill="none"
            strokeWidth="1"
          />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="9.6"
            fill="none"
            strokeWidth="1"
          />
        </SvgIcon>
      </Box>

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.25 4.99951L7.5 14.9995L3.75 11.2495"
            stroke="black"
            strokeWidth="2.0625"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      </Box>
    </Box>
  )
}
