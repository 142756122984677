import React, { useMemo } from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { t } from '@lingui/macro'
import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Autocomplete } from 'mui-rff'

import { SupplierDto } from 'api/types'

const useStyles = makeStyles((theme) => ({
  supplierSelection: {
    marginTop: theme.spacing(2),
  },
}))

const LISTBOX_PADDING = 8

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props

  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})
OuterElementType.displayName = 'OuterElementType'

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.length * itemSize
  }
  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

interface SupplierSelectionProps {
  active: boolean
  availableSuppliers: SupplierDto[]
}

const SupplierSelection: React.FC<SupplierSelectionProps> = ({ active, availableSuppliers }) => {
  const classes = useStyles()
  const [supplierIds, getSupplierName] = useMemo(() => {
    const supplierIds: string[] = []
    const supplierNames: [string, string][] = []

    for (const supplier of availableSuppliers) {
      supplierIds.push(supplier.id)
      supplierNames.push([supplier.id, supplier.name])
    }

    const supplierNamesMap = new Map(supplierNames)
    const getSupplierName = (supplierId: string) => supplierNamesMap.get(supplierId) ?? '-'

    return [supplierIds, getSupplierName]
  }, [availableSuppliers])

  return (
    <Autocomplete
      multiple
      disableListWrap
      name="suppliers"
      disabled={!active}
      className={classes.supplierSelection}
      label={t`admin.account.label.supplier_assignment`}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={supplierIds}
      getOptionLabel={(supplierId) => getSupplierName(supplierId)}
      renderOption={(supplierId) => <Typography noWrap>{getSupplierName(supplierId)}</Typography>}
      data-testid="supplier-selection"
      ListboxProps={{
        'data-testid': 'supplier-selection-menu',
      }}
    />
  )
}

export default SupplierSelection
