import React, { useMemo } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import InfiniteScrolling from 'components/InifiniteScrolling'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors, SupplierFeedbackData } from 'store/supplierFeedback'
import { FeedbackListItem, SingleFeedbackRowData } from './FeedbackListItem'
import classes from './FeedbackList.module.scss'

export const gutter = 30
export const estimatedItemSize = 486 + gutter

interface ListProps {
  canBeResolved?: boolean
}

const getItemKey = (entry: SupplierFeedbackData) => entry.id

export default function FeedbackList(props: ListProps) {
  const feedbackArticles = useSelector(selectors.feedbackList)
  const boundActions = useBoundActions(actions)
  const { promiseInProgress: loadFeedbackInProgress } = usePromiseTracker({
    area: 'feedbackLoadIndicator',
  })

  const entryProps = useMemo(
    (): SingleFeedbackRowData => ({
      onResolveItem: props.canBeResolved ? boundActions.removeSingleSupplierFeedback : undefined,
      isInProgressIndicator: 'feedbackActionRunningIndicator',
    }),
    [boundActions.removeSingleSupplierFeedback, props.canBeResolved]
  )

  return (
    <InfiniteScrolling
      className={classes.feedbackListContainer}
      entries={feedbackArticles}
      entryProps={entryProps}
      Component={FeedbackListItem}
      margin={gutter}
      requestInProgress={loadFeedbackInProgress}
      estimateHeight={estimatedItemSize}
      itemKey={getItemKey}
      emptyListMessage={t`feedback.dashboard.hint.no_feedback_found`}
    />
  )
}
