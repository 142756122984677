import type { ThunkAction } from 'redux-thunk'
import type { ArticleOverviewAction } from 'store/articleOverview'
import type { GalleryPageAction } from 'store/galleryPage'
import type { ImageReviewsAction } from 'store/imageReviews'
import type { RootState } from 'store/reducers'
import type { UserProfileAction } from './reducer'

import { translateErrorResponse } from 'api/errors'
import userProfileApi from 'api/userProfile'
import { actions as articleOverviewActions } from 'store/articleOverview'
import { actions as galleryPageActions } from 'store/galleryPage'
import { actions as imageReviewActions } from 'store/imageReviews'
import { getApplicationLocale, loadAndActivateLocale } from 'utils/localizations/i18nUtil'
import * as types from './actionTypes'
import selectors from './selectors'

const subscribedUsersStorageKey = 'subscribedUsers'

type UserProfileThunkAction<T = void> = ThunkAction<
  T,
  RootState,
  null,
  UserProfileAction | ArticleOverviewAction | ImageReviewsAction | GalleryPageAction
>

export const fetchUserProfile = (): UserProfileThunkAction => {
  return (dispatch, getState) => {
    userProfileApi.fetchUserProfile().then(
      (response) => {
        const { data } = response
        const subscribedUsersInStorage = window.localStorage.getItem(subscribedUsersStorageKey)
        const subscribedUsers: string[] = subscribedUsersInStorage
          ? JSON.parse(subscribedUsersInStorage)
          : []

        dispatch({
          type: types.SET_PROFILE,
          payload: { ...data, subscribedUsers },
        })

        const userLanguage = data.language ?? getApplicationLocale()
        loadAndActivateLocale(userLanguage)
        const totalSubscribedBrands = selectors.totalSubscribedBrands(getState())
        dispatch(articleOverviewActions.setFilter({ brandIds: totalSubscribedBrands }))
        dispatch(imageReviewActions.setFilter({ brandIds: totalSubscribedBrands }))
        dispatch(galleryPageActions.setFilter({ brandIds: totalSubscribedBrands }))
        dispatch({ type: types.USER_PROFILE_LOADED, payload: {} })
      },
      (error) => {
        const errorResponse = translateErrorResponse(error)
        dispatch({ type: types.USER_PROFILE_LOAD_ERROR, payload: errorResponse })
      }
    )
  }
}

export interface UpdateBrandSubscriptionsPayload {
  brandIds?: string[]
  subscribedUsers?: string[]
}

export const updateBrandSubscriptions = (
  brandIds?: string[],
  subscribedUsers?: string[]
): UserProfileThunkAction => {
  return (dispatch, getState) => {
    if (brandIds || subscribedUsers) {
      dispatch({
        type: types.UPDATE_BRAND_SUBSCRIPTIONS,
        payload: { brandIds, subscribedUsers },
      })
    }

    if (subscribedUsers) {
      window.localStorage.setItem(subscribedUsersStorageKey, JSON.stringify(subscribedUsers))
    }

    if (brandIds) {
      userProfileApi.setBrandSubscriptions(brandIds)
    }

    if (brandIds || subscribedUsers) {
      const totalSubscribedBrands = selectors.totalSubscribedBrands(getState())
      dispatch(articleOverviewActions.setFilter({ brandIds: totalSubscribedBrands }))
      dispatch(imageReviewActions.setFilter({ brandIds: totalSubscribedBrands }))
      dispatch(galleryPageActions.setFilter({ brandIds: totalSubscribedBrands }))

      const { pathname } = window.location
      if (pathname.indexOf('/images') === 0) {
        dispatch(imageReviewActions.fetchImageReviews())
      } else if (pathname.indexOf('/overview') === 0) {
        dispatch(articleOverviewActions.fetchArticleOverview(0))
      }
    }
  }
}
