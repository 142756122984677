import { t } from '@lingui/macro'

import { AvailableInStockIcon, OutOfStockIcon } from './index'
import classes from './StockStatusIndicator.module.scss'

export default function StockStatusIndicator(props: {
  isInStock: boolean | null
  useSpecialText: boolean
}) {
  if (props.isInStock === null) {
    return (
      <span className={classes.stockStatusUnknownText} data-testid="stockStatusIndicator.unknown">
        {t`image.review.article.headline.unknown_stock_status`}
      </span>
    )
  }

  return props.isInStock ? (
    <AvailableInStockIcon useSpecialText={props.useSpecialText} />
  ) : (
    <OutOfStockIcon />
  )
}
