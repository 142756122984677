import React from 'react'
import { t } from '@lingui/macro'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core'

import DateUtils from 'utils/converter/DateUtils'
import useSharedFilterStyle from './hooks/useSharedFilterStyle'

interface PrioGoLiveFilterProps {
  disabled?: boolean | undefined
  goLiveFrom: string
  goLiveTo: string
  onChange: (goLiveFrom: string, goLiveTo: string) => void
  'data-testid'?: string
}

const emptyGoLiveDateFilter = { goLiveFrom: '', goLiveTo: '' }
const prioGoLiveDateFilter = {
  goLiveFrom: DateUtils.todayAddWeeks(-2),
  goLiveTo: DateUtils.todayAddWeeks(6),
}

export const PrioGoLiveFilter: React.FC<PrioGoLiveFilterProps> = ({
  disabled,
  goLiveFrom,
  goLiveTo,
  onChange,
  'data-testid': dataTest = 'prioGoLiveFilter',
}) => {
  const globalClasses = useSharedFilterStyle()
  const checked =
    goLiveFrom === prioGoLiveDateFilter.goLiveFrom && goLiveTo === prioGoLiveDateFilter.goLiveTo
  const handleChange = () => {
    const newFilter = checked ? emptyGoLiveDateFilter : prioGoLiveDateFilter
    onChange(newFilter.goLiveFrom, newFilter.goLiveTo)
  }

  return (
    <div className={globalClasses.filterFieldRoot}>
      <div>
        <FormControl margin="dense" title={t`filter.tooltip.prio_date_switch`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
              />
            }
            className={globalClasses.filterSwitch}
            label={t`filter.label.prio_date_switch`}
            labelPlacement="end"
            data-testid={dataTest}
          />
        </FormControl>
      </div>
    </div>
  )
}

export default PrioGoLiveFilter
