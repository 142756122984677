import { useEffect, useState } from 'react'

const intervalTime = 200

export default function useTimedMessage(
  startTimedMessage: boolean,
  duration = 5000
): [boolean, number] {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    if (startTimedMessage) {
      setShowMessage(true)
      let elapsedTime = 0
      const intervalId = setInterval(() => {
        elapsedTime += intervalTime
        setProgress(Math.round((elapsedTime * 100) / duration))
        if (elapsedTime >= duration) {
          clearInterval(intervalId)
          setTimeout(() => setShowMessage(false), 500)
        }
      }, intervalTime)

      return () => {
        clearInterval(intervalId)
        setShowMessage(false)
      }
    }
  }, [startTimedMessage, duration])

  return [showMessage, progress]
}
