import React from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { t } from '@lingui/macro'
import {
  Backdrop,
  DialogContentText,
  FormControlProps,
  MenuListProps,
  TextFieldProps,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ValidationErrors } from 'final-form'
import { Radios, Select, Switches } from 'mui-rff'

import { BrandDto, RoleDto, RoleId, SupplierDto } from 'api/types'
import AlertMessageList from 'components/AlertMessageList/AlertMessageList'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import { TypedMessage } from 'hooks/useMessageTextCollection'
import { colors } from 'style/douglas-materialui-theme'
import { availableTenants } from 'utils/tenants'
import BrandSelection from '../BrandSelection'
import { EmailTextField, FullNameTextFields } from '../TextField'
import SupplierSelection from './SupplierSelection'

const useStyles = makeStyles({
  disabledTextField: {
    '& input': {
      color: colors.black,
    },
  },
  selectionRadioIcon: {
    color: colors.originalMint,
    width: '1.25rem',
    height: '1.25rem',
    borderRadius: '50%',
    boxShadow: `inset 0 0 0 2px ${colors.checkboxBorder}, inset 0 -2px 0 ${colors.checkboxBorder}`,
  },
  checkedRadioIcon: {
    color: colors.originalMint,
    width: '1.25rem',
    height: '1.25rem',
    borderRadius: '50%',
    boxShadow: `inset 0 0 0 2px ${colors.originalMint}, inset 0 -2px 0 ${colors.originalMint}`,
  },
  selection: {
    marginTop: '1.5rem',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
  },
})

export interface UserFormValues {
  userId?: string
  email: string
  firstName: string
  lastName: string
  role: RoleId
  tenants: string[]
  hasNoBrandRestriction: boolean
  brands: string[]
  suppliers: string[]
}

interface UserFormProps {
  existingUser: boolean
  initialValues: UserFormValues
  availableRoles: RoleDto[]
  availableBrands: BrandDto[]
  availableSuppliers: SupplierDto[]
  alerts: TypedMessage[]
  promiseInProgress: boolean
  isServiceAccount: boolean
  validate?: (values: UserFormValues) => ValidationErrors
  onSubmit: (values: UserFormValues) => ValidationErrors | undefined | void
  onCancel: () => void
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const classes = useStyles()
  const { initialValues, existingUser, availableBrands, availableSuppliers } = props
  const commonTextFieldProps: TextFieldProps = {
    margin: 'dense',
    variant: 'outlined',
    disabled: existingUser,
    'aria-readonly': existingUser,
    required: !existingUser,
    className: existingUser ? classes.disabledTextField : '',
  }

  return (
    <Form
      onSubmit={props.onSubmit}
      validate={props.validate}
      validateOnBlur={true}
      initialValues={initialValues}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} id="user-form" data-testid="user-form">
          <DialogContentText>
            {existingUser
              ? t`admin.edit.account.user.intro.text`
              : t`admin.create.account.user.intro.text`}
          </DialogContentText>
          <Backdrop className={classes.backdrop} open={props.promiseInProgress}>
            <DataLoadingIndicator />
          </Backdrop>
          <AlertMessageList messages={props.alerts} />
          {!props.isServiceAccount ? (
            <React.Fragment>
              <FullNameTextFields {...commonTextFieldProps} data-testid="name" />
              <EmailTextField {...commonTextFieldProps} data-testid="email" />
              <Radios
                label={t`admin.account.label.user_role`}
                name="role"
                data={props.availableRoles.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
                radioGroupProps={{ row: true, 'aria-label': 'userRole' }}
                disableRipple
                checkedIcon={<CheckCircleIcon className={classes.checkedRadioIcon} />}
                icon={<span className={classes.selectionRadioIcon} />}
                formControlProps={
                  {
                    className: classes.selection,
                    'data-testid': 'role-selection',
                    // eslint-disable-next-line @typescript-eslint/ban-types
                  } as Partial<FormControlProps<'div', {}>>
                }
                inputProps={{ 'aria-label': 'decorative checkbox' }}
              />
            </React.Fragment>
          ) : null}
          <Select
            name="tenants"
            multiple
            required
            aria-required
            label={t`admin.account.label.tenant_assignment`}
            data={availableTenants.map((tenant) => ({
              value: tenant.key,
              label: tenant.label,
            }))}
            formControlProps={
              {
                className: classes.selection,
                'data-testid': 'tenant-selection',
                // eslint-disable-next-line @typescript-eslint/ban-types
              } as Partial<FormControlProps<'div', {}>>
            }
            MenuProps={{
              'data-testid': 'tenant-selection-menu',
              MenuListProps: {
                'data-testid': 'tenant-selection-item',
              } as Partial<MenuListProps>,
            }}
          />
          <SupplierSelection
            active={values.role === 'CONTACT_PERSON' || props.isServiceAccount}
            availableSuppliers={availableSuppliers}
          />
          {!props.isServiceAccount ? (
            <Switches
              name="hasNoBrandRestriction"
              label={t`admin.edit.account.user.label.brand_restriction`}
              data={{ label: t`admin.edit.account.user.label.all_brands`, value: true }}
              color="primary"
              formControlProps={{
                className: classes.selection,
              }}
              data-testid="brand-restriction"
            />
          ) : null}
          <BrandSelection availableBrands={availableBrands} fullWidth />
          <OnChange name="role">
            {(value) => {
              if (value === 'CONTACT_PERSON') {
                form.change('hasNoBrandRestriction', false)
              }
            }}
          </OnChange>
        </form>
      )}
    />
  )
}

export default UserForm
