import { UserDto, UserOptionsDto } from 'api/adminUser'
import { MessageLevel } from 'hooks/useMessageTextCollection'
import { createReducer } from 'store/utils'
import * as types from './actionTypes'
import initialState, { AdminUsersState } from './initialState'

type Handler<T = void> = (state: AdminUsersState, payload: T) => AdminUsersState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.SET_USERS]: ((state, users): AdminUsersState => ({
    ...state,
    users,
  })) as Handler<UserDto[]>,

  [types.CLEAN_ALERT_MESSAGES]: ((state): AdminUsersState => ({
    ...state,
    alertMessages: [],
  })) as HandlerWithoutPayload,

  [types.ADD_ERRORS]: ((state, errors): AdminUsersState => ({
    ...state,
    alertMessages: state.alertMessages.concat(
      errors.map((message) => ({ msgType: MessageLevel.Error, message }))
    ),
  })) as Handler<string[]>,

  [types.CHANGE_PAGE]: ((state, page): AdminUsersState => ({
    ...state,
    page,
  })) as Handler<number>,

  [types.SET_SEARCH_TERM]: ((state, searchTerm): AdminUsersState => ({
    ...state,
    searchTerm,
    page: 1,
  })) as Handler<string>,

  [types.SET_USER_OPTIONS]: ((state, payload): AdminUsersState => ({
    ...state,
    ...payload,
  })) as Handler<UserOptionsDto>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type AdminUsersAction = Actions[keyof Actions]

export default createReducer<AdminUsersState, AdminUsersAction>(initialState, handlers)
