import { trackPromise } from 'react-promise-tracker'

import backendRequest from '../backendRequest'
import { MessageDto } from '../types'
import { ImageGalleryFeedbackListDto } from './supplierFeedbackTypes'

export function fetchSupplierFeedback(onlyResolved: boolean, waitingProgressArea?: string) {
  const url = `/image-gallery-feedback?resolved=${onlyResolved}`

  return trackPromise(backendRequest().get<ImageGalleryFeedbackListDto>(url), waitingProgressArea)
}

export function resolveSupplierFeedback(feedbackId: string, waitingProgressArea?: string) {
  const url = `/image-gallery-feedback/${feedbackId}/resolve`
  return trackPromise(backendRequest().post<MessageDto>(url), waitingProgressArea)
}
