import { Fragment, ReactElement, useEffect } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Box } from '@material-ui/core'

import DataLoadingIndicator from 'components/DataLoadingIndicator'
import useNavigationWarning from 'hooks/useNavigationWarning'
import { actions, selectors } from 'store/variantDetail'
import GalleriesOfTenants from './components/GalleriesOfTenants'
import VariantDetailMessages from './components/VariantDetailMessages'
import VariantDetailSubmitNotification from './components/VariantDetailSubmitNotification'
import VariantImageSuggestions from './components/VariantImageSuggestions'
import VariantMasterData from './components/VariantMasterData'
import VariantWithNoGalleriesHint from './components/VariantWithNoGalleriesHint'
import classes from './VariantDetailPage.module.scss'

interface QueryParams {
  articleNumber?: string
}

interface MatchParams {
  params: QueryParams
}

export default function VariantDetailPage(props: { match: MatchParams }): ReactElement {
  const { params } = props.match
  const dispatch = useDispatch()
  const { promiseInProgress: loadGalleriesInProgress } = usePromiseTracker({
    area: 'galleriesLoadArea',
  })
  const articleNumber = useSelector(selectors.articleNumber)
  const hasChanged = useSelector(selectors.changesMade)

  useNavigationWarning(hasChanged)

  useEffect(() => {
    if (params.articleNumber) {
      dispatch(actions.cleanNotifications())
      dispatch(actions.fetchVariantDetails(params.articleNumber, 'galleriesLoadArea'))
    }
  }, [dispatch, params.articleNumber])

  return (
    <Box data-testid="variant-detail-root">
      {!params.articleNumber ? (
        <h1 className={classes.missingArticleHint}>
          <Trans id="variantdetails.images.hint.missing_article_number">
            Please provide article no. or EAN in search field on the left side to enter its details.
          </Trans>
        </h1>
      ) : null}
      <VariantDetailMessages />
      <VariantDetailSubmitNotification />
      <VariantMasterData />
      <VariantWithNoGalleriesHint isDataLoading={!articleNumber || loadGalleriesInProgress} />

      {loadGalleriesInProgress ? (
        <DataLoadingIndicator />
      ) : (
        <Fragment>
          <VariantImageSuggestions />
          <GalleriesOfTenants />
        </Fragment>
      )}
    </Box>
  )
}
