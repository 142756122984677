import React, { useState } from 'react'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import classNames from 'classnames'

import styles from './GalleryAccordion.module.scss'

interface GalleryAccordionProps {
  itemCount: number
  children: React.ReactNode
  isVisible?: boolean
  title: string
  trash?: boolean
  'data-count-testid'?: string
  'data-headline-testid'?: string
  className?: string
}

const GalleryAccordion: React.FC<GalleryAccordionProps> = (props) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const handleToggleClick = () => setOpen(!isOpen)

  return typeof props.isVisible !== 'boolean' || props.isVisible ? (
    <React.Fragment>
      <div
        className={classNames(styles.rootContainer, props.className)}
        data-testid={props['data-headline-testid'] ?? 'galleryAccordion'}
        onClick={handleToggleClick}
      >
        {props.trash ? <DeleteOutlineOutlinedIcon fontSize="small" /> : null}
        {props.title} (<span data-testid={props['data-count-testid']}>{props.itemCount}</span>)
        <div className={styles.columnHeaderActions}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {isOpen ? <div className={styles.content}>{props.children}</div> : null}
    </React.Fragment>
  ) : null
}

export default GalleryAccordion
