import type {
  ArticleOverviewDto,
  ArticleOverviewFiltersDto,
  ArticleOverviewSearchForm,
  BulkSetBlockedArticlesForm,
} from './articleOverviewTypes'

import backendRequest from 'api/backendRequest'
import { ArticleOverviewFilter } from 'store/articleOverview'
import DateUtils from 'utils/converter/DateUtils'
import { ArticleBlockDto } from './articleOverviewTypes'

export function fetchArticleOverviewFilters() {
  return backendRequest().get<ArticleOverviewFiltersDto>('image/reviews/filter')
}

function processFilterList(value: string, separator: string | RegExp): string[] {
  return value
    ? value
        .split(separator)
        .map((x) => x.trim().toLocaleUpperCase())
        .filter(Boolean)
    : []
}

function processEanOrArticleFilter(value: string): string[] {
  return processFilterList(value, /[\s,]+/)
}

function processBrandLinesFilter(value: string): string[] {
  return processFilterList(value, ',')
}

function processDepotNumberFilter(depotText: string): string[] {
  return processFilterList(depotText, /[\s,]+/)
}

export function fetchArticleOverview(filter: ArticleOverviewFilter, page = 0) {
  const form: ArticleOverviewSearchForm = {
    articleNumbers: processEanOrArticleFilter(filter.articleNumbersAndEans),
    articleNumbersAndEans: processEanOrArticleFilter(filter.articleNumbersAndEans),
    depotNumbers: processDepotNumberFilter(filter.depotNumber),
    brands: filter.brandIds,
    supplierIds: filter.supplierIds,
    brandLines: processBrandLinesFilter(filter.brandLines),
    onlineReady: filter.onlineReady,
    inStock: filter.inStock,
    hasSuggestedGalleries: filter.hasSuggestions,
    blocked: filter.blocked,
    goLiveDateFrom: DateUtils.uiDateStringToApiDateString(filter.goLiveFrom),
    goLiveDateTo: DateUtils.uiDateStringToApiDateString(filter.goLiveTo),
    page,
    size: 20,
  }
  return backendRequest().post<ArticleOverviewDto>('/article/overview', form)
}

export function setArticleBlocked(articleNumber: string, isBlocked: boolean) {
  return backendRequest().post(
    `/article/${articleNumber}/${isBlocked ? 'block' : 'unblock'}-article`
  )
}

export function setArticleBlockedBulk(articleNumbers: string[], isBlocked: boolean) {
  const request: BulkSetBlockedArticlesForm = {
    articleNumbers,
  }
  return backendRequest().post<ArticleBlockDto>(
    `/article/${isBlocked ? 'block' : 'unblock'}-for-multiple-articles`,
    request
  )
}
