import React from 'react'
import { DragPreviewOptions, useDrag, useDrop } from 'react-dnd'

import { ImagePosition, ImageState } from 'api/types'
import ImageSlot from 'components/ImageSlot'
import { ImageGalleryEntry } from 'types/image'
import { getImageInPosition, updateImageInGallery } from 'utils/imageGallery'
import { useImagePermissions } from './hooks'
import ImageCard from './ImageCard'
import { ImageGalleryProps } from './ImageGallery'

export interface DraggableImageSlotProps extends ImageGalleryProps {
  imagePosition: ImagePosition
}

export const DraggableImageSlot: React.FC<DraggableImageSlotProps> = (props) => {
  const { tenantGallery, imagePosition } = props
  const image = getImageInPosition(tenantGallery, imagePosition, props.visibleGalleries)
  const { canRemove, canReplace } = useImagePermissions(
    tenantGallery,
    imagePosition,
    props.canUpdateGallery
  )
  const droppableType = canReplace && props.droppableType ? props.droppableType : 'nothing'
  const draggableType = canRemove && props.draggableType ? props.draggableType : undefined
  const [collectedProps, drop] = useDrop({
    accept: droppableType,
    drop: (droppedItem: ImageGalleryEntry, monitor) => {
      if (props.onGalleryChange) {
        const target: ImageGalleryEntry = {
          galleryName: props.galleryName,
          image: droppedItem.image,
          position: imagePosition,
        }
        props.onGalleryChange(
          updateImageInGallery(droppedItem, target, tenantGallery.galleries),
          droppedItem
        )
      }
    },
    canDrop: (item: ImageGalleryEntry) => {
      if (typeof props.canDrop === 'function') {
        return props.canDrop(item, props.galleryName, image)
      } else if (typeof props.canDrop === 'boolean') {
        return props.canDrop
      }
      return Boolean(droppableType) && item.galleryName === props.galleryName
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop() && monitor.getItem() !== null,
      isOver: monitor.isOver(),
    }),
  })
  const [collected, drag, preview] = useDrag({
    type: draggableType ?? 'cannot-drag-type',
    item: {
      image: image,
      galleryName: props.galleryName,
      position: props.imagePosition,
    } as ImageGalleryEntry,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: () => Boolean(draggableType && image && image?.state !== ImageState.INHERITED),
    previewOptions: { anchorX: 1, anchorY: 1 } as DragPreviewOptions,
  })

  return (
    <ImageSlot connectDropTarget={drop} imagePosition={imagePosition} imageId={image?.imageId}>
      <ImageCard
        ref={collected.isDragging ? preview : drag}
        image={image}
        isOver={collectedProps.isOver}
        isDropAllowed={collectedProps.canDrop}
        {...props}
      />
    </ImageSlot>
  )
}

export default DraggableImageSlot
