import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from '@lingui/macro'
import { IconButton, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CloudUpload } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(1) },
  message: { width: '100%' },
  innerAlert: {
    padding: theme.spacing(2, 3),
  },
  buttonContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    position: 'relative',
    bottom: '1px',
    marginLeft: theme.spacing(0.5),
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
}))

export interface FeedbackElement {
  positionLabel: string
  feedback: string
}

export const isFeedbackElement = (
  element: FeedbackElement | undefined | null
): element is FeedbackElement =>
  typeof element?.positionLabel === 'string' && element?.feedback === 'string'

export interface FeedbackProps {
  rejectedImages?: (string | number)[]
  feedback?: (string | FeedbackElement)[]
  rejectedAll?: boolean
  reupload?: boolean
  expanded?: boolean
}

export default function FeedbackHint(props: FeedbackProps) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(props.expanded || false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  function buildTitle() {
    const title = props.rejectedAll
      ? t`feedback.hint.all_images_rejected`
      : t`feedback.hint.some_images_rejected`
    return (
      <span data-testid="feedback-summary-substitution-info">
        {props.reupload ? `${title} ${t`feedback.hint.reupload`}` : title}
      </span>
    )
  }

  function buildRejectedImagesLabel() {
    if (props.rejectedImages && props.rejectedImages.length) {
      return props.rejectedImages.join(', ')
    }
  }

  function buildSubtitle() {
    return props.rejectedAll ? (
      t`feedback.hint.all_images_not_applied`
    ) : (
      <span>
        {t`feedback.hint.some_images_not_applied`}: <i>{buildRejectedImagesLabel()}</i>
      </span>
    )
  }

  function feedbackExists() {
    return props.feedback && props.feedback.length > 0
  }

  function renderMoreButton() {
    if (feedbackExists()) {
      return (
        <Button
          color="inherit"
          size="small"
          endIcon={<ExpandMoreIcon />}
          onClick={handleExpandClick}
        >
          {t`feedback.button.details`}
        </Button>
      )
    }
  }

  function renderGoToUploadButton() {
    if (props.reupload) {
      return (
        <Tooltip title={t`feedback.button.upload.tooltip`} placement="top">
          <IconButton
            color="primary"
            size="small"
            className={classes.uploadButton}
            component={Link}
            to="/bulkupload"
          >
            <CloudUpload />
          </IconButton>
        </Tooltip>
      )
    }
  }

  function mapFeedback() {
    return (
      props.feedback &&
      props.feedback.map((element, i) => {
        return typeof element === 'string' ? (
          <ListItem dense key={i}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <Typography>{element}</Typography>
          </ListItem>
        ) : (
          <ListItem dense key={i}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <Typography>
              <strong>{element.positionLabel}</strong>: {element.feedback}
            </Typography>
          </ListItem>
        )
      })
    )
  }

  function renderGivenFeedback() {
    if (feedbackExists()) {
      return (
        <Container>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Alert
              severity={variant()}
              icon={false}
              variant="outlined"
              className={classes.innerAlert}
            >
              <Typography>{t`feedback.hint.reasons_for_rejection`}</Typography>
              <List>{mapFeedback()}</List>
            </Alert>
          </Collapse>
        </Container>
      )
    }
  }

  const variant = () => {
    return props.reupload ? 'error' : 'info'
  }
  return (
    <Alert severity={variant()} className={classes.root} classes={{ message: classes.message }}>
      <AlertTitle>
        {buildTitle()}
        {renderGoToUploadButton()}
      </AlertTitle>
      <Typography data-testid="feedback-summary-headline-text">{buildSubtitle()}</Typography>
      {renderMoreButton()}
      {renderGivenFeedback()}
    </Alert>
  )
}
