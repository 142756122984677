import React from 'react'
import { makeStyles, Tooltip, Typography } from '@material-ui/core'

interface EntryProps {
  label?: string
  tooltipTitle: string
  icon: React.ReactChild
  'data-testid'?: string
}

const useStyles = makeStyles((theme) => ({
  accountAdditionsEntry: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  gapLeft: {
    marginLeft: theme.spacing(0.5),
  },
}))

export default function HeadlineSubEntry(props: EntryProps) {
  const { label, tooltipTitle, icon } = props
  const classes = useStyles()

  return label ? (
    <div className={classes.accountAdditionsEntry}>
      <Tooltip title={tooltipTitle} placement="top">
        <span>{icon}</span>
      </Tooltip>
      <Typography className={classes.gapLeft} data-testid={props['data-testid']}>
        {label}
      </Typography>
    </div>
  ) : null
}
