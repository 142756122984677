import React from 'react'
import { Button } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'

import classes from './ItemTransferList.module.scss'

interface ItemTransferButtonsProps {
  onTransferLeftClick: () => void
  onTransferRightClick: () => void
}

const ItemTransferButtons: React.FC<ItemTransferButtonsProps> = (props) => {
  return (
    <div className={classes.buttons}>
      <Button variant="outlined" onClick={props.onTransferLeftClick} data-testid="transfer-left">
        <ArrowBack />
      </Button>
      <Button variant="outlined" onClick={props.onTransferRightClick} data-testid="transfer-right">
        <ArrowForward />
      </Button>
    </div>
  )
}

export default ItemTransferButtons
