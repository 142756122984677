//import './wdyr'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CssBaseline, StylesProvider } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import { store } from 'store'
import theme from 'style/douglas-materialui-theme'
import App from 'views/App/App'
import 'index.scss'
import 'style/douglas-style.scss'

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

declare const window: Window &
  typeof globalThis & {
    Cypress: unknown
    store: typeof store
  }

if (window.Cypress) {
  window.store = store
}
