import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import style from './UnauthorizedError.module.scss'

export default function UnauthorizedError(): ReactElement {
  return (
    <h1 className={style.rootContainer}>
      <Trans id="error.text.unauthorized">Sorry, you are not allowed to be here.</Trans>
    </h1>
  )
}
