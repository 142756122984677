import React from 'react'
import { t } from '@lingui/macro'
import { Link } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import classes from './ImageGuidelineLink.module.scss'

const ImageGuidelineLink: React.FC = () => (
  <div className={classes.guidelineLink}>
    <OpenInNewIcon fontSize={'small'} className={classes.linkIcon} />
    <Link
      href={'https://ecom-data.douglas.de/'}
      target="_blank"
      rel="noopener"
      className={classes.inlineLink}
    >
      <span>{t`link.label.guideline_product_images`}</span>
    </Link>
  </div>
)

export default ImageGuidelineLink
