import { ReactElement } from 'react'
import { Box, CircularProgress, CircularProgressProps, SvgIcon } from '@material-ui/core'

const mainColor = '#F2F2F2'

export default function UploadProgressStateReady(props: CircularProgressProps): ReactElement {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} value={100} style={{ color: mainColor }} />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SvgIcon
          className="MuiCircularProgress-svg"
          viewBox="0 0 24 24"
          style={{ width: props.size, height: props.size }}
          htmlColor={mainColor}
        >
          <circle cx="12" cy="12" r="10.6" fill="none" strokeWidth="2" color="#F2F2F2" />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="11.6"
            fill="none"
            strokeWidth="1"
          />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="9.6"
            fill="none"
            strokeWidth="1"
          />
        </SvgIcon>
      </Box>

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.375 9.53198L10 3.90698L15.625 9.53198"
            stroke="black"
            strokeWidth="2.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 4.68799V16.0942"
            stroke="black"
            strokeWidth="2.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    </Box>
  )
}
