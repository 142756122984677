import React from 'react'
import { plural, t } from '@lingui/macro'
import { Container, makeStyles, Paper, Typography } from '@material-ui/core'
import { LocalOffer, Person, SupervisedUserCircle } from '@material-ui/icons'

import { Role } from 'api/types'
import TenantFlagGroup from 'components/TenantFlag/TenantFlagGroup'
import textEnumeration from 'utils/textEnumeration'
import HeadlineSubEntry from './HeadlineSubEntry'

export interface DashboardHeadlineProps {
  accountFullName: string
  assignedTenantIds: string[]
  accountRole?: string
  ownBrandLabels?: string[]
  subscribedUserNames?: string[]
  assignedSupplierLabels?: string[]
}

const useStyles = makeStyles((theme) => ({
  headlineRoot: {
    padding: `${theme.spacing(2)} 0`,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  headlineContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  accountAdditions: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  accountAdditionsEntry: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  gapLeft: {
    marginLeft: theme.spacing(0.5),
  },
}))

const roleMap = new Map<string, string>([
  [Role.ADMIN, 'Admin'],
  [Role.ANONYMOUS, 'Anonymous'],
  [Role.CONTACT_PERSON, 'Contact Person'],
  [Role.DOUGLAS, 'Master Data Manager'],
  [Role.DOUGLAS_COM, 'Douglas Commercial'],
])

function getBrandLabel(labels?: string[]) {
  if (Array.isArray(labels) && labels.length > 0) {
    return t({
      id: 'dashboard.headline.brands',
      message: plural(labels.length, {
        one: 'brand',
        other: 'brands',
      }),
    })
  }
}

export default function DashboardHeadline(props: DashboardHeadlineProps) {
  const classes = useStyles()

  return (
    <Paper className={classes.headlineRoot} data-testid="dashboard-headline">
      <Container maxWidth="lg" className={classes.headlineContainer}>
        <Typography variant="h3">{props.accountFullName}</Typography>
        <TenantFlagGroup tenantIds={props.assignedTenantIds} />
      </Container>
      <Container className={classes.accountAdditions}>
        <HeadlineSubEntry
          tooltipTitle={t`dashboard.headline.entry.title.role`}
          icon={<SupervisedUserCircle />}
          label={props.accountRole ? roleMap.get(props.accountRole) : roleMap.get(Role.ANONYMOUS)}
          data-testid="account-role"
        />
        <HeadlineSubEntry
          tooltipTitle={t`dashboard.headline.entry.title.subscribed_brands`}
          icon={<LocalOffer />}
          label={getBrandLabel(props.ownBrandLabels)}
        />
        <HeadlineSubEntry
          tooltipTitle={t`dashboard.headline.entry.title.subscribed_colleagues`}
          icon={<Person />}
          label={textEnumeration(props.subscribedUserNames, 2)}
        />
      </Container>
    </Paper>
  )
}
