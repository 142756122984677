import dayjs from 'dayjs'
import log from 'loglevel'
import prefix from 'loglevel-plugin-prefix'

prefix.reg(log)
prefix.apply(log, {
  timestampFormatter(date) {
    return dayjs(date).format('MM-DDTHH:mm:ss.SSS')
  },
})

const originalErrorMethod = log.error
const extendedErrorMethod = function (...msg: unknown[]) {
  // this code snippet is never called - why??
  originalErrorMethod(msg)
  ineum('reportError', msg)
}

log.error = extendedErrorMethod

log.setLevel(process.env.NODE_ENV === 'development' ? 'debug' : 'info')
export default log
