import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import ArticleImageGallery from 'components/ArticleImageGallery'
import { TenantGalleryCallbackProps } from 'components/ArticleImageGallery/ArticleImageGallery'
import InfiniteScrolling, { InfiniteScrollingRow } from 'components/InifiniteScrolling'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/galleryPage'
import { ArticleGalleries } from 'types'
import classes from './GalleryPage.module.scss'

const margin = 20
const estimatedItemSize = 332 + margin
const estimatedTenantAccordionSize = 48

const estimateHeight = (entry: ArticleGalleries) => {
  const tenantCount = entry.tenants.length

  return (
    estimatedItemSize +
    (tenantCount - 1) * estimatedTenantAccordionSize +
    (tenantCount > 1 ? margin : 0)
  )
}

const getItemKey = (entry: ArticleGalleries) => entry.articleNumber

const ImageReviewEntry: React.FC<
  InfiniteScrollingRow<ArticleGalleries, TenantGalleryCallbackProps>
> = (props) => {
  const { measureRef, entry, entryProps } = props

  return <ArticleImageGallery mode="gallery" ref={measureRef} article={entry} {...entryProps} />
}

const ArticleList: React.FC = () => {
  const articles = useSelector(selectors.articles)
  const isLastRequest = useSelector(selectors.isLastRequest)
  const requestInProgress = useSelector(selectors.requestInProgress)
  const visibleStartIndex = useSelector(selectors.visibleStartIndex)
  const canCopyImageGalleries = useSelector(selectors.canCopyImageGalleries)
  const boundActions = useBoundActions(actions)
  const entryProps = useMemo(
    () => ({
      onToggleArticleSelection: canCopyImageGalleries
        ? boundActions.toggleSelectedArticle
        : undefined,
      onGalleryChange: boundActions.updateImageGallery,
      onAddToPaperBin: boundActions.addToPaperBin,
      onRemoveFromPaperBin: boundActions.removeFromPaperBin,
      onApproveGallery: boundActions.approveGallery,
      onRejectGallery: boundActions.rejectGallery,
      onTenantNotificationExpired: boundActions.hideSuccessNotification,
    }),
    [boundActions, canCopyImageGalleries]
  )

  return (
    <InfiniteScrolling
      className={classes.list}
      entries={articles}
      entryProps={entryProps}
      Component={ImageReviewEntry}
      margin={margin}
      isLastRequest={isLastRequest}
      requestInProgress={requestInProgress}
      visibleStartIndex={visibleStartIndex}
      estimateHeight={estimateHeight}
      itemKey={getItemKey}
      onEndReached={boundActions.fetchMoreGalleryPage}
      onVisibleStartIndexChanged={boundActions.setVisibleStartIndex}
      emptyListMessage={t`gallerypage.emptyList`}
    />
  )
}

export default ArticleList
