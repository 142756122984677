import React, { useRef } from 'react'
import { t } from '@lingui/macro'
import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Clear } from '@material-ui/icons'
import classnames from 'classnames'

import useSharedFilterStyle from './hooks/useSharedFilterStyle'

interface DateFilterProps {
  label: string
  value: string
  disabled?: boolean
  maxDate?: Date
  onChange: (newDate: string) => void
  onSubmit: () => void
}

const useStyle = makeStyles((theme) => ({
  emptyDateField: {
    '& input[type="date"]:not(:focus)': {
      color: 'transparent',
    },
  },
}))

// Firefox 1.0+
const isFirefox = navigator.userAgent.indexOf('Firefox') > -1

const DeleteDateIcon = ({
  value,
  disabled,
  handleClickClear,
}: {
  value: string
  disabled?: boolean
  handleClickClear: () => void
}) => {
  const globalClasses = useSharedFilterStyle()
  return !isFirefox && value ? (
    <InputAdornment
      position="end"
      onClick={disabled ? undefined : handleClickClear}
      className={disabled ? globalClasses.clearFilterIconDisabled : globalClasses.clearFilterIcon}
      title={t`general.field.date.delete.tooltip`}
    >
      <Clear fontSize="small" />
    </InputAdornment>
  ) : null
}

const padZero = (value: number): string => (value < 10 ? '0' : '') + value.toString()

const convertDate = (date: Date): string =>
  [date.getFullYear(), padZero(date.getMonth() + 1), padZero(date.getDate())].join('-')

export const DateFilter: React.FC<DateFilterProps> = (props) => {
  const { value, disabled } = props
  const inputRef = useRef<HTMLInputElement>()
  const classes = useStyle()
  const globalClasses = useSharedFilterStyle()

  const dateClasses = (date: string): string =>
    classnames(globalClasses.filterFieldRoot, {
      [classes.emptyDateField]: date === '',
    })

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(event.target.value)

  const handleClickClear = () => {
    props.onChange('')
    props.onSubmit()
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      type="date"
      label={props.label}
      value={value}
      disabled={disabled}
      className={dateClasses(value)}
      inputRef={inputRef}
      InputProps={{
        readOnly: disabled,
        onKeyDown: handleKeyDown,
        endAdornment: <DeleteDateIcon {...{ value, disabled, handleClickClear }} />,
      }}
      inputProps={{
        max: props.maxDate ? convertDate(props.maxDate) : undefined,
      }}
      onBlur={props.onSubmit}
      onChange={handleDateChange}
    />
  )
}

export default DateFilter
