import React, { useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'

import ImageGallery from 'components/ImageGallery'
import TenantFlag from 'components/TenantFlag'
import { ImageGalleries } from 'types/image'
import { TenantGallery } from 'types/index'
import { GlobalTenantId } from 'utils/tenants'
import classes from './TenantImages.module.scss'

interface TenantImagesProps {
  tenantId: string | null
  tenantGallery?: TenantGallery
  visibleGalleries?: (keyof ImageGalleries)[]
  errors?: string[] | null
  severity?: 'warning' | 'error' | null
  alreadyCopied?: boolean
  'data-testid'?: string
}

export const TenantImages: React.FC<TenantImagesProps> = ({
  tenantId,
  tenantGallery,
  visibleGalleries,
  errors,
  severity,
  alreadyCopied,
  'data-testid': dataTest = 'tenantImages',
}) => {
  const [showAlertNotification, setShowAlertNotification] = useState(true)

  useEffect(() => {
    setShowAlertNotification(true)
  }, [tenantGallery])

  return (
    <div className={classes.root} data-testid={dataTest}>
      <div className={classes.flag}>
        <TenantFlag tenantId={tenantId || GlobalTenantId} />
        {errors && !alreadyCopied && !showAlertNotification ? (
          <div className={classes.alertIconContainer}>
            <IconButton
              size="small"
              className={clsx(classes.alertIcon, {
                [classes.error]: severity === 'error',
                [classes.warning]: severity === 'warning',
              })}
              onClick={() => setShowAlertNotification(true)}
            >
              {severity === 'error' ? <ErrorOutlineIcon /> : <ReportProblemOutlinedIcon />}
            </IconButton>
          </div>
        ) : null}
      </div>
      <div className={classes.status}>
        {tenantGallery ? (
          <ImageGallery
            disableMenu
            className={classes.imageGallery}
            tenantGallery={tenantGallery}
            galleryName={`galleryPage_tenant_${tenantId}`}
            visibleGalleries={visibleGalleries}
            canDrop={false}
          />
        ) : (
          <div className={classes.invalidArticle}>Invalid Article</div>
        )}
        {errors && !alreadyCopied && showAlertNotification ? (
          <div className={classes.alertContainer}>
            <Alert
              className={clsx(classes.alert, { [classes.warning]: severity === 'warning' })}
              severity={severity || 'error'}
              onClose={() => setShowAlertNotification(false)}
              data-testid={`${dataTest}.alert`}
              data-severity={severity}
            >
              {errors.map((error, idx) => (
                <React.Fragment key={idx}>
                  {error}
                  <br />
                </React.Fragment>
              ))}
            </Alert>
          </div>
        ) : null}
      </div>
    </div>
  )
}
