import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Tab, Tabs } from '@material-ui/core'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'

import { UserPermission } from 'api/types'
import SnackbarNotification from 'components/Snackbar/SnackbarNotification'
import { actions, selectors } from 'store/supplierFeedback'
import * as types from 'store/supplierFeedback/actionTypes'
import { selectors as profileSelectors } from 'store/userProfile'
import FeedbackList from './components/FeedbackList'
import classes from './SupplierFeedbackPage.module.scss'

const TabOpen = 'open'
const TabResolved = 'resolved'

export default function SupplierFeedbackPage() {
  const errors = useSelector(selectors.errors)
  const permissions = useSelector(profileSelectors.permissions)
  const dispatch = useDispatch()
  const [currentOpenTab, setCurrentOpenTab] = useState(TabOpen)

  const tabLabelOpen = t({ id: 'feedback.dashboard.tab.label.open', message: 'New Feedback' })
  const tabLabelResolved = t({
    id: 'feedback.dashboard.tab.label.resolved',
    message: 'Resolved Feedback',
  })

  useEffect(() => {
    dispatch(actions.fetchSupplierFeedback(currentOpenTab === TabResolved, 'feedbackLoadIndicator'))
  }, [dispatch, currentOpenTab])

  const handleTabChange = (event: ChangeEvent<unknown>, newValue: string) => {
    if (currentOpenTab !== newValue) {
      setCurrentOpenTab(newValue)
      dispatch({ type: types.RESET_FEEDBACK_LIST })
    }
  }

  return (
    <div className={classes.feedBackRoot}>
      <div>
        <Tabs
          value={currentOpenTab}
          indicatorColor="primary"
          textColor={'primary'}
          onChange={handleTabChange}
          aria-label="feedback list selection"
          className={classes.listTypeTabContainer}
        >
          <Tab
            value={TabOpen}
            label={tabLabelOpen}
            icon={<FeedbackOutlinedIcon />}
            data-testid="feedback-tab-open"
          />
          <Tab
            value={TabResolved}
            label={tabLabelResolved}
            icon={<AssignmentTurnedInOutlinedIcon />}
            data-testid="feedback-tab-resolved"
          />
        </Tabs>
      </div>
      <div className={classes.listTopContainer}>
        <FeedbackList
          canBeResolved={
            currentOpenTab === TabOpen &&
            permissions.has(UserPermission.RESOLVE_IMAGE_GALLERY_FEEDBACK)
          }
        />
      </div>
      {errors && (
        <SnackbarNotification severity={'error'} data-testid="feedback-snackbar">
          {errors.map((e, idx) => (
            <div key={idx}>{e}</div>
          ))}
        </SnackbarNotification>
      )}
    </div>
  )
}
