import type { FileRejection } from 'react-dropzone'

import React from 'react'
import { t } from '@lingui/macro'
import { Alert, AlertTitle } from '@material-ui/lab'

import classes from './BulkUserDialog.module.scss'

interface FileRejectionErrorProps {
  fileRejection: FileRejection
}

const FileRejectionError: React.FC<FileRejectionErrorProps> = ({ fileRejection }) => (
  <Alert severity="error" className={classes.warning}>
    <AlertTitle>{fileRejection.file.name}</AlertTitle>
    {fileRejection.errors.map((error) => (
      <div key={error.code}>{error.message}</div>
    ))}
  </Alert>
)

interface BulkUserFileErrorsProps {
  acceptedFiles: File[]
  fileRejections: FileRejection[]
}

const BulkUserFileErrors: React.FC<BulkUserFileErrorsProps> = (props) => {
  const { acceptedFiles, fileRejections } = props

  if (acceptedFiles.length > 1) {
    return (
      <Alert severity="error" className={classes.warning}>
        <AlertTitle>{t`admin.account.bulkuser.file_limit`}</AlertTitle>
      </Alert>
    )
  }
  if (fileRejections.length > 0) {
    return (
      <React.Fragment>
        {fileRejections.map((fileRejection) => (
          <FileRejectionError key={fileRejection.file.name} fileRejection={fileRejection} />
        ))}
      </React.Fragment>
    )
  }
  return null
}

export default BulkUserFileErrors
