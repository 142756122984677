export const SET_ARTICLES_AND_FILTER = 'galleryPage/SET_ARTICLES_AND_FILTER'
export const ADD_ARTICLES = 'galleryPage/ADD_ARTICLES'
export const SET_FILTER = 'galleryPage/SET_FILTER'
export const INITIATED_REQUEST = 'galleryPage/INITIATED_REQUEST'
export const FINISHED_REQUEST = 'galleryPage/FINISHED_REQUEST'
export const SET_VISIBLE_START_INDEX = 'galleryPage/SET_VISIBLE_START_INDEX'
export const RESET_FILTER = 'galleryPage/RESET_FILTER'
export const TOGGLE_SELECTED_ARTICLE = 'galleryPage/TOGGLE_SELECTED_ARTICLE'
export const TOGGLE_ALL_SELECTED_ARTICLES = 'galleryPage/TOGGLE_ALL_SELECTED_ARTICLES'
export const REPLACE_ARTICLE = 'galleryPage/REPLACE_ARTICLE'
export const UPDATE_TENANT = 'galleryPage/UPDATE_ARTICLE_TENANT'
export const IGNORE_FILTER_WARNING = 'galleryPage/IGNORE_FILTER_WARNING'
export const ADD_TO_PAPERBIN = 'galleryPage/ADD_TO_PAPERBIN'
export const REMOVE_FROM_PAPERBIN = 'galleryPage/REMOVE_FROM_PAPERBIN'
