import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button, Checkbox } from '@material-ui/core'

import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/bulkCopyGallery'
import { ArticleGalleries } from 'types/index'
import classes from './ArticleHeadline.module.scss'

interface ArticleHeadlineProps {
  article?: ArticleGalleries
}

export const ArticleHeadline: React.FC<ArticleHeadlineProps> = ({ article }) => {
  const disabledArticles = useSelector(selectors.disabledArticles)
  const { totalErrors } = useSelector(selectors.articleNumbersWithConflicts)
  const manuallyCopiedArticles = useSelector(selectors.manuallyCopiedArticles)
  const boundActions = useBoundActions(actions)
  const brandName = article?.tenants.find((tenant) => tenant.brand)?.brand
  const alreadyCopied = manuallyCopiedArticles.includes(article?.articleNumber ?? '')
  const canCopy = article && !alreadyCopied && !totalErrors.includes(article.articleNumber)

  return article ? (
    <div className={classes.root} data-testid="articleHeadline">
      <div className={classes.articleSummary}>
        <Checkbox
          color="primary"
          className={classes.selectionCheckbox}
          checked={!disabledArticles.has(article.articleNumber)}
          disabled={alreadyCopied}
          onClick={() => boundActions.toggleDisabledArticle(article.articleNumber)}
          data-testid="articleHeadline.articleSelection"
        />
        <div data-testid="articleHeadline.label">
          {t`general.artNr`} {article.articleNumber} - {brandName}
        </div>
      </div>
      {!alreadyCopied ? (
        <div>
          <Button
            variant="contained"
            disabled={!canCopy}
            onClick={() => boundActions.singleGalleryCopy(article.articleNumber)}
            data-testid="articleHeadline.copySingleGallery"
          >
            {t({
              id: 'galleryPage.bulkCopy.copyCurrentArticle',
              values: {
                0: article.articleNumber,
              },
            })}
          </Button>
        </div>
      ) : null}
    </div>
  ) : null
}
