import type { ItemTransferListWithStateProps } from './ItemTransferListWithState'

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'

import ItemTransferListWithState from './ItemTransferListWithState'

interface ItemTransferDialogProps extends ItemTransferListWithStateProps {
  title: string
  open: boolean
}

const ItemTransferDialog: React.FC<ItemTransferDialogProps> = (props) => {
  const { title, open, ...restProps } = props

  return (
    <Dialog maxWidth="md" open={props.open} onClose={props.onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <ItemTransferListWithState {...restProps} />
      </DialogContent>
    </Dialog>
  )
}

export default ItemTransferDialog
