import { Fragment } from 'react'
import { t } from '@lingui/macro'
import { SvgIcon } from '@material-ui/core'

import { ReactComponent as InStockIcon } from 'style/icons/prima-icon-instock.svg'
import classes from './StockStatusIndicator.module.scss'

export default function AvailableInStockIcon({ useSpecialText }: { useSpecialText: boolean }) {
  return (
    <Fragment>
      <SvgIcon className={classes.stockIconPositive} data-testid="availableInStockIcon">
        <InStockIcon />
      </SvgIcon>
      <span>
        {useSpecialText
          ? t`image.review.article.headline.available_in_stock_special`
          : t`image.review.article.headline.available_in_stock`}
      </span>
    </Fragment>
  )
}
