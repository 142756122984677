import type { ListChildComponentProps } from 'react-window'

import { useCallback, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { InputAdornment, Paper, TextField } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import classnames from 'classnames'

import { TransferItem } from './ItemTransferListWithState'
import classes from './ItemTransferList.module.scss'

export interface ItemTransferPanelProps {
  title: string
  items: string[]
  itemMap: Map<string, TransferItem>
  inputLabel: string
  autoFocus?: boolean
  'data-testid'?: string
  onClick: (value: string) => void
  onChange: (items: string[]) => void
}

export const TransferItemRow: React.FC<ListChildComponentProps> = ({ index, style, data }) => {
  const value = data.items[index]
  const handleClick = () => data.onClick(value)
  const handleEnter = () => data.onChangeHighlight(index)
  const transferItem = data.itemMap.get(value)

  return transferItem ? (
    <div
      style={style}
      className={classnames(classes.listItem, {
        [classes.highlight]: index === data.highlightedIndex,
      })}
      onClick={handleClick}
      onMouseEnter={handleEnter}
      data-testid="transfer-item"
    >
      <div>{transferItem.label}</div>
      {transferItem.detail ? <div className={classes.details}>{transferItem.detail}</div> : null}
    </div>
  ) : null
}

const ItemTransferPanel: React.FC<ItemTransferPanelProps> = (props) => {
  const { itemMap, items, onClick, onChange } = props
  const [filter, setFilter] = useState('')
  const [hasFocus, setHasFocus] = useState(false)
  const [highlightIndex, setHighlightIndex] = useState(-1)
  const filteredItems = useMemo(() => {
    const lowerCaseFilter = filter.toLocaleLowerCase()
    const filteredItems = !filter
      ? items
      : items.filter((item) => {
          const label = itemMap.get(item)?.label
          return label ? label.toLocaleLowerCase().indexOf(lowerCaseFilter) === 0 : false
        })
    onChange(filteredItems)
    return filteredItems
  }, [filter, items, itemMap, onChange])
  const handleFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(ev.target.value)
    setHighlightIndex(-1)
  }
  const updateHighlightedIndex = useCallback(
    (index: number) => {
      index = Math.min(filteredItems.length - 1, Math.max(0, index))
      setHighlightIndex(index)
    },
    [filteredItems]
  )
  const handleClick = useCallback(
    (value: string) => {
      if (value) {
        onClick(value)
      }
      updateHighlightedIndex(-1)
    },
    [onClick, updateHighlightedIndex]
  )
  const itemData = useMemo(
    () => ({
      itemMap,
      items: filteredItems,
      highlightedIndex: highlightIndex,
      onClick: handleClick,
      onChangeHighlight: updateHighlightedIndex,
    }),
    [itemMap, filteredItems, highlightIndex, updateHighlightedIndex, handleClick]
  )
  const inputProps = {
    onKeyDown: (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      switch (ev.key) {
        case 'ArrowDown':
          updateHighlightedIndex(highlightIndex + 1)
          break
        case 'ArrowUp':
          updateHighlightedIndex(highlightIndex - 1)
          break
        case 'PageDown':
          updateHighlightedIndex(highlightIndex + 10)
          break
        case 'PageUp':
          updateHighlightedIndex(highlightIndex - 10)
          break
        case 'Enter':
          handleClick(filteredItems[highlightIndex])
          break
      }
    },
    onFocus: () => setHasFocus(true),
    onBlur: () => setHasFocus(false),
    endAdornment: filter ? (
      <InputAdornment position="end">
        <Clear className={classes.clearFilter} onClick={() => setFilter('')} />
      </InputAdornment>
    ) : null,
  }

  return (
    <div className={classes.panel} data-testid={props['data-testid'] ?? 'itemTransferPanel'}>
      {props.title}
      <TextField
        autoFocus={props.autoFocus}
        variant="outlined"
        size="small"
        label={props.inputLabel}
        className={classes.filterInput}
        value={filter}
        onChange={handleFilterChange}
        InputProps={inputProps}
        data-testid="filter-input"
      />
      <Paper
        className={classnames(classes.paper, {
          [classes.highlightInactive]: !hasFocus,
        })}
        elevation={2}
      >
        <FixedSizeList
          width="100%"
          height={400}
          itemSize={27}
          itemCount={filteredItems.length}
          itemData={itemData}
        >
          {TransferItemRow}
        </FixedSizeList>
      </Paper>
    </div>
  )
}

export default ItemTransferPanel
