import axios, { AxiosInstance, ResponseType } from 'axios'

import keycloakClient from './keycloak/KeycloakClient'

const createRequest = (responseType: ResponseType = 'json'): AxiosInstance => {
  return axios.create({
    baseURL: '/api/public',
    responseType: responseType,
    timeout: 90000,
    headers: { Authorization: `Bearer ${keycloakClient.token}` },
  })
}

export default createRequest
