import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ArticleImageGallery from 'components/ArticleImageGallery'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/variantDetail'
import classes from './VariantImageSuggestions.module.scss'

const VariantImageSuggestions: React.FC = React.memo(() => {
  const boundActions = useBoundActions(actions)
  const imageSuggestions = useSelector(selectors.imageSuggestionsWithGlobalGallery)

  return imageSuggestions && imageSuggestions.tenants.length ? (
    <Accordion
      defaultExpanded
      className={classes.accordion}
      data-testid="pending-suggestions-panel"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Badge
          color="primary"
          badgeContent={imageSuggestions.tenants.length}
          className={classes.suggestionCountBadge}
          data-testid="pending-suggestions-count"
        >
          <Typography style={{ paddingRight: 5 }}>
            {t`variantdetails.imagereviews.pending`}
          </Typography>
        </Badge>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <ArticleImageGallery
          mode="variantDetailSuggestion"
          article={imageSuggestions}
          onGalleryChange={boundActions.updateSuggestionGallery}
          onApproveGallery={boundActions.approveSuggestionGallery}
          onRejectGallery={boundActions.rejectSuggestionGallery}
          onAddToPaperBin={boundActions.addToSuggestionPaperBin}
          onRemoveFromPaperBin={boundActions.removeFromSuggestionPaperBin}
        />
      </AccordionDetails>
    </Accordion>
  ) : null
})
VariantImageSuggestions.displayName = 'VariantImageSuggestions'

export default VariantImageSuggestions
