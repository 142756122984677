import { createReducer } from '../utils'
import * as types from './actionTypes'
import initialState, { SupplierFeedbackData, SupplierFeedbackState } from './initialState'
import {
  AddFeedbackListPayload,
  RemoveSingleFeedbackPayload,
  SetErrorListPayload,
} from './payloadTypes'

type Handler<T = void> = (state: SupplierFeedbackState, payload: T) => SupplierFeedbackState
type HandlerWithoutPayload = Handler<undefined>

const findFeedbackInList = (f: SupplierFeedbackData, list: SupplierFeedbackData[]) =>
  list.find((curFeedback) => curFeedback.id === f.id)

const createUniqueFeedbackList = (
  prevList: SupplierFeedbackData[],
  newList: SupplierFeedbackData[]
) => prevList.concat(newList.filter((newFeedback) => !findFeedbackInList(newFeedback, prevList)))

const handlers = {
  [types.INITIATE_FEEDBACK_LOAD]: ((previousState) => ({
    ...previousState,
    errors: null,
    requestInProgress: true,
  })) as HandlerWithoutPayload,
  [types.AFTER_FEEDBACK_LOAD]: ((previousState) => ({
    ...previousState,
    errors: null,
    requestInProgress: false,
  })) as HandlerWithoutPayload,
  [types.RESET_FEEDBACK_LIST]: ((previousState) => ({
    ...previousState,
    feedbackList: [],
  })) as HandlerWithoutPayload,
  [types.ADD_FEEDBACK_LIST]: ((previousState, payload) => ({
    ...previousState,
    feedbackList: createUniqueFeedbackList(previousState.feedbackList, payload.feedbackList),
    hasMore: payload.hasMore,
    requestInProgress: false,
  })) as Handler<AddFeedbackListPayload>,
  [types.REMOVE_SINGLE_FEEDBACK]: ((previousState, payload) => ({
    ...previousState,
    feedbackList: previousState.feedbackList.filter((f) => f.id !== payload.feedbackId),
  })) as Handler<RemoveSingleFeedbackPayload>,
  [types.SET_ERROR_LIST]: ((previousState, payload) => ({
    ...previousState,
    requestInProgress: false,
    errors: payload,
  })) as Handler<SetErrorListPayload>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload?: Parameters<typeof handlers[T]>[1]
  }
}
export type SupplierFeedbackAction = Actions[keyof Actions]
export default createReducer<SupplierFeedbackState, SupplierFeedbackAction>(initialState, handlers)
