import type { ArticleRow } from 'store/articleOverview/initialState'

import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import {
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { UserPermission } from 'api/types'
import { VariantDetailLink } from 'components/Links'
import StockStatusIndicator from 'components/StockStatusIndicator'
import { actions } from 'store/articleOverview'
import { selectors as profileSelectors } from 'store/userProfile'
import { isGlobalTenant } from 'utils/tenants'
import classes from './ArticleOverviewTable.module.scss'

interface ArticleTableRowProps {
  article: ArticleRow
}

const ArticleTableRow: React.FC<ArticleTableRowProps> = ({ article }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const brandLabels = useSelector(profileSelectors.brandLabels)
  const permissions = useSelector(profileSelectors.permissions)
  const handleToggleBlockArticle = (ev: React.ChangeEvent<HTMLInputElement>, blocked: boolean) => {
    dispatch(actions.updateArticleBlocked(article.articleNumber, blocked))
  }

  return (
    <Fragment>
      <TableRow
        key={`header-${article.articleNumber}`}
        className={classes.row}
        data-testid="article-overview-row"
        data-article-number={article.articleNumber}
      >
        <TableCell className={classes.cell}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell} component="th" scope="row">
          <VariantDetailLink articleNumber={article.articleNumber} />
        </TableCell>
        <TableCell className={classes.cell} align="left">
          {article.eans.join(', ')}
        </TableCell>
        <TableCell className={classes.cell} align="left">
          {article.brandIds.map((b) => brandLabels.get(b)).join(', ')}
        </TableCell>
        <TableCell className={classes.cell} align="left">
          {article.brandLines.join(', ')}
        </TableCell>
        <TableCell className={classes.cell} align="left" data-testid="tenants">
          {article.tenants.join(', ')}
        </TableCell>
        {permissions.has(UserPermission.BLOCK_ARTICLE) && (
          <TableCell className={classes.cell} align="center" data-testid="article-blocked">
            <Checkbox
              color="primary"
              className={classes.checkbox}
              checked={article.blocked}
              onChange={handleToggleBlockArticle}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow key={`detail-${article.articleNumber}`} className={classes.detailRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="detail-panel">
              <Table
                size="small"
                className={classes.subTable}
                aria-label={t({
                  id: 'variantlist.eans.headline',
                  message: `EANs for ${article.articleNumber}`,
                })}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_article_number`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_supplier`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_depot`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_shop`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_stock_status`}
                    </TableCell>
                    <TableCell className={classes.subTableHeader}>
                      {t`variantlist.header.ean_goLive`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {article.details.map((details) => (
                    <TableRow key={details.ean + details.tenant}>
                      <TableCell component="th" scope="row">
                        {details.ean}
                      </TableCell>
                      <TableCell>{details.supplierArticleNumber}</TableCell>
                      <TableCell>{details.supplierName}</TableCell>
                      <TableCell>{details.depotNumber}</TableCell>
                      <TableCell>{details.tenant}</TableCell>
                      <TableCell>
                        <StockStatusIndicator
                          isInStock={details.inStock}
                          useSpecialText={isGlobalTenant(details.tenant)}
                        />
                      </TableCell>
                      <TableCell>{details.goLiveDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ArticleTableRow
