import { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'

import { actions } from 'store/imageUpload'
import ImageUploadContainer from './components/ImageUploadContainer'
import classes from './ImageUploadView.module.scss'

const columnWidth1 = 9
const columnWidth2 = 3

export default function ImageUploadView(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.fetchAvailableTenants())
  }, [dispatch])

  return (
    <Grid container direction="column" className={classes.rootContainer}>
      <Grid item container justifyContent="flex-start" spacing={2}>
        <ImageUploadContainer colWidth1={columnWidth1} colWidth2={columnWidth2} />
      </Grid>
    </Grid>
  )
}
