import type { UserProfileDto } from './userProfileTypes'

import backendRequest from 'api/backendRequest'
import config from 'config'

export const fetchUserProfile = () => {
  let features = ''
  if (Array.isArray(config.featureToggles) && config.featureToggles.length > 0) {
    features = '?requestedFeatures=' + config.featureToggles.join(',')
  }

  return backendRequest().get<UserProfileDto>('/user/own-profile' + features)
}

export const setBrandSubscriptions = (brandIds: string[]) => {
  return backendRequest().post<void>('/user/set-own-brand-subscriptions', {
    brandSubscriptions: brandIds,
  })
}

export const setUserLanguage = (language: string) => {
  return backendRequest().post<void>('/user/set-own-language', { language: language })
}
