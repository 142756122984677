import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import { UserPermission } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import theme from 'style/douglas-materialui-theme'
import DashboardElement from './components/DashboardElement'
import Headline from './components/Headline'
import useTileConfig from './useTileConfig'

export interface DashboardTileProps {
  subscribedBrandIds: string[]
}

const useStyles = makeStyles((theme) => ({
  introContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  dashboardContainer: {
    padding: theme.spacing(3),
  },
  introText: {
    marginLeft: theme.spacing(0.5),
    color: grey[500],
  },
  divider: {
    margin: theme.spacing(0, 3),
  },
}))

export default function Dashboard() {
  const classes = useStyles()
  const tileConfig = useTileConfig()
  const accountFullName = useSelector(profileSelectors.fullName)
  const assignedTenantIds = useSelector(profileSelectors.tenants)
  const totalSubscribedBrands = useSelector(profileSelectors.totalSubscribedBrands)

  const permissions = useSelector(profileSelectors.permissions)
  const subscribedUsers = useSelector(profileSelectors.subscribedUsers)
  const usernameLabels = useSelector(profileSelectors.usernameLabels)
  const subscribedUserNames = permissions.has(UserPermission.SHOW_MDM_DASHBOARD)
    ? subscribedUsers.map((username) => usernameLabels.get(username) ?? username)
    : []

  const assignedSuppliers = useSelector(profileSelectors.suppliers)
  const accountRole = useSelector(profileSelectors.role)

  const brandLabels = useSelector(profileSelectors.brandLabels)
  const ownBrandLabels = permissions.has(UserPermission.SHOW_MDM_DASHBOARD)
    ? totalSubscribedBrands.map((brandId) => brandLabels.get(brandId) ?? '-')
    : []
  const assignedSupplierLabels = assignedSuppliers.map((s) => s.name ?? '-')

  return (
    <div data-testid="dashboard-overview">
      <Headline
        {...{
          accountFullName,
          assignedTenantIds,
          ownBrandLabels,
          subscribedUserNames,
          assignedSupplierLabels,
          accountRole,
        }}
      />
      <br />
      <Divider className={classes.divider} />
      <Container
        maxWidth="lg"
        className={classes.introContainer}
        color={theme.palette.text.disabled}
      >
        <Typography className={classes.introText}>{t`dashboard.intro.text`}</Typography>
      </Container>
      <Container
        maxWidth="lg"
        className={classes.dashboardContainer}
        data-testid="dashboard-container"
      >
        <Grid container spacing={3}>
          {tileConfig.map((tileProps, idx) => (
            <DashboardElement key={idx} subscribedBrandIds={totalSubscribedBrands} {...tileProps} />
          ))}
        </Grid>
      </Container>
    </div>
  )
}
