import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import clsx from 'clsx'

import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/bulkCopyGallery'
import { DotNavigationOption } from 'views/GalleryPage/BulkCopyGallery/DotNavigationOption'
import classes from './DotNavigation.module.scss'

export const DotNavigation: React.FC = () => {
  const selectedArticles = useSelector(selectors.selectedArticles)
  const currentPage = useSelector(selectors.currentPage)
  const visibleArticles = useSelector(selectors.visibleArticles)
  const startIndex = useSelector(selectors.paginationStartIndex)
  const endIndex = useSelector(selectors.paginationEndIndex)
  const maxPage = useSelector(selectors.maxPage)
  const stateBefore = useSelector(selectors.stateBeforeCurrentPage) || 'normal'
  const stateAfter = useSelector(selectors.stateAfterCurrentPage) || 'normal'
  const boundActions = useBoundActions(actions)

  return (
    <div className={classes.root} data-testid="dotNavigation">
      <IconButton
        size="small"
        className={clsx(classes.navigationButton, classes[stateBefore])}
        onClick={boundActions.prevPage}
        disabled={currentPage === 0}
        data-testid="dotNavigation.prevPage"
        data-status={stateBefore}
      >
        <KeyboardArrowLeft className={clsx(classes.navigationIcon, classes[stateBefore])} />
      </IconButton>
      <div>
        <div className={classes.options}>
          {visibleArticles.map((option, idx) => (
            <DotNavigationOption key={`${option}_${idx}`} option={option} />
          ))}
        </div>
        <div className={classes.currentPage} data-testid="dotNavigation.paginationLabel">
          {t({
            id: 'galleryPage.bulkCopy.pagination_summary',
            values: {
              0: startIndex + 1,
              1: Math.min(endIndex, selectedArticles.length),
              2: selectedArticles.length,
            },
          })}
        </div>
      </div>
      <IconButton
        size="small"
        className={clsx(classes.navigationButton, classes[stateAfter])}
        onClick={boundActions.nextPage}
        disabled={currentPage === maxPage}
        data-testid="dotNavigation.nextPage"
        data-status={stateAfter}
      >
        <KeyboardArrowRight className={clsx(classes.navigationIcon, classes[stateAfter])} />
      </IconButton>
    </div>
  )
}
