import React, { useState } from 'react'
import { Button, Dialog, MenuItem, Typography } from '@material-ui/core'

import backendRequest from 'api/backendRequest'
import { ReactComponent as LoadingIndicator } from './loadingIndicator.svg'
import classes from './ProfileMenu.module.scss'

interface ResetDatabaseProps {
  onOpen: () => void
}

const ResetDatabase: React.FC<ResetDatabaseProps> = (props) => {
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)
  const handleClick = () => {
    props.onOpen()
    setShowLoadingIndicator(true)
    backendRequest()
      .post('/init-test-data')
      .then(closeDialog, () => setErrorOccurred(true))
  }
  const closeDialog = () => {
    setShowLoadingIndicator(false)
    setErrorOccurred(false)
  }

  return (
    <React.Fragment>
      <MenuItem onClick={handleClick} data-testid="profileMenu.resetDatabase">
        <Typography variant="inherit" className={classes.resetDatabase} noWrap>
          Reset database
        </Typography>
      </MenuItem>
      <Dialog open={showLoadingIndicator} onClose={() => setShowLoadingIndicator(false)}>
        {errorOccurred ? (
          <div className={classes.error}>
            Oh nein! Irgendwas ist schiefgelaufen
            <br />
            <br />
            <Button size="large" variant="contained" onClick={closeDialog}>
              Close
            </Button>
          </div>
        ) : (
          <LoadingIndicator className={classes.loadingIndicator} />
        )}
      </Dialog>
    </React.Fragment>
  )
}

export default ResetDatabase
