import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from 'store/galleryPage'

let isInitialRequest = true
export const useInitialRequest = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isInitialRequest) {
      isInitialRequest = false
      dispatch(actions.fetchGalleryPage())
    }
  }, [dispatch])
}
