import type * as Payloads from './actions'

import { translateErrorResponse } from 'api/errors'
import { TenantDto } from 'api/types'
import { createReducer } from 'store/utils'
import { isGlobalTenant } from 'utils/tenants'
import * as types from './actionTypes'
import initialState, { ImageFile, ImageUploadState, UploadStatus } from './initialState'
import { buildSuccessfulUploadMessage, compareImage } from './utils'

function updateImage(
  state: ImageUploadState,
  imageId: string,
  updater: (image: ImageFile) => ImageFile
) {
  return state.images.map((current) => (current.id === imageId ? updater(current) : current))
}

type Handler<T = void> = (state: ImageUploadState, payload: T) => ImageUploadState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.ADD_UPLOADED_IMAGES]: ((state, newImages) => ({
    ...state,
    images: [...state.images, ...newImages].sort(compareImage),
  })) as Handler<ImageFile[]>,

  [types.SET_IMAGE_DATA_URL]: ((state, { imageId, dataUrl }) => ({
    ...state,
    images: updateImage(state, imageId, (match) => ({ ...match, dataUrl })),
  })) as Handler<Payloads.AddImageDataUrlPayload>,

  [types.SET_ALLOWED_TENANTS]: ((state, allowedTenants) => ({
    ...state,
    allowedTenants,
  })) as Handler<TenantDto[]>,

  [types.SET_SELECTED_TENANTS]: ((state, selectedTenants) => ({
    ...state,
    selectedTenants,
  })) as Handler<TenantDto[]>,

  [types.BULK_UPLOAD_STARTED]: ((state, totalUploadCount) => ({
    ...state,
    totalUploadCount,
    uploadCount: 0,
  })) as Handler<number>,

  [types.START_FILE_UPLOAD]: ((state, imageId) => ({
    ...state,
    images: updateImage(state, imageId, (match) => {
      const result = { ...match, percent: 0, isImageProcessed: true }
      if (match.uploadStatus === UploadStatus.READY) {
        Object.assign(result, { uploadStatus: UploadStatus.UPLOADING, messages: ['started'] })
      }
      return result
    }),
  })) as Handler<string>,

  [types.SET_UPLOAD_PROGRESS]: ((state, { percent, imageId }) => ({
    ...state,
    images: updateImage(state, imageId, (match) => ({ ...match, percent })),
  })) as Handler<Payloads.SetUploadProgressPayload>,

  [types.SET_UPLOAD_RESULT]: ((state, { response, imageId }) => ({
    ...state,
    uploadCount: state.uploadCount + 1,
    images: updateImage(state, imageId, (match) => ({
      ...match,
      uploadStatus: UploadStatus.UPLOADED_OK,
      messages: buildSuccessfulUploadMessage(response),
      url: response.imageUrl,
      updatedTenants: response.updatedTenants.join(', '),
    })),
  })) as Handler<Payloads.SetUploadResultPayload>,

  [types.SET_UPLOAD_ERROR]: ((state, { error, imageId }) => ({
    ...state,
    uploadCount: state.uploadCount + 1,
    images: updateImage(state, imageId, (match) => ({
      ...match,
      uploadStatus: UploadStatus.INVALID,
      messages: translateErrorResponse(error),
    })),
  })) as Handler<Payloads.SetUploadErrorPayload>,

  [types.REMOVE_IMAGE]: ((state, imageId) => ({
    ...state,
    images: state.images.filter((image) => image.id !== imageId),
  })) as Handler<string>,

  [types.REMOVE_ALL_IMAGES]: ((state) => ({
    ...state,
    images: [],
  })) as HandlerWithoutPayload,

  [types.TOGGLE_SELECTED_TENANT]: ((state, tenantId) => {
    const isAlreadySelected = state.selectedTenants.find((tenant) => tenant.key === tenantId)
    let selectedTenants = state.selectedTenants

    if (isAlreadySelected) {
      selectedTenants = state.selectedTenants.filter((tenant) => tenant.key !== tenantId)
    } else {
      const globalTenant = state.allowedTenants.find((tenant) => isGlobalTenant(tenant.key))
      if (tenantId === globalTenant?.key) {
        selectedTenants = [globalTenant]
      } else {
        const tenant = state.allowedTenants.find((tenant) => tenant.key === tenantId)
        if (tenant) {
          selectedTenants = [
            ...state.selectedTenants.filter((tenant) => tenant.key !== globalTenant?.key),
            tenant,
          ]
        }
      }
    }

    return { ...state, selectedTenants }
  }) as Handler<string>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type ImageUploadAction = Actions[keyof Actions]

export default createReducer<ImageUploadState, ImageUploadAction>(initialState, handlers)
