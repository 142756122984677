import React from 'react'
import { t } from '@lingui/macro'

import { BooleanFilter, BooleanOptionKey, BooleanValue } from './BooleanFilter'

export interface BlockedFilterProps {
  value: BooleanValue
  disabled?: boolean
  onChange: (newValue: boolean | null) => void
  'data-testid'?: string
}

export function BlockedFilter(props: BlockedFilterProps) {
  const optionLabels: Record<BooleanOptionKey, string> = {
    optionNotSet: '\u00a0',
    optionTrue: t`filter.options.blocked`,
    optionFalse: t`filter.options.unblocked`,
  } as { [K in BooleanOptionKey]: string }

  return (
    <BooleanFilter
      {...props}
      label={t`filter.label.blocked`}
      optionLabels={optionLabels}
      data-testid={props['data-testid'] ?? 'blockedFilter'}
    />
  )
}
