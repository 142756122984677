import React from 'react'
import { t } from '@lingui/macro'
import { MenuItem, Typography } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import keycloak from 'api/keycloak/KeycloakClient'

const Logout: React.FC = () => {
  const handleLogout = () => keycloak.logout()

  return (
    <MenuItem
      onClick={handleLogout}
      data-testid="userData-logout"
      title={t`general.profile.menu.title.logout`}
    >
      <ExitToApp />
      <Typography variant="inherit" noWrap>
        {t`general.profile.menu.label.logout`}
      </Typography>
    </MenuItem>
  )
}

export default Logout
