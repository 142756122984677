import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import { ImageDto, ImageState, ImageType } from 'api/types'
import ImageContextMenu from 'components/ImageContextMenu'
import ArticleImage from 'components/ImageGallery/ArticleImage'
import { ImageSlotProps } from 'components/ImageSlot'
import { colors } from 'style/douglas-materialui-theme'
import FeedbackTooltip from './FeedbackTooltip'

const useStyles = makeStyles({
  feedbackImageCard: {
    borderRadius: '5%',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'transparent',
    border: `1px solid ${colors.mediumGrey}`,
    transition: 'background-color 250ms ease, border-color 250ms ease',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.2) 0 0 8px',
    },
  },
  oldImage: {
    borderColor: colors.lightGrey,
    backgroundColor: colors.veryLightGrey,
    opacity: 0.7,
  },
  approved: {
    borderColor: 'green',
    backgroundColor: '#0f03',
  },
  suggested: {
    borderColor: 'gray',
    backgroundColor: '#e6e876',
  },
  rejected: {
    borderColor: 'orange',
    backgroundColor: 'fff3eb',
  },
  rejectedNeedSubstitution: {
    borderColor: 'red',
    backgroundColor: '#f003',
  },
})

interface FeedbackImageCardProps extends ImageSlotProps {
  image?: ImageDto
  articleNumber: string
  galleryName: string
}

const FeedbackImageCard = React.forwardRef<HTMLDivElement, FeedbackImageCardProps>((props, ref) => {
  const { image, imagePosition } = props
  const classes = useStyles()

  return (
    <div
      ref={ref}
      className={clsx(classes.feedbackImageCard, {
        [classes.oldImage]:
          image?.type === ImageType.TENANT_IMAGE || image?.type === ImageType.GLOBAL_IMAGE,
        [classes.rejectedNeedSubstitution]:
          image?.type === ImageType.NEW_IMAGE &&
          image?.state === ImageState.REJECTED &&
          image?.requestNewImage,
        [classes.rejected]:
          image?.type === ImageType.NEW_IMAGE &&
          image?.state === ImageState.REJECTED &&
          !image?.requestNewImage,
        [classes.approved]:
          image?.type === ImageType.NEW_IMAGE && image?.state === ImageState.APPROVED,
      })}
    >
      <FeedbackTooltip image={image}>
        <ArticleImage image={image} imagePosition={imagePosition} galleryName={props.galleryName} />
      </FeedbackTooltip>

      <ImageContextMenu
        image={image}
        imagePosition={props.imagePosition}
        articleNumber={props.articleNumber}
      />
    </div>
  )
})

FeedbackImageCard.displayName = 'FeedbackImageCard'

export default FeedbackImageCard
