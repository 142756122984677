import type { CreateBulkUsersDto } from 'api/adminUser'
import type { ErrorDto } from 'api/types'

import React from 'react'
import { t } from '@lingui/macro'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Alert } from '@material-ui/lab'

import { translateErrorMessage } from 'api/errors'
import { isErrorDto } from 'api/types'
import { BulkUserDialogMode } from './BulkUserDialog'
import classes from './BulkUserDialog.module.scss'

interface UploadErrorsProps {
  mode: BulkUserDialogMode
  response: CreateBulkUsersDto | ErrorDto | null
}

function createErrorReport(response: CreateBulkUsersDto) {
  if (!response.errors) {
    return null
  }

  const csv: string[][] = [['row', 'error']]

  for (const [row, errors] of Object.entries(response.errors)) {
    csv.push([row, errors.map((error) => translateErrorMessage(error)).join(', ')])
  }
  const blob = new Blob([csv.map((d) => d.join(';')).join('\n')], { type: 'text/csv' })
  const URL = window.URL || window.webkitURL
  return URL.createObjectURL(blob)
}

const ErrorReport: React.FC<{ response: CreateBulkUsersDto }> = ({ response }) => {
  const errorReport = createErrorReport(response)
  const targetFilename = `create_bulk_user_errors-${new Date().toISOString()}.csv`

  return errorReport ? (
    <div className={classes.errorReport}>
      <Button href={errorReport} download={targetFilename}>
        <GetAppIcon fontSize="small" className={classes.linkIcon} />
        Download report
      </Button>
    </div>
  ) : null
}

const UploadErrors: React.FC<UploadErrorsProps> = ({ response, mode }) => {
  if (!response) {
    return null
  }

  return isErrorDto(response) ? (
    <div>
      {response.errors.map((error, idx) => (
        <Alert key={idx} className={classes.errorMessage} severity="error">
          {translateErrorMessage(error)}
        </Alert>
      ))}
    </div>
  ) : (
    <React.Fragment>
      <Alert severity="error" className={classes.errorMessage}>
        {mode === 'create'
          ? t`admin.account.bulkuser.create_error`
          : t`admin.account.bulkuser.update_error`}
      </Alert>
      <ErrorReport response={response} />
      <TableContainer component={Paper} className={classes.errorTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">Row</TableCell>
              <TableCell>Error</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {response.errors
              ? Object.entries(response.errors).map(([row, errors]) => (
                  <TableRow key={row}>
                    <TableCell align="right">{row}</TableCell>
                    <TableCell>
                      {errors.map((error) => translateErrorMessage(error)).join(', ')}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default UploadErrors
