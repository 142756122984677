import { any, curry } from 'ramda'

import { ReviewImageGalleryEntryForm } from 'api/imageReviews'
import {
  ImageDto,
  ImageGallery,
  ImagePosition,
  imagePositions,
  ImageState,
  ImageType,
} from 'api/types'
import { TenantGallery } from 'types'
import { ImageGalleries } from 'types/image'
import { getImagesInPosition, updateImageState } from 'utils/imageGallery'

const addGlobalImageToList = (
  list: ReviewImageGalleryEntryForm[],
  globalImageId: string,
  position: ImagePosition
) => {
  list.push({
    imageId: globalImageId,
    position: String(position),
    state: 'APPROVED',
    type: ImageType.GLOBAL_IMAGE,
    feedback: [],
  })
}

const addSuggestedImageWithFeedbackToList = (
  list: ReviewImageGalleryEntryForm[],
  suggestedImage: ImageDto,
  position: ImagePosition,
  rejectAll: boolean | undefined
) => {
  list.push({
    imageId: suggestedImage.imageId,
    position: String(position),
    state: suggestedImage.state === ImageState.SUGGESTED && !rejectAll ? 'APPROVED' : 'REJECTED',
    type: ImageType.NEW_IMAGE,
    feedback: suggestedImage.feedback ? suggestedImage.feedback.map(String) : [],
    newImageRequested: suggestedImage?.requestNewImage ?? false,
  })
}

const addApprovedImageToList = (
  list: ReviewImageGalleryEntryForm[],
  approvedImageId: string,
  position: ImagePosition
) => {
  list.push({
    imageId: approvedImageId,
    position: String(position),
    state: 'APPROVED',
    type: ImageType.TENANT_IMAGE,
    feedback: [],
  })
}

const toReviewImageGalleryEntryForm = (
  tenant: TenantGallery,
  rejectAll?: boolean
): ReviewImageGalleryEntryForm[] => {
  const entries: ReviewImageGalleryEntryForm[] = []
  for (const position of imagePositions) {
    const { suggestedImage, approvedImage, globalImage } = getImagesInPosition(
      position,
      tenant.galleries
    )

    if (
      suggestedImage?.state === ImageState.SUGGESTED ||
      suggestedImage?.state === ImageState.REJECTED
    ) {
      addSuggestedImageWithFeedbackToList(entries, suggestedImage, position, rejectAll)
    }

    if (approvedImage) {
      // approved images also for rejected suggestions at same position
      if (!suggestedImage || suggestedImage.state === ImageState.REJECTED || rejectAll) {
        addApprovedImageToList(entries, approvedImage.imageId, position)
      }
    } else if (globalImage) {
      addGlobalImageToList(entries, globalImage.imageId, position)
    }
  }

  return entries
}

const addGlobalGallery = curry((tenant: TenantGallery, globalTenant: TenantGallery) => {
  return tenant !== globalTenant
    ? {
        ...tenant,
        galleries: {
          ...tenant.galleries,
          global: updateImageState(ImageState.INHERITED, globalTenant.galleries.approved),
        },
      }
    : tenant
})

const existsImageInGallery = (gallery?: ImageGallery) => {
  return gallery ? Object.keys(gallery).length > 0 : false
}

const existsMainImage = (gallery?: ImageGallery) => {
  return gallery ? Boolean(gallery['0']) : false
}

const hasImage = (tenant: TenantGallery) => {
  return any(existsImageInGallery)([
    tenant.galleries.suggested,
    tenant.galleries.approved,
    tenant.galleries.global,
  ])
}

const hasApprovedImages = (tenant?: TenantGallery) => {
  return tenant ? existsImageInGallery(tenant.galleries.approved) : false
}

const hasApprovedImagesExceptBlob = (tenant?: TenantGallery) => {
  if (!tenant?.galleries?.approved) {
    return false
  }

  const positions = Object.keys(tenant.galleries.approved).filter((position) => position !== 'blob')
  return positions.length > 0
}

const hasSuggestedImages = (tenant?: TenantGallery) => {
  return tenant ? existsImageInGallery(tenant.galleries.suggested) : false
}

const hasMainImage = (
  tenant?: TenantGallery,
  galleryType: (keyof ImageGalleries)[] = ['approved']
) => {
  return tenant ? any(existsMainImage)(galleryType.map((type) => tenant.galleries[type])) : false
}

export type ImageCountResult = {
  total: number
  approved: number
  originalApproved: number
  suggested: number
}

const getImageCount = (tenant?: TenantGallery): ImageCountResult => {
  const result = {
    total: 0,
    approved: 0,
    originalApproved: 0,
    suggested: 0,
  }

  if (!tenant) {
    return result
  }

  for (const imagePosition of imagePositions) {
    let increaseTotal = 0
    if (tenant.galleries.approved?.[imagePosition]) {
      result.approved++
      increaseTotal = 1
    }
    if (tenant.galleries.originalApproved?.[imagePosition]) {
      result.originalApproved++
    }
    if (tenant.galleries.suggested?.[imagePosition]) {
      result.suggested++
      increaseTotal = 1
    }
    result.total += increaseTotal
  }

  return result
}

export default {
  toReviewImageGalleryEntryForm,
  addGlobalGallery,
  hasImage,
  hasApprovedImages,
  hasApprovedImagesExceptBlob,
  hasSuggestedImages,
  hasMainImage,
  getImageCount,
}
