import { ReactElement } from 'react'
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  SvgIcon,
  Typography,
} from '@material-ui/core'

export default function UploadProgressStateUploading(
  props: CircularProgressProps & { value: number }
): ReactElement {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SvgIcon
          className="MuiCircularProgress-svg"
          viewBox="0 0 24 24"
          style={{ width: props.size, height: props.size }}
          color={'primary'}
        >
          <circle cx="12" cy="12" r="10.6" fill="none" strokeWidth="2" color="#e6e6e6" />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="11.6"
            fill="none"
            strokeWidth="1"
          />
          <circle
            className="MuiCircularProgress-circle"
            cx="12"
            cy="12"
            r="9.6"
            fill="none"
            strokeWidth="1"
          />
        </SvgIcon>
      </Box>

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}
