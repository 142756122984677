import type { GeneralState } from './initialState'

import { createReducer } from 'store/utils'
import * as types from './actionTypes'
import initialState from './initialState'

type Handler<T = void> = (state: GeneralState, payload: T) => GeneralState

const handlers = {
  [types.SET_ZOOM_IMAGE_URL]: ((state, zoomImageUrl) => ({
    ...state,
    zoomImageUrl,
  })) as Handler<string | null>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type GeneralAction = Actions[keyof Actions]

export default createReducer<GeneralState, GeneralAction>(initialState, handlers)
