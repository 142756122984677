import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import api, { ImageGalleryFeedbackListDto } from 'api/supplierFeedback'
import DashboardTile from './DashboardTile'

const fetchFeedback = () => api.fetchSupplierFeedback(false)

const getResponseCount = (res: ImageGalleryFeedbackListDto) => String(res.articles.length)

const FeedbackTile: React.FC = (props) => {
  const history = useHistory()
  const handleClick = () => history.push('/feedback/images')

  return (
    <DashboardTile
      subtitle={t`dashboard.tile.subtitle.feedback`}
      caption={t`dashboard.tile.caption.feedback`}
      onClick={handleClick}
      fetchStatus={fetchFeedback}
      getResponseCount={getResponseCount}
    />
  )
}

export default FeedbackTile
