import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { MenuItem } from '@material-ui/core'

import { UserPermission } from 'api/types'
import MeatballsMenu from 'components/MeatballsMenu'
import { selectors as profileSelectors } from 'store/userProfile'
import { actions } from 'store/variantDetail'
import { TenantGallery } from 'types'
import ImportGalleryDialog from './ImportGalleryDialog'
import classes from './TenantContextMenu.module.scss'

interface TenantMenuProps {
  tenantGallery: TenantGallery
  onClickExport: () => void
}

const TenantMenu: React.FC<TenantMenuProps> = (props) => {
  const dispatch = useDispatch()
  const permissions = useSelector(profileSelectors.permissions)
  const [showImportGalleryDlg, setShowImportGalleryDlg] = useState(false)

  const handleImportGallery = (selectedTenantId: string) => {
    dispatch(actions.copyTenantGallery(selectedTenantId, props.tenantGallery.tenantId))
  }

  const idSuffix = `${props.tenantGallery.articleNumber}-${props.tenantGallery.tenantId}`

  const hasAnyMenuEntry =
    permissions.has(UserPermission.EXPORT_GALLERY) || permissions.has(UserPermission.APPROVE_IMAGE)

  return hasAnyMenuEntry ? (
    <div className={classes.tenantMenu}>
      <MeatballsMenu data-testid={`tenant-context-menu-${idSuffix}`}>
        {permissions.has(UserPermission.EXPORT_GALLERY) && (
          <MenuItem
            onClick={props.onClickExport}
            data-testid="context-menu-item-export-again"
          >{t`variantdetails.export_again`}</MenuItem>
        )}

        {permissions.has(UserPermission.APPROVE_IMAGE) && (
          <MenuItem
            onClick={() => setShowImportGalleryDlg(true)}
            data-testid="context-menu-item-import-gallery"
          >{t`variantdetails.import_gallery`}</MenuItem>
        )}
      </MeatballsMenu>
      <ImportGalleryDialog
        open={showImportGalleryDlg}
        tenantGallery={props.tenantGallery}
        onClose={() => setShowImportGalleryDlg(false)}
        onApply={handleImportGallery}
      />
    </div>
  ) : null
}

export default TenantMenu
