import { FormControl } from '@material-ui/core'

import TenantSelection from 'components/TenantSelection'
import { GlobalTenantId } from 'utils/tenants'
import classes from './TenantSelectionForm.module.scss'

export interface TenantSelectionFormProps {
  label: string
  value: string | null
  tenants: string[]
  onChange: (tenantId: string) => void
  'data-testid'?: string
}

export const TenantSelectionForm: React.FC<TenantSelectionFormProps> = ({
  label,
  value,
  tenants,
  onChange,
  'data-testid': dataTestId = 'tenentSelection',
}) => {
  return (
    <>
      <div className={classes.label}>{label}</div>
      <FormControl variant="outlined" margin="dense">
        <TenantSelection
          className={classes.tenantSelection}
          value={value || GlobalTenantId}
          onChange={onChange}
          tenants={tenants}
          data-testid={dataTestId}
        />
      </FormControl>
    </>
  )
}
