import { defineMessage } from '@lingui/macro'

import { TenantDto } from 'api/types'
import { ArticleMasterData, TenantGallery } from 'types'

defineMessage({ id: 'general.galleryname.dgl-GLOBAL' })
defineMessage({ id: 'general.galleryname.dgl-DE' })
defineMessage({ id: 'general.galleryname.dgl-CH' })
defineMessage({ id: 'general.galleryname.dgl-NL' })
defineMessage({ id: 'general.galleryname.dgl-BE' })
defineMessage({ id: 'general.galleryname.dgl-PL' })
defineMessage({ id: 'general.galleryname.dgl-AT' })
defineMessage({ id: 'general.galleryname.dgl-IT' })
defineMessage({ id: 'general.galleryname.dgl-ES' })
defineMessage({ id: 'general.galleryname.ncb-FR' })
defineMessage({ id: 'general.galleryname.dgl-CZ' })
defineMessage({ id: 'general.galleryname.dgl-SK' })

export const GlobalTenantId = 'dgl-GLOBAL'

const tenantOrder = [
  GlobalTenantId,
  'dgl-DE',
  'dgl-CH',
  'dgl-AT',
  'dgl-PL',
  'dgl-NL',
  'dgl-BE',
  'dgl-IT',
  'dgl-ES',
  'ncb-FR',
  'dgl-CZ',
  'dgl-SK',
]

export function getTenantPosition(tenantId: string) {
  return tenantOrder.indexOf(tenantId)
}

export function isGlobalTenant(tenant: TenantGallery | ArticleMasterData | string) {
  return typeof tenant === 'string' ? tenant === GlobalTenantId : tenant.tenantId === GlobalTenantId
}

export function compareTenantIds(a: string, b: string): number {
  const indexA = tenantOrder.indexOf(a)
  const indexB = tenantOrder.indexOf(b)

  return indexA - indexB
}

export function compareTenantDto(a: TenantDto, b: TenantDto): number {
  return compareTenantIds(a.key, b.key)
}

export function tenantLabel(tenantId: string) {
  const [shop, tenant] = tenantId.toLowerCase().split('-')

  let domain

  switch (shop) {
    case 'ncb':
      domain = 'nocibe'
      break
    default:
      domain = 'douglas'
  }

  return `${domain}.${tenant}`
}

export const availableTenants: TenantDto[] = tenantOrder.map((key) => ({
  key,
  label: tenantLabel(key),
}))
