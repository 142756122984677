import { i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

import { ArticleDto, ImageGallery, ImageType } from 'api/types'

export interface ImageReviewsFilterDto {
  tenants: string[]
  brands: string[]
  suppliers: string[]
}

export interface ImageReviewsDto {
  articles: ArticleDto[]
  last: boolean
  totalElements: number
}

export interface ImageReviewSearchForm {
  articleNumbers: string[]
  articleNumbersAndEans: string[]
  brands: string[]
  tenants: string[]
  supplierIds: string[]
  uploadDate: { start: string | null; end: string | null }
  page: number
  size: number
}

export interface GalleryImageMap {
  images: ImageGallery
}

export interface ImageGalleriesDto {
  suggestion: GalleryImageMap
  current: GalleryImageMap | null
  global: GalleryImageMap | null
}

export interface ReviewImageGalleryEntryForm {
  imageId: string
  position: string
  state: 'APPROVED' | 'REJECTED'
  type: ImageType
  feedback: string[]
  newImageRequested?: boolean
}

export interface ReviewImageGalleryForm {
  feedback: string[]
  newGalleryRequested: boolean
  entries: ReviewImageGalleryEntryForm[]
}

export enum ImageFeedback {
  QUESTIONABLE_IMAGE = 'QUESTIONABLE_IMAGE',
  WRONG_IMAGE_SECTION = 'WRONG_IMAGE_SECTION',
  COLOR_BLOB_MISSING = 'COLOR_BLOB_MISSING',
  PACKAGE_IN_MAIN_IMAGE = 'PACKAGE_IN_MAIN_IMAGE',
  MAIN_IMAGE_MISSING = 'MAIN_IMAGE_MISSING',
  COLORED_BACKGROUND_IN_MAIN_IMAGE = 'COLORED_BACKGROUND_IN_MAIN_IMAGE',
  PRODUCT_TOO_FAR = 'PRODUCT_TOO_FAR',
  SHADING_IN_PICTURE = 'SHADING_IN_PICTURE',
  SEAL_IN_PICTURE = 'SEAL_IN_PICTURE',
  WRONG_MAIN_IMAGE = 'WRONG_MAIN_IMAGE',
  BAD_QUALITY = 'BAD_QUALITY',
  PRODUCT_IMAGE_REQUIRE_VERTICAL_POSITION = 'PRODUCT_IMAGE_REQUIRE_VERTICAL_POSITION',
  INCORRECT_LANGUAGE = 'INCORRECT_LANGUAGE',
  WRONG_IMAGE_POSITION = 'WRONG_IMAGE_POSITION',
  WRONG_IMAGE_ARTICLE = 'WRONG_IMAGE_ARTICLE',
  NONE = 'NONE',
}

defineMessage({ id: 'imageFeedback.QUESTIONABLE_IMAGE' })
defineMessage({ id: 'imageFeedback.PRODUCT_TOO_FAR' })
defineMessage({ id: 'imageFeedback.WRONG_MAIN_IMAGE' })
defineMessage({ id: 'imageFeedback.BAD_QUALITY' })
defineMessage({ id: 'imageFeedback.PRODUCT_IMAGE_REQUIRE_VERTICAL_POSITION' })
defineMessage({ id: 'imageFeedback.INCORRECT_LANGUAGE' })
defineMessage({ id: 'imageFeedback.WRONG_IMAGE_POSITION' })
defineMessage({ id: 'imageFeedback.WRONG_IMAGE_ARTICLE' })
defineMessage({ id: 'imageFeedback.NONE' })

export function translatedImageFeedback(feedback: ImageFeedback) {
  return i18n._({ id: `imageFeedback.${feedback}` })
}
