import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import { AxiosError, AxiosResponse } from 'axios'

import { translateErrorResponse } from 'api/errors'
import KpiTile from 'components/KpiTile'

interface DashboardTileProps<T> {
  caption: string
  subtitle?: string
  'data-tile-type'?: string
  onClick: () => void
  fetchStatus: () => Promise<AxiosResponse<T>>
  getResponseCount: (response: T) => string
}

const DashboardTile = <T,>(props: DashboardTileProps<T>) => {
  const { fetchStatus, getResponseCount } = props
  const fetchAttempts = useRef(0)
  const [value, setValue] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const refreshTileContent = useCallback(() => {
    setErrors([])
    setLoading(true)

    fetchStatus().then(
      (response) => {
        fetchAttempts.current = 0
        setErrors([])
        setValue(getResponseCount(response.data))
        setLoading(false)
      },
      (err: AxiosError) => {
        setLoading(false)
        fetchAttempts.current++
        if (fetchAttempts.current < 3) {
          setTimeout(() => {
            refreshTileContent()
          }, 100)
        } else {
          fetchAttempts.current = 0
          setValue((oldValue) => (oldValue.length > 0 ? oldValue : '?'))
          setErrors(translateErrorResponse(err))
        }
      }
    )
  }, [fetchStatus, getResponseCount])

  useEffect(() => {
    refreshTileContent()
  }, [refreshTileContent])

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      data-testid="dashboard-tile"
      data-tile-type={props['data-tile-type']}
    >
      <KpiTile
        withRefresh
        value={value}
        errors={errors}
        loading={loading}
        subtitle={props.subtitle}
        caption={props.caption}
        onClick={props.onClick}
        onRefreshClick={refreshTileContent}
        onErrorNotificationClose={() => setErrors([])}
      />
    </Grid>
  )
}

export default DashboardTile
