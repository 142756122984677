import moment, { Moment } from 'moment'

const uiDateStringToApiDateString = (uiDate: string): string | null =>
  uiDate.length > 0 ? toDateString(moment(uiDate, 'YYYY-MM-DD')) : null

const toDateString = (date: Moment | null): string | null => {
  return date?.format('YYYY-MM-DD') ?? null
}

const toHumanString = (date: Moment | null): string | null => {
  return date?.format('DD.MM.YYYY') ?? null
}
const toHumanDateTimeString = (date: Moment | null): string | null => {
  return date?.format('DD.MM.YYYY hh:mm:ss') ?? null
}

const todayAddWeeks = (weeksCount: number) =>
  moment().add(weeksCount, 'w').format('YYYY-MM-DD') ?? ''

export default {
  uiDateStringToApiDateString,
  toDateString,
  toHumanString,
  toHumanDateTimeString,
  todayAddWeeks,
}
