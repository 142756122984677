import React from 'react'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import classes from './AlertMessageList.module.scss'

export default function ErrorMessageList({ errors }: { errors: string[] }) {
  return errors.length === 0 ? null : (
    <Box className={classes.errorListContainer} data-testid="errorMessageList">
      {errors.map((e, idx) => (
        <Alert key={idx} severity="error" variant="outlined">
          {e}
        </Alert>
      ))}
    </Box>
  )
}
