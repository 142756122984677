import React from 'react'
import { DragPreviewOptions, useDrag } from 'react-dnd'

import { ImageGalleryEntry } from 'types/image'
import { ImageThumbnailProps } from './ImageThumbnail'
import TenantImage from './TenantImage'

interface DraggableImageProps extends ImageThumbnailProps {
  className?: string
}

export const DraggableImage: React.FC<DraggableImageProps> = (props) => {
  const { image } = props
  const [collected, drag, preview] = useDrag({
    type: props.draggableType ?? 'cannot-drag-type',
    item: {
      image: image,
      galleryName: props.galleryName,
      position: props.imagePosition,
    } as ImageGalleryEntry,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: () => Boolean(props.draggableType),
    previewOptions: { anchorX: 1, anchorY: 1 } as DragPreviewOptions,
  })

  return (
    <div ref={collected.isDragging ? preview : drag} className={props.className}>
      <TenantImage {...props} />
    </div>
  )
}
export default DraggableImage
