import { createSelector } from 'reselect'

import { UserPermission } from 'api/types'
import { TransferItem } from 'components/ItemTransferList/ItemTransferListWithState'
import { createBasicSelectors } from 'store/utils'
import { BrandLabels, SupplierLabels } from 'types'
import textEnumeration from 'utils/textEnumeration'
import initialState from './initialState'

const selectors = createBasicSelectors(initialState, 'userProfile')

const createFullName = (firstName?: string, lastName?: string): string => {
  return [firstName?.trim(), lastName?.trim()].filter(Boolean).join(' ')
}

const fullName = createSelector(selectors.firstName, selectors.lastName, createFullName)

const defaultRoute = createSelector(selectors.permissions, (permissions) => {
  if (permissions.has(UserPermission.SHOW_ADMIN_OVERVIEW)) {
    return '/useradmin'
  }
  if (
    permissions.has(UserPermission.SHOW_MDM_DASHBOARD) ||
    permissions.has(UserPermission.SHOW_CONTACT_PERSON_DASHBOARD)
  ) {
    return '/dashboard'
  }
  if (permissions.has(UserPermission.SHOW_IMAGE_GALLERY_FEEDBACK)) {
    return '/feedback/images'
  }
  if (permissions.has(UserPermission.SHOW_IMAGE_UPLOAD)) {
    return '/bulkupload'
  }
  if (permissions.has(UserPermission.SHOW_ARTICLE_GALLERY_OVERVIEW)) {
    return '/galleries'
  }
  if (permissions.has(UserPermission.SHOW_ARTICLE_OVERVIEW)) {
    return '/overview'
  }
  return '/not-enough-permission'
})

const totalSubscribedBrands = createSelector(
  selectors.brandSubscriptions,
  selectors.subscribedUsers,
  selectors.username,
  (brandSubscriptions, subscribedUsers, username) => {
    const users = new Set([...subscribedUsers, username])

    return [
      ...new Set(
        brandSubscriptions
          .filter((brand) => users.has(brand.username))
          .flatMap((brand) => brand.brandIds)
      ),
    ]
  }
)

const ownSubscribedBrands = createSelector(
  selectors.brandSubscriptions,
  selectors.username,
  (brandSubscriptions, username) =>
    brandSubscriptions.find((brand) => brand.username === username)?.brandIds ?? []
)

const usernameLabels = createSelector(selectors.brandSubscriptions, (brandSubscriptions) => {
  return new Map(
    brandSubscriptions.map((user) => [
      user.username,
      createFullName(user.firstName, user.lastName) || user.username,
    ])
  )
})

const brandLabels = createSelector(selectors.allBrands, (allBrands): BrandLabels => {
  return new Map(allBrands.map((brand) => [brand.id, brand.name]))
})

const supplierLabels = createSelector(selectors.suppliers, (suppliers): SupplierLabels => {
  return new Map(suppliers.map((supplier) => [supplier.id, supplier.name]))
})

const isBrandLabel = (item: string | undefined): item is string => {
  return typeof item === 'string'
}

const subscribedUsersAvailable = createSelector(
  selectors.username,
  selectors.brandSubscriptions,
  usernameLabels,
  brandLabels,
  (username, brandSubscriptions, usernameLabels, brandLabels) => {
    return brandSubscriptions
      .filter((user) => username !== user.username)
      .map(
        (user): TransferItem => ({
          id: user.username,
          label: usernameLabels.get(user.username) ?? '',
          detail: textEnumeration(
            user.brandIds.map((id) => brandLabels.get(id)).filter(isBrandLabel),
            1
          ),
        })
      )
  }
)

export default {
  ...selectors,
  fullName,
  defaultRoute,
  totalSubscribedBrands,
  ownSubscribedBrands,
  usernameLabels,
  brandLabels,
  supplierLabels,
  subscribedUsersAvailable,
}
