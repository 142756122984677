import React from 'react'
import classnames from 'classnames'

import { ImageThumbnailProps } from './ImageThumbnail'
import { getImageUrl } from './misc'
import classes from './ImageGallery.module.scss'

const InheritedImage: React.FC<ImageThumbnailProps> = (props) => {
  const { image } = props

  return (
    <img
      draggable="false"
      src={getImageUrl(image)}
      alt={image.imageId}
      className={classnames(classes.image, classes.global)}
      data-testid="galleryimage"
      data-imagestate="global"
    />
  )
}
export default InheritedImage
