import React from 'react'
import { t } from '@lingui/macro'

import classes from './VariantTenantDetails.module.scss'

export default function TenantEditBlockedIndicator({ isBlocked }: { isBlocked: boolean }) {
  return (
    <div className={classes.noPermissionInfo}>
      {isBlocked ? (
        <span data-testid="no-permission-outstanding-review">
          {t`variantdetails.tenant.blocked_due_to_outstanding_review`}
        </span>
      ) : (
        <span data-testid="no-permission-for-tenant">
          {t`variantdetails.no.edit.permission.for.tenant`}
        </span>
      )}
    </div>
  )
}
