import { equals } from 'ramda'
import { createSelector } from 'reselect'

import { Role } from 'api/types'
import profileSelectors from 'store/userProfile/selectors'
import { createBasicSelectors } from 'store/utils'
import initialState, { initialFilter } from './initialState'

const selectors = createBasicSelectors(initialState, 'articleOverview')

const blockedArticles = createSelector(selectors.articles, (articles) =>
  articles.filter((article) => article.blocked)
)

export const currentFilterEqualsInitialFilter = createSelector(
  selectors.filter,
  profileSelectors.totalSubscribedBrands,
  (filter, brandIds) =>
    equals(filter, initialFilter) ||
    (brandIds.length ? equals(filter, { ...initialFilter, brandIds }) : false)
)

export const showFilterWarning = createSelector(
  currentFilterEqualsInitialFilter,
  selectors.ignoreFilterWarning,
  profileSelectors.role,
  (currentFilterEqualsInitialFilter, ignoreFilterWarning, role) =>
    !ignoreFilterWarning && currentFilterEqualsInitialFilter && role === Role.DOUGLAS
)

export default {
  ...selectors,
  currentFilterEqualsInitialFilter,
  showFilterWarning,
  blockedArticles,
}
