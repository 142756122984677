import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

import metaData from '../../../../version-metadata.json'
import classes from './VersionHint.module.scss'

export default function VersionHint(): ReactElement {
  return (
    <div className={classes.rootContainer}>
      <Typography className={classes.versionLabel}>
        <Trans id="general.version.label">Version:</Trans>
      </Typography>
      <Typography>{`${metaData.buildMajor}.${metaData.buildMinor}-${metaData.buildRevision}`}</Typography>
    </div>
  )
}
