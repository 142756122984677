import { __, curry } from 'ramda'

import {
  ArticleDto,
  ImageDto,
  ImageGalleryDto,
  ImageState,
  TenantMap,
  TenantSpecificArticleAttributeDto,
} from 'api/types'
import { ArticleDetailDto } from 'api/variantDetail'
import { ArticleGalleries, BrandLabels, TenantGallery } from 'types'
import { ImageGalleries } from 'types/image'
import { updateImageState } from 'utils/imageGallery'
import { compareTenantIds, GlobalTenantId, isGlobalTenant } from 'utils/tenants'
import tenantGallery from './tenantGallery'

function hasGlobalStock(attributes: TenantMap<TenantSpecificArticleAttributeDto>) {
  for (const tenantId in attributes) {
    const tenant = attributes[tenantId]
    if (tenant.inStock) {
      return true
    }
  }
  return false
}

const hasOnlyGlobalSuggested = (suggestedGalleries: TenantMap<ImageGalleryDto>) =>
  Object.keys(suggestedGalleries).length === 1 && isGlobalTenant(Object.keys(suggestedGalleries)[0])

const getFirstBrandId = (
  attributes: TenantMap<TenantSpecificArticleAttributeDto>
): string | undefined => {
  for (const a in attributes) {
    return attributes[a].brandId
  }
}

const convertArticleDto = (
  article: ArticleDto,
  brandLabels: BrandLabels,
  tenantIds: string[]
): ArticleGalleries => {
  const { tenantSpecificAttributes } = article
  const { suggestedGalleries, approvedGalleries } = article.imageGalleries
  const inGlobalStock = hasGlobalStock(tenantSpecificAttributes)
  const globalGallery = article.imageGalleries.approvedGalleries[GlobalTenantId]
  tenantIds.sort(compareTenantIds)

  // see PRI-1532:
  const defaultBrand = brandLabels.get(getFirstBrandId(tenantSpecificAttributes) ?? '') ?? ''
  const suggestedOnlyForGlobal = hasOnlyGlobalSuggested(suggestedGalleries)

  return {
    articleNumber: article.articleNumber,
    sample: article.sample,
    blocked: article.blocked,
    isSelected: false,
    tenants: tenantIds.map((tenantId): TenantGallery => {
      const isGlobal = isGlobalTenant(tenantId)
      const tenantAttributes = tenantSpecificAttributes[tenantId]

      return {
        id: article.articleNumber + tenantId,
        articleNumber: article.articleNumber,
        ean: tenantAttributes?.ean,
        brand:
          isGlobal && suggestedOnlyForGlobal
            ? defaultBrand
            : brandLabels.get(tenantAttributes?.brandId) ?? '',
        onCounterDate: tenantAttributes?.onCounterDate,
        tenantId,
        blocked: Boolean(approvedGalleries[tenantId]?.blocked),
        isSelected: false,
        galleries: {
          suggested: updateImageState(ImageState.SUGGESTED, suggestedGalleries[tenantId]?.images),
          approved: updateImageState(ImageState.APPROVED, approvedGalleries[tenantId]?.images),
          originalApproved: updateImageState(
            ImageState.APPROVED,
            approvedGalleries[tenantId]?.images
          ),
          global: isGlobal
            ? undefined
            : updateImageState(ImageState.INHERITED, globalGallery?.images),
        },
        isInStock: isGlobal ? inGlobalStock : tenantAttributes?.inStock,
      }
    }),
  }
}

const fromArticleDetailDto = curry((articleDetail: ArticleDetailDto, brandLabels: BrandLabels) => {
  const { article, tenants } = articleDetail
  const tenantIds = tenants.map((tenant) => tenant.key)
  tenantIds.push(GlobalTenantId)

  return convertArticleDto(article, brandLabels, tenantIds)
})

type ArticleGalleryTypes = 'suggested' | 'approved'
const fromArticleDto = curry(
  (
    article: ArticleDto,
    brandLabels: BrandLabels,
    types: ArticleGalleryTypes[]
  ): ArticleGalleries => {
    const { suggestedGalleries, approvedGalleries } = article.imageGalleries
    const tenantIds = Object.keys({
      ...(types.includes('approved') ? approvedGalleries : null),
      ...(types.includes('suggested') ? suggestedGalleries : null),
    })
    return convertArticleDto(article, brandLabels, tenantIds)
  }
)

const updateTenant = curry(
  (
    article: ArticleGalleries,
    tenantId: string,
    updater: Partial<TenantGallery> | ((match: TenantGallery) => Partial<TenantGallery>)
  ): ArticleGalleries => {
    return {
      ...article,
      tenants: article.tenants.map((tenant) =>
        tenant.tenantId === tenantId
          ? { ...tenant, ...(typeof updater === 'function' ? updater(tenant) : updater) }
          : tenant
      ),
    }
  }
)

const removeTenant = curry((article: ArticleGalleries, tenantId: string): ArticleGalleries => {
  return { ...article, tenants: article.tenants.filter((tenant) => tenant.tenantId !== tenantId) }
})

const addToPaperBin = curry(
  (
    article: ArticleGalleries,
    tenantId: string,
    image: ImageDto,
    galleries: ImageGalleries
  ): ArticleGalleries => {
    return updateTenant(article, tenantId, (match) => ({
      galleries,
      hasChanged: true,
      paperbin: [...(match.paperbin || []), image],
    }))
  }
)

const removeFromPaperBin = curry(
  (article: ArticleGalleries, tenantId: string, image: ImageDto): ArticleGalleries => {
    return updateTenant(article, tenantId, (match) => ({
      paperbin: (match.paperbin || []).filter((current) => current !== image),
    }))
  }
)

const addGlobalGallery = (
  article: ArticleGalleries,
  globalTenant?: TenantGallery
): ArticleGalleries => {
  globalTenant = globalTenant || article.tenants.find(isGlobalTenant)

  return globalTenant
    ? {
        ...article,
        tenants: article.tenants.map(tenantGallery.addGlobalGallery(__, globalTenant)),
      }
    : article
}

const findTenant = (article: ArticleGalleries, tenantId: string | null) => {
  return article.tenants.find((tenant) => tenant.tenantId === tenantId)
}

export default {
  fromArticleDto,
  fromArticleDetailDto,
  updateTenant,
  removeTenant,
  addToPaperBin,
  removeFromPaperBin,
  addGlobalGallery,
  findTenant,
}
