import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export interface NotificationDialogProps {
  open: boolean
  title?: string
  boldStartText?: string
  content: string
  onClose: () => void
}

export const NotificationDialog: React.FC<NotificationDialogProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="notify-dialog-title"
      aria-describedby="notify-dialog-description"
      data-testid="notificationDialog"
    >
      <DialogTitle id="notify-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="notify-dialog-description">
          {props.boldStartText && <b>{props.boldStartText}</b>} {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" type="reset" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
