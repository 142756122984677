import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import classnames from 'classnames'

import { ImageFeedback } from 'api/imageReviews'
import RejectionDetailDialog from 'components/RejectionDetailDialog'
import { actions, selectors } from 'store/imageReviews'
import BulkApprovalProgress from './BulkApprovalProgress'
import classes from './BulkApproval.module.scss'

const getSelectionText = (selectedCount: number, totalCount: number) => {
  if (selectedCount === 0) {
    return t({
      id: 'image.review.bulkapproval.select.all',
      values: { 0: totalCount },
    })
  }

  if (selectedCount === totalCount) {
    return t({
      id: 'image.review.bulkapproval.selected.all',
      values: { 0: selectedCount },
    })
  }

  return t({
    id: 'image.review.bulkapproval.selected',
    values: { 0: selectedCount, 1: totalCount },
  })
}

interface BulkApprovalProps {
  onMouseEnterReject: () => void
  onMouseLeaveReject: () => void
}

const BulkApproval: React.FC<BulkApprovalProps> = (props) => {
  const dispatch = useDispatch()
  const [showRejectionDialog, setShowRejectionDialog] = useState(false)
  const allTenants = useSelector(selectors.allTenants)
  const selectedTenants = useSelector(selectors.selectedTenants)
  const handleSelectionChange = () => dispatch(actions.toggleAllSelectedTenants())
  const areTenantsSelected = selectedTenants.length > 0
  const areSomeTenantsSelected = areTenantsSelected && selectedTenants.length < allTenants.length

  const selectionText = useMemo(
    () => getSelectionText(selectedTenants.length, allTenants.length),
    [selectedTenants, allTenants]
  )
  const handleReject = () => {
    setShowRejectionDialog(true)
  }
  const handleApproval = () => dispatch(actions.startBulkApproval())
  const handleRejectionFeedback = (feedback: ImageFeedback[], requestNewImage: boolean) => {
    setShowRejectionDialog(false)
    dispatch(actions.startBulkRejections(feedback, requestNewImage))
  }

  return (
    <div className={classnames(classes.root, { [classes.noArticles]: allTenants.length === 0 })}>
      <div className={classes.selectionText}>
        <FormControlLabel
          control={
            <Checkbox
              checked={areTenantsSelected}
              indeterminate={areSomeTenantsSelected}
              color={areTenantsSelected ? 'primary' : 'secondary'}
              onChange={handleSelectionChange}
            />
          }
          data-testid="bulk-approval-label"
          label={selectionText}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          color="primary"
          disabled={!areTenantsSelected}
          size="large"
          className={classnames(classes.bulkActionButton, classes.reject)}
          onClick={handleReject}
          onMouseEnter={props.onMouseEnterReject}
          onMouseLeave={props.onMouseLeaveReject}
          data-testid="bulk-reject-all"
        >{t`image.review.bulkapproval.reject.selected`}</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!areTenantsSelected}
          onClick={handleApproval}
          size="large"
          className={classes.bulkActionButton}
          data-testid="bulk-approve"
        >{t`image.review.bulkapproval.approve.selected`}</Button>
      </div>
      <BulkApprovalProgress />
      <RejectionDetailDialog
        mode="gallery"
        open={showRejectionDialog}
        onReject={handleRejectionFeedback}
        onClose={() => setShowRejectionDialog(false)}
      />
    </div>
  )
}

export default BulkApproval
