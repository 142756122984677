import { useEffect, useState } from 'react'

type MountState = 'entering' | 'entered' | 'exiting' | 'exited'

const useMountTransition = (open: boolean, duration: number) => {
  const [mountState, setMountState] = useState<MountState>('exited')

  useEffect(() => {
    if (open) {
      setTimeout(() => setMountState('entering'), 10)
    }
  }, [open])

  useEffect(() => {
    if (!open && mountState !== 'exiting' && mountState !== 'exited') {
      setMountState('exiting')
    }
  }, [open, mountState])

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    if (mountState === 'entering') {
      timerId = setTimeout(() => setMountState('entered'), duration)
    } else if (mountState === 'exiting') {
      timerId = setTimeout(() => setMountState('exited'), duration)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [mountState, duration])

  const isOpen =
    open || mountState === 'entering' || mountState === 'entered' || mountState === 'exiting'
  const showOpenTransition = mountState === 'entering' || mountState === 'entered'

  return [isOpen, showOpenTransition]
}

export default useMountTransition
