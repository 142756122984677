import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'

import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/bulkCopyGallery'
import classes from './ConflictNotification.module.scss'

export const ConflictNotification: React.FC = () => {
  const { next, prev } = useSelector(selectors.articleNumbersWithConflicts)
  const stateOfSelectedArticles = useSelector(selectors.stateOfSelectedArticles)
  const boundActions = useBoundActions(actions)
  const handleNextClick = () => {
    boundActions.setSelectedArticleNumber(next[0])
  }
  const handlePrevClick = () => {
    boundActions.setSelectedArticleNumber(prev[0])
  }

  return stateOfSelectedArticles ? (
    <div data-testid="bulkCopyDialog.conflictNotification">
      <Alert
        severity={stateOfSelectedArticles}
        className={clsx(classes.alert, {
          [classes.warning]: stateOfSelectedArticles === 'warning',
        })}
      >
        <span className={classes.notification}>{t`galleryPage.bulkCopy.there_are_conflicts`}</span>
        <IconButton
          className={classes.link}
          onClick={handlePrevClick}
          disabled={prev.length === 0}
          data-testid="bulkCopyDialog.conflictNotification.prevConflict"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          className={classes.link}
          onClick={handleNextClick}
          disabled={next.length === 0}
          data-testid="bulkCopyDialog.conflictNotification.nextConflict"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Alert>
    </div>
  ) : null
}
