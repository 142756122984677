import React, { FormEvent, ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { t, Trans } from '@lingui/macro'
import { Box, IconButton, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Alert } from '@material-ui/lab'

import classes from './InstantSearch.module.scss'

export default function InstantSearch(): ReactElement {
  const history = useHistory()
  const defaultSearchValue = ''

  const [searchValue, setSearchValue] = useState<string>(defaultSearchValue)
  const [errorMessageVisible, setErrorMessageVisible] = useState<boolean>(false)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (searchValue && searchValue.length !== 0) {
      history.push(`/variant/${searchValue}`)
    }
  }

  const handleSearchInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchValue = event.target.value
    const regexNumbers = /[a-zA-ZäöüÄÖÜß ]+/
    if (!regexNumbers.test(searchValue)) {
      setSearchValue(searchValue)
      setErrorMessageVisible(false)
    } else {
      setErrorMessageVisible(true)
    }
  }

  const displayErrorMessage = () => {
    return (
      <Box className={classes.errorMessage}>
        <Alert severity="error">
          <Trans id="general.quicksearch.hint.format_error">Only numbers are allowed!</Trans>
        </Alert>
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.rootContainer}>
        <InputBase
          autoFocus
          fullWidth
          type={'text'}
          autoComplete={'off'}
          name={'articleNumber'}
          value={searchValue}
          onChange={handleSearchInput}
          className={classes.searchInput}
          placeholder={t({ id: 'general.quicksearch.placeholder', message: 'Art.No., EAN' })}
          data-testid={'instantSearch-input'}
        />
        <IconButton
          type="submit"
          className={classes.searchIcon}
          data-testid={'instantSearch-button'}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      {errorMessageVisible && displayErrorMessage()}
    </form>
  )
}
