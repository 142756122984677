import { t } from '@lingui/macro'

import { ImageUploadStatusDto } from 'api/imageUpload'
import { ImageFile, UploadStatus } from './initialState'

const supportedMimeTypes = ['image/png', 'image/jpeg', 'image/tiff']
const maxFilesize = 4 * 1024 * 1024 // 4 MB
const filenameRegex = /^(\d*)(_(\d|blob))?\.(png|tif|tiff|jpg|jpeg)$/i
const csvColumnDelimiter = ','
const csvLineDelimiter = '\n'

const cleanUpFilename = (name: string) => name.trim().replace(/[\u200B-\u200D\uFEFF]/g, '')
const isMimeTypeNotSupported = (fileToCheck: File) => {
  return !supportedMimeTypes.some((type) => fileToCheck.type === type)
}

const isFileSizeTooBig = (fileToCheck: File) => {
  return fileToCheck.size > maxFilesize
}

const isFilenameNotAllowed = (fileToCheck: File) => {
  return !filenameRegex.test(cleanUpFilename(fileToCheck.name))
}

export function validateImage(file: File): ImageFile {
  const messages: string[] = []
  if (isMimeTypeNotSupported(file)) {
    messages.push(t`upload.status.error.invalid_file_type`)
  }
  if (isFileSizeTooBig(file)) {
    messages.push(t`upload.status.error.image_too_large`)
  }
  if (isFilenameNotAllowed(file)) {
    messages.push(t`upload.status.error.invalid_file_name`)
  }
  const isValid = messages.length === 0

  return {
    file,
    id: `${file.name}${file.size}`,
    percent: 0,
    uploadStatus: isValid ? UploadStatus.READY : UploadStatus.INVALID,
    isImageProcessed: false,
    messages: isValid ? [t`upload.status.hint.ready_for_upload`] : messages,
  }
}

export const buildSuccessfulUploadMessage = (data: ImageUploadStatusDto): string[] => {
  if (data.updatedTenants && data.updatedTenants.length > 0) {
    return [
      t({
        id: 'upload.status.hint.uploaded_and_assigned',
        message: `uploaded and assigned to ${data.updatedTenants.join(', ')}`,
      }),
    ]
  }
  return [t({ id: 'upload.status.hint.uploaded', message: 'uploaded' })]
}

const csvStringValue = (data?: string) => (data ? `"${data}"` : '')

export const createUploadReport = (processedImages: ImageFile[]): string => {
  const exportData: string[][] = [['file name', 'messages', 'assigned to'].map(csvStringValue)]
  for (const image of processedImages) {
    exportData.push(
      [image.file.name, image.messages.join(' '), image.updatedTenants].map(csvStringValue)
    )
  }

  const csv = exportData.map((d) => d.join(csvColumnDelimiter)).join(csvLineDelimiter)

  const blob = new Blob([csv], { type: 'text/csv' })
  const URL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window. URL works in Firefox
  return URL.createObjectURL(blob)
}

export const compareImage = (a: ImageFile, b: ImageFile): number => {
  const filenameA = a.file.name.toLocaleLowerCase()
  const filenameB = b.file.name.toLocaleLowerCase()
  const matchA = filenameA.match(filenameRegex)
  const matchB = filenameB.match(filenameRegex)

  if (matchA && matchB) {
    const [, artNrA, , sequenceA, extensionA] = matchA
    const [, artNrB, , sequenceB, extensionB] = matchB

    if (artNrA !== artNrB) {
      return artNrA.localeCompare(artNrB)
    }
    if (sequenceA !== sequenceB) {
      if (!sequenceA) {
        return -1
      }
      if (!sequenceB) {
        return 1
      }
      return sequenceA.localeCompare(sequenceB)
    }
    if (extensionA !== extensionB) {
      return extensionA.localeCompare(extensionB)
    }
    return 0
  }

  return filenameA.localeCompare(filenameB)
}
