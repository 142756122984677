import { useMemo } from 'react'
import classnames from 'classnames'

import { ImageDto, ImagePosition, ImageState } from 'api/types'
import { TenantGallery } from 'types'
import { canDeleteImageInPosition, canReplaceImageInPosition } from 'utils/imageGallery'
import classes from './ImageGallery.module.scss'

export const useImageStateClasses = (
  image?: ImageDto,
  rejectAllSuggestions?: boolean,
  ...args: string[]
): string =>
  classnames(...args, {
    [classes.suggested]: image?.state === ImageState.SUGGESTED && !rejectAllSuggestions,
    [classes.rejected]:
      image?.state === ImageState.REJECTED ||
      (image?.state === ImageState.SUGGESTED && rejectAllSuggestions),
    [classes.approved]: image?.state === ImageState.APPROVED,
  })

interface UseImagePermissionsResult {
  canRemove: boolean
  canReplace: boolean
}
export const useImagePermissions = (
  tenantGallery: TenantGallery,
  imagePosition: ImagePosition,
  canUpdateGallery?: boolean
): UseImagePermissionsResult => {
  return useMemo(() => {
    const canUpdate = typeof canUpdateGallery === 'boolean' ? canUpdateGallery : true
    return canUpdate
      ? {
          canRemove: canDeleteImageInPosition(imagePosition, tenantGallery),
          canReplace: canReplaceImageInPosition(imagePosition),
        }
      : {
          canRemove: false,
          canReplace: false,
        }
  }, [tenantGallery, imagePosition, canUpdateGallery])
}
