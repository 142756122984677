import { Action, combineReducers } from 'redux'
import { ThunkAction } from 'redux-thunk'

import adminUsers from './adminUsers'
import articleOverview from './articleOverview'
import bulkCopyGallery from './bulkCopyGallery'
import galleryPage from './galleryPage'
import general from './general'
import imageReviews from './imageReviews'
import imageUpload from './imageUpload'
import mediathek from './mediathek'
import supplierFeedback from './supplierFeedback'
import userProfile from './userProfile'
import variantDetail from './variantDetail'

const rootReducer = combineReducers({
  adminUsers,
  articleOverview,
  bulkCopyGallery,
  galleryPage,
  general,
  imageReviews,
  imageUpload,
  mediathek,
  supplierFeedback,
  userProfile,
  variantDetail,
})

export type RootState = ReturnType<typeof rootReducer>

export type PrimaThunkAction<T, A extends Action> = ThunkAction<T, RootState, null, A>

export default rootReducer
