import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import initialState from './initialState'

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actions.resetDialog, () => initialState)
    .addCase(actions.setSrcTenantId.fulfilled, (state, action) => {
      state.srcTenantId = action.payload
    })
    .addCase(actions.setDstTenantId, (state, action) => {
      state.dstTenantId = action.payload
    })
    .addCase(actions.setShowDialog, (state, action) => {
      state.showDialog = action.payload
    })
    .addCase(actions.setSelectedArticleNumber.fulfilled, (state, action) => {
      const { articleNumber, newPage } = action.payload
      state.currentPage = newPage
      state.selectedArticleNumber = articleNumber
    })
    .addCase(actions.setCurrentPage.fulfilled, (state, action) => {
      const { currentPage, selectedArticleNumber } = action.payload
      state.currentPage = currentPage
      state.selectedArticleNumber = selectedArticleNumber
    })
    .addCase(actions.toggleDisabledArticle, (state, action) => {
      if (state.disabledArticles.includes(action.payload)) {
        state.disabledArticles = state.disabledArticles.filter(
          (article) => article !== action.payload
        )
      } else {
        state.disabledArticles.push(action.payload)
      }
    })
    .addCase(actions.setCopyInProgress, (state, action) => {
      state.copyInProgress = action.payload
    })
    .addCase(actions.setCopiedGalleries, (state, action) => {
      state.copiedGalleries = action.payload
    })
    .addCase(actions.setCopyErrors, (state, action) => {
      state.copyErrors = action.payload
    })
    .addCase(actions.singleGalleryCopy.fulfilled, (state, action) => {
      const disabledArticles = new Set(state.disabledArticles)
      disabledArticles.add(action.payload)
      state.manuallyCopiedArticles = [...state.manuallyCopiedArticles, action.payload]
      state.disabledArticles = [...disabledArticles]
    })
})
