import type { ErrorDto } from 'api/types'
import type { AxiosError } from 'axios'

import { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { usePromiseTracker } from 'react-promise-tracker'
import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import adminUserApi, { CreateBulkUsersDto } from 'api/adminUser'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import ModalDialog from 'components/ModalDialog'
import BulkUserFileErrors from './BulkUserFileErrors'
import UploadErrors from './UploadErrors'
import classes from './BulkUserDialog.module.scss'

export type BulkUserDialogMode = 'update' | 'create' | null

interface BulkUserDialogProps {
  mode: BulkUserDialogMode
  onClose: () => void
  onRequestFinished: () => void
}

const BulkUserDialog: React.FC<BulkUserDialogProps> = (props) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [uploadResponse, setUploadResponse] = useState<CreateBulkUsersDto | ErrorDto | null>(null)
  const { promiseInProgress } = usePromiseTracker({ area: 'bulkuserrequest' })
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop: () => setUploadResponse(null),
    onDropAccepted: setAcceptedFiles,
    onDropRejected: setRejectedFiles,
  })
  const handleFileUpload = () => {
    if (typeof props.mode === 'string') {
      adminUserApi.bulkUser(props.mode, acceptedFiles[0], 'bulkuserrequest').then(
        (response) => {
          setShowSuccessMessage(true)
          props.onRequestFinished()
        },
        (error: AxiosError<CreateBulkUsersDto | ErrorDto>) => {
          if (error.response?.data) {
            setUploadResponse(error.response.data)
          }
          props.onRequestFinished()
        }
      )
    }
  }
  const handleClose = () => {
    props.onClose()
    setAcceptedFiles([])
    setRejectedFiles([])
    setUploadResponse(null)
    setShowSuccessMessage(false)
  }
  const validFileDropped = acceptedFiles.length === 1 && rejectedFiles.length === 0

  return (
    <ModalDialog
      open={Boolean(props.mode)}
      onClose={handleClose}
      width="sm"
      title={
        props.mode === 'create'
          ? t`admin.account.bulkuser.dialog.title.create`
          : t`admin.account.bulkuser.dialog.title.update`
      }
    >
      <div {...getRootProps({ className: classes.dropContainer })}>
        <input {...getInputProps()} />
        {t`admin.account.bulkuser.dragndrop_description`}
        <br />
        <em>({t`admin.account.bulkuser.file_limit`})</em>
      </div>
      <BulkUserFileErrors acceptedFiles={acceptedFiles} fileRejections={rejectedFiles} />
      {validFileDropped && !promiseInProgress ? (
        <div className={classes.uploadAction}>
          <div>{acceptedFiles[0].name}</div>
          {!uploadResponse ? (
            <Button variant="contained" color="primary" onClick={handleFileUpload}>
              {props.mode === 'create' ? 'Create users' : 'Update users'}
            </Button>
          ) : null}
        </div>
      ) : null}
      {promiseInProgress ? <DataLoadingIndicator /> : null}
      <UploadErrors mode={props.mode} response={uploadResponse} />
      {showSuccessMessage ? (
        <Alert>
          {props.mode === 'create'
            ? t`admin.account.bulkuser.create_success`
            : t`admin.account.bulkuser.update_success`}
        </Alert>
      ) : null}
    </ModalDialog>
  )
}

export default BulkUserDialog
