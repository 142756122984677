import { Fragment, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Divider, Drawer, List } from '@material-ui/core'
import {
  CloudUploadOutlined,
  CommentOutlined,
  Dashboard,
  FormatListBulletedRounded,
  PhotoLibraryOutlined,
} from '@material-ui/icons'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import BuildIcon from '@material-ui/icons/Build'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ScheduleIcon from '@material-ui/icons/Schedule'
import classnames from 'classnames'

import { UserPermission } from 'api/types'
import { environment } from 'config'
import usePageType from 'hooks/usePageType'
import { selectors as profileSelectors } from 'store/userProfile'
import { DouglasLogo } from 'style/icons'
import AppTopBar from './AppTopBar'
import InstantSearch from './InstantSearch'
import LegalHintLinks from './LegalHintLinks'
import PageMenuGroup from './PageMenuGroup'
import PageMenuItem from './PageMenuItem'
import VersionHint from './VersionHint'
import classes from './PageMenuBar.module.scss'

const primaLogoClassName = classnames({
  [`${classes.douglasLogo__Dev}`]: environment === 'dev',
  [`${classes.douglasLogo__Stage}`]: environment === 'stg',
})

const primaLabelClassName = classnames(classes.appName, {
  [`${classes.appName__Dev}`]: environment === 'dev',
  [`${classes.appName__Stage}`]: environment === 'stg',
})

const primaSubLabelClassName = classnames(classes.appNameSub, {
  [`${classes.appNameSub__Dev}`]: environment === 'dev',
  [`${classes.appNameSub__Stage}`]: environment === 'stg',
})

export default function PageMenuBar(): ReactElement {
  const permissions = useSelector(profileSelectors.permissions)
  const pageType = usePageType()

  const isInstantSearchVisible = permissions.has(UserPermission.SHOW_VARIANT_DETAILS)
  const isProductGroupVisible =
    permissions.has(UserPermission.SHOW_ARTICLE_OVERVIEW) ||
    permissions.has(UserPermission.SHOW_ARTICLE_GALLERY_OVERVIEW)

  return (
    <Fragment>
      <AppTopBar />

      <Drawer
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.appSideBar, docked: classes.appTopBarRoot }}
      >
        <div className={classes.logo}>
          <a href="/" className={classes.appLink}>
            <DouglasLogo className={primaLogoClassName} style={{ verticalAlign: 'middle' }} />
            <span className={primaLabelClassName}>PRIMA</span>
            <span className={primaSubLabelClassName}>-</span>
          </a>
        </div>

        <Divider />

        <div className={classes.instantSearchWrapper}>
          {isInstantSearchVisible ? <InstantSearch /> : null}
        </div>

        <List className={classes.menu}>
          <PageMenuItem
            id="dashboard"
            selected={pageType === 'dashboard'}
            icon={<Dashboard />}
            hidden={
              !permissions.has(UserPermission.SHOW_MDM_DASHBOARD) &&
              !permissions.has(UserPermission.SHOW_CONTACT_PERSON_DASHBOARD)
            }
            route="/dashboard"
            label={t`general.menu.label.dashboard`}
            data-testid="menuEntry-dashboard"
          />
          {isProductGroupVisible ? (
            <PageMenuGroup
              label={t`general.menu.label.group_products`}
              icon={<FormatListBulletedRounded />}
              collapsable
              data-testid="menuGroup-variants"
            >
              <PageMenuItem
                id="eanoverview"
                selected={pageType === 'articleoverview'}
                hidden={!permissions.has(UserPermission.SHOW_ARTICLE_OVERVIEW)}
                route="/overview"
                label={t`general.menu.label.product_overview`}
                data-testid="menuEntry-variant-overview"
              />
              <PageMenuItem
                id="eangalleries"
                selected={pageType === 'galleryoverview'}
                hidden={!permissions.has(UserPermission.SHOW_ARTICLE_GALLERY_OVERVIEW)}
                route="/galleries"
                label={t`general.menu.label.product_galleries`}
                data-testid="menuEntry-variant-galleries"
              />
            </PageMenuGroup>
          ) : null}
          <PageMenuItem
            id="images"
            selected={pageType === 'imagereview'}
            icon={<PhotoLibraryOutlined />}
            hidden={!permissions.has(UserPermission.SHOW_IMAGE_REVIEW)}
            route="/images"
            label={t`general.menu.label.image_review`}
            data-testid="menuEntry-image-overview"
          />

          <PageMenuItem
            id="bulkimgupload"
            selected={pageType === 'bulkupload'}
            icon={<CloudUploadOutlined />}
            hidden={!permissions.has(UserPermission.SHOW_IMAGE_UPLOAD)}
            route="/bulkupload"
            label={t`general.menu.label.image_upload`}
            data-testid="menuEntry-bulk-image-upload"
          />

          <PageMenuItem
            id="image_feedback"
            selected={pageType === 'supplierfeedback'}
            icon={<CommentOutlined />}
            hidden={!permissions.has(UserPermission.SHOW_IMAGE_GALLERY_FEEDBACK)}
            route="/feedback/images"
            label={t`general.menu.label.image_feedback`}
            data-testid="menuEntry-image-feedback"
          />

          <PageMenuItem
            id="admin_user"
            selected={pageType === 'useradmin'}
            icon={<AccountBoxIcon />}
            hidden={!permissions.has(UserPermission.SHOW_ADMIN_OVERVIEW)}
            route="/useradmin"
            label={t`general.menu.label.admin`}
            data-testid="menuEntry-admin-overview"
          />

          <PageMenuItem
            id="admin_service"
            selected={pageType === 'serviceadmin'}
            icon={<ScheduleIcon />}
            hidden={!permissions.has(UserPermission.SHOW_ADMIN_OVERVIEW)}
            route="/serviceadmin"
            label={t`general.menu.label.admin_service`}
            data-testid="menuEntry-admin-service"
          />

          <PageMenuItem
            id="variantDetailPage"
            selected={pageType === 'variantdetail'}
            icon={<BuildIcon />}
            route="/variant/"
            label={t`general.menu.label.variant_details`}
            hidden
            data-testid="menuEntry-variant-details"
          />

          <PageMenuItem
            id="brand_guideline_link"
            selected={false}
            icon={<OpenInNewIcon />}
            hidden={!permissions.has(UserPermission.SHOW_IMAGE_UPLOAD)}
            route="https://ecom-data.douglas.de/"
            isExternalRoute
            label={t`general.menu.label.brandsguideline`}
            data-testid="menuEntry-brand-guideline-link"
          />
        </List>

        <LegalHintLinks />

        <Divider />

        <VersionHint />
      </Drawer>
    </Fragment>
  )
}
