import type {
  CreateAdminDto,
  CreateAdminForm,
  CreateBulkUsersDto,
  CreateUserForm,
  EditUserForm,
  UserDto,
  UserOptionsDto,
} from './userManagementTypes'

import { trackPromise } from 'react-promise-tracker'

import backendRequest from 'api/backendRequest'
import { MessageDto } from 'api/types'

export const createUser = (user: CreateUserForm, waitingProgressArea?: string) => {
  return trackPromise(backendRequest().post<void>('/user/create-user', user), waitingProgressArea)
}

export const createAdmin = (adminData: CreateAdminForm, waitingProgressArea?: string) => {
  return trackPromise(
    backendRequest().post<CreateAdminDto>('/user/create-admin', adminData),
    waitingProgressArea
  )
}

export const bulkUser = (mode: 'update' | 'create', file: File, waitingProgressArea?: string) => {
  const data = new FormData()
  const url =
    mode === 'create' ? '/user/create-users-from-csv' : '/user/add-user-relations-from-csv'
  data.append('file', file)
  return trackPromise(
    backendRequest().post<CreateBulkUsersDto>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
    waitingProgressArea
  )
}

export const fetchUser = (userId: string, waitingProgressArea?: string) => {
  return trackPromise(backendRequest().get<UserDto>(`/user/${userId}`), waitingProgressArea)
}

export const updateUser = (userId: string, user: EditUserForm, waitingProgressArea?: string) => {
  return trackPromise(
    backendRequest().post<MessageDto>(`/user/${userId}/update`, user),
    waitingProgressArea
  )
}

export const enableUser = (userId: string, waitingProgressArea?: string) => {
  return trackPromise(backendRequest().post(`/user/${userId}/enable`), waitingProgressArea)
}

export const disableUser = (userId: string, waitingProgressArea?: string) => {
  return trackPromise(backendRequest().post(`/user/${userId}/disable`), waitingProgressArea)
}

export function fetchUsers() {
  return trackPromise(backendRequest().get<UserDto[]>('/user/overview'))
}

export function fetchUserOptions() {
  return backendRequest().get<UserOptionsDto>('/user/options')
}

export const resetUserPassword = (userId: string) => {
  return backendRequest().post(`/user/${userId}/send-reset-password-mail`)
}

export function fetchUserCsvExport() {
  return backendRequest('blob')
    .get(`/user/export/csv`)
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'prima_users.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
