import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Dialog, DialogContent, DialogTitle, LinearProgress, Typography } from '@material-ui/core'

import { selectors } from 'store/imageReviews'
import styles from './BulkApprovalProgress.module.scss'

const percentValue = (part: number, total: number) => {
  return total === 0 ? 0 : (part / total) * 100
}

const BulkApprovalProgress: React.FC = () => {
  const bulkApproval = useSelector(selectors.bulkApproval)
  const isBulkApprovalInProgress = useSelector(selectors.isBulkApprovalInProgress)
  const title = bulkApproval.rejectSuggestions
    ? t`image.review.button.reject_image_suggestions`
    : t`image.review.bulkapproval.approve_suggestions`

  return (
    <Dialog open={isBulkApprovalInProgress} aria-labelledby="approval-progress-dialog">
      <DialogTitle id="approval-progress-dialog">{title}</DialogTitle>
      <DialogContent>
        <Typography className={styles.counterText}>
          <span>{bulkApproval.count}</span> / <span>{bulkApproval.totalCount}</span>
        </Typography>
        <LinearProgress
          variant="determinate"
          value={percentValue(bulkApproval.count, bulkApproval.totalCount)}
          color="primary"
          classes={{ root: styles.progressBarRoot, colorPrimary: styles.progressBar }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default BulkApprovalProgress
