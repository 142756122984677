import type * as Payloads from './actions'
import type { ImageReviewsFilter, ImageReviewsState } from './initialState'

import { __ } from 'ramda'

import { ArticleIdentifier } from 'api/types'
import { createReducer } from 'store/utils'
import { ArticleGalleries } from 'types'
import articleGalleries from 'utils/articleGalleries'
import * as types from './actionTypes'
import initialState from './initialState'

function updateArticle(
  state: ImageReviewsState,
  article: ArticleIdentifier,
  updater: (article: ArticleGalleries, tenantId: string) => ArticleGalleries
): ImageReviewsState {
  const { articleNumber, tenantId } = article
  return {
    ...state,
    articles: state.articles.map((current) =>
      current.articleNumber === articleNumber
        ? { ...current, ...updater(current, tenantId) }
        : current
    ),
  }
}

function addErrorNotification(state: ImageReviewsState, errorNotification: string[] | null) {
  return {
    errorNotification:
      state.errorNotification && errorNotification
        ? state.errorNotification.concat(errorNotification)
        : errorNotification,
  }
}

function mergeArticles(existing: ArticleGalleries[], refetched: ArticleGalleries[]) {
  const result: ArticleGalleries[] = []

  let idx = 0
  for (const currentRefetched of refetched) {
    const currentExisting = existing[idx]
    if (currentExisting?.articleNumber !== currentRefetched.articleNumber) {
      result.push(currentRefetched)
    } else {
      result.push(currentExisting)
      idx++
    }
  }

  return result
}

type Handler<T = void> = (state: ImageReviewsState, payload: T) => ImageReviewsState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.ADD_IMAGES_TO_REVIEW]: ((state, { articles, ...payload }): ImageReviewsState => ({
    ...state,
    ...payload,
    articles: state.articles.concat(articles),
    requestInProgress: false,
  })) as Handler<Payloads.ImageReviewsPayload>,

  [types.SET_IMAGE_REVIEWS]: ((state, payload): ImageReviewsState => ({
    ...state,
    ...payload,
    page: 0,
    requestInProgress: false,
  })) as Handler<Payloads.ImageReviewsPayload>,

  [types.MERGE_ARTICLES]: ((state, payload): ImageReviewsState => ({
    ...state,
    ...payload,
    articles: mergeArticles(state.articles, payload.articles),
    requestInProgress: false,
  })) as Handler<Payloads.ImageReviewsPayload>,

  [types.SET_AVAILABLE_FILTER]: ((state, payload) => ({
    ...state,
    ...payload,
  })) as Handler<Payloads.SetAvailableFilterPayload>,

  [types.ADD_TO_PAPERBIN]: ((state, { article, image, galleries }): ImageReviewsState =>
    updateArticle(
      state,
      article,
      articleGalleries.addToPaperBin(__, __, image, galleries)
    )) as Handler<Payloads.AddToPaperBinPayload>,

  [types.REMOVE_FROM_PAPERBIN]: ((state, { article, image }): ImageReviewsState =>
    updateArticle(
      state,
      article,
      articleGalleries.removeFromPaperBin(__, __, image)
    )) as Handler<Payloads.RemoveFromPaperBinPayload>,

  [types.SET_FILTER]: ((state, filter): ImageReviewsState => ({
    ...state,
    filter: {
      ...state.filter,
      ...filter,
    },
  })) as Handler<Partial<ImageReviewsFilter>>,

  [types.TOGGLE_SELECTED_GALLERY]: ((state, article): ImageReviewsState =>
    updateArticle(
      state,
      article,
      articleGalleries.updateTenant(__, __, (match) => ({ isSelected: !match.isSelected }))
    )) as Handler<ArticleIdentifier>,

  [types.TOGGLE_ALL_SELECTED_TENANTS]: ((state, isSelected): ImageReviewsState => ({
    ...state,
    articles: state.articles.map((article) => ({
      ...article,
      tenants: article.tenants.map((tenant) => ({ ...tenant, isSelected })),
    })),
  })) as Handler<boolean>,

  [types.BULK_APPROVAL_STARTED]: ((state, payload): ImageReviewsState => ({
    ...state,
    bulkApproval: { count: 0, inProgress: true, ...payload },
  })) as Handler<Payloads.BulkApprovalStartedPayload>,

  [types.BULK_APPROVAL_FINISHED]: ((state): ImageReviewsState => ({
    ...state,
    bulkApproval: { ...state.bulkApproval, inProgress: false },
  })) as HandlerWithoutPayload,

  [types.INCREASE_BULK_COUNT]: ((state): ImageReviewsState => ({
    ...state,
    bulkApproval: { ...state.bulkApproval, count: state.bulkApproval.count + 1 },
  })) as HandlerWithoutPayload,

  [types.RESET_FILTER]: ((state, { subscribedBrandIds }): ImageReviewsState => ({
    ...state,
    filter: {
      ...initialState.filter,
      brandIds: subscribedBrandIds,
    },
  })) as Handler<Payloads.ResetAllFiltersPayload>,

  [types.SET_BRAND_FILTER]: ((state, brandIds): ImageReviewsState => ({
    ...state,
    filter: {
      ...state.filter,
      brandIds,
    },
  })) as Handler<string[]>,

  [types.INITIATED_REQUEST]: ((state, fetchMore): ImageReviewsState => ({
    ...state,
    errorNotification: null,
    page: fetchMore ? state.page + 1 : 0,
    visibleStartIndex: fetchMore ? state.visibleStartIndex : 0,
    requestInProgress: true,
  })) as Handler<boolean>,

  [types.FINISHED_REQUEST]: ((state, errorNotification): ImageReviewsState => ({
    ...state,
    ...addErrorNotification(state, errorNotification),
    requestInProgress: false,
  })) as Handler<string[] | null>,

  [types.ADD_ERROR_NOTIFICATION]: ((state, errorNotification): ImageReviewsState => ({
    ...state,
    ...addErrorNotification(state, errorNotification),
  })) as Handler<string[] | null>,

  [types.REMOVE_TENANT]: ((state, article): ImageReviewsState => ({
    ...updateArticle(state, article, articleGalleries.removeTenant),
    totalElements: Math.max(0, state.totalElements - 1),
  })) as Handler<ArticleIdentifier>,

  [types.REMOVE_ARTICLE]: ((state, articleNumber): ImageReviewsState => ({
    ...state,
    articles: state.articles.filter((article) => article.articleNumber !== articleNumber),
  })) as Handler<string>,

  [types.SET_VISIBLE_START_INDEX]: ((state, visibleStartIndex): ImageReviewsState => ({
    ...state,
    visibleStartIndex,
  })) as Handler<number>,

  [types.REMOVE_TENANT_FROM_ARTICLE]: ((state, { articleNumber, tenantId }): ImageReviewsState => {
    const foundArticle = state.articles.find((current) => current.articleNumber === articleNumber)

    if (foundArticle) {
      const updatedArticle = articleGalleries.removeTenant(foundArticle, tenantId)
      return {
        ...state,
        articles:
          updatedArticle.tenants.length === 0
            ? state.articles.filter((current) => current.articleNumber !== articleNumber)
            : state.articles.map((current) =>
                current.articleNumber === articleNumber ? updatedArticle : current
              ),
        totalElements: Math.max(0, state.totalElements - 1),
      }
    }
    return state
  }) as Handler<ArticleIdentifier>,

  [types.UPDATE_TENANT]: ((state, { article, update }) => ({
    ...state,
    articles: state.articles.map((current) => {
      if (current.articleNumber !== article.articleNumber) {
        return current
      }

      return articleGalleries.updateTenant(current, article.tenantId, update)
    }),
  })) as Handler<Payloads.UpdateTenantPayload>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type ImageReviewsAction = Actions[keyof Actions]

export default createReducer<ImageReviewsState, ImageReviewsAction>(initialState, handlers)
