import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button, Grid, Typography } from '@material-ui/core'

import { actions, selectors } from 'store/imageUpload'
import QueueDetailElement from './QueueDetailElement'
import classes from './UploadQueueContainer.module.scss'

const ClearHistoryButton: React.FC<{ position: string }> = (props) => {
  const dispatch = useDispatch()
  const isUploadInProgress = useSelector(selectors.isUploadInProgress)
  const handleClick = () => dispatch(actions.removeAllImages())

  return (
    <Button
      className={classes.clearHistoryButton}
      data-testid={'clear-history-button-' + props.position}
      disableFocusRipple
      onClick={handleClick}
      disabled={isUploadInProgress}
    >
      <Trans id="upload.queue.button.clear_list">Clear list</Trans>
    </Button>
  )
}

export default function UploadQueueContainer(): ReactElement {
  const imageFiles = useSelector(selectors.images)

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Typography variant="h3" className={classes.headline}>
            <Trans id="upload.queue.headline">Queue</Trans>
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'end' }}>
          {imageFiles.length > 0 ? <ClearHistoryButton position="top" /> : null}
        </Grid>

        <Grid item xs={11}>
          <Typography className={classes.detailsTitle}>
            <Trans id="upload.queue.header.details">Details</Trans>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.statusTitle}>
            <Trans id="upload.queue.header.status">Status</Trans>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.divider} />
        </Grid>

        {imageFiles.length === 0 ? (
          <Grid item xs={12}>
            <Typography className={classes.emptyListMessage}>
              <Trans id="upload.queue.hint.no_items">
                Oops, nothing here. Please add files first.
              </Trans>
            </Typography>
          </Grid>
        ) : (
          imageFiles.map((image) => <QueueDetailElement key={image.id} image={image} />)
        )}

        {imageFiles.length > 0 ? (
          <Grid item xs={12} style={{ textAlign: 'end' }}>
            <ClearHistoryButton position="bottom" />
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.spacer} />
        )}
      </Grid>
    </div>
  )
}
