import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { LinearProgress, Typography } from '@material-ui/core'

import { selectors } from 'store/imageUpload'
import classes from './UploadInProgressBlocker.module.scss'

const percentValue = (part: number, total: number) => {
  return total === 0 ? 0 : (part / total) * 100
}

export default function UploadInProgressBlocker(): ReactElement {
  const uploadCount = useSelector(selectors.uploadCount)
  const totalUploadCount = useSelector(selectors.totalUploadCount)

  return (
    <div className={classes.blockerRoot}>
      <Typography variant="h4" className={classes.blockerHeadline}>
        <Trans id="upload.progress.label">Upload in progress</Trans>
      </Typography>
      <Typography className={classes.counterText}>
        <span>{uploadCount}</span> / <span>{totalUploadCount}</span>
      </Typography>
      <LinearProgress
        variant="determinate"
        value={percentValue(uploadCount, totalUploadCount)}
        color="primary"
        classes={{ root: classes.progressBarRoot, colorPrimary: classes.progressBar }}
      />
    </div>
  )
}
