import type { UserDto } from 'api/adminUser'

import { Fragment, ReactElement, useEffect, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button, PropTypes, Typography } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { AxiosError } from 'axios'

import userAdminApi from 'api/adminUser'
import { translateErrorResponse } from 'api/errors'
import AlertMessageList from 'components/AlertMessageList/AlertMessageList'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import useBoundActions from 'hooks/useBoundActions'
import useMessageTextCollection, { MessageLevel } from 'hooks/useMessageTextCollection'
import { actions, selectors } from 'store/adminUsers'
import BulkUserDialog from './components/BulkUserDialog'
import CreateAdminDialog from './components/CreateAdminDialog'
import EditUserDialog from './components/EditUserDialog'
import { UserMenuOption } from './components/UserMenu'
import UserTable from './UserTable'
import 'react-image-lightbox/style.css'
import classes from './AdminPage.module.scss'

const Spinner = (props: { area: string; delay: number }) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area, delay: props.delay })
  return promiseInProgress ? <DataLoadingIndicator /> : <Fragment />
}

const commonButtonProps: { color: PropTypes.Color; variant: 'text' | 'outlined' | 'contained' } = {
  color: 'primary',
  variant: 'contained',
}

export default function UserAdminPage(): ReactElement {
  const boundActions = useBoundActions(actions)
  const [showUserEditDialog, setShowUserEditDialog] = useState(false)
  const roles = useSelector(selectors.roles)
  const brands = useSelector(selectors.brands)
  const suppliers = useSelector(selectors.suppliers)
  const [user, setUser] = useState<UserDto | undefined>(undefined)
  const [createAdminDialogOpen, setCreateAdminDialogOpen] = useState(false)
  const [bulkUserDialogMode, setBulkUserDialogMode] = useState<'update' | 'create' | null>(null)
  const [alertMessages, addErrors, addInfos, cleanAlertMessages] = useMessageTextCollection()

  useEffect(() => {
    boundActions.fetchUsers()
    boundActions.fetchUserOptions()
  }, [boundActions])

  const refreshUserList = () => {
    boundActions.cleanAlertMessages()
    boundActions.fetchUsers()
  }

  const fetchUser = (userId?: string) => {
    cleanAlertMessages()
    if (userId) {
      userAdminApi.fetchUser(userId).then(
        (response) => {
          setUser(response.data)
          setShowUserEditDialog(true)
        },
        (error: AxiosError) => {
          if (error.response?.status === 403) {
            addErrors(
              userId
                ? t`admin.accounts.error.edit_account_forbidden`
                : t`admin.accounts.error.create_account_forbidden`
            )
          }
          addErrors(error.message)
        }
      )
    } else {
      setUser(undefined)
      setShowUserEditDialog(true)
    }
  }

  const handleDownloadUserCsvExport = () => {
    userAdminApi.fetchUserCsvExport()
  }

  const handleEditUserClose = () => {
    setShowUserEditDialog(false)
  }

  const handleEditUserSubmit = (successMsg?: string) => {
    if (successMsg) {
      addInfos(successMsg, MessageLevel.Success)
    }
    setShowUserEditDialog(false)
    refreshUserList()
  }

  const handleClose = () => {
    setCreateAdminDialogOpen(false)
  }

  const handleCreateAdminSubmit = () => {
    setCreateAdminDialogOpen(false)
    refreshUserList()
  }

  const handleUserMenuClick = (user: UserDto, menuOption: UserMenuOption) => {
    cleanAlertMessages()
    const handleError = (error: AxiosError) => addErrors(translateErrorResponse(error))

    switch (menuOption) {
      case 'disable':
        userAdminApi[user.enabled ? 'disableUser' : 'enableUser'](user.userId).then(
          refreshUserList,
          handleError
        )
        break
      case 'reset password':
        userAdminApi.resetUserPassword(user.userId).then(() => {
          addInfos(
            t({
              id: `admin.accounts.reset.password.success`,
              values: { 0: user.username },
            }),
            MessageLevel.Success
          )
        }, handleError)
        break
    }
  }

  return (
    <Fragment>
      <div>
        <Typography variant="h3" className={classes.headline}>
          {t`admin.accounts.list.headline`}
        </Typography>
        <AlertMessageList messages={alertMessages} />
        <div className={classes.userButtons}>
          <div>
            <Button {...commonButtonProps} onClick={() => fetchUser()} data-testid="create-user">
              {t`admin.accounts.button.create_user`}
            </Button>
            <Button
              {...commonButtonProps}
              onClick={() => setCreateAdminDialogOpen(true)}
              data-testid="create-admin"
            >
              {t`admin.accounts.button.create_admin`}
            </Button>
          </div>
          <div>
            <Button {...commonButtonProps} onClick={() => setBulkUserDialogMode('create')}>
              {t`admin.accounts.button.create_bulk_user`}
            </Button>
            <Button {...commonButtonProps} onClick={() => setBulkUserDialogMode('update')}>
              {t`admin.accounts.button.update_bulk_user`}
            </Button>
          </div>
          <div>
            <Button
              {...commonButtonProps}
              onClick={() => handleDownloadUserCsvExport()}
              startIcon={<GetAppIcon />}
            >
              {t`admin.accounts.button.download_user_csv`}
            </Button>
          </div>
        </div>
        <Spinner area="loadUsersArea" delay={200} />
        <UserTable onClickUserInList={fetchUser} onClickUserMenu={handleUserMenuClick} />
      </div>

      <Spinner area="loadOptionsArea" delay={200} />
      <CreateAdminDialog
        open={createAdminDialogOpen}
        onClose={handleClose}
        onCommit={handleCreateAdminSubmit}
      />
      <BulkUserDialog
        mode={bulkUserDialogMode}
        onClose={() => setBulkUserDialogMode(null)}
        onRequestFinished={boundActions.fetchUsers}
      />
      <EditUserDialog
        open={showUserEditDialog}
        user={user}
        roles={roles}
        brands={brands}
        suppliers={suppliers}
        onClose={handleEditUserClose}
        onSubmit={handleEditUserSubmit}
      />
    </Fragment>
  )
}
