import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { actions, selectors } from 'store/bulkCopyGallery'
import { actions as galleryPageActions } from 'store/galleryPage'
import styles from './BulkCopyProgress.module.scss'

const percentValue = (current: number, total: number) => {
  return total === 0 ? 0 : (current / total) * 100
}

export const BulkCopyProgress: React.FC = () => {
  const dispatch = useDispatch()
  const copyInProgress = useSelector(selectors.copyInProgress)
  const copiedGalleries = useSelector(selectors.copiedGalleries)
  const totalGalleries = useSelector(selectors.enabledSelectedArticles).length
  const copyErrors = useSelector(selectors.copyErrors)
  const handleClose = () => {
    dispatch(actions.setCopyInProgress(false))
    dispatch(galleryPageActions.resetSelectedArticles())
  }

  return (
    <Dialog open={copyInProgress}>
      <DialogTitle>{t`galleryPage.bulkCopy.copy_galleries`}</DialogTitle>
      <DialogContent data-testid="bulkCopy.progress">
        <Typography className={styles.counterText} data-testid="bulkCopy.progress.label">
          <span>{copiedGalleries}</span> / <span>{totalGalleries}</span>
        </Typography>
        <LinearProgress
          variant="determinate"
          value={percentValue(copiedGalleries, totalGalleries)}
          color="primary"
          classes={{ root: styles.progressBarRoot, colorPrimary: styles.progressBar }}
        />
        {copyErrors?.length ? (
          <Alert severity="error">
            {copyErrors.map((error, idx) => (
              <React.Fragment key={idx}>
                {error}
                <br />
              </React.Fragment>
            ))}
          </Alert>
        ) : null}
      </DialogContent>
      {copyErrors?.length ? (
        <DialogActions className={styles.action}>
          <Button variant="contained" onClick={handleClose}>
            {t`general.close`}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}
