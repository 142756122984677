import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ArticleImageGallery from 'components/ArticleImageGallery'
import { TenantGalleryCallbackProps } from 'components/ArticleImageGallery/ArticleImageGallery'
import InfiniteScrolling, { InfiniteScrollingRow } from 'components/InifiniteScrolling'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/imageReviews'
import { ArticleGalleries } from 'types'
import classes from './ImageReviewList.module.scss'

const margin = 20
const estimatedItemSize = 413 + margin
const estimatedTenantAccordionSize = 79

interface ImageReviewListProps {
  showSuggestionsAsRejected?: boolean
}

const estimateHeight = (entry: ArticleGalleries) =>
  estimatedItemSize + (entry.tenants.length - 1) * estimatedTenantAccordionSize

const getItemKey = (entry: ArticleGalleries) => entry.articleNumber

const ImageReviewEntry: React.FC<
  InfiniteScrollingRow<ArticleGalleries, ImageReviewListProps & TenantGalleryCallbackProps>
> = (props) => {
  const { measureRef, entry, entryProps } = props

  return <ArticleImageGallery mode="imageReview" ref={measureRef} article={entry} {...entryProps} />
}

const ImageReviewList: React.FC<ImageReviewListProps> = (props) => {
  const { showSuggestionsAsRejected } = props
  const articles = useSelector(selectors.articles)
  const isLastRequest = useSelector(selectors.isLastRequest)
  const requestInProgress = useSelector(selectors.requestInProgress)
  const visibleStartIndex = useSelector(selectors.visibleStartIndex)
  const boundActions = useBoundActions(actions)
  const entryProps = useMemo(
    () => ({
      showSuggestionsAsRejected,
      onGalleryChange: boundActions.updateImageGallery,
      onAddToPaperBin: boundActions.addToPaperBin,
      onRemoveFromPaperBin: boundActions.removeFromPaperBin,
      onApproveGallery: boundActions.approveGallery,
      onRejectGallery: boundActions.rejectGallery,
      onRemove: boundActions.removeArticle,
      onToggleTenantSelection: boundActions.toggleSelectedTenant,
    }),
    [boundActions, showSuggestionsAsRejected]
  )

  return (
    <InfiniteScrolling
      className={classes.reviewList}
      entries={articles}
      entryProps={entryProps}
      Component={ImageReviewEntry}
      margin={margin}
      isLastRequest={isLastRequest}
      requestInProgress={requestInProgress}
      visibleStartIndex={visibleStartIndex}
      estimateHeight={estimateHeight}
      onEndReached={boundActions.fetchMoreImageReviews}
      onVisibleStartIndexChanged={boundActions.setVisibleStartIndex}
      itemKey={getItemKey}
    />
  )
}

export default ImageReviewList
