import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import keycloakClient, { initialized } from 'api/keycloak/KeycloakClient'
import ErrorNotification from 'components/ErrorNotification'
import { actions as profileActions, selectors as profileSelectors } from 'store/userProfile'
import AppLoadingIndicator from './components/AppLoadingIndicator'
import classes from './UserProfileLoader.module.scss'

const UserProfileLoader = ({ children }: { children?: React.ReactNode }) => {
  const dispatch = useDispatch()
  const isProfileLoaded = useSelector(profileSelectors.isLoaded)
  const errorResponse = useSelector(profileSelectors.errorResponse)

  useEffect(() => {
    const interval = setInterval(() => {
      if (initialized && keycloakClient.authenticated) {
        dispatch(profileActions.fetchUserProfile())
        clearInterval(interval)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [dispatch])

  if (errorResponse) {
    return (
      <div className={classes.root}>
        <ErrorNotification className={classes.errorNotification}>
          {errorResponse.map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </ErrorNotification>
      </div>
    )
  }

  if (!isProfileLoaded) {
    return <AppLoadingIndicator />
  }

  return children as unknown as JSX.Element
}

export default UserProfileLoader
