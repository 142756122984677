import { ReactElement, useState } from 'react'
import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import TranslateIcon from '@material-ui/icons/Translate'

import { Locale } from 'utils/localizations/Locale'
import { Locales } from 'utils/localizations/Locales'
import classes from './LanguageSwitcher.module.scss'

export default function LanguageSwitcher(props: {
  initialSelectedKey: string
  onSelectLanguage: (langKey: string) => void
}): ReactElement {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const displayLocaleOption = (locale: Locale) => {
    return (
      <MenuItem
        value={locale.key}
        key={locale.key}
        data-key={locale.key}
        className={classes.formControlEntry}
        onClick={() => saveLanguage(locale.key)}
      >
        {locale.label}
      </MenuItem>
    )
  }

  const showLanguageOptions: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const saveLanguage = (selectedLanguage: string) => {
    props.onSelectLanguage(selectedLanguage)
    closeLanguageOptions()
  }

  const closeLanguageOptions = () => {
    setAnchorEl(null)
  }

  const displayLocale = () => {
    const language = Locales.find((value) => props.initialSelectedKey === value.key)
    return language ? language.label : 'Unknown'
  }

  return (
    <Box className={classes.rootContainer}>
      <Button
        className={classes.languageButton}
        data-testid="language-switcher"
        aria-controls="simple-menu"
        aria-haspopup="true"
        startIcon={<TranslateIcon className={classes.lightGrey} />}
        endIcon={<ArrowDropDownIcon />}
        onClick={showLanguageOptions}
        variant="outlined"
        size="small"
      >
        {displayLocale()}
      </Button>

      <Menu
        data-testid="language-switcher-options"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageOptions}
      >
        {Locales.map((locale) => displayLocaleOption(locale))}
      </Menu>
    </Box>
  )
}
