import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { UserPermission } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import ServiceAdminPage from 'views/Admin/ServiceAdminPage'
import UserAdminPage from 'views/Admin/UserAdminPage'
import ArticleOverviewPage from 'views/ArticleOverview'
import Dashboard from 'views/Dashboard'
import GalleryPage from 'views/GalleryPage'
import ImageReviewsPage from 'views/ImageReviews'
import ImageUploadView from 'views/ImageUpload'
import SupplierFeedbackPage from 'views/SupplierFeedback'
import VariantDetailPage from 'views/VariantDetail'
import NotFoundError from './NotFoundError'
import UnauthorizedError from './UnauthorizedError'

export default function AppRoutes(): ReactElement {
  const permissions = useSelector(profileSelectors.permissions)
  const defaultRoute = useSelector(profileSelectors.defaultRoute)

  return (
    <Switch>
      <Redirect exact from="/" to={defaultRoute} />

      {permissions.has(UserPermission.SHOW_IMAGE_UPLOAD) && (
        <Route path="/bulkupload" component={ImageUploadView} />
      )}
      {permissions.has(UserPermission.SHOW_ADMIN_OVERVIEW) && (
        <Route exact path="/useradmin" component={UserAdminPage} />
      )}
      {permissions.has(UserPermission.SHOW_ADMIN_OVERVIEW) && (
        <Route exact path="/serviceadmin" component={ServiceAdminPage} />
      )}
      {permissions.has(UserPermission.SHOW_VARIANT_DETAILS) && (
        <Route path="/variant/:articleNumber?" component={VariantDetailPage} />
      )}
      {permissions.has(UserPermission.SHOW_ARTICLE_OVERVIEW) && (
        <Route path="/overview" component={ArticleOverviewPage} />
      )}
      {permissions.has(UserPermission.SHOW_IMAGE_REVIEW) && (
        <Route exact path="/images" component={ImageReviewsPage} />
      )}
      {permissions.has(UserPermission.SHOW_IMAGE_GALLERY_FEEDBACK) && (
        <Route path="/feedback/images" component={SupplierFeedbackPage} />
      )}
      {permissions.has(UserPermission.SHOW_ARTICLE_GALLERY_OVERVIEW) && (
        <Route path="/galleries" component={GalleryPage} />
      )}
      {(permissions.has(UserPermission.SHOW_MDM_DASHBOARD) ||
        permissions.has(UserPermission.SHOW_CONTACT_PERSON_DASHBOARD)) && (
        <Route path="/dashboard" component={Dashboard} />
      )}

      <Route path="/err/unauthorized" component={UnauthorizedError} />

      <Route component={NotFoundError} />
    </Switch>
  )
}
