import { t } from '@lingui/macro'

function textEnumeration(textList?: string[], visibleFirstElements = 2): string {
  if (!Array.isArray(textList)) {
    return ''
  }

  const firstPart = textList.slice(0, visibleFirstElements)
  const rest = textList.slice(visibleFirstElements, textList.length)

  if (firstPart.length === 0) {
    return ''
  }
  if (rest.length === 0) {
    return firstPart.length === 1
      ? firstPart[0]
      : textEnumeration(textList, visibleFirstElements - 1)
  }

  return [
    firstPart.join(', '),
    rest.length === 1 ? rest[0] : rest.length + ' ' + t`general.more`,
  ].join(' ' + t`general.and` + ' ')
}

export default textEnumeration
