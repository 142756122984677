import React, { useState } from 'react'
import { Fade, IconButton, Menu } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'

import classes from './MeatballsMenu.module.scss'

interface MeatballsMenuProps {
  size?: 'small' | 'medium'
  'data-testid'?: string
}

const MeatballsMenu: React.FC<MeatballsMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(ev.currentTarget)
  }
  const handleClose = (ev: React.MouseEvent<HTMLDivElement | HTMLLIElement>) => {
    ev.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <div
        className={classes.button}
        onClick={handleClick}
        data-testid={props['data-testid'] ?? 'meatballsMenu'}
      >
        <IconButton size={props.size} className={classes.button}>
          <MoreHoriz />
        </IconButton>
      </div>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleClose}
        onClick={handleClose}
        data-testid={props['data-testid'] ? props['data-testid'] + '-menulist' : undefined}
      >
        {props.children}
      </Menu>
    </React.Fragment>
  )
}

export default MeatballsMenu
