import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { makeStyles } from '@material-ui/core/styles'

import { selectors as profileSelectors } from 'store/userProfile'
import { BrandLabels } from 'types'
import arrayCompare from 'utils/arrayCompare'
import { ChangeFilterSwitch } from './ChangeFilterSwitch'
import TransferListFilter from './TransferListFilter'

interface BrandFilterProps {
  brandIds: string[]
  allowedBrands: string[]
  brandLabels: BrandLabels
  className?: string
  disabled?: boolean
  'data-testid'?: string
  onChange: (brandIds: string[]) => void
}

const useStyle = makeStyles((theme) => ({
  brandFilter: {
    display: 'inline-flex',
  },
}))

export const BrandFilter: React.FC<BrandFilterProps> = (props) => {
  const classes = useStyle()
  const { brandIds, allowedBrands, brandLabels, disabled, onChange } = props
  const totalSubscribedBrands = useSelector(profileSelectors.totalSubscribedBrands)
  const availableBrands = useMemo(
    () => allowedBrands.map((id) => ({ id, label: brandLabels.get(id) ?? id })),
    [allowedBrands, brandLabels]
  )
  const handleUserBrandSwitch = () => {
    onChange(selectedBrandsEqualsSubscribed ? [] : totalSubscribedBrands)
  }
  const selectedBrandsEqualsSubscribed = arrayCompare(brandIds, totalSubscribedBrands)

  return (
    <div className={classes.brandFilter} data-testid="brandFilter">
      <TransferListFilter
        disabled={disabled}
        label={t`image.review.filter.label.brands`}
        selected={brandIds}
        available={availableBrands}
        onChange={onChange}
        data-testid={props['data-testid'] ?? 'filter-brands'}
      />
      {totalSubscribedBrands.length > 0 ? (
        <ChangeFilterSwitch
          disabled={disabled}
          checked={selectedBrandsEqualsSubscribed}
          onChange={handleUserBrandSwitch}
          label={t`general.subscribed.brands`}
          tooltip={t`filter.tooltip.subscribed_brands`}
          data-testid="subscribed-brands-switch"
        />
      ) : null}
    </div>
  )
}
