import { MenuItem, Select } from '@material-ui/core'

import TenantLabel from 'components/TenantLabel'

export interface TenantSelectionProps {
  value: string
  onChange: (tenantId: string) => void
  tenants: string[]
  isDisabled?: (tenantId: string) => boolean
  className?: string
  labelId?: string
  'data-testid'?: string
}

export const TenantSelection: React.FC<TenantSelectionProps> = ({
  value,
  onChange,
  tenants,
  isDisabled,
  className,
  labelId,
  'data-testid': dataTestId = 'tenantSelection',
}) => {
  const handleTenantChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    onChange(ev.target.value as string)
  }
  const isTenantDisabled = (tenantId: string) => {
    return isDisabled ? isDisabled(tenantId) : false
  }

  return (
    <Select
      disabled={tenants.length < 2}
      labelId={labelId}
      value={value}
      onChange={handleTenantChange}
      className={className}
      data-testid={dataTestId}
      data-value={value}
    >
      {tenants.map((tenantId) => (
        <MenuItem key={tenantId} value={tenantId} data-testid={`${dataTestId}.menuItem`}>
          <TenantLabel tenantId={tenantId} disabled={isTenantDisabled(tenantId)} />
        </MenuItem>
      ))}
    </Select>
  )
}
