import { ReactElement } from 'react'
import { t } from '@lingui/macro'
import { Typography } from '@material-ui/core'

import { ImageDto, imagePositions } from 'api/types'
import GalleryAccordion from 'components/GalleryAccordion'
import { DraggableImage } from 'components/ImageGallery'
import classes from './Paperbin.module.scss'

interface PaperBinProps {
  articleNumber: string
  paperbin?: ImageDto[]
  galleryName: string
  draggableType: string
  className?: string
}

export default function GalleryPaperBin(props: PaperBinProps): ReactElement {
  return (
    <GalleryAccordion
      trash
      title={t`paperbin.headline`}
      isVisible={Boolean(props.paperbin && props.paperbin.length > 0)}
      itemCount={props.paperbin?.length || 0}
      className={props.className}
    >
      <Typography variant="caption" align="center" display="block">
        {t`paperbin.empties.after.reload`}
      </Typography>
      <div className={classes.gallery}>
        {props.paperbin
          ? props.paperbin.map((image, idx) => (
              <DraggableImage
                key={image.imageId}
                image={image}
                imagePosition={imagePositions[idx]}
                galleryName={props.galleryName}
                draggableType={props.draggableType}
                className={classes.draggableImage}
              />
            ))
          : null}
      </div>
    </GalleryAccordion>
  )
}
