import type { BrandSubscriptionsDto, UserProfileDto } from 'api/userProfile'
import type * as Payloads from './actions'
import type { UserProfileState } from './initialState'

import { createReducer } from 'store/utils'
import * as types from './actionTypes'
import initialState from './initialState'

type Handler<T = void> = (state: UserProfileState, payload: T) => UserProfileState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.SET_PROFILE]: ((state, { features, permissions, ...payload }): UserProfileState => ({
    ...state,
    ...payload,
    permissions: permissions ? new Set(permissions) : state.permissions,
    enabledFeatures: features ? new Set(features) : state.enabledFeatures,
  })) as Handler<UserProfileDto>,

  [types.UPDATE_BRAND_SUBSCRIPTIONS]: ((
    state,
    { brandIds, subscribedUsers }
  ): UserProfileState => ({
    ...state,
    subscribedUsers: subscribedUsers ?? state.subscribedUsers,
    brandSubscriptions: brandIds
      ? state.brandSubscriptions.map(
          (current): BrandSubscriptionsDto =>
            current.username === state.username ? { ...current, brandIds } : current
        )
      : state.brandSubscriptions,
  })) as Handler<Payloads.UpdateBrandSubscriptionsPayload>,

  [types.USER_PROFILE_LOADED]: ((state): UserProfileState => ({
    ...state,
    isLoaded: true,
    errorResponse: null,
  })) as HandlerWithoutPayload,

  [types.USER_PROFILE_LOAD_ERROR]: ((state, errorResponse): UserProfileState => ({
    ...state,
    errorResponse,
    isLoaded: false,
  })) as Handler<string[] | null>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type UserProfileAction = Actions[keyof Actions]

export default createReducer<UserProfileState, UserProfileAction>(initialState, handlers)
