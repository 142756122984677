import { createSelector } from 'reselect'

import { ImageState } from 'api/types'
import { createBasicSelectors } from 'store/utils'
import { ArticleGalleries, TenantGallery } from 'types'
import articleGalleries from 'utils/articleGalleries'
import { updateImageState } from 'utils/imageGallery'
import { isGlobalTenant } from 'utils/tenants'
import initialState from './initialState'

const selectors = createBasicSelectors(initialState, 'variantDetail')

const globalTenant = createSelector(selectors.tenants, (tenants) => tenants.find(isGlobalTenant))

function addGlobalGallery<T extends TenantGallery>(
  tenants: T[],
  globalTenant?: TenantGallery
): T[] {
  return globalTenant
    ? tenants.map(
        (tenant: T): T =>
          tenant !== globalTenant
            ? {
                ...tenant,
                galleries: {
                  ...tenant.galleries,
                  global: updateImageState(ImageState.INHERITED, globalTenant.galleries.approved),
                },
              }
            : tenant
      )
    : tenants
}

const tenantsWithGlobalGallery = createSelector(selectors.tenants, globalTenant, addGlobalGallery)

const imageSuggestionsWithGlobalGallery = createSelector(
  selectors.imageSuggestions,
  globalTenant,
  (imageSuggestions, globalTenant): ArticleGalleries | null =>
    globalTenant && imageSuggestions
      ? articleGalleries.addGlobalGallery(imageSuggestions, globalTenant)
      : imageSuggestions
)

export default {
  ...selectors,
  tenantsWithGlobalGallery,
  imageSuggestionsWithGlobalGallery,
}
