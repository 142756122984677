import Loader from 'react-loader-spinner'
import classnames from 'classnames'

import theme from 'style/douglas-materialui-theme'
import classes from './DataLoadingIndicator.module.scss'

interface DataLoadingIndicatorProps {
  className?: string
  'data-testid'?: string
}

const DataLoadingIndicator: React.FC<DataLoadingIndicatorProps> = (props) => {
  return (
    <div
      className={classnames(classes.rootContainer, props.className)}
      data-testid={props['data-testid'] ?? 'loading-indicator'}
    >
      <Loader type="ThreeDots" color={theme.palette.primary.main} height={100} width={100} />
    </div>
  )
}

export default DataLoadingIndicator
