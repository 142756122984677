import type { MediathekImage } from 'store/mediathek'

import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import AlertMessageList from 'components/AlertMessageList/AlertMessageList'
import { MessageLevel, TypedMessage } from 'hooks/useMessageTextCollection'
import { selectors } from 'store/mediathek'
import MediathekImageCard from './MediathekImageCard'
import classes from './Mediathek.module.scss'

export interface MediathekPanelProps {
  onCardReplace: (image: MediathekImage) => void
  onCardDelete: (image: MediathekImage) => void
}

const panelFlexProperties = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

export default function MediathekPanel(props: MediathekPanelProps) {
  const images = useSelector(selectors.images)
  const errors = useSelector(selectors.errors)

  return (
    <div className={classes.panelRoot}>
      <AlertMessageList
        messages={errors.map(
          (s) =>
            ({
              message: s,
              msgType: MessageLevel.Error,
            } as TypedMessage)
        )}
      />

      <Box display="flex" {...panelFlexProperties} gridGap={'0.5rem'}>
        {images.map((i) => (
          <MediathekImageCard
            key={i.imageId}
            image={i}
            onCardReplace={props.onCardReplace}
            onCardDelete={props.onCardDelete}
          />
        ))}
      </Box>
    </div>
  )
}
