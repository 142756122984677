import { createSelector } from 'reselect'

import { createBasicSelectors } from 'store/utils'
import { isGlobalTenant } from 'utils/tenants'
import initialState, { UploadStatus } from './initialState'
import { createUploadReport } from './utils'

const selectors = createBasicSelectors(initialState, 'imageUpload')

const processedImages = createSelector(selectors.images, (images) =>
  images.filter((image) => image.isImageProcessed)
)

const invalidProcessedImages = createSelector(processedImages, (images) =>
  images.filter((image) => image.uploadStatus === UploadStatus.INVALID)
)

const unprocessedImages = createSelector(selectors.images, (images) =>
  images.filter((image) => !image.isImageProcessed)
)

const validUnprocessedImages = createSelector(unprocessedImages, (images) =>
  images.filter((image) => image.uploadStatus !== UploadStatus.INVALID)
)

const isUploadInProgress = createSelector(
  selectors.uploadCount,
  selectors.totalUploadCount,
  (uploadCount, totalUploadCount) => totalUploadCount > 0 && uploadCount < totalUploadCount
)

const isUploadAllowed = createSelector(
  selectors.selectedTenants,
  validUnprocessedImages,
  (selectedTenants, validUnprocessedImages) =>
    selectedTenants.length > 0 && validUnprocessedImages.length > 0
)

const globalTenant = createSelector(selectors.allowedTenants, (tenants) =>
  tenants.find((t) => isGlobalTenant(t.key))
)

const uploadReportLink = createSelector(processedImages, (processedImages) => {
  return processedImages.length > 0 ? createUploadReport(processedImages) : null
})

export default {
  ...selectors,
  processedImages,
  invalidProcessedImages,
  unprocessedImages,
  validUnprocessedImages,
  isUploadInProgress,
  isUploadAllowed,
  globalTenant,
  uploadReportLink,
}
