import { FeatureToggles } from 'types'

// @ts-expect-error ts(7015)
const primaEnv = window['_env_']

type PrimaEnvironment = 'test' | 'dev' | 'stg' | 'prd'
export let environment: PrimaEnvironment = 'prd'
if (primaEnv?.REACT_APP_NAMESPACE === 'prima-stg') {
  environment = 'stg'
} else if (process.env.NODE_ENV === 'test') {
  environment = 'test'
} else if (
  process.env.NODE_ENV === 'development' ||
  primaEnv?.REACT_APP_NAMESPACE === 'prima-dev'
) {
  environment = 'dev'
}

interface PrimaConfig {
  environment: PrimaEnvironment
  featureToggles: FeatureToggles[]
}

const primaConfig: PrimaConfig = {
  environment,
  // List of possible feature toggles the frontend is interested in
  featureToggles: [],
}

export default primaConfig
