import { Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { Checkbox } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import classNames from 'classnames'

import { ArticleIdentifier } from 'api/types'
import StockStatusIndicator from 'components/StockStatusIndicator'
import TenantFlag from 'components/TenantFlag'
import { ArticleMasterData } from 'types'
import { isGlobalTenant, tenantLabel } from 'utils/tenants'
import classes from './ArticleProductDataSummary.module.scss'

interface ArticleProductDataSummaryProps {
  article: ArticleMasterData
  onToggleSelection?: (article: ArticleIdentifier) => void
  hideBrandInTitle?: boolean
  hideArticleNoInTitle?: boolean
  hideEanInTitle?: boolean
  hideStockStatusInTitle?: boolean
  disableVariantLink?: boolean
}

const ArticleNumberInHeader = (props: {
  articleNumber: string
  variantDetailLink?: string
}): ReactElement =>
  props.variantDetailLink ? (
    <Link to={props.variantDetailLink}>
      <span data-testid="articleNumber">{props.articleNumber}</span>
      <OpenInNewIcon fontSize="small" className={classes.linkIcon} />
    </Link>
  ) : (
    <span>{props.articleNumber}</span>
  )

function ArticleSelectionCheckbox(props: {
  available: boolean
  isSelected: boolean
  handleSelectionChange?: () => void
}): ReactElement | null {
  return props.available ? (
    <Checkbox color="primary" checked={props.isSelected} onChange={props.handleSelectionChange} />
  ) : null
}

function ArticleNumberLine(props: {
  available: boolean
  articleNumber: string
  detailLink?: string
}): ReactElement {
  if (!props.available) {
    return <Grid item xs={12} />
  }

  return (
    <Fragment>
      <Grid item xs={4}>
        <span>
          <Trans id="image.review.article.headline.article_number">Art. Number</Trans>:{' '}
        </span>
      </Grid>
      <Grid item xs={8}>
        <ArticleNumberInHeader
          articleNumber={props.articleNumber}
          variantDetailLink={props.detailLink}
        />
      </Grid>
    </Fragment>
  )
}

function EanLabelLine(props: { available: boolean; ean: string }): ReactElement {
  if (!props.available || !props.ean) {
    return <Grid item xs={12} />
  }

  return (
    <Fragment>
      <Grid item xs={4}>
        EAN:
      </Grid>
      <Grid item xs={8}>
        {props.ean}
      </Grid>
    </Fragment>
  )
}

function BrandLine(props: { available: boolean; brandName?: string }): ReactElement {
  if (!props.available) {
    return <Grid item xs={12} />
  }

  return (
    <Fragment>
      <Grid item xs={3}>
        <Trans id="image.review.article.headline.article_brand">Brand:</Trans>
      </Grid>
      <Grid item xs={9}>
        <span data-testid="articleBrand">{props.brandName ? props.brandName : '-'}</span>
      </Grid>
    </Fragment>
  )
}

function StockStatusLine(props: {
  available: boolean
  isInStock: boolean | null
  specialLabelText: boolean
}): ReactElement {
  if (!props.available) {
    return <Grid item xs={12} />
  }

  return (
    <Fragment>
      <Grid item xs={3} />
      <Grid item xs={9} style={{ flexGrow: 2 }}>
        <StockStatusIndicator isInStock={props.isInStock} useSpecialText={props.specialLabelText} />
      </Grid>
    </Fragment>
  )
}

export default function ArticleProductDataSummary(
  props: ArticleProductDataSummaryProps
): ReactElement {
  const { article } = props
  const variantDetailLink = !props.disableVariantLink
    ? '/variant/' + article.articleNumber
    : undefined
  const handleSelectionChange = () => props.onToggleSelection?.(article)

  return (
    <div data-testid="articleHeadline" className={classes.articleHeadlineRoot}>
      <div className={classes.selection}>
        <ArticleSelectionCheckbox
          available={props.onToggleSelection !== undefined}
          isSelected={Boolean(article.isSelected)}
          handleSelectionChange={handleSelectionChange}
        />
      </div>
      <div className={classes.articleHeader}>
        <Grid container>
          <ArticleNumberLine
            available={!props.hideArticleNoInTitle}
            articleNumber={article.articleNumber}
            detailLink={variantDetailLink}
          />
          <EanLabelLine available={!props.hideEanInTitle} ean={article.ean} />
        </Grid>
      </div>
      <div
        className={classNames(classes.articleHeader, classes.flag)}
        title={`Tenant: ${article.tenantId}`}
      >
        <TenantFlag tenantId={article.tenantId} shopName={tenantLabel(article.tenantId)} />
      </div>
      <div className={classes.articleHeader}>
        <Grid container>
          <BrandLine available={!props.hideBrandInTitle} brandName={article.brand} />
          <StockStatusLine
            available={!props.hideStockStatusInTitle}
            isInStock={article.isInStock}
            specialLabelText={isGlobalTenant(article)}
          />
        </Grid>
      </div>
    </div>
  )
}
