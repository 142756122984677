import React, { Fragment, ReactNode, useCallback, useState } from 'react'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import classnames from 'classnames'
import { pick } from 'ramda'

import classes from './PageMenuBar.module.scss'

export interface GroupProps {
  collapsable?: boolean
  label: string
  icon?: ReactNode
  children: ReactNode
  'data-testid'?: string
}

export default function PageMenuGroup(props: GroupProps) {
  const [groupOpen, setGroupOpen] = useState(false)
  const { collapsable } = props

  const switchGroupOpen = () => setGroupOpen((wasOpen) => !wasOpen)

  const MenuGroupTitle = useCallback(
    (titleProps: { 'data-testid'?: string; children: ReactNode }) =>
      collapsable ? (
        <ListItem
          onClick={switchGroupOpen}
          className={classnames(classes.menuGroup, classes.itemClickable)}
          data-testid={titleProps['data-testid']}
        >
          {titleProps.children}
          {groupOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      ) : (
        <ListItem className={classes.menuGroup} data-testid={titleProps['data-testid']}>
          {titleProps.children}
        </ListItem>
      ),
    [collapsable, groupOpen]
  )

  const MenuGroupContent = useCallback(
    (contentProps: { children: ReactNode }) =>
      collapsable ? (
        <Collapse in={groupOpen} timeout="auto" className={classes.menuGroupContent}>
          {contentProps.children}
        </Collapse>
      ) : (
        <div className={classes.menuGroupContent}>{contentProps.children}</div>
      ),
    [collapsable, groupOpen]
  )

  return (
    <Fragment>
      <MenuGroupTitle {...pick(['data-testid'], props)}>
        <div className={classes.menuGroupSelectionMarkerPlaceholder} />
        <ListItemIcon className={classes.menuIcon}>{props.icon}</ListItemIcon>
        <ListItemText
          primary={props.label}
          className={classnames({ [classes.menuGroupLabelWithoutIcon]: props.icon === undefined })}
        />
      </MenuGroupTitle>

      <MenuGroupContent>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </MenuGroupContent>
    </Fragment>
  )
}
