import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { makeStyles } from '@material-ui/core/styles'

import Paper from 'components/Paper'
import {
  BrandFilter,
  DateFilter,
  EanArticleNumbersFilter,
  ResetAllFilterButton,
  SupplierFilter,
  TenantFilter,
  useInitialFiltersChangedListener,
  VerticalFilterDivider,
} from 'components/VariantFilter'
import { actions, selectors } from 'store/imageReviews'
import { ImageReviewsFilterName, initialFilters } from 'store/imageReviews/initialState'
import { selectors as profileSelectors } from 'store/userProfile'
import './DatePicker.css'

interface ImageReviewFilterProps {
  disabled?: boolean
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '3em',
  },
  filterShops: {
    width: theme.spacing(25),
    marginRight: '20px',
  },
  dateFilterRoot: {
    display: 'flex',
  },
  filterUploadStartDate: {
    width: theme.spacing(25),
    marginRight: '20px',
  },
  filterUploadEndDate: {
    width: theme.spacing(25),
    marginRight: '20px',
  },
}))

export const ImageReviewsFilter: React.FC<ImageReviewFilterProps> = (props) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const filter = useSelector(selectors.filter)

  const allowedSuppliers = useSelector(selectors.allowedSuppliers)
  const allowedTenants = useSelector(selectors.allowedTenants)
  const allowedBrands = useSelector(selectors.allowedBrands)
  const brandLabels = useSelector(profileSelectors.brandLabels)
  const supplierLabels = useSelector(profileSelectors.supplierLabels)

  const totalSubscribedBrands: string[] = useSelector(profileSelectors.totalSubscribedBrands)

  const resetFiltersUnavailable = useInitialFiltersChangedListener(filter, initialFilters)

  const handleFilterChange = (name: ImageReviewsFilterName) => (value: unknown) => {
    dispatch(actions.setFilter({ [name]: value }))
  }
  const handleFilterChangeAndUpdate = (name: ImageReviewsFilterName) => (value: unknown) => {
    dispatch(actions.setFilter({ [name]: value }))
    dispatch(actions.fetchImageReviews())
  }
  const handleResetAll = () => {
    dispatch(actions.resetFilter(totalSubscribedBrands))
    dispatch(actions.fetchImageReviews())
  }
  const handleSubmit = () => {
    dispatch(actions.fetchImageReviews())
  }

  return (
    <Paper className={classes.root} data-testid="review-filter-bar">
      <EanArticleNumbersFilter
        disabled={props.disabled}
        value={filter.articleNumbers}
        onChange={handleFilterChange('articleNumbers')}
        onSubmit={handleSubmit}
      />
      <VerticalFilterDivider />
      <BrandFilter
        disabled={props.disabled}
        brandIds={filter.brandIds}
        allowedBrands={allowedBrands}
        brandLabels={brandLabels}
        onChange={handleFilterChangeAndUpdate('brandIds')}
        data-testid="filter-brands"
      />
      <VerticalFilterDivider />
      <SupplierFilter
        disabled={props.disabled}
        supplier={filter.supplierIds}
        allowedSuppliers={allowedSuppliers}
        supplierLabels={supplierLabels}
        onChange={handleFilterChangeAndUpdate('supplierIds')}
      />
      <VerticalFilterDivider />
      {allowedTenants.length > 1 && (
        <Fragment>
          <div className={classes.filterShops}>
            <TenantFilter
              label={t`image.review.filter.label.shops`}
              tenants={allowedTenants}
              availableTenants={filter.tenantIds}
              onFilterChanged={handleFilterChange('tenantIds')}
              onClose={handleSubmit}
              disabled={props.disabled}
              data-testid="filter-tenants"
            />
          </div>
          <VerticalFilterDivider />
        </Fragment>
      )}

      <div className={classes.dateFilterRoot}>
        <div className={classes.filterUploadStartDate}>
          <DateFilter
            value={filter.uploadStart}
            label={t`image.review.filter.label.upload_date_from`}
            disabled={props.disabled}
            onChange={handleFilterChange('uploadStart')}
            onSubmit={handleSubmit}
            maxDate={new Date()}
          />
        </div>

        <div className={classes.filterUploadEndDate}>
          <DateFilter
            value={filter.uploadEnd}
            label={t`image.review.filter.label.upload_date_to`}
            disabled={props.disabled}
            onChange={handleFilterChange('uploadEnd')}
            onSubmit={handleSubmit}
          />
        </div>
      </div>

      <VerticalFilterDivider />

      <ResetAllFilterButton hidden={resetFiltersUnavailable} onResetAll={handleResetAll} />
    </Paper>
  )
}

export default ImageReviewsFilter
