import { Fragment, ReactNode } from 'react'

import classes from './Fieldset.module.scss'

interface FieldsetProps {
  label: string
  children: ReactNode
}

export default function Fieldset(props: FieldsetProps) {
  return (
    <Fragment>
      <fieldset className={classes.fieldset} data-testid="fieldset">
        <legend className={classes.fieldsetLegend}>{props.label}</legend>
        <div className={classes.fieldsetContent}>{props.children}</div>
      </fieldset>
    </Fragment>
  )
}
