import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Refresh'

import Paper from 'components/Paper'
import { ComponentCommonProps } from 'components/types'
import style from './NoFilterWarning.module.scss'

export type NoFilterWarningProps = ComponentCommonProps & {
  onClick: () => void
}

export const NoFilterWarning: React.FC<NoFilterWarningProps> = ({
  onClick,
  'data-testid': dataTest = 'noFilterWarning',
}) => {
  return (
    <Paper className={style.root} data-testid={dataTest}>
      <div className={style.message}>{t`general.too_many_potential_results_without_filter`}</div>
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReplayIcon />}
          onClick={onClick}
          data-testid={`${dataTest}.button`}
        >
          {t`general.load_anyway`}
        </Button>
      </div>
    </Paper>
  )
}
