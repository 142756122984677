import React from 'react'
import Loader from 'react-loader-spinner'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import theme from 'style/douglas-materialui-theme'

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: 'white',
  },
}))

export default function TileLoadingIndicator() {
  const classes = useStyles()
  return (
    <Tooltip
      title="Loading Data..."
      placement="top"
      className={classes.loading}
      data-testid="tile-loading-indicator"
    >
      <span>
        <Loader type="Oval" color={theme.palette.background.paper} height={20} width={20} />
      </span>
    </Tooltip>
  )
}
