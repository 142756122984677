export const SET_IMAGE_REVIEWS = 'imagereviews/SET_IMAGE_REVIEWS'
export const SET_AVAILABLE_FILTER = 'imagereviews/SET_AVAILABLE_FILTER'
export const ADD_IMAGES_TO_REVIEW = 'imagereviews/ADD_IMAGES_TO_REVIEW'
export const ADD_TO_PAPERBIN = 'imagereviews/ADD_TO_PAPERBIN'
export const REMOVE_FROM_PAPERBIN = 'imagereviews/REMOVE_FROM_PAPERBIN'
export const SET_FILTER = 'imagereviews/SET_FILTER'
export const TOGGLE_SELECTED_GALLERY = 'imagereviews/TOGGLE_SELECTED_GALLERY'
export const TOGGLE_ALL_SELECTED_TENANTS = 'imagereviews/TOGGLE_ALL_SELECTED_TENANTS'
export const BULK_APPROVAL_STARTED = 'imagereviews/BULK_APPROVAL_STARTED'
export const BULK_APPROVAL_FINISHED = 'imagereviews/BULK_APPROVAL_FINISHED'
export const INCREASE_BULK_COUNT = 'imagereviews/INCREASE_BULK_COUNT'
export const RESET_FILTER = 'imagereviews/RESET_FILTER'
export const SET_BRAND_FILTER = 'imagereviews/SET_BRAND_FILTER'
export const INITIATED_REQUEST = 'imagereviews/INITIATED_REQUEST'
export const FINISHED_REQUEST = 'imagereviews/FINISHED_REQUEST'
export const ADD_ERROR_NOTIFICATION = 'imagereviews/ADD_ERROR_NOTIFICATION'
export const REMOVE_TENANT = 'imagereviews/REMOVE_TENANT'
export const REMOVE_ARTICLE = 'imagereviews/REMOVE_ARTICLE'
export const SET_VISIBLE_START_INDEX = 'imagereviews/SET_VISIBLE_START_INDEX'
export const REMOVE_TENANT_FROM_ARTICLE = 'imagereviews/REMOVE_TENANT_FROM_ARTICLE'
export const MERGE_ARTICLES = 'imagereviews/MERGE_ARTICLES'
export const UPDATE_TENANT = 'imagereviews/UPDATE_TENANT'
