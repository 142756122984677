import React from 'react'
import { useSelector } from 'react-redux'
import * as SentryIntegrations from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { environment } from 'config'
import usePageType from 'hooks/usePageType'
import { selectors as profileSelectors } from 'store/userProfile'

const sentryOptions = {
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 0.05,
  normalizeDepth: 10,
  attachStackTrace: true,
  release: process.env.COMMIT_HASH,
  ignoreErrors: [/object XMLHttpRequest/, 'Non-Error promise rejection captured'],
  ...(environment === 'prd' || environment === 'stg'
    ? {
        integrations: [new Integrations.BrowserTracing()],
      }
    : {
        beforeSend: () => null,
        integrations: [new SentryIntegrations.Debug()],
      }),
}
Sentry.init(sentryOptions)

const SentryProvider: React.FC = ({ children }) => {
  const pageType = usePageType()
  const username = useSelector(profileSelectors.username)
  const enabledFeatures = useSelector(profileSelectors.enabledFeatures)
  const permissions = useSelector(profileSelectors.permissions)

  Sentry.setUser(username ? { username } : null)
  Sentry.configureScope((scope) => {
    scope.setExtras({
      features: enabledFeatures ? [...enabledFeatures] : [],
      permissions: permissions ? [...permissions] : [],
      environment,
    })
    scope.setTag('page', pageType)
  })

  return (
    <Sentry.ErrorBoundary fallback={<>An error has ocurred</>}>{children}</Sentry.ErrorBoundary>
  )
}

export default SentryProvider
