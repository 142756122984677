import { i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { makeStyles, MenuItem, SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CommentIcon from '@material-ui/icons/Comment'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'

import { ImageGallery } from 'api/types'

export enum ImageContextMenuType {
  Approve = 'approve',
  Reject = 'reject',
  EditFeedback = 'editfeedback',
  Delete = 'delete',
  Download = 'download',
  Mediathek = 'mediathek',
}

defineMessage({ id: 'imagegallery.menu.approve' })
defineMessage({ id: 'imagegallery.menu.reject' })
defineMessage({ id: 'imagegallery.menu.edit_feedback' })
defineMessage({ id: 'imagegallery.menu.delete' })
defineMessage({ id: 'imagegallery.menu.download_image' })
defineMessage({ id: 'imagegallery.menu.mediathek' })

const imageMenuProps: Record<ImageContextMenuType, [OverridableComponent<SvgIconTypeMap>, string]> =
  {
    [ImageContextMenuType.Approve]: [CheckIcon, 'imagegallery.menu.approve'],
    [ImageContextMenuType.Reject]: [ClearIcon, 'imagegallery.menu.reject'],
    [ImageContextMenuType.EditFeedback]: [CommentIcon, 'imagegallery.menu.edit_feedback'],
    [ImageContextMenuType.Delete]: [DeleteIcon, 'imagegallery.menu.delete'],
    [ImageContextMenuType.Download]: [CloudDownloadIcon, 'imagegallery.menu.download_image'],
    [ImageContextMenuType.Mediathek]: [DashboardIcon, 'imagegallery.menu.mediathek'],
  }

interface ImageContextMenuItemProps {
  menuType: ImageContextMenuType
  imagePosition: keyof ImageGallery
  onClick?: (menuType: ImageContextMenuType) => void
}

const useStyles = makeStyles({
  menuItemIcon: {
    marginRight: 10,
  },
})

const ImageContextMenuItem: React.FC<ImageContextMenuItemProps> = ({
  menuType,
  imagePosition,
  onClick,
}) => {
  const classes = useStyles()
  const [Icon, label] = imageMenuProps[menuType]
  const handleClick = () => onClick?.(menuType)

  return (
    <MenuItem onClick={handleClick} data-testid={`imagemenu-${imagePosition}-${menuType}`}>
      <Icon className={classes.menuItemIcon} />
      {i18n._({ id: label })}
    </MenuItem>
  )
}

export default ImageContextMenuItem
