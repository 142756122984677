import { ImageFeedback } from 'api/imageReviews'
import { SupplierFeedbackImagesMapDto } from 'api/supplierFeedback'
import { ArticleMasterData } from 'types'

export type SupplierFeedbackData = ArticleMasterData & {
  galleryFeedbackKeys: ImageFeedback[]
  replacementImagesRequired: boolean
  gallery: SupplierFeedbackImagesMapDto
}

export interface SupplierFeedbackState {
  feedbackList: SupplierFeedbackData[]
  errors: string[] | null
}

const initialState: SupplierFeedbackState = {
  feedbackList: [],
  errors: [],
}

export default initialState
