import type { MediathekImage } from 'store/mediathek'
import type { ConfirmDeleteInfo } from './ConfirmDeleteDialog'
import type { ConfirmReplaceInfo } from './ConfirmReplaceDialog'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Dialog, IconButton } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

import { ImagePosition } from 'api/types'
import variantDetailApi from 'api/variantDetail'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import TenantFlag from 'components/TenantFlag'
import { actions, selectors } from 'store/mediathek'
import { TenantGallery } from 'types'
import { tenantLabel } from 'utils/tenants'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import ConfirmReplaceDialog from './ConfirmReplaceDialog'
import MediathekPanel from './MediathekPanel'
import classes from './Mediathek.module.scss'

interface MediathekDialogProps {
  isOpen: boolean
  tenantGallery: TenantGallery
  imagePosition: ImagePosition
  onClose: () => void
  onCardReplace: (image: MediathekImage) => void
}

const isClickOutsideDialogOrEscapeKey = (reason: string) =>
  reason === 'backdropClick' || reason === 'escapeKeyDown'

export default function MediathekDialog(props: MediathekDialogProps) {
  const { tenantGallery, isOpen } = props
  const { tenantId, articleNumber } = tenantGallery
  const [confirmTenant, setConfirmTenant] = useState<ConfirmReplaceInfo | null>(null)
  const [confirmDelete, setConfirmDelete] = useState<ConfirmDeleteInfo | null>(null)
  const requestInProgress = useSelector(selectors.requestInProgress)
  const mediathekArticleNumber = useSelector(selectors.articleNumber)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.resetMediathek())
  }, [dispatch])

  useEffect(() => {
    if (isOpen && mediathekArticleNumber !== articleNumber) {
      dispatch(actions.fetchMediathek(articleNumber))
    }
  }, [isOpen, mediathekArticleNumber, articleNumber, dispatch])

  const handleCloseDialog = (event: never, reason: string) =>
    !isClickOutsideDialogOrEscapeKey(reason) && props.onClose()
  const handleCardReplace = (image: MediathekImage) => {
    const imageIds = new Set(
      (image.uploadedInfo || []).map((uploadInfo) => uploadInfo.uploadedForTenantId)
    )
    if (!imageIds.has(tenantId)) {
      setConfirmTenant({ image, originalTenants: [...imageIds], newTenant: tenantId })
    } else {
      props.onCardReplace(image)
    }
  }
  const handleConfirmReplace = (image: MediathekImage) => {
    setConfirmTenant(null)
    props.onCardReplace(image)
  }
  const handleCardDelete = (image: MediathekImage) => {
    setConfirmDelete({ image })
  }
  const handleConfirmDelete = (image: MediathekImage) => {
    variantDetailApi.deleteMediathekImage(articleNumber, image.imageId).then(() => {
      dispatch(actions.fetchMediathek(articleNumber))
    })
    setConfirmDelete(null)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="mediathek-dialog-title"
      scroll="body"
      maxWidth="lg"
    >
      <MuiDialogTitle id="mediathek-dialog-title" disableTypography className={classes.dialogTitle}>
        <div className={classes.headline}>
          <div>{t`mediathek.dialog.title`}</div>
          <div className={classes.contextInformation}>
            <TenantFlag tenantId={tenantId} />
            &nbsp;{tenantLabel(tenantId)}, position {props.imagePosition}
          </div>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            className={classes.closeDialogIcon}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </MuiDialogTitle>

      {requestInProgress ? (
        <DataLoadingIndicator />
      ) : (
        <MediathekPanel onCardReplace={handleCardReplace} onCardDelete={handleCardDelete} />
      )}
      {confirmTenant ? (
        <ConfirmReplaceDialog
          info={confirmTenant}
          open={true}
          onClose={() => setConfirmTenant(null)}
          onConfirm={handleConfirmReplace}
        />
      ) : null}
      {confirmDelete ? (
        <ConfirmDeleteDialog
          info={confirmDelete}
          open={true}
          onClose={() => setConfirmDelete(null)}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
    </Dialog>
  )
}
