import React, { useState } from 'react'
import { t } from '@lingui/macro'
import { Popover } from '@material-ui/core'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'

import classes from './MissingImageHint.module.scss'

const MissingImageHint: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root} data-testid="missingImageHint">
      <PhotoLibraryIcon
        className={classes.icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handlePopoverClose}
      >
        {t`image.review.article.has.no.image`}
      </Popover>
    </div>
  )
}

export default MissingImageHint
