import React, { useMemo, useState } from 'react'
import { useField } from 'react-final-form'
import { FixedSizeList } from 'react-window'
import { t } from '@lingui/macro'
import { FormControl, FormGroup, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { BrandDto } from 'api/types'
import BrandCheckbox from './BrandCheckbox'

const useStyles = makeStyles((theme) => ({
  brandSelection: { marginTop: theme.spacing(1) },
}))

interface BrandSelectionProps {
  availableBrands: BrandDto[]
  fullWidth?: boolean
  formValueNameForSelected?: string
}

const BrandSelection: React.FC<BrandSelectionProps> = (props) => {
  const classes = useStyles()
  const { availableBrands, fullWidth, formValueNameForSelected } = props
  const [brandFilter, setBrandFilter] = useState('')
  const totalBrands = useMemo(
    () =>
      availableBrands.map((brand) => ({
        value: brand.id,
        label: brand.name,
      })),
    [availableBrands]
  )
  const {
    input: { value: hasNoBrandRestriction },
  } = useField('hasNoBrandRestriction', { subscription: { value: true } })

  const filteredBrands = useMemo(() => {
    const upperCaseBrandFilter = brandFilter.toLocaleUpperCase()

    return totalBrands.filter(
      (b) => !upperCaseBrandFilter || b.label.indexOf(upperCaseBrandFilter) !== -1
    )
  }, [brandFilter, totalBrands])

  const selectionData = {
    filteredBrand: filteredBrands,
    formValueName: formValueNameForSelected ?? 'brands',
  }

  const handleBrandFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setBrandFilter(ev.target.value)

  return hasNoBrandRestriction ? null : (
    <div className={classes.brandSelection}>
      <div>
        <TextField
          id="articleNumberFilterText"
          type="text"
          margin="dense"
          fullWidth={fullWidth}
          style={fullWidth ? undefined : { minWidth: '15rem' }}
          value={brandFilter}
          onChange={handleBrandFilterChange}
          placeholder={t`admin.account.brandfilter`}
        />
      </div>
      <FormControl
        style={{ width: fullWidth ? '100%' : 'unset', minWidth: fullWidth ? undefined : '15rem' }}
      >
        <FormGroup>
          <FixedSizeList
            height={200}
            itemCount={filteredBrands.length}
            width={fullWidth ? '100%' : 'unset'}
            itemSize={38}
            itemData={selectionData}
          >
            {BrandCheckbox}
          </FixedSizeList>
        </FormGroup>
      </FormControl>
    </div>
  )
}

export default React.memo(BrandSelection)
