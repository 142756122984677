import Keycloak, { KeycloakConfig, KeycloakInstance } from 'keycloak-js'

import logger from 'utils/logger'

// @ts-expect-error ts(7015)
const primaEnv = window['_env_']
const keycloakUrl = primaEnv?.REACT_APP_KEYCLOAK_URL ?? process.env.REACT_APP_KEYCLOAK_URL
const keycloakRealm = primaEnv?.REACT_APP_KEYCLOAK_REALM ?? process.env.REACT_APP_KEYCLOAK_REALM

// @ts-expect-error:  ts(7009)
const keycloakClient: KeycloakInstance = new Keycloak({
  realm: keycloakRealm,
  url: keycloakUrl,
  clientId: 'prima-login',
} as KeycloakConfig)

export let initialized = false

function initKeycloakClient() {
  initialized = false
  keycloakClient
    .init({
      onLoad: 'login-required',
      enableLogging: true,
      checkLoginIframe: true,
      redirectUri: window.location.href,
    })
    .then((authenticated) => {
      initialized = true
    })
    .catch((error) => {
      initialized = false
      logger.error(`keycloak native promise - error: ${error}`)
    })
}

keycloakClient.onTokenExpired = () => {
  logger.warn('token expired at', new Date())

  keycloakClient
    .updateToken(5)
    .then((refreshed) => {
      if (refreshed) {
        initialized = true
      }
    })
    .catch(() => {
      logger.error('Failed to refresh the token, or the session has expired')
      keycloakClient.logout()
      initKeycloakClient()
    })
}

initKeycloakClient()

export default keycloakClient
