import React, { Fragment } from 'react'
import { t } from '@lingui/macro'
import { Tooltip, withStyles } from '@material-ui/core'
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined'

import { translatedImageFeedback } from 'api/imageReviews'
import { ImageDto, ImageState, ImageType } from 'api/types'

interface FeedbackTooltipProps {
  image?: ImageDto
}

const RejectedImageTooltip: React.FC<FeedbackTooltipProps> = ({ image }) => {
  const feedbackTextList = image?.feedback ? image.feedback.map(translatedImageFeedback) : []
  const substitutionRequired = image?.requestNewImage

  return (
    <Fragment>
      {feedbackTextList.length > 0 ? (
        <Fragment>
          {t`image.slot.popover.feedback_text_prefix`}
          <ul>
            {feedbackTextList.map((feedback, idx) => (
              <li key={idx}>{feedback}</li>
            ))}
          </ul>
        </Fragment>
      ) : (
        t`image.slot.popover.feedback_text_no_reason`
      )}
      <div>
        {substitutionRequired ? (
          <div style={{ display: 'flex' }}>
            <AutorenewOutlinedIcon fontSize="small" color="error" />
            {t`image.slot.popover.substitution_requested`}
          </div>
        ) : (
          t`image.slot.popover.substitution_not_requested`
        )}
      </div>
    </Fragment>
  )
}

const ApprovedImageTooltip: React.FC<FeedbackTooltipProps> = ({ image }) => {
  return <div>{t`image.slot.popover.feedback.approved`}</div>
}

const ExistingImageTooltip: React.FC<FeedbackTooltipProps> = ({ image }) => {
  return <div>{t`image.slot.popover.feedback.existing`}</div>
}

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '1.1em',
    padding: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
})(Tooltip)

function getTooltipContent(image?: ImageDto) {
  if (image?.type === ImageType.TENANT_IMAGE || image?.type === ImageType.GLOBAL_IMAGE) {
    return <ExistingImageTooltip image={image} />
  }
  if (image?.type === ImageType.NEW_IMAGE) {
    if (image?.state === ImageState.APPROVED) {
      return <ApprovedImageTooltip image={image} />
    } else if (image?.state === ImageState.REJECTED) {
      return <RejectedImageTooltip image={image} />
    }
  }
}

const FeedbackTooltip: React.FC<FeedbackTooltipProps> = ({ image, children }) => {
  const tooltipContent = getTooltipContent(image)

  return tooltipContent ? (
    <StyledTooltip title={tooltipContent} placement="top">
      <div>{children}</div>
    </StyledTooltip>
  ) : (
    <div>{children}</div>
  )
}

export default FeedbackTooltip
