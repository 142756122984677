import React from 'react'

import { ArticleOverviewUrlParameter } from 'views/ArticleOverview/useInitialFilter'
import ArticleOverviewTile from './ArticleOverviewTile'
import DashboardDivider from './DashboardDivider'
import FeedbackTile from './FeedbackTile'
import PendingReviewsTile from './PendingReviewsTile'

export interface DashboardElementProps {
  subscribedBrandIds: string[]
  type?: 'articleOverviewTile' | 'imageReviewsTile' | 'feedbackTile' | 'divider'
  urlParameter?: ArticleOverviewUrlParameter
  hideFilterInCaption?: string[]
  messageId?: string
}

const DashboardElement: React.FC<DashboardElementProps> = ({ type, ...props }) => {
  switch (type) {
    case 'imageReviewsTile':
      return <PendingReviewsTile {...props} />
    case 'articleOverviewTile':
      return <ArticleOverviewTile urlParameter={{}} {...props} />
    case 'feedbackTile':
      return <FeedbackTile {...props} />
    case 'divider':
      return <DashboardDivider {...props} />
    default:
      return null
  }
}

export default DashboardElement
