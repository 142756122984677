import React, { useCallback, useEffect } from 'react'
import { t } from '@lingui/macro'
import { Checkbox } from '@material-ui/core'
import classnames from 'classnames'
import { any } from 'ramda'

import { ImageFeedback } from 'api/imageReviews'
import { ArticleIdentifier, ImageDto } from 'api/types'
import { VariantDetailLink } from 'components/Links'
import useCloseAnimation from 'hooks/useCloseAnimation'
import { ArticleGalleries } from 'types'
import { ImageGalleries } from 'types/image'
import tenantGallery from 'utils/tenantGallery'
import FirstBrandName from './FirstBrandName'
import MissingImageHint from './MissingImageHint'
import TenantAccordion from './TenantAccordion'
import classes from './ArticleImageGallery.module.scss'

export interface TenantGalleryCallbackProps {
  onGalleryChange?: (article: ArticleIdentifier, galleries: ImageGalleries) => void
  onAddToPaperBin?: (article: ArticleIdentifier, image: ImageDto, galleries: ImageGalleries) => void
  onRemoveFromPaperBin?: (article: ArticleIdentifier, image: ImageDto) => void
  onToggleTenantSelection?: (article: ArticleIdentifier) => void
  onApproveGallery?: (article: ArticleIdentifier) => void
  onRejectGallery?: (
    article: ArticleIdentifier,
    feedback: ImageFeedback[],
    newGalleryRequested: boolean
  ) => void
  onTenantNotificationExpired?: (article: ArticleIdentifier) => void
}

export type ArticleImageGalleryMode = 'imageReview' | 'variantDetailSuggestion' | 'gallery'

export interface ArticleImageGalleryProps extends TenantGalleryCallbackProps {
  article: ArticleGalleries
  mode: ArticleImageGalleryMode
  showSuggestionsAsRejected?: boolean
  onRemove?: (articleNumber: string) => void
  onToggleArticleSelection?: (articleNumber: string) => void
}

const ArticleImageGallery = React.forwardRef<HTMLDivElement, ArticleImageGalleryProps>(
  (props, ref) => {
    const { article, onRemove, onToggleArticleSelection } = props
    const handleClose = useCallback(() => {
      if (onRemove) {
        onRemove(article.articleNumber)
      }
    }, [onRemove, article.articleNumber])
    const [isClosing, initiateClose, closingElementProps] = useCloseAnimation(handleClose)
    useEffect(() => {
      if (article.tenants.length === 0) {
        initiateClose()
      }
    }, [article.tenants, initiateClose])
    const hasTenantWithImage = any(tenantGallery.hasImage)(article.tenants)
    const handleToggleArticleSelection = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation()
      onToggleArticleSelection?.(article.articleNumber)
    }

    return (
      <div
        ref={ref}
        data-testid="article-image-gallery"
        data-articlenumber={props.article.articleNumber}
        className={classnames(classes.root, { [classes.closing]: isClosing })}
        {...closingElementProps}
      >
        {props.mode !== 'variantDetailSuggestion' ? (
          <div className={classes.articleSummary}>
            {onToggleArticleSelection ? (
              <Checkbox
                color="primary"
                className={classes.articleSelectionCheckbox}
                checked={article.isSelected}
                onClick={handleToggleArticleSelection}
                data-testid="articleSelection"
              />
            ) : null}
            {t`general.artNr`}{' '}
            <VariantDetailLink
              articleNumber={article.articleNumber}
              className={classes.variantDetailLink}
              data-testid="articleNumber"
            />
            <FirstBrandName article={article} />
            {!hasTenantWithImage ? <MissingImageHint /> : null}
          </div>
        ) : null}
        {article.tenants.map((tenant, idx) => (
          <TenantAccordion
            key={tenant.id}
            tenant={tenant}
            defaultExpanded={idx === 0}
            articleNumber={article.articleNumber}
            mode={props.mode}
            onGalleryChange={props.onGalleryChange}
            onToggleTenantSelection={props.onToggleTenantSelection}
            onAddToPaperBin={props.onAddToPaperBin}
            onRemoveFromPaperBin={props.onRemoveFromPaperBin}
            onApproveGallery={props.onApproveGallery}
            onRejectGallery={props.onRejectGallery}
            onTenantNotificationExpired={props.onTenantNotificationExpired}
            showSuggestionsAsRejected={props.showSuggestionsAsRejected}
          />
        ))}
      </div>
    )
  }
)
ArticleImageGallery.displayName = 'ImageReviewGallery'

export default ArticleImageGallery
