import type * as Payloads from './actions'
import type { ArticleOverviewFilter, ArticleOverviewState } from './initialState'

import { createReducer } from 'store/utils'
import * as types from './actionTypes'
import initialState, { initialFilter } from './initialState'

type Handler<T = void> = (state: ArticleOverviewState, payload: T) => ArticleOverviewState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.INITIATE_REQUEST]: ((state): ArticleOverviewState => ({
    ...state,
    requestInProgress: true,
    errorNotification: null,
  })) as HandlerWithoutPayload,

  [types.SET_ARTICLE_OVERVIEW]: ((state, { articleOverview, articles }): ArticleOverviewState => ({
    ...state,
    articles,
    requestInProgress: false,
    errorNotification: null,
    currentPage: articleOverview.searchResult.number,
    pageCount: articleOverview.searchResult.totalPages,
  })) as Handler<Payloads.SetArticleOverviewPayload>,

  [types.SET_AVAILABLE_FILTER]: ((state, payload) => ({
    ...state,
    ...payload,
  })) as Handler<Payloads.SetAvailableFilterPayload>,

  [types.SET_ERROR_MESSAGE]: ((state, errorNotification): ArticleOverviewState => ({
    ...state,
    errorNotification,
    requestInProgress: false,
  })) as Handler<string[] | null>,

  [types.SET_FILTER]: ((state, filter): ArticleOverviewState => ({
    ...state,
    filter: {
      ...state.filter,
      ...filter,
    },
    pageCount: 0,
    currentPage: 0,
    ignoreFilterWarning: false,
  })) as Handler<Partial<ArticleOverviewFilter>>,

  [types.RESET_FILTER]: ((state, { subscribedBrandIds }): ArticleOverviewState => ({
    ...state,
    articles: [],
    ignoreFilterWarning: false,
    filter: { ...initialFilter, brandIds: subscribedBrandIds },
  })) as Handler<Payloads.ResetAllFiltersPayload>,

  [types.IGNORE_FILTER_WARNING]: ((state, ignoreFilterWarning): ArticleOverviewState => ({
    ...state,
    ignoreFilterWarning,
  })) as Handler<boolean>,

  [types.SET_ARTICLE_BLOCKED]: ((state, { articleNumbers, isBlocked }): ArticleOverviewState => ({
    ...state,
    articles: state.articles.map((article) =>
      articleNumbers.has(article.articleNumber) ? { ...article, blocked: isBlocked } : article
    ),
  })) as Handler<Payloads.ArticleBlockedPayload>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type ArticleOverviewAction = Actions[keyof Actions]

export default createReducer<ArticleOverviewState, ArticleOverviewAction>(initialState, handlers)
