import React, { ReactNode } from 'react'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'

import classes from './ModalDialog.module.scss'

export interface ModalDialogProps {
  id?: string
  open: boolean
  onClose: () => void
  onApply?: () => void
  title?: string
  submitButtonLabel?: string
  testId?: string
  children: ReactNode
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

interface DialogTitleProps {
  title: string
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const DialogTitle = (props: DialogTitleProps) => {
  const { title, onClose, ...other } = props
  return (
    <MuiDialogTitle
      id="modal-dialog-title"
      className={classes.dialogTitle}
      disableTypography
      {...other}
    >
      <div>
        <Typography className={classes.dialogTitleText}>{title}</Typography>
        <IconButton aria-label="close" onClick={onClose} className={classes.closeDialogIcon}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </div>
    </MuiDialogTitle>
  )
}

export default function ModalDialogTemplate(props: ModalDialogProps) {
  const maxWidth = props.width || 'xs'
  const minWidth = props.width || 'xs'
  return (
    <Dialog
      id={props.id}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-dialog-title"
      scroll="body"
      maxWidth={maxWidth}
    >
      <div
        data-testid={props.testId}
        className={classNames({
          [classes.dialogBase]: true,
          [classes.dialogXs]: minWidth === 'xs',
          [classes.dialogSm]: minWidth === 'sm',
          [classes.dialogMd]: minWidth === 'md',
          [classes.dialogLg]: minWidth === 'lg',
          [classes.dialogXl]: minWidth === 'xl',
        })}
      >
        {props.title && <DialogTitle title={props.title} onClose={props.onClose} />}
        <DialogContent dividers className={classes.dialogContainer}>
          {props.children}
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <Button
            size="medium"
            onClick={props.onClose}
            className={classes.dialogButtonCancel}
            data-testid={props.testId + '-btn-cancel'}
          >
            <Trans id="modal.dialog.button.cancel">Cancel</Trans>
          </Button>
          {props.onApply && (
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="medium"
              data-testid={props.testId + '-btn-submit'}
              onClick={props.onApply}
              className={classes.dialogButtonSubmit}
            >
              {props.submitButtonLabel ?? t`general.apply`}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  )
}
