import { useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import Alert from '@mui/material/Alert'

import { ImageFeedback, translatedImageFeedback } from 'api/imageReviews/imageReviewsTypes'
import Fieldset from 'components/Fieldset'
import ModalDialog from 'components/ModalDialog'
import classes from './RejectionDetailDialog.module.scss'

const availableFeedback = {
  image: [
    ImageFeedback.WRONG_MAIN_IMAGE,
    ImageFeedback.PRODUCT_TOO_FAR,
    ImageFeedback.BAD_QUALITY,
    ImageFeedback.QUESTIONABLE_IMAGE,
    ImageFeedback.PRODUCT_IMAGE_REQUIRE_VERTICAL_POSITION,
    ImageFeedback.INCORRECT_LANGUAGE,
    ImageFeedback.WRONG_IMAGE_POSITION,
    ImageFeedback.WRONG_IMAGE_ARTICLE,
  ],
  gallery: [
    ImageFeedback.WRONG_MAIN_IMAGE,
    ImageFeedback.PRODUCT_TOO_FAR,
    ImageFeedback.BAD_QUALITY,
    ImageFeedback.QUESTIONABLE_IMAGE,
    ImageFeedback.PRODUCT_IMAGE_REQUIRE_VERTICAL_POSITION,
    ImageFeedback.INCORRECT_LANGUAGE,
    ImageFeedback.COLOR_BLOB_MISSING,
    ImageFeedback.WRONG_IMAGE_ARTICLE,
  ],
}

export interface RejectionDialogProps {
  open: boolean
  mode: 'image' | 'gallery'
  feedback?: ImageFeedback[]
  requestNewImage?: boolean
  onClose: () => void
  onReject: (feedback: ImageFeedback[], requestNewImage: boolean) => void
}

export default function RejectionDetailDialog(props: RejectionDialogProps) {
  const [feedback, setFeedback] = useState<Set<ImageFeedback>>(new Set([]))
  const [requestNewImage, setRequestNewImage] = useState(true)
  const [noFeedbackSelection, setNoFeedBackSelection] = useState(false)

  useEffect(() => {
    if (props.open) {
      setFeedback(new Set(props.feedback ?? []))
      setRequestNewImage(props.requestNewImage ?? true)
    }
  }, [props.open, props.feedback, props.requestNewImage])

  const resetSelectedFeedback = () => {
    setFeedback(new Set())
    setRequestNewImage(true)
  }

  const handleClose = () => {
    resetSelectedFeedback()
    props.onClose()
  }

  const handleSubmitDialog = () => {
    if (feedback.size === 0) {
      setNoFeedBackSelection(true)
      return null
    }
    resetSelectedFeedback()
    props.onReject([...feedback], requestNewImage)
  }

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    if (checked) {
      feedback.add(name as ImageFeedback)
    } else {
      feedback.delete(name as ImageFeedback)
    }
    setFeedback(new Set([...feedback]))
    setNoFeedBackSelection(false)
  }

  const handleRequestNewImageSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target

    setRequestNewImage(checked)
  }

  return (
    <ModalDialog
      id="rejectImageDetailDialog"
      open={props.open}
      submitButtonLabel={t({ id: 'image.reject.dialog.button.submit', message: 'Reject' })}
      onClose={handleClose}
      onApply={handleSubmitDialog}
      title={
        props.mode === 'image'
          ? t`image.reject.dialog.title_image`
          : t`image.reject.dialog.title_gallery`
      }
      testId="rejection-dialog"
      width="lg"
    >
      <Fieldset
        label={t({ id: 'image.reject.dialog.feedback.label', message: 'Feedback for Supplier' })}
      >
        <FormGroup>
          {availableFeedback[props.mode].map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={feedback.has(option)}
                  onChange={handleFeedbackChange}
                  name={option}
                  color="primary"
                  data-testid={`imageFeedback-${option}`}
                />
              }
              label={translatedImageFeedback(option)}
            />
          ))}
        </FormGroup>
      </Fieldset>
      {noFeedbackSelection && (
        <Alert
          onClose={() => setNoFeedBackSelection(false)}
          className={classes.alert}
          severity="error"
          data-testid={`imageFeedback-dialog-warning`}
        >
          {t`image.reject.dialog.feedback.select.warning`}
        </Alert>
      )}
    </ModalDialog>
  )
}
