import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import useMountTransition from 'hooks/useMountTransition'
import DataLoadingIndicator from './DataLoadingIndicator'

const animationDuration = 100

const useStyles = makeStyles({
  dataLoadingOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    pointerEvents: 'none',
    opacity: 0,
    transition: `opacity ${animationDuration}ms ease-in-out`,

    '&.open': {
      opacity: 1,
    },
  },
})

interface DataLoadingOverlayProps {
  open: boolean
}

const DataLoadingOverlay: React.FC<DataLoadingOverlayProps> = ({ open }) => {
  const classes = useStyles()
  const [isOpen, showOpenTransition] = useMountTransition(open, animationDuration)

  return isOpen ? (
    <div
      className={clsx(classes.dataLoadingOverlay, {
        open: showOpenTransition,
      })}
      data-testid="dataLoadingOverlay"
    >
      <DataLoadingIndicator />
    </div>
  ) : null
}

export default DataLoadingOverlay
