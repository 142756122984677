import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import keycloakClient from 'api/keycloak/KeycloakClient'

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (!keycloakClient.isTokenExpired()) {
    const e = event || window.event
    e.preventDefault()
    if (e) {
      e.returnValue = ''
    }
    return ''
  }
}

export default function useNavigationWarning(showExitPrompt: boolean): void {
  const history = useHistory()

  useEffect(() => {
    if (showExitPrompt) {
      const unblock = history.block(() => {
        return keycloakClient.isTokenExpired() ? true : window.confirm(t`unsaved.changes.are.lost`)
      })
      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        unblock()
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [showExitPrompt, history])
}
