import { ImageFeedback } from 'api/imageReviews'
import { SupplierFeedbackImagesMapDto } from 'api/supplierFeedback'
import {
  convertSingleImageFeedbackDataToImageDto,
  ImageDto,
  ImageGallery,
  ImagePosition,
  imagePositions,
  ImageState,
} from 'api/types'
import { TenantGallery } from 'types'
import { ImageGalleries, ImageGalleryEntry } from 'types/image'
import { isGlobalTenant } from './tenants'

export const getImagesInPosition = (position: ImagePosition, galleries: ImageGalleries) => ({
  suggestedImage: galleries.suggested?.[position],
  approvedImage: galleries.approved?.[position],
  globalImage: galleries.global?.[position],
})

export function canDeleteImageInPosition(position: ImagePosition, tenantGallery: TenantGallery) {
  if (!canReplaceImageInPosition(position)) {
    return false
  }
  if (position === 0) {
    const { suggestedImage, approvedImage, globalImage } = getImagesInPosition(
      position,
      tenantGallery.galleries
    )
    if (isGlobalTenant(tenantGallery)) {
      if (suggestedImage) {
        return Boolean(approvedImage)
      }
      if (approvedImage) {
        return false
      }
    } else {
      if (suggestedImage) {
        return Boolean(approvedImage || globalImage)
      }
      if (approvedImage) {
        return Boolean(globalImage)
      }
    }
    return false
  }

  return true
}

export function canReplaceImageInPosition(position: ImagePosition) {
  return position !== 'blob'
}

function switchPosition(src: ImagePosition, dst: ImagePosition, gallery: ImageGallery | undefined) {
  return gallery
    ? {
        ...gallery,
        [dst]: gallery[src],
        [src]: gallery[dst],
      }
    : undefined
}

export function updateImageInGallery(
  src: ImageGalleryEntry,
  dst: ImageGalleryEntry,
  galleries: ImageGalleries
): ImageGalleries {
  if (src.galleryName === dst.galleryName) {
    const alsoSwitchApproved =
      src.image?.state === ImageState.APPROVED ||
      Boolean(!galleries.approved?.[src.position] && !galleries.suggested?.[dst.position]) ||
      Boolean(galleries.approved?.[src.position] && galleries.approved?.[dst.position])
    return {
      ...galleries,
      suggested: switchPosition(src.position, dst.position, galleries.suggested),
      approved: alsoSwitchApproved
        ? switchPosition(src.position, dst.position, galleries.approved)
        : galleries.approved,
    }
  } else {
    let suggested = galleries.suggested
    let approved = galleries.approved
    if (src.image?.state === ImageState.SUGGESTED || src.image?.state === ImageState.REJECTED) {
      suggested = { ...suggested, [dst.position]: src.image }
    } else if (src.image?.state === ImageState.APPROVED) {
      approved = { ...approved, [dst.position]: src.image }
    }
    return { ...galleries, suggested, approved }
  }
}

export function hasImageInFirstPosition(galleries: ImageGalleries) {
  return Boolean(galleries.suggested?.[0] || galleries.approved?.[0] || galleries.global?.[0])
}

export function isEmptyGallery(galleries: ImageGalleries, galleryType?: keyof ImageGalleries) {
  const galleryTypes: (keyof ImageGalleries)[] = galleryType
    ? [galleryType]
    : ['suggested', 'approved', 'global']
  let images = 0
  for (const gallery of galleryTypes) {
    images += Object.keys(galleries[gallery] || {}).length
  }

  return images === 0
}

function removeImageInGallery(imagePosition: ImagePosition, gallery: ImageGallery) {
  const newGallery = { ...gallery }
  if (newGallery[imagePosition]) {
    delete newGallery[imagePosition]
  }
  return newGallery
}

export function deleteImageInGalleries(
  image: ImageDto,
  imagePosition: ImagePosition,
  galleries: ImageGalleries
) {
  let suggested = galleries.suggested || {}
  let approved = galleries.approved || {}
  if (image.state === ImageState.APPROVED) {
    approved = removeImageInGallery(imagePosition, approved)
  } else if (image.state === ImageState.SUGGESTED) {
    suggested = removeImageInGallery(imagePosition, suggested)
  }

  return { ...galleries, suggested, approved }
}

export function getImageInPositionOfFeedbackGallery(
  imagePosition: ImagePosition,
  gallery: SupplierFeedbackImagesMapDto
): ImageDto | undefined {
  if (gallery?.[imagePosition]) {
    return convertSingleImageFeedbackDataToImageDto(gallery[imagePosition], imagePosition)
  }
}

export function getImageInPosition(
  tenantGallery: TenantGallery,
  imagePosition: ImagePosition,
  visibleGalleries: (keyof ImageGalleries)[] = ['suggested', 'approved', 'global']
): ImageDto | undefined {
  const { galleries } = tenantGallery

  for (const galleryType of visibleGalleries) {
    const gallery = galleries[galleryType]
    if (gallery?.[imagePosition]) {
      return gallery[imagePosition]
    }
  }
}

export function setImageStateForGallery(gallery: ImageGallery | undefined, imageState: ImageState) {
  if (gallery) {
    for (const imagePosition of imagePositions) {
      const image = gallery[imagePosition]
      if (image) {
        image.state = imageState
      }
    }
  }

  return gallery
}

function updateImageInPosition(
  imageGalleries: ImageGalleries,
  imagePosition: ImagePosition,
  galleryType: keyof ImageGalleries,
  updater: (image: ImageDto) => ImageDto
): ImageGalleries {
  const image = imageGalleries?.[galleryType]?.[imagePosition]
  return image
    ? {
        ...imageGalleries,
        [galleryType]: {
          ...imageGalleries.suggested,
          [imagePosition]: updater(image),
        },
      }
    : imageGalleries
}

export function toggleReviewState(
  imageGalleries: ImageGalleries,
  imagePosition: ImagePosition
): ImageGalleries {
  return updateImageInPosition(imageGalleries, imagePosition, 'suggested', (image) => ({
    ...image,
    state: image.state === ImageState.SUGGESTED ? ImageState.REJECTED : ImageState.SUGGESTED,
  }))
}

export function setReviewFeedback(
  imageGalleries: ImageGalleries,
  imagePosition: ImagePosition,
  feedback?: ImageFeedback[],
  requestNewImage?: boolean
) {
  return updateImageInPosition(imageGalleries, imagePosition, 'suggested', (image) => ({
    ...image,
    state: ImageState.REJECTED,
    feedback: feedback ?? image.feedback,
    requestNewImage: requestNewImage ?? image.requestNewImage,
  }))
}

export function updateImageState(
  state: ImageState,
  gallery?: ImageGallery
): ImageGallery | undefined {
  if (gallery) {
    const result: ImageGallery = {}

    for (const position of imagePositions) {
      const image = gallery?.[position]
      if (image) {
        result[position] = { ...image, state }
      }
    }

    return result
  }
}
