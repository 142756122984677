import { FormControl, FormLabel } from '@material-ui/core'

import classes from './ReadonlyDataFields.module.scss'

interface DataFieldProps {
  label: string
  value?: string
}

export default function ReadonlyDataField(props: DataFieldProps) {
  return (
    <FormControl className={classes.dataContainer}>
      <FormLabel id="dataFieldLabel" htmlFor="dataField" className={classes.dataLabel}>
        {props.label}
      </FormLabel>
      <div className={classes.dataValue}>{props.value ?? '-'}</div>
    </FormControl>
  )
}
