import { ChangeEvent, KeyboardEvent, MouseEvent, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DeleteTextAdornment } from './DeleteTextAdornment'
import useSharedFilterStyle from './hooks/useSharedFilterStyle'

interface TextFilterProps {
  label: string
  value: string
  disabled?: boolean
  'data-testid'?: string
  validateInput?: (value: string) => string | undefined
  onChange: (value: string) => void
  onSubmit: () => void
}

const useStyle = makeStyles((theme) => ({
  filterField: {
    '& *': {
      cursor: 'pointer',
    },

    '& > div': {
      paddingRight: theme.spacing(1),
    },
  },
}))

export function TextFilter(props: TextFilterProps) {
  const classes = useStyle()
  const globalClasses = useSharedFilterStyle()
  const inputRef = useRef<HTMLInputElement>()
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (props.validateInput) {
      const validationErrorMsg = props.validateInput(ev.target.value)
      setErrorMsg(validationErrorMsg)
    }
    props.onChange(ev.target.value)
  }
  const handleClear = (ev: MouseEvent) => {
    ev.stopPropagation()
    setErrorMsg(undefined)
    props.onChange('')
    props.onSubmit()
  }

  const handleBlur = () => {
    if (!errorMsg?.length) {
      props.onSubmit()
    }
  }
  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  return (
    <div className={globalClasses.filterFieldRoot}>
      <TextField
        fullWidth
        variant="outlined"
        margin="dense"
        label={props.label}
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(errorMsg)}
        helperText={errorMsg ?? ''}
        disabled={props.disabled}
        className={classes.filterField}
        inputRef={inputRef}
        data-testid={props['data-testid']}
        InputProps={{
          readOnly: props.disabled,
          endAdornment: props.value ? (
            <DeleteTextAdornment disabled={props.disabled} onClick={handleClear} />
          ) : null,
          onKeyDown: handleKeyDown,
        }}
      />
    </div>
  )
}
