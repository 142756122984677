import React from 'react'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core'

import useSharedFilterStyle from './hooks/useSharedFilterStyle'

interface SwitchProps {
  label?: string
  tooltip?: string
  disabled?: boolean | undefined
  checked?: boolean
  onChange?: () => void
  'data-testid'?: string
}

export function ChangeFilterSwitch(props: SwitchProps) {
  const globalClasses = useSharedFilterStyle()
  return (
    <div className={globalClasses.filterFieldRoot}>
      <div>
        <FormControl margin="dense" title={props.tooltip}>
          <FormControlLabel
            control={<Switch color="primary" {...props} size="small" />}
            className={globalClasses.filterSwitch}
            label={props.label}
            labelPlacement="end"
            data-testid={props['data-testid']}
          />
        </FormControl>
      </div>
    </div>
  )
}
