import type { MediathekImage } from 'store/mediathek'

import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import MediathekImageCard from './MediathekImageCard'
import classes from './Mediathek.module.scss'

export interface ConfirmReplaceInfo {
  image: MediathekImage
  originalTenants: string[]
  newTenant: string
}

interface ConfirmReplaceDialogProps {
  open: boolean
  info: ConfirmReplaceInfo
  onClose: () => void
  onConfirm: (image: MediathekImage) => void
}

const ConfirmReplaceDialog = (props: ConfirmReplaceDialogProps) => {
  const { info } = props
  const handleConfirm = () => {
    if (props?.info?.image) {
      props.onConfirm(props.info.image)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t`mediathek.image.originally.uploaded.for.another.tenant`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t({
            id: 'mediathek.image.originally.uploaded.for.another.tenant.detailed',
            values: { 0: info.originalTenants.join(', ') },
          })}
          <br />
          <br />
          {t({
            id: 'mediathek.image.originally.uploaded.for.another.tenant.confirm',
            values: { 0: info.newTenant },
          })}
        </DialogContentText>
        <div className={classes.confirmDialogImageContainer}>
          <MediathekImageCard hideMenu image={info.image} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t`general.cancel`}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t`general.apply`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmReplaceDialog
