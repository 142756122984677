import React from 'react'
import classnames from 'classnames'

import { ReactComponent as AlternativeIcon } from './alternativeIcon.svg'
import { ReactComponent as DefaultIcon } from './defaultIcon.svg'
import classes from './ErrorNotification.module.scss'

type ErrorNotificationIcon = 'default' | 'alternative'

function getErrorNotificationIcon(icon?: ErrorNotificationIcon) {
  switch (icon) {
    case 'alternative':
      return AlternativeIcon
  }

  return DefaultIcon
}

interface ErrorNotificationProps {
  className?: string
  showRefresh?: boolean
  icon?: ErrorNotificationIcon
  children?: React.ReactNode
}

export const ErrorNotification: React.FC<ErrorNotificationProps> = (props) => {
  const Icon = getErrorNotificationIcon(props.icon)

  return (
    <div className={classnames(classes.root, props.className)} data-testid="errorNotification">
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.messageContainer}>
        <div className={classes.message}>
          {props.children ? <div>{props.children}</div> : null}
          {props.showRefresh === undefined || props.showRefresh ? (
            <a href="." className={classes.refresh}>
              Refresh page
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ErrorNotification
