import React, { ReactElement } from 'react'
import { TableCell, TableRow } from '@material-ui/core'

interface Props {
  message: string
  columns: number
}

export default function SingleTextTableRow(props: Props): ReactElement {
  return (
    <TableRow className="table-row">
      <TableCell className="table-cell" colSpan={props.columns} align={'center'}>
        {props.message}
      </TableCell>
    </TableRow>
  )
}
