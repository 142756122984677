export const SET_VARIANT_DETAILS = 'variantdetail/SET_VARIANT_DETAILS'
export const RESET_VARIANT_DETAILS = 'variantdetail/RESET_VARIANT_DETAILS'
export const SET_ERRORS = 'variantdetail/SET_ERRORS'
export const ADD_ERROR = 'variantdetail/ADD_ERROR'
export const SET_INFO_MESSAGE = 'variantdetail/SET_INFOMESSAGE'
export const CLEAN_NOTIFICATIONS = 'variantdetail/CLEAN_NOTIFICATIONS'
export const SET_IMAGE_GALLERY = 'variantdetail/SET_IMAGE_GALLERY'
export const SHOW_SUBMIT_NOTIFICATION = 'variantdetail/SHOW_SUBMIT_NOTIFICATION'
export const CLOSE_SUBMIT_NOTIFICATION = 'variantdetail/CLOSE_SUBMIT_NOTIFICATION'
export const REMOVE_IMAGE_SUGGESTION = 'variantdetail/REMOVE_TENANT_SUGGESTION'
export const UPDATE_IMAGE_SUGGESTION = 'variantdetail/UPDATE_IMAGE_SUGGESTION'
export const ADD_TO_PAPERBIN = 'variantdetail/ADD_TO_PAPERBIN'
export const REMOVE_FROM_PAPERBIN = 'variantdetail/REMOVE_FROM_PAPERBIN'
export const ADD_TO_SUGGESTION_PAPERBIN = 'variantdetail/ADD_TO_SUGGESTION_PAPERBIN'
export const REMOVE_FROM_SUGGESTION_PAPERBIN = 'variantdetail/REMOVE_FROM_SUGGESTION_PAPERBIN'
export const REPLACE_WITH_MEDIATHEK_IMAGE = 'variantdetail/REPLACE_WITH_MEDIATHEK_IMAGE'
export const COPY_TENANT_GALLERY = 'variantdetail/COPY_TENANT_GALLERY'
