import { useCallback, useMemo, useRef } from 'react'
import { VariableSizeList } from 'react-window'

import debounce from 'utils/debounce'
import { ListEntry } from './useInfiniteScrolling'

const emptyList = 120
const dataLoadingIndicatorSize = 100

export type GetEntryHeight = (index: number) => number

export type SetEntryHeight<EntryType> = (
  index: number,
  size: number,
  entry: ListEntry<EntryType>
) => void

type UseItemHeightResult<EntryType> = [GetEntryHeight, SetEntryHeight<EntryType>]

function useEntryHeight<EntryType>(
  entries: ListEntry<EntryType>[],
  listRef: React.MutableRefObject<VariableSizeList | null>,
  margin: number,
  estimateHeight: ((entry: EntryType) => number) | number,
  itemKey: (entry: EntryType) => string
): UseItemHeightResult<EntryType> {
  const heightMap = useRef<{ [key: string]: number }>({})
  const getSize = useCallback(
    (index: number) => {
      const currentItem = entries[index]
      switch (currentItem) {
        case 'empty list':
          return emptyList + margin
        case 'loading indicator':
          return dataLoadingIndicatorSize + margin
      }
      const key = itemKey(currentItem)
      if (heightMap.current[key]) {
        return heightMap.current[key]
      }

      return typeof estimateHeight === 'function' ? estimateHeight(currentItem) : estimateHeight
    },
    [entries, estimateHeight, margin, itemKey]
  )
  const setSize = useMemo(() => {
    const debouncedResetAfterIndex = debounce<() => void>(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0)
      }
    }, 10)

    return (index: number, size: number, item: ListEntry<EntryType>) => {
      const key = typeof item === 'string' ? item : itemKey(item)
      if (heightMap.current[key] !== size) {
        heightMap.current[key] = size
        debouncedResetAfterIndex()
      }
    }
  }, [listRef, itemKey])

  return [getSize, setSize]
}

export default useEntryHeight
