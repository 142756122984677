import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import NotificationDialog from 'components/Notification'
import { actions, selectors } from 'store/variantDetail'

const VariantDetailSubmitNotification: React.FC = () => {
  const dispatch = useDispatch()
  const showSubmitNotification = useSelector(selectors.showSubmitNotification)
  const closeSubmitNotification = () => dispatch(actions.closeSubmitNotification())

  return (
    <NotificationDialog
      title={t({
        id: 'variantdetails.images.notify.dialog.title.save_successful',
        message: 'Galleries saved',
      })}
      boldStartText={t({
        id: 'variantdetails.images.notify.dialog.boldtext.save_successful',
        message: 'Note:',
      })}
      content={t({
        id: 'variantdetails.images.notify.dialog.content.save_successful',
        message:
          'Images are not transferred to the shop immediately but within the next 15 minutes.',
      })}
      open={showSubmitNotification}
      onClose={closeSubmitNotification}
    />
  )
}

export default VariantDetailSubmitNotification
