import React, { Fragment, useState } from 'react'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { InfoOutlined, Refresh } from '@material-ui/icons'
import clsx from 'clsx'

import SnackbarNotification from '../Snackbar/SnackbarNotification'
import TileLoadingIndicator from './TileLoadingIndicator'

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  tileRoot: {
    aspectRatio: '3 / 2',
    width: '100%',
    position: 'relative',
    display: 'inline-flex',
    cursor: 'pointer',
    padding: theme.spacing(2),
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    opacity: 0.8,
  },
  refresh: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: 'white',
  },
  caption: {
    textAlign: 'center',
    fontVariant: 'small-caps',
  },
  captionInfo: {
    color: '#2f4f4f',
  },
  captionWarning: {
    color: '#A52A2A',
  },
  captionError: {
    color: '#800000',
  },
}))

export enum KpiTileSeverity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface KpiTileProps {
  caption: string
  value: string
  errors?: string[]
  subtitle?: string
  description?: string
  severity?: KpiTileSeverity
  loading?: boolean
  withRefresh?: boolean
  onClick?: () => void
  onRefreshClick?: () => void
  onErrorNotificationClose?: () => void
  'data-testid'?: string
}

export default function KpiTile(props: KpiTileProps) {
  const [elevation, setElevation] = useState(4)

  const classes = useStyles()
  const severity = props.severity || KpiTileSeverity.INFO
  const severityLevelStyles = classes[severity]
  const fixedHeightPaper = clsx(severityLevelStyles, classes.tileRoot)

  const handleRefreshClick = (ev: React.MouseEvent<SVGSVGElement>) => {
    if (props.onRefreshClick) {
      ev.stopPropagation()
      props.onRefreshClick()
    }
  }

  const renderTooltip = () => {
    if (props.description) {
      return (
        <Tooltip title={props.description} placement="top" className={classes.tooltip}>
          <span>
            <InfoOutlined />
          </span>
        </Tooltip>
      )
    }
  }

  const renderRefreshButton = () => {
    return (
      <Tooltip title="Refresh Data" placement="top" className={classes.refresh}>
        <span>
          <Refresh onClick={handleRefreshClick} />
        </span>
      </Tooltip>
    )
  }

  const renderRefreshOrLoader = () => {
    if (props.loading) {
      return <TileLoadingIndicator />
    } else if (props.withRefresh) {
      return renderRefreshButton()
    }
  }

  const mapCaptionColor = () => {
    switch (props.severity) {
      case KpiTileSeverity.WARNING:
        return classes.captionWarning
      case KpiTileSeverity.ERROR:
        return classes.captionError
      default:
        return classes.captionInfo
    }
  }

  return (
    <Fragment>
      <Card
        className={fixedHeightPaper}
        elevation={elevation}
        onClick={props.onClick}
        onMouseOver={() => setElevation(8)}
        onMouseOut={() => setElevation(4)}
        onMouseDown={() => setElevation(4)}
        onMouseUp={() => setElevation(8)}
        data-testid={props['data-testid'] ?? 'kpi-tile'}
      >
        {renderTooltip()}
        {renderRefreshOrLoader()}
        <Typography
          variant="subtitle1"
          component="span"
          color="inherit"
          gutterBottom
          className={clsx(classes.caption, mapCaptionColor())}
        >
          <strong>{props.caption}</strong>
        </Typography>
        <Typography variant="h3" component="span" color="inherit" data-testid="tile-value">
          <strong>{props.value}</strong>
        </Typography>
        <Typography variant="subtitle1" component="span" color="inherit" gutterBottom>
          <strong>{props.subtitle}</strong>
        </Typography>
      </Card>
      {props.errors && props.errors?.length > 0 && (
        <SnackbarNotification
          severity="error"
          withCloseButton
          withPageReload
          onClose={props.onErrorNotificationClose}
          data-testid="dashboard-error-snackbar"
        >
          {props.errors.map((e, idx) => (
            <div key={idx}>{e}</div>
          ))}
        </SnackbarNotification>
      )}
    </Fragment>
  )
}
