import { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { ImageDto, ImageState, UserPermission } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import classes from './ImageGallery.module.scss'

export interface ReviewIconsProps {
  image: ImageDto
  rejectAllSuggestions?: boolean
  onToggleReviewState?: () => void
}

const ReviewIcons: React.FC<ReviewIconsProps> = (props) => {
  const { image } = props
  const permissions = useSelector(profileSelectors.permissions)

  if (
    permissions.has(UserPermission.APPROVE_IMAGE) &&
    (image.state === ImageState.SUGGESTED || image.state === ImageState.REJECTED)
  ) {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
      ev.stopPropagation()
      props.onToggleReviewState?.()
    }
    const isRejected = image.state === ImageState.REJECTED || props.rejectAllSuggestions
    const ReviewIcon = isRejected ? CancelIcon : CheckCircleIcon

    return (
      <IconButton
        size="small"
        className={classes.reviewIcon}
        onClick={handleClick}
        data-testid="review-icon"
      >
        <ReviewIcon className={isRejected ? classes.rejected : classes.suggested} />
      </IconButton>
    )
  }

  return null
}

export default ReviewIcons
