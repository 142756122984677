import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import classes from './PageMenuBar.module.scss'

export interface PageMenuProps {
  id: string
  selected: boolean
  icon?: React.ReactNode
  route: string
  isExternalRoute?: boolean
  label: string
  hidden?: boolean
  'data-testid'?: string
}

const PageMenuItem: React.FC<PageMenuProps> = (props) => {
  return !props.hidden ? (
    <ListItem
      button
      selected={props.selected}
      className={props.selected ? classes.menuEntrySelected : classes.menuEntry}
      key={props.id}
      component={Link}
      to={!props.isExternalRoute ? props.route : { pathname: props.route }}
      target={props.isExternalRoute ? '_blank' : undefined}
      data-testid={props['data-testid']}
    >
      <div className={classes.menuSelectionMarker} />
      <ListItemIcon className={classes.menuIcon}>{props.icon}</ListItemIcon>
      <ListItemText primary={props.label} />
    </ListItem>
  ) : null
}

export default PageMenuItem
