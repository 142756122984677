import type { AxiosError } from 'axios'
import type { ThunkAction } from 'redux-thunk'
import type { RootState } from 'store/reducers'
import type { MediathekImage, MediathekState, UploadInfo } from './initialState'
import type { MediathekAction } from './reducer'

import { translateErrorResponse } from 'api/errors'
import { ImageState, VariantImagesDto } from 'api/types'
import variantDetailApi from 'api/variantDetail'
import { compareMediathekImage } from 'utils/mediathek'
import { compareTenantIds } from 'utils/tenants'
import * as types from './actionTypes'

type MediathekThunkAction<T = void> = ThunkAction<T, RootState, null, MediathekAction>

export const setMediathek = (payload: MediathekState): MediathekAction => ({
  type: types.SET_MEDIATHEK,
  payload,
})

export const setErrors = (errors: string | string[]): MediathekAction => ({
  type: types.SET_ERRORS,
  payload: errors,
})

export const resetMediathek = () => ({
  type: types.RESET_MEDIATHEK,
  payload: {},
})

export function convertApiResponse(
  articleNumber: string,
  responseData: VariantImagesDto
): MediathekState {
  return {
    articleNumber: articleNumber,
    images: responseData.images.sort(compareMediathekImage).map(
      (i) =>
        ({
          imageId: i.imageId,
          urlTemplate: i.urlTemplate,
          uploadedInfo: Object.entries(i.tenantSpecificAttributes)
            .filter(([t, d]) => d.state === ImageState.APPROVED)
            .sort(([tenandIdA], [tenantIdB]) => compareTenantIds(tenandIdA, tenantIdB))
            .map(
              ([t, details]) =>
                ({
                  uploadedForTenantId: t,
                  uploadDate: details.uploadedTimestamp,
                  uploadedByUserId: details.uploadedBy,
                } as UploadInfo)
            ),
          isOrphan: i.orphan,
        } as MediathekImage)
    ),
    requestInProgress: false,
    errors: [],
  } as MediathekState
}

export const fetchMediathek = (articleNumber: string): MediathekThunkAction => {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_REQUEST, payload: {} })
    variantDetailApi.fetchMediathek(articleNumber).then(
      (response) => {
        const payload = convertApiResponse(articleNumber, response.data)
        dispatch(setMediathek(payload))
      },
      (error: AxiosError) => {
        let errorMessages: string[] = [error.message]
        const errors = translateErrorResponse(error)
        if (errors.length) {
          errorMessages = errors
        }
        dispatch(setErrors(errorMessages))
      }
    )
  }
}
