import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'style/douglas-materialui-theme'

const useSharedFilterStyle = makeStyles((theme) => ({
  dividerRoot: {
    backgroundColor: colors.veryDarkGrey,
    width: 1,
    height: theme.spacing(5),
    alignSelf: 'center',
    marginLeft: theme.spacing(-1.25),
    marginRight: theme.spacing(1.25),
  },
  dividerRootNoColor: {
    width: 1,
    height: theme.spacing(5),
    alignSelf: 'center',
    marginLeft: theme.spacing(-1.25),
    marginRight: theme.spacing(1.25),
  },
  filterFieldRoot: {
    width: theme.spacing(25),
    marginRight: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  filterTextField: {
    '& *': {
      cursor: 'pointer',
    },

    '& > div': {
      paddingRight: theme.spacing(1.25),
    },
  },
  filterTextFieldUnselected: {
    '& input': {
      color: colors.labelTextColor,
    },
  },
  filterTextFieldDisabled: {
    ' & *': {
      cursor: 'default',
    },
  },
  filterSelectionField: {
    padding: '0 1em 1em 0',
  },
  clearFilterIcon: {
    cursor: 'pointer',
    color: '#0000008a',
  },
  clearFilterIconDisabled: {
    cursor: 'unset',
    color: '#000000aa',
  },
  filterSwitch: {
    margin: theme.spacing(0, 0, 0, 0),
  },
}))

export default useSharedFilterStyle
