import { i18n } from '@lingui/core'

import logger from '../logger'
import { DefaultLocale, isValidLocale, Locales } from './Locales'
import { getStoredLocale, setStoredLocale } from './LocaleStorage'

export const getApplicationLocale = (): string => {
  return getStoredLocale() || DefaultLocale.key
}

i18n.on('missing', (event) => {
  if (process.env.NODE_ENV !== 'test') {
    console.warn(`Translation in locale '${event.locale}' for key '${event.id}' is missing!`)
  }
})

const buildLocaleData = () => {
  return Locales.reduce((prev, current) => ({ ...prev, [current.key]: current }), {})
}

i18n.loadLocaleData(buildLocaleData())

export async function loadAndActivateLocale(locale: string): Promise<void> {
  if (!isValidLocale(locale)) {
    logger.error('Try to change to unsupported locale "%s"', locale)
    return
  }

  setStoredLocale(locale)

  const { messages } = await import(`localizations/${locale}`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}
