import React, { useMemo, useRef } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { t } from '@lingui/macro'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { AxiosError } from 'axios'
import { ValidationErrors } from 'final-form'

import adminUser, { UserDto } from 'api/adminUser'
import { translateErrorMessage } from 'api/errors'
import { BrandDto, ErrorDto, RoleDto, SupplierDto } from 'api/types'
import { useMessageTextCollection } from 'hooks'
import UserForm, { UserFormValues } from './UserForm'

function validateFormValues(values: UserFormValues): Record<string, string> | null {
  const result: Record<string, string> = {}

  if (values.role !== 'SUPPLIER_SERVICE_ACCOUNT') {
    if (!/.+@.+\..{2,}/.test(values.email)) {
      result.email = t`admin.create.account.user.error.invalid_mail`
    }
    if (!/\D+/.test(values.firstName)) {
      result.firstName = t`admin.create.account.user.error.missing_first_name`
    }
    if (!/\D+/.test(values.lastName)) {
      result.lastName = t`admin.create.account.user.error.missing_last_name`
    }
  }

  return Object.keys(result).length ? result : null
}

interface EditUserDialogProps {
  open: boolean
  user?: UserDto
  roles: RoleDto[]
  brands: BrandDto[]
  suppliers: SupplierDto[]
  onClose: () => void
  onSubmit: (infoMsg?: string) => void
}

const EditUserDialog: React.FC<EditUserDialogProps> = (props) => {
  const { open, user, roles, brands, suppliers, onSubmit, onClose } = props
  const existingUser = !!user
  const dialogContentRef = useRef<HTMLDivElement>()
  const { promiseInProgress } = usePromiseTracker({ area: 'saveProgress' })
  const [alertMessages, addToErrors, , cleanAlertMessages] = useMessageTextCollection()
  const initialValues = useMemo(
    () => ({
      userId: user ? user.userId : '',
      email: user ? user.email : '',
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      role: user ? user.role.id : 'CONTACT_PERSON',
      tenants: user?.tenantIds ?? [],
      brands: user?.brandIds ?? [],
      suppliers: user?.supplierIds ?? [],
      hasNoBrandRestriction: user ? user.hasNoBrandRestriction : false,
    }),
    [user]
  )
  const isServiceAccount = user?.role.id === 'SUPPLIER_SERVICE_ACCOUNT'
  const addErrors = (msg: string | string[]) => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = 0
    }
    addToErrors(msg)
  }
  const handleSubmit = (values: UserFormValues): ValidationErrors | undefined => {
    cleanAlertMessages()
    const errors = validateFormValues(values)
    if (errors) {
      if (dialogContentRef.current) {
        dialogContentRef.current.scrollTop = 0
      }
      return errors
    }
    const formValues = {
      ...values,
      suppliers: values.role === 'CONTACT_PERSON' || isServiceAccount ? values.suppliers : [],
    }
    const request = existingUser
      ? adminUser.updateUser(user.userId, formValues, 'saveProgress')
      : adminUser.createUser(formValues, 'saveProgress')
    request.then(
      (response) => {
        if (response.data) {
          onSubmit(translateErrorMessage(response.data))
        } else {
          onSubmit()
        }
      },
      (error: AxiosError<ErrorDto>) => {
        if (error.response) {
          const { status, data } = error.response
          if (status === 403) {
            if (existingUser) {
              addErrors(t`admin.edit.account.user.error.user_creation_forbidden`)
            } else {
              addErrors(t`admin.create.account.user.error.user_creation_forbidden`)
            }
          } else if (status === 400 && data.errors) {
            addErrors(data.errors.map((error) => translateErrorMessage(error)))
          } else {
            addErrors(error.message)
          }
        }
      }
    )
  }
  const handleClose = () => {
    cleanAlertMessages()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        {existingUser
          ? t`admin.edit.account.user.dialog.title`
          : t`admin.create.account.user.dialog.title`}
      </DialogTitle>
      <DialogContent dividers ref={dialogContentRef}>
        <UserForm
          existingUser={existingUser}
          alerts={alertMessages}
          promiseInProgress={promiseInProgress}
          initialValues={initialValues}
          availableRoles={roles}
          availableBrands={brands}
          availableSuppliers={suppliers}
          isServiceAccount={isServiceAccount}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="reset" onClick={handleClose} data-testid="user-form-cancel">
          {t`admin.edit.account.user.button.cancel`}
        </Button>
        <Button color="primary" type="submit" form="user-form" data-testid="user-form-submit">
          {existingUser
            ? t`admin.edit.account.user.button.create`
            : t`admin.create.account.user.button.create`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditUserDialog
