import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Accordion, AccordionDetails } from '@material-ui/core'
import { partial } from 'ramda'

import { ArticleIdentifier, ImageDto, UserPermission } from 'api/types'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import GalleryAccordion from 'components/GalleryAccordion'
import GalleryPaperBin from 'components/GalleryPaperBin'
import ImageGallery from 'components/ImageGallery'
import useImageCount from 'hooks/useImageCount'
import { selectors as profileSelectors } from 'store/userProfile'
import { TenantGallery } from 'types'
import { ImageGalleries, ImageGalleryEntry } from 'types/image'
import noop from 'utils/noop'
import ApprovalButtons from './ApprovalButtons'
import { ArticleImageGalleryMode, TenantGalleryCallbackProps } from './ArticleImageGallery'
import TenantAccordionSummary from './TenantAccordionSummary'
import TenantNotification from './TenantNotification'
import classes from './ArticleImageGallery.module.scss'

interface TenantAccordionProps extends TenantGalleryCallbackProps {
  tenant: TenantGallery
  articleNumber: string
  mode: ArticleImageGalleryMode
  defaultExpanded?: boolean
  showSuggestionsAsRejected?: boolean
}

const useGalleryNames = (tenant: TenantGallery) => {
  const { id } = tenant
  return useMemo(
    () => ({
      paperbin: id + '_paperbin',
      suggestion: id + '_suggestion',
      current: id + '_current',
    }),
    [id]
  )
}

const useTenantAccordionHandlers = (props: TenantAccordionProps) => {
  const {
    articleNumber,
    tenant,
    onGalleryChange,
    onToggleTenantSelection,
    onAddToPaperBin,
    onRemoveFromPaperBin,
    onApproveGallery,
    onRejectGallery,
  } = props
  const { tenantId } = tenant
  const galleryNames = useGalleryNames(tenant)

  return useMemo(() => {
    const identifier: ArticleIdentifier = { articleNumber, tenantId }

    return {
      galleryChange:
        onGalleryChange || onRemoveFromPaperBin
          ? (galleries: ImageGalleries, droppedItem?: ImageGalleryEntry) => {
              if (onGalleryChange) {
                onGalleryChange(identifier, galleries)
              }
              if (
                onRemoveFromPaperBin &&
                droppedItem?.image &&
                droppedItem?.galleryName === galleryNames.paperbin
              ) {
                onRemoveFromPaperBin(identifier, droppedItem.image)
              }
            }
          : noop,
      deleteImage: onAddToPaperBin ? partial(onAddToPaperBin, [identifier]) : noop,
      toggleSelection: onToggleTenantSelection ? () => onToggleTenantSelection(identifier) : noop,
      approveGallery: onApproveGallery ? () => onApproveGallery(identifier) : noop,
      rejectGallery: onRejectGallery ? partial(onRejectGallery, [identifier]) : noop,
    }
  }, [
    articleNumber,
    tenantId,
    galleryNames,
    onGalleryChange,
    onToggleTenantSelection,
    onAddToPaperBin,
    onRemoveFromPaperBin,
    onApproveGallery,
    onRejectGallery,
  ])
}

const visibleGalleriesForCurrent: (keyof ImageGalleries)[] = ['originalApproved', 'global']

export const TenantAccordion: React.FC<TenantAccordionProps> = (props) => {
  const { tenant } = props
  const permissions = useSelector(profileSelectors.permissions)
  const tenants = useSelector(profileSelectors.tenants)
  const [showSuggestionsAsRejected, setShowSuggestionsAsRejected] = useState(false)
  const handlers = useTenantAccordionHandlers(props)
  const galleryNames = useGalleryNames(tenant)

  const dndTypeKey = `image_review_${props.articleNumber}_${tenant.tenantId}`
  const canDrop = useCallback(
    (droppedItem: ImageGalleryEntry, targetGallery: string, targetImage?: ImageDto) => {
      const srcGallery = droppedItem.galleryName
      if (srcGallery === targetGallery) {
        return true
      }
      if (targetGallery === galleryNames.suggestion) {
        if (srcGallery === galleryNames.paperbin) {
          return !targetImage
        }
        if (srcGallery === galleryNames.current) {
          return true
        }
      }

      return false
    },
    [galleryNames]
  )
  const disableDownload =
    !tenants.includes(tenant.tenantId) &&
    !permissions.has(UserPermission.DOWNLOAD_UNASSIGNED_IMAGES)
  const imageCount = useImageCount(tenant)
  const showCurrentGallery =
    props.mode !== 'variantDetailSuggestion' &&
    imageCount.originalApproved > 0 &&
    imageCount.suggested > 0
  const handleRejectEnter = useCallback(() => setShowSuggestionsAsRejected(true), [])
  const handleRejectLeave = useCallback(() => setShowSuggestionsAsRejected(false), [])

  const isOneOfUserTenants = tenants.includes(tenant.tenantId)
  const canUpdateGallery =
    isOneOfUserTenants &&
    (((props.mode === 'variantDetailSuggestion' || props.mode === 'imageReview') &&
      permissions.has(UserPermission.APPROVE_IMAGE)) ||
      (props.mode === 'gallery' && permissions.has(UserPermission.UPDATE_IMAGE_GALLERY)))

  return (
    <Accordion
      defaultExpanded={props.defaultExpanded}
      data-testid="tenant-accordion"
      data-tenantid={tenant.tenantId}
    >
      {tenant.fetchState === 'FETCHING' ? (
        <DataLoadingIndicator className={classes.loadingIndicator} />
      ) : null}
      <TenantAccordionSummary
        tenant={tenant}
        mode={props.mode}
        onToggleSelection={handlers.toggleSelection}
        canUpdateGallery={canUpdateGallery}
      />
      <AccordionDetails className={classes.accordionDetails} data-testid="tenant-accordion-details">
        <GalleryPaperBin
          articleNumber={tenant.articleNumber}
          galleryName={galleryNames.paperbin}
          draggableType={dndTypeKey}
          paperbin={tenant.paperbin}
        />
        <div className={classes.imageGallery}>
          <ImageGallery
            tenantGallery={tenant}
            draggableType={dndTypeKey}
            droppableType={dndTypeKey}
            canUpdateGallery={canUpdateGallery}
            canDrop={canDrop}
            disableDownload={disableDownload}
            galleryName={galleryNames.suggestion}
            rejectAllSuggestions={
              showSuggestionsAsRejected || (props.showSuggestionsAsRejected && tenant.isSelected)
            }
            onGalleryChange={handlers.galleryChange}
            onImageDelete={handlers.deleteImage}
          />
          <TenantNotification
            tenant={tenant}
            articleNumber={props.articleNumber}
            onTenantNotificationExpired={props.onTenantNotificationExpired}
          />
        </div>
        {showCurrentGallery ? (
          <GalleryAccordion
            itemCount={imageCount.originalApproved}
            className={classes.currentGallery}
            title={t`image.review.gallery.current.headline`}
            data-headline-testid="oldGalleryHeadline"
            data-count-testid="oldGalleryItemCount"
          >
            <ImageGallery
              canDrop={false}
              canUpdateGallery={false}
              disableDownload={disableDownload}
              tenantGallery={tenant}
              draggableType={dndTypeKey}
              galleryName={galleryNames.current}
              visibleGalleries={visibleGalleriesForCurrent}
              data-testid="oldGallery"
            />
          </GalleryAccordion>
        ) : null}

        <ApprovalButtons
          tenant={tenant}
          onMouseEnterReject={handleRejectEnter}
          onMouseLeaveReject={handleRejectLeave}
          onApproveGallery={handlers.approveGallery}
          onRejectGallery={handlers.rejectGallery}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default TenantAccordion
