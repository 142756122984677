import backendRequest from 'api/backendRequest'
import { MessageDto } from 'api/types'
import { ImageReviewsFilter } from 'store/imageReviews/initialState'
import DateUtils from 'utils/converter/DateUtils'
import {
  ImageReviewsDto,
  ImageReviewSearchForm,
  ImageReviewsFilterDto,
  ReviewImageGalleryForm,
} from './imageReviewsTypes'

export function updateReviewImageGallery(
  articleNumber: string,
  tenantId: string,
  data: ReviewImageGalleryForm
) {
  return backendRequest().post<MessageDto>(
    `/article/${articleNumber}/suggested-gallery/${tenantId}/review`,
    data
  )
}

function processArticleFilter(value: string): string[] {
  return value
    ? value
        .split(/[\s,]+/)
        .map((x) => x.trim())
        .filter(Boolean)
    : []
}

export function fetchImageReviews(filter: ImageReviewsFilter, page = 0, size = 10) {
  const form: ImageReviewSearchForm = {
    brands: filter.brandIds,
    tenants: filter.tenantIds,
    supplierIds: filter.supplierIds,
    articleNumbers: processArticleFilter(filter.articleNumbers),
    articleNumbersAndEans: processArticleFilter(filter.articleNumbers),
    uploadDate: {
      start: DateUtils.uiDateStringToApiDateString(filter.uploadStart),
      end: DateUtils.uiDateStringToApiDateString(filter.uploadEnd),
    },
    page,
    size,
  }

  return backendRequest().post<ImageReviewsDto>('image/reviews', form)
}

export function fetchImageReviewFilters() {
  return backendRequest().get<ImageReviewsFilterDto>('image/reviews/filter')
}
