import { ThunkAction } from 'redux-thunk'

import { translateErrorResponse } from 'api/errors'
import api, { ImageGalleryFeedbackListDto } from 'api/supplierFeedback'
import logger from 'utils/logger'
import { RootState } from '../reducers'
import * as types from './actionTypes'
import { SupplierFeedbackData } from './initialState'
import { AddFeedbackListPayload } from './payloadTypes'
import { SupplierFeedbackAction } from './reducers'

export const convertApiResponseToPayload = (
  r: ImageGalleryFeedbackListDto
): AddFeedbackListPayload =>
  ({
    feedbackList: r.articles
      ? r.articles.map(
          (f) =>
            ({
              id: f.id,
              articleNumber: f.articleNumber,
              ean: f.ean,
              brand: f.brand,
              tenantId: f.tenantId,
              galleryFeedbackKeys: f.galleryFeedbackSet,
              replacementImagesRequired: f.newImageGalleryRequested,
              gallery: f.imageFeedbackByPosition,
              isInStock: f.inStock,
              isSelected: false,
              blocked: false,
            } as SupplierFeedbackData)
        )
      : [],
  } as AddFeedbackListPayload)

type SupplierFeedbackThunkAction<T = void> = ThunkAction<T, RootState, null, SupplierFeedbackAction>

export const fetchSupplierFeedback = (
  onlyResolved: boolean,
  waitingProgressIndicator?: string
): SupplierFeedbackThunkAction<Promise<void>> => {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_FEEDBACK_LOAD })
    return api.fetchSupplierFeedback(onlyResolved, waitingProgressIndicator).then(
      (apiResponse) => {
        const { data } = apiResponse
        if (data) {
          dispatch({
            type: types.ADD_FEEDBACK_LIST,
            payload: convertApiResponseToPayload(data),
          })
        } else {
          dispatch({ type: types.SET_ERROR_LIST, payload: ['no data in response'] })
        }
      },
      (error) => {
        logger.error('fetchSupplierFeedback returned an error: %O', error)
        logger.error('error translated: ', translateErrorResponse(error))

        dispatch({ type: types.SET_ERROR_LIST, payload: translateErrorResponse(error) })
      }
    )
  }
}
export const removeSingleSupplierFeedback = (feedbackId: string) => ({
  type: types.REMOVE_SINGLE_FEEDBACK,
  payload: { feedbackId },
})

export const resolveSupplierFeedback = (
  feedbackId: string,
  onSuccess: () => void,
  waitingProgressIndicator?: string
): SupplierFeedbackThunkAction<Promise<void>> => {
  return (dispatch) => {
    return api.resolveSupplierFeedback(feedbackId, waitingProgressIndicator).then(
      (apiResponse) => {
        const { data } = apiResponse
        if (data) {
          onSuccess()
        } else {
          dispatch({ type: types.SET_ERROR_LIST, payload: ['no data in response'] })
        }
      },
      (error) => {
        logger.error('resolveSupplierFeedback returned an error: %O', error)
        logger.error('resolveSupplierFeedback error translated: ', translateErrorResponse(error))

        dispatch({ type: types.SET_ERROR_LIST, payload: translateErrorResponse(error) })
      }
    )
  }
}
