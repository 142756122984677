import React from 'react'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TypedMessage } from 'hooks/useMessageTextCollection'
import classes from './AlertMessageList.module.scss'

export default function AlertMessageList({ messages }: { messages: TypedMessage[] }) {
  return messages.length === 0 ? null : (
    <Box className={classes.alertListContainer} data-testid="alertMessageList">
      {messages.map((msg, idx) => (
        <Alert key={idx} severity={msg.msgType} variant="outlined">
          {msg.message}
        </Alert>
      ))}
    </Box>
  )
}
