import { UserDto } from 'api/adminUser'
import { BrandDto, RoleDto, SupplierDto } from 'api/types'
import { TypedMessage } from 'hooks/useMessageTextCollection'

export interface AdminUsersState {
  users: UserDto[]
  page: number
  size: number
  searchTerm: string
  alertMessages: TypedMessage[]
  brands: BrandDto[]
  suppliers: SupplierDto[]
  roles: RoleDto[]
}

const initialState: AdminUsersState = {
  users: [],
  page: 1,
  size: 20,
  searchTerm: '',
  alertMessages: [],
  brands: [],
  suppliers: [],
  roles: [],
}

export default initialState
