import type { TextFieldProps } from '@material-ui/core'

import { useState } from 'react'
import { TextField } from '@material-ui/core'
import classNames from 'classnames'

import { ItemTransferDialog } from 'components/ItemTransferList'
import { TransferItem } from 'components/ItemTransferList/ItemTransferListWithState'
import { DeleteTextAdornment } from './DeleteTextAdornment'
import useSharedFilterStyle from './hooks/useSharedFilterStyle'

interface TransferListFilterProps {
  label: string
  disabled?: boolean
  selected: string[]
  available: TransferItem[]
  'data-testid'?: string
  onChange: (selected: string[]) => void
}

const getFilterLabel = (label: string, items: string[]) =>
  items.length === 0 ? label : `${label} (${items.length})`

const TransferListFilter: React.FC<TransferListFilterProps> = (props) => {
  const { selected } = props
  const [isOpen, setOpen] = useState(false)
  const globalClasses = useSharedFilterStyle()

  const commonTextFieldProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'dense',
    disabled: props.disabled,
    fullWidth: true,
  }
  const handleOk = (newSelectedItems: string[]) => {
    setOpen(false)
    props.onChange(newSelectedItems)
  }
  const handleClear = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    setOpen(false)
    if (!props.disabled) {
      props.onChange([])
    }
  }

  const className = classNames(globalClasses.filterTextField, {
    [globalClasses.filterTextFieldUnselected]: selected.length === 0,
    [globalClasses.filterTextFieldDisabled]: props.disabled,
  })

  return (
    <div className={globalClasses.filterFieldRoot}>
      <TextField
        {...commonTextFieldProps}
        value={getFilterLabel(props.label, selected)}
        onClick={!props.disabled ? () => setOpen(true) : undefined}
        className={className}
        data-testid={props['data-testid']}
        InputProps={{
          readOnly: true,
          endAdornment: selected.length ? (
            <DeleteTextAdornment
              disabled={props.disabled}
              onClick={handleClear}
              data-testid="clear-filter"
            />
          ) : null,
        }}
      />
      <ItemTransferDialog
        open={isOpen}
        title={props.label}
        selected={selected}
        available={props.available}
        inputLabel={props.label}
        onCancel={() => setOpen(false)}
        onOk={handleOk}
      />
    </div>
  )
}

export default TransferListFilter
