import React from 'react'

import { ImageDto, ImagePosition, ImageState } from 'api/types'
import InheritedImage from './InheritedImage'
import { getImageUrl } from './misc'
import TenantImage from './TenantImage'
import classes from './ImageGallery.module.scss'

export interface ImageThumbnailProps {
  image: ImageDto
  imagePosition: ImagePosition
  galleryName: string
  draggableType?: string
  rejectAllSuggestions?: boolean
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
  const { image } = props

  switch (image.state) {
    case ImageState.INHERITED:
      return <InheritedImage {...props} />
  }

  return <TenantImage {...props} />
}

export default ImageThumbnail
