import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as QueryString from 'querystring'
import { pick } from 'ramda'

import { actions } from 'store/articleOverview'
import { selectors as profileSelectors } from 'store/userProfile'
import useInitialFilter from './useInitialFilter'

let isInitialRequest = true
export const useInitialLoad = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const totalSubscribedBrands = useSelector(profileSelectors.totalSubscribedBrands)
  const params = QueryString.parse(location.search.replace('?', ''))
  const urlParameter: Record<string, boolean> = {}
  for (const name of Object.keys(params)) {
    urlParameter[name] = params[name] === 'true'
  }
  const initialFilter = useInitialFilter(totalSubscribedBrands, urlParameter)

  useEffect(() => {
    if (isInitialRequest || location.state?.fromDashboard) {
      history.replace(pick(['pathname', 'search'], location))
      dispatch(actions.setFilter(initialFilter))
      dispatch(actions.fetchArticleOverview(0))
    } else {
      dispatch(actions.fetchArticleOverview())
    }
    isInitialRequest = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
