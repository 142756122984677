import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, IconButton, LinearProgress, makeStyles, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, Color } from '@material-ui/lab'
import clsx from 'clsx'

export interface SnackbarProps {
  severity?: Color
  onClose?: () => void
  children: ReactNode
  withPageReload?: boolean
  withCloseButton?: boolean
  hideAfterMs?: number
}

const animationTimeStepMs = 100
const defaultAnimationDurationProgressBar = 5000
const useStyles = makeStyles((theme) => ({
  snackbar: { maxWidth: '50vw' },
  progress: {
    width: '100%',
    position: 'absolute',
    top: 0,
    borderRadius: '4px 4px 0 0',
    backgroundColor: 'inherit',
  },
  bar1Determinate: {
    transition: `transform ${animationTimeStepMs}ms linear`,
    opacity: 0.5,
  },
  warning: { backgroundColor: theme.palette.warning.dark },
  error: { backgroundColor: theme.palette.error.dark },
  info: { backgroundColor: theme.palette.info.dark },
  success: { backgroundColor: theme.palette.success.dark },
  bar: { height: '3px' },
  elevation: {
    boxShadow: theme.shadows[4],
    border: 'none !important',
  },
}))

export default function SnackbarNotification(props: SnackbarProps) {
  const classes = useStyles()
  const variant = props.severity || 'info'
  const [snackbarOpen, setSnackbarOpen] = useState(true)
  const initialProgress = 1
  const fullProgress = 100
  const [progress, setProgress] = useState(initialProgress)
  const animationDuration = props.hideAfterMs || defaultAnimationDurationProgressBar
  const interval = fullProgress / (Math.ceil(animationDuration / animationTimeStepMs) - 1)

  const runAnimateProgress = useCallback(() => {
    setProgress(initialProgress + interval)
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return Math.min(oldProgress + interval, fullProgress)
      })
    }, animationTimeStepMs)

    return () => {
      clearInterval(timer)
    }
  }, [interval])

  useEffect(() => {
    return runAnimateProgress()
  }, [runAnimateProgress])

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    // only on reason "timeout"
    setSnackbarOpen(false)
    props.onClose?.()
  }

  const reload = () => {
    window.location.reload()
  }
  const pageReloadButton = () => (
    <Button color="primary" size="small" onClick={reload} style={{ whiteSpace: 'nowrap' }}>
      {t`snackbar.button.label.reload`}
    </Button>
  )

  const closeButton = () => (
    <IconButton aria-label="close" color="inherit" onClick={handleClose} size="small">
      <CloseIcon />
    </IconButton>
  )

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={props.withCloseButton ? null : animationDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={classes.snackbar}
    >
      <div>
        <Alert
          className={classes.elevation}
          onClose={handleClose}
          severity={variant}
          action={
            <Fragment>
              {props.withPageReload && pageReloadButton()}
              {props.withCloseButton && closeButton()}
            </Fragment>
          }
        >
          {props.children}
        </Alert>
        {!props.withCloseButton && (
          <LinearProgress
            variant={'determinate'}
            value={progress}
            className={classes.progress}
            classes={{
              bar1Determinate: clsx(classes.bar1Determinate, classes.bar, classes[variant]),
            }}
          />
        )}
      </div>
    </Snackbar>
  )
}
