import { useSelector } from 'react-redux'
import { defineMessage } from '@lingui/macro'

import { UserPermission } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import { DashboardElementProps } from './components/DashboardElement'

defineMessage({ id: 'dashboard.divider.label.with.suggestions' })
defineMessage({ id: 'dashboard.divider.label.without.suggestions' })

const mdmTiles: Partial<DashboardElementProps>[] = [
  {
    type: 'divider',
    messageId: 'dashboard.divider.label.with.suggestions',
  },
  { type: 'imageReviewsTile' },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      onlineReady: false,
      inStock: true,
      hasSuggestions: true,
      subscribedBrands: true,
    },
    hideFilterInCaption: ['hasSuggestions'],
  },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      onlineReady: false,
      hasSuggestions: true,
      subscribedBrands: true,
      prioGoLive: true,
    },
    hideFilterInCaption: ['hasSuggestions'],
  },
  {
    type: 'divider',
    messageId: 'dashboard.divider.label.without.suggestions',
  },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      onlineReady: false,
      inStock: true,
      hasSuggestions: false,
      subscribedBrands: true,
    },
    hideFilterInCaption: ['hasSuggestions'],
  },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      onlineReady: false,
      inStock: true,
      hasSuggestions: false,
      subscribedBrands: true,
      prioGoLive: true,
    },
    hideFilterInCaption: ['hasSuggestions'],
  },
]

const contactPersonTiles: Partial<DashboardElementProps>[] = [
  {
    type: 'articleOverviewTile',
    urlParameter: {
      hasSuggestions: true,
    },
  },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      prioGoLive: true,
      onlineReady: false,
      hasSuggestions: false,
    },
  },
  {
    type: 'articleOverviewTile',
    urlParameter: {
      inStock: true,
      onlineReady: false,
      hasSuggestions: false,
    },
  },
  { type: 'feedbackTile' },
]

export default function () {
  const permissions = useSelector(profileSelectors.permissions)

  if (permissions.has(UserPermission.SHOW_MDM_DASHBOARD)) {
    return mdmTiles
  }
  if (permissions.has(UserPermission.SHOW_CONTACT_PERSON_DASHBOARD)) {
    return contactPersonTiles
  }

  return []
}
