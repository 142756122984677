import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'

import rootStateReducer, { RootState } from './reducers'

const asyncStoreEnhancer = applyMiddleware(thunk)
export const store = createStore(rootStateReducer, composeWithDevTools(asyncStoreEnhancer))

export const createPrimaStore = (initialState: Partial<RootState>) =>
  createStore(rootStateReducer, initialState, applyMiddleware(thunk))

if (window.Cypress) {
  window.store = store
}
