import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Alert } from '@material-ui/lab'

import { selectors } from 'store/variantDetail'
import { TenantGallery } from 'types'
import classes from '../VariantDetailPage.module.scss'

const hasAnyImages = (v: TenantGallery) =>
  Object.keys(v.galleries.approved ?? {}).length > 0 ||
  Object.keys(v.galleries.suggested ?? {}).length > 0

export default function VariantWithNoGalleriesHint(props: { isDataLoading: boolean }) {
  const tenants = useSelector(selectors.tenants)
  const hideHint = props.isDataLoading || tenants.some(hasAnyImages)

  return hideHint ? null : (
    <div className={classes.noGalleryHint}>
      <Alert severity="info">
        <span>
          <Trans id="variantdetails.images.hint.no_gallery_images">
            There is no image gallery or image suggestions for this article.
          </Trans>
        </span>
      </Alert>
    </div>
  )
}
