import React from 'react'
import { t } from '@lingui/macro'

import { BooleanFilter, BooleanOptionKey, BooleanValue } from './BooleanFilter'

export interface HasSuggestionsFilterProps {
  value: BooleanValue
  disabled?: boolean
  onChange: (newValue: boolean | null) => void
  'data-testid'?: string
}

export function HasSuggestionsFilter(props: HasSuggestionsFilterProps) {
  const optionLabels = {
    optionNotSet: t`filter.options.has_suggestions_unfiltered`,
    optionTrue: t`filter.options.has_suggestions`,
    optionFalse: t`filter.options.has_no_suggestions`,
  } as { [K in BooleanOptionKey]: string }

  return (
    <BooleanFilter {...props} label={t`filter.label.has_suggestions`} optionLabels={optionLabels} />
  )
}
