import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import classes from './Links.module.scss'

export interface VariantDetailLinkProps {
  articleNumber: string
  className?: string
  'data-testid'?: string
}

const VariantDetailLink: React.FC<VariantDetailLinkProps> = (props) => {
  return (
    <Link
      to={`/variant/${props.articleNumber}`}
      data-testid={props['data-testid'] ?? 'variantDetailLink'}
      className={classnames(classes.link, props.className)}
    >
      {props.articleNumber}
    </Link>
  )
}

export default VariantDetailLink
