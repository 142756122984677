import type { ExportImageGalleryForBrandsForm } from 'api/adminService'
import type { BrandDto } from 'api/types'

import { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { usePromiseTracker } from 'react-promise-tracker'
import { t, Trans } from '@lingui/macro'
import { Box, Button, FormLabel, Grid, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Alert } from '@material-ui/lab'
import { AxiosError } from 'axios'
import { ValidationErrors } from 'final-form'
import { Radios, Select, SelectData, TextField } from 'mui-rff'

import adminServiceApi from 'api/adminService'
import { translateErrorMessage, translateErrorResponse } from 'api/errors'
import AlertMessageList from 'components/AlertMessageList/AlertMessageList'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import useMessageTextCollection, { MessageLevel } from 'hooks/useMessageTextCollection'
import logger from 'utils/logger'
import { availableTenants } from 'utils/tenants'
import BrandSelection from './components/BrandSelection'
import classes from './AdminPage.module.scss'

const UnknownOptionValue = 'Unknown'
const OptionArticleNumberKey = 'articleNumber'
const OptionBrandKey = 'brands'

interface ResyncOption {
  key: string
  label: string
}

interface ResyncFormValues {
  selectedResyncOption: string
  selectedTenant: string | null
  selectedBrands: string[]
  articleNumber?: string
}

const initialFormValues = {
  selectedResyncOption: OptionArticleNumberKey,
  articleNumber: '',
  selectedBrands: [],
  selectedTenant: UnknownOptionValue,
} as ResyncFormValues

const sortBrands = (brands: BrandDto[]): BrandDto[] =>
  [...brands].sort((a, b) => a.name.localeCompare(b.name))

export default function ServiceAdminPage() {
  const [availableBrands, setAvailableBrands] = useState<BrandDto[]>([])
  const [resyncError, setResyncError] = useState<string | null>(null)
  const [alertMessages, addGlobalErrors, addGlobalInfos, cleanAlertMessages] =
    useMessageTextCollection()
  const { promiseInProgress: loadInProgress } = usePromiseTracker({ area: 'optionDataLoadArea' })
  const { promiseInProgress: resyncInProgress } = usePromiseTracker({ area: 'resyncWaitArea' })

  const UnknownTenant: SelectData = {
    value: UnknownOptionValue,
    label: t`admin.resync.images.option.label.unknown_tenant`,
  }

  const ArticleNumberOption: ResyncOption = {
    key: OptionArticleNumberKey,
    label: t`admin.resync.images.option.label.by_article_number`,
  }

  const BrandOption: ResyncOption = {
    key: OptionBrandKey,
    label: t`admin.resync.images.option.label.by_brand`,
  }

  const allResyncOptions = [ArticleNumberOption, BrandOption]

  // initial rendering: load tenants and brands
  useEffect(() => {
    function loadOptions() {
      cleanAlertMessages()
      fetchAvailableBrands()
    }

    loadOptions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const submitArticleResync = (values: ResyncFormValues): ValidationErrors | undefined => {
    cleanAlertMessages()

    const validationErrors = validateFormValues(values)
    if (validationErrors) {
      logger.warn('found form errors: %O', validationErrors)
      return validationErrors
    }

    if (values.selectedResyncOption === OptionArticleNumberKey) {
      const articleNumbers = values.articleNumber?.split(/[\s,;]+/).filter(Boolean) || []
      sendArticleResyncData(articleNumbers, values.selectedTenant || '')
    } else if (values.selectedResyncOption === OptionBrandKey) {
      const requestData = {
        brands: values.selectedBrands,
        tenant: values.selectedTenant,
      } as ExportImageGalleryForBrandsForm

      sendBrandResyncData(requestData)
    } else {
      logger.error('selected resync option unknown: [key=%s]', values.selectedResyncOption)
      const result: Record<string, string> = {
        resyncOptionSwitcher: t`admin.resync.images.error.option_selection_required`,
      }
      return result
    }
  }

  const fetchAvailableBrands = () => {
    adminServiceApi.fetchAllAvailableBrands('optionDataLoadArea').then(
      (response) => setAvailableBrands(sortBrands(response.data.brands)),
      (error) => addGlobalErrors(translateErrorResponse(error))
    )
  }

  const sendArticleResyncData = (articleNumbers: string[], tenantId: string) => {
    setResyncError(null)
    return adminServiceApi.resyncArticleNumbers(articleNumbers, tenantId, 'resyncWaitArea').then(
      (response) => {
        addGlobalInfos(
          translateErrorMessage(response.data, response.data.message ?? 'Resync successful.'),
          MessageLevel.Success
        )
        return response.data
      },
      (error: AxiosError) => {
        const errorTexts = translateErrorResponse(error)
        if (errorTexts && errorTexts.length > 0) {
          setResyncError(errorTexts[0])
        }
        return null
      }
    )
  }

  const sendBrandResyncData = (requestData: ExportImageGalleryForBrandsForm) => {
    setResyncError(null)
    return adminServiceApi.resyncBrand(requestData, 'resyncWaitArea').then(
      (response) => {
        addGlobalInfos(
          translateErrorMessage(response.data, response.data.message ?? 'Resync successful.'),
          MessageLevel.Success
        )
        return response.data
      },
      (error: AxiosError) => {
        const errorTexts = translateErrorResponse(error)
        if (errorTexts && errorTexts.length > 0) {
          setResyncError(errorTexts[0])
        }
        return null
      }
    )
  }

  function validateFormValues(values: ResyncFormValues): Record<string, string> | null {
    const result: Record<string, string> = {}

    if (
      values.selectedResyncOption === OptionArticleNumberKey &&
      values.articleNumber?.length === 0
    ) {
      result.articleNumber = t`admin.resync.images.error.missing_article_number`
    }
    if (
      values.selectedResyncOption === OptionBrandKey &&
      (values.selectedBrands === null || values.selectedBrands.length === 0)
    ) {
      result.selectedBrands = t`admin.resync.images.error.missing_brand_selection`
    }
    if (values.selectedTenant === null || values.selectedTenant === UnknownOptionValue) {
      result.selectedTenant = t`admin.resync.images.error.missing_tenant_selection`
    }

    return Object.keys(result).length ? result : null
  }

  return (
    <Fragment>
      {loadInProgress ? (
        <DataLoadingIndicator />
      ) : (
        <Form
          onSubmit={submitArticleResync}
          initialValues={initialFormValues}
          render={({ handleSubmit, values, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <AlertMessageList messages={alertMessages} />
              {availableBrands && (
                <Box>
                  <Typography variant={'h3'} className={classes.headline}>
                    <Trans id="admin.resync.images.headline">PRIMA Resync Images</Trans>
                  </Typography>
                  <Box className={classes.resyncSettingsContainer}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'flex-end',
                          textAlign: 'end',
                          paddingRight: '1rem',
                          paddingBottom: '0.5rem',
                        }}
                      >
                        <div>
                          <FormLabel
                            required
                            htmlFor="resyncTenantSelection"
                            style={{ whiteSpace: 'nowrap' }}
                          >{t`admin.resync.images.label.tenant_selection`}</FormLabel>
                        </div>
                      </Grid>

                      <Grid item xs={10}>
                        <Select
                          id="resyncTenantSelection"
                          name="selectedTenant"
                          required
                          aria-required
                          autoWidth
                          data={[UnknownTenant].concat(
                            availableTenants.map((t) => ({
                              value: t.key,
                              label: t.label,
                            }))
                          )}
                          formControlProps={{
                            className: classes.resyncTenantSelection,
                            fullWidth: false,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'flex-end',
                          textAlign: 'end',
                          paddingRight: '1rem',
                          paddingBottom: '0.5rem',
                        }}
                      >
                        <div>
                          <FormLabel htmlFor="resyncBrandSelection">{t`admin.resync.images.label.option_switch`}</FormLabel>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <Radios
                          id="resyncBrandSelection"
                          name="selectedResyncOption"
                          data={allResyncOptions.map((option) => ({
                            label: option.label,
                            value: option.key,
                          }))}
                          radioGroupProps={{ row: true, 'aria-label': 'resyncOptions' }}
                          checkedIcon={<CheckCircleIcon className={classes.checkedRadioIcon} />}
                          icon={<span className={classes.selectionRadioIcon} />}
                          formControlProps={{
                            className: classes.resyncOptionSelection,
                          }}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                        />
                      </Grid>

                      <Grid item xs={2}></Grid>

                      <Grid item xs={10}>
                        <Box
                          style={{
                            display:
                              values.selectedResyncOption === OptionArticleNumberKey
                                ? 'initial'
                                : 'none',
                          }}
                        >
                          <TextField
                            multiline
                            maxRows={6}
                            margin="normal"
                            variant="outlined"
                            required={values.selectedResyncOption === OptionArticleNumberKey}
                            aria-required={values.selectedResyncOption === OptionArticleNumberKey}
                            type="text"
                            name="articleNumber"
                            label={t`admin.resync.images.label.article_number`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                        <Box
                          style={{
                            display:
                              values.selectedResyncOption === OptionBrandKey ? 'initial' : 'none',
                          }}
                          className={classes.resyncBrandSelectionContainer}
                        >
                          {submitErrors && submitErrors.selectedBrands && (
                            <div className={classes.fieldValidationErrorMessage}>
                              {submitErrors.selectedBrands}
                            </div>
                          )}
                          <BrandSelection
                            availableBrands={availableBrands}
                            formValueNameForSelected="selectedBrands"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.resyncButtonContainer}>
                    {resyncInProgress ? (
                      <DataLoadingIndicator />
                    ) : (
                      <Button variant={'contained'} color={'primary'} type="submit">
                        <Trans id="admin.resync.images.button.resync">RESYNC NOW</Trans>
                      </Button>
                    )}
                  </Box>
                  {resyncError && <Alert severity="error">{resyncError}</Alert>}
                </Box>
              )}
            </form>
          )}
        />
      )}
    </Fragment>
  )
}
