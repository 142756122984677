import React from 'react'
import { t } from '@lingui/macro'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import DialogTitle from 'components/DialogTitle'
import styles from './ImportGalleryVerifyDialog.module.scss'

interface ImportGalleryDialogProps {
  open: boolean
  onClose: () => void
  onApply: () => void
}

export const ImportGalleryVerifyDialog: React.FC<ImportGalleryDialogProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      data-testid="importGalleryVerifyDialog"
    >
      <DialogTitle className={styles.title} onClose={props.onClose}>
        {t`variantdetails.import_gallery.images_will_be_overwritten_in_target_tenant.headline`}
        <WarningIcon className={styles.icon} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        {t`variantdetails.import_gallery.images_will_be_overwritten_in_target_tenant.content`}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t`general.cancel`}
        </Button>
        <Button color="primary" onClick={props.onApply}>
          {t`general.apply`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
