import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import { selectors } from 'store/imageReviews'
import classes from '../ImageReviews.module.scss'

export default function TotalCountLabel() {
  const totalGalleriesCount = useSelector(selectors.totalElements)
  const galleryCount = useSelector(selectors.galleryCount)

  return (
    <div className={classes.openChecksStatus} data-testid="total-count-label">
      {totalGalleriesCount > 0 && (
        <span className={classes.openChecksLabel}>
          {t({
            id: 'image.review.open.total.label',
            values: {
              0: galleryCount,
              1: totalGalleriesCount,
            },
          })}
        </span>
      )}
    </div>
  )
}
