import type { ReactElement } from 'react'

import { useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { UserDto } from 'api/adminUser'
import SingleTextTableRow from 'components/SingleTextTableRow/SingleTextTableRow'
import { selectors } from 'store/adminUsers'
import { selectors as profileSelectors } from 'store/userProfile'
import { UserMenuOption } from '../components/UserMenu'
import UserTableControls from './UserTableControls'
import UserTableRow from './UserTableRow'
import useStyles from './useStyles'

interface Props {
  onClickUserInList: (userId: string) => void
  onClickUserMenu: (user: UserDto, menuOption: UserMenuOption) => void
}

export default function UserTable(props: Props): ReactElement {
  const classes = useStyles()
  const username = useSelector(profileSelectors.username)
  const users = useSelector(selectors.currentPage)
  const searchWords = useSelector(selectors.searchWords)

  let errorMsg: string | null = null
  if (!users || users.length === 0) {
    errorMsg = t`admin.accounts.list.hint.no_users`
  }

  return (
    <div data-testid="user-list">
      <UserTableControls showSearchTerm />
      <TableContainer>
        <Table aria-label="collapsible table" className={classes.table} stickyHeader size="small">
          <TableHead className={classes.header}>
            <TableRow className={classes.row}>
              <TableCell width="20%">
                <Trans id="admin.accounts.list.header.user_id">ID</Trans>
              </TableCell>
              <TableCell width="15%" align="left">
                <Trans id="admin.accounts.list.header.user_mail">Mail</Trans>
              </TableCell>
              <TableCell width="20%" align="left">
                <Trans id="admin.accounts.list.header.user_name">Name</Trans>
              </TableCell>
              <TableCell width="7%" align="left">
                <Trans id="admin.accounts.list.header.user_role">Role</Trans>
              </TableCell>
              <TableCell width="20%" align="left">
                <Trans id="admin.accounts.list.header.user_brands">Brand(s)</Trans>
              </TableCell>
              <TableCell width="13%" align="left">
                <Trans id="admin.accounts.list.header.user_tenants">Tenant(s)</Trans>
              </TableCell>
              <TableCell width="5%" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {errorMsg ? (
              <SingleTextTableRow message={errorMsg} columns={6} />
            ) : (
              users.map((user) => (
                <UserTableRow
                  key={user.userId}
                  user={user}
                  currentUsername={username}
                  searchWords={searchWords}
                  onClick={props.onClickUserInList}
                  onClickOptionMenu={props.onClickUserMenu}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <UserTableControls />
    </div>
  )
}
