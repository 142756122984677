import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import Paper from 'components/Paper'
import { ComponentCommonProps } from 'components/types'
import {
  BrandFilter,
  EanArticleNumbersFilter,
  ResetAllFilterButton,
  SupplierFilter,
  TenantFilter,
  useInitialFiltersChangedListener,
} from 'components/VariantFilter'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/galleryPage'
import { GalleryPageFilterName, initialFilter } from 'store/galleryPage/initialState'
import { selectors as profileSelectors } from 'store/userProfile'
import style from './GalleryPage.module.scss'

const GalleryPageFilter: React.FC<ComponentCommonProps> = ({
  'data-testid': dataTest = 'galleryPageFilter',
}) => {
  const boundActions = useBoundActions(actions)
  const requestInProgress = useSelector(selectors.requestInProgress)
  const filter = useSelector(selectors.filter)
  const allowedBrands = useSelector(selectors.allowedBrands)
  const allowedSuppliers = useSelector(selectors.allowedSuppliers)
  const allowedTenants = useSelector(selectors.allowedTenants)
  const brandLabels = useSelector(profileSelectors.brandLabels)
  const supplierLabels = useSelector(profileSelectors.supplierLabels)

  const totalSubscribedBrands: string[] = useSelector(profileSelectors.totalSubscribedBrands)

  const resetFiltersUnavailable = useInitialFiltersChangedListener(filter, initialFilter)

  const handleFilterChange = (name: GalleryPageFilterName) => (value: unknown) => {
    boundActions.setFilter({ [name]: value })
  }
  const handleFilterChangeAndUpdate = (name: GalleryPageFilterName) => (value: unknown) => {
    boundActions.setFilter({ [name]: value })
    boundActions.fetchGalleryPage()
  }
  const handleResetAll = () => {
    boundActions.resetFilter(totalSubscribedBrands)
    boundActions.fetchGalleryPage()
  }
  const handleSubmit = () => {
    boundActions.fetchGalleryPage()
  }

  return (
    <Paper className={style.filterPanel} data-testid={dataTest}>
      <EanArticleNumbersFilter
        disabled={requestInProgress}
        value={filter.articleNumbersAndEans}
        onChange={handleFilterChange('articleNumbersAndEans')}
        onSubmit={handleSubmit}
      />
      <BrandFilter
        disabled={requestInProgress}
        className={style.filter}
        brandIds={filter.brandIds}
        allowedBrands={allowedBrands}
        brandLabels={brandLabels}
        onChange={handleFilterChangeAndUpdate('brandIds')}
      />
      <SupplierFilter
        disabled={requestInProgress}
        supplier={filter.supplierIds}
        allowedSuppliers={allowedSuppliers}
        supplierLabels={supplierLabels}
        onChange={handleFilterChangeAndUpdate('supplierIds')}
      />
      {allowedTenants.length > 1 ? (
        <div className={style.filter}>
          <TenantFilter
            disabled={requestInProgress}
            label={t`gallerypage.filter.tenants`}
            tenants={allowedTenants}
            availableTenants={filter.tenantIds}
            onFilterChanged={handleFilterChange('tenantIds')}
            onClose={handleSubmit}
          />
        </div>
      ) : null}
      <ResetAllFilterButton
        hidden={resetFiltersUnavailable}
        onResetAll={handleResetAll}
        data-testid={`${dataTest}.reset`}
      />
    </Paper>
  )
}

export default GalleryPageFilter
