import { Fragment } from 'react'
import { t } from '@lingui/macro'
import { SvgIcon } from '@material-ui/core'

import { ReactComponent as InStockIcon } from 'style/icons/prima-icon-instock.svg'
import { ReactComponent as StrikeThroughIcon } from 'style/icons/prima-icon-strike-through.svg'
import classes from './StockStatusIndicator.module.scss'

export default function OutOfStockIcon() {
  return (
    <Fragment>
      <SvgIcon className={classes.stockIconNegative} data-testid="outOfStockIcon">
        <InStockIcon />
        <StrikeThroughIcon />
      </SvgIcon>
      <span>{t`image.review.article.headline.not_in_stock`}</span>
    </Fragment>
  )
}
