import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import clsx from 'clsx'

import DialogTitle from 'components/DialogTitle'
import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/bulkCopyGallery'
import { ArticleHeadline } from './ArticleHeadline'
import { ConflictNotification } from './ConflictNotification'
import { DotNavigation } from './DotNavigation'
import { TenantImages } from './TenantImages'
import { TenantSelectionForm } from './TenantSelectionForm'
import classes from './BulkCopyGallery.module.scss'

export const BulkCopyGalleryDialog: React.FC = () => {
  const showDialog = useSelector(selectors.showDialog)
  const selectedArticle = useSelector(selectors.selectedArticle)
  const srcTenant = useSelector(selectors.srcTenant)
  const dstTenant = useSelector(selectors.dstTenant)
  const srcTenantId = useSelector(selectors.srcTenantId)
  const dstTenantId = useSelector(selectors.dstTenantId)
  const stateOfSelectedArticles = useSelector(selectors.stateOfSelectedArticles)
  const availableSrcTenants = useSelector(selectors.availableSrcTenants)
  const availableDstTenants = useSelector(selectors.availableDstTenants)
  const srcTenantWarnings = useSelector(selectors.srcTenantWarnings)
  const srcTenantWarning = srcTenantWarnings.get(selectedArticle?.articleNumber ?? '') ?? null
  const dstTenantWarnings = useSelector(selectors.dstTenantWarnings)
  const dstTenantWarning = dstTenantWarnings.get(selectedArticle?.articleNumber ?? '') ?? null
  const manuallyCopiedArticles = useSelector(selectors.manuallyCopiedArticles)
  const alreadyCopied = manuallyCopiedArticles.includes(selectedArticle?.articleNumber ?? '')
  const boundActions = useBoundActions(actions)

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={showDialog}
      onClose={boundActions.closeDialog}
      data-testid="bulkCopyDialog"
    >
      <DialogTitle
        onClose={boundActions.closeDialog}
      >{t`galleryPage.bulkCopy.copy_galleries`}</DialogTitle>
      <DialogContent>
        <div className={classes.controls}>
          <div className={classes.gallerySelection}>
            <TenantSelectionForm
              label={t`galleryPage.bulkCopy.source`}
              value={srcTenantId}
              tenants={availableSrcTenants}
              onChange={boundActions.setSrcTenantId}
              data-testid="bulkCopyDialog.srcTenantSelection"
            />
            <TenantSelectionForm
              label={t`galleryPage.bulkCopy.destination`}
              value={dstTenantId}
              tenants={availableDstTenants}
              onChange={boundActions.setDstTenantId}
              data-testid="bulkCopyDialog.dstTenantSelection"
            />
          </div>
          <DotNavigation />
        </div>
        <div className={classes.ruler} />
        <div className={clsx(classes.selectedArticle, { [classes.alreadyCopied]: alreadyCopied })}>
          <ArticleHeadline article={selectedArticle} />
          <TenantImages
            tenantId={srcTenantId}
            tenantGallery={srcTenant}
            alreadyCopied={alreadyCopied}
            visibleGalleries={['approved', 'global']}
            data-testid="srcTenant"
            {...srcTenantWarning}
          />
          <div className={classes.arrowContainer}>
            <ArrowDownwardIcon className={classes.arrowIcon} />
          </div>
          <TenantImages
            tenantId={dstTenantId}
            tenantGallery={dstTenant}
            alreadyCopied={alreadyCopied}
            visibleGalleries={['approved', 'suggested', 'global']}
            data-testid="dstTenant"
            {...dstTenantWarning}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ConflictNotification />
        <div className={classes.spacer} />
        <Button
          variant="outlined"
          className={classes.dialogButton}
          onClick={boundActions.closeDialog}
          data-testid="bulkCopyDialog.cancel"
        >
          {t`general.cancel`}
        </Button>
        <Button
          variant="contained"
          className={classes.dialogButton}
          disabled={stateOfSelectedArticles === 'error'}
          onClick={boundActions.startGalleryCopy}
          data-testid="bulkCopyDialog.applyForAll"
        >
          {t`galleryPage.bulkCopy.applyForAll`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
