import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'

import imageApi from 'api/image'
import { ImageFeedback } from 'api/imageReviews/imageReviewsTypes'
import { ImageDto, ImageGallery, ImageState, UserPermission } from 'api/types'
import MeatballsMenu from 'components/MeatballsMenu'
import RejectionDetailDialog from 'components/RejectionDetailDialog'
import { selectors as profileSelectors } from 'store/userProfile'
import ImageContextMenuItem, { ImageContextMenuType } from './ImageContextMenuItem'

const useStyles = makeStyles({
  menu: {
    paddingBottom: '40%',
    position: 'relative',
  },
  menuIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export interface ImageContextMenuProps {
  image?: ImageDto
  articleNumber: string
  imagePosition: keyof ImageGallery
  canRemove?: boolean
  canReplace?: boolean
  hideDownloadEntry?: boolean
  mediathekEnabled?: boolean
  onToggleReviewState?: () => void
  onSetReviewFeedback?: (feedback?: ImageFeedback[], requestNewImage?: boolean) => void
  onImageDelete?: () => void
  onMediathekClick?: () => void
}

interface RejectionDetails {
  feedback?: ImageFeedback[]
  requestNewImage?: boolean
}

const ImageContextMenu: React.FC<ImageContextMenuProps> = (props) => {
  const {
    image,
    imagePosition,
    canRemove = false,
    canReplace = false,
    hideDownloadEntry = false,
    mediathekEnabled = false,
  } = props
  const classes = useStyles()
  const permissions = useSelector(profileSelectors.permissions)
  const [rejectionDetails, setRejectionDetails] = useState<RejectionDetails | null>(null)
  const enabledMenus = useMemo<ImageContextMenuType[]>(() => {
    const canApprove = permissions.has(UserPermission.APPROVE_IMAGE)

    return [
      canApprove && image?.state === ImageState.REJECTED ? ImageContextMenuType.Approve : null,
      canApprove && image?.state === ImageState.SUGGESTED ? ImageContextMenuType.Reject : null,
      canApprove && image?.state === ImageState.REJECTED && image?.feedback?.length
        ? ImageContextMenuType.EditFeedback
        : null,
      canRemove && image && image?.state === ImageState.APPROVED
        ? ImageContextMenuType.Delete
        : null,
      !hideDownloadEntry && image && imagePosition !== 8 ? ImageContextMenuType.Download : null,
      canReplace && mediathekEnabled ? ImageContextMenuType.Mediathek : null,
    ].filter((item: ImageContextMenuType | null): item is ImageContextMenuType => item !== null)
  }, [
    image,
    imagePosition,
    canRemove,
    canReplace,
    hideDownloadEntry,
    mediathekEnabled,
    permissions,
  ])
  const handleMenuClick = (menuType: ImageContextMenuType) => {
    switch (menuType) {
      case ImageContextMenuType.Approve:
        props?.onToggleReviewState?.()
        break
      case ImageContextMenuType.Reject:
      case ImageContextMenuType.EditFeedback:
        setRejectionDetails({
          feedback: image?.feedback,
          requestNewImage: image?.requestNewImage,
        })
        break
      case ImageContextMenuType.Delete:
        props.onImageDelete?.()
        break
      case ImageContextMenuType.Download:
        if (image?.imageId) {
          imageApi.downloadImage(image.imageId, `${props.articleNumber}_${image.position}`)
        }
        break
      case ImageContextMenuType.Mediathek:
        props.onMediathekClick?.()
        break
    }
  }
  const handleImageReject = (feedback: ImageFeedback[], requestNewImage: boolean) => {
    props.onSetReviewFeedback?.(feedback, requestNewImage)
    setRejectionDetails(null)
  }

  return (
    <div className={classes.menu} data-testid="imageContextMenu">
      {enabledMenus.length > 0 ? (
        <div className={classes.menuIcon}>
          <MeatballsMenu size="small" data-testid="imagemenu">
            {enabledMenus.map((menuType) => (
              <ImageContextMenuItem
                key={menuType}
                menuType={menuType}
                imagePosition={props.imagePosition}
                onClick={handleMenuClick}
              />
            ))}
          </MeatballsMenu>
        </div>
      ) : null}
      <RejectionDetailDialog
        {...rejectionDetails}
        mode="image"
        open={Boolean(rejectionDetails)}
        onReject={handleImageReject}
        onClose={() => setRejectionDetails(null)}
      />
    </div>
  )
}

export default ImageContextMenu
