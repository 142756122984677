import { useMemo } from 'react'
import { t } from '@lingui/macro'

import { SupplierLabels } from 'types'
import TransferListFilter from './TransferListFilter'

interface SupplierFilterProps {
  supplier: string[]
  allowedSuppliers: string[]
  supplierLabels: SupplierLabels
  onChange: (supplier: string[]) => void
  disabled?: boolean
}

export const SupplierFilter: React.FC<SupplierFilterProps> = (props) => {
  const { allowedSuppliers, supplierLabels } = props
  const availableSuppliers = useMemo(
    () => allowedSuppliers.map((id) => ({ id, label: supplierLabels.get(id) ?? id })),
    [allowedSuppliers, supplierLabels]
  )

  return (
    <TransferListFilter
      label={t`image.review.filter.label.suppliers`}
      selected={props.supplier}
      available={availableSuppliers}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  )
}
