import { MouseEvent } from 'react'
import { InputAdornment } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import useSharedFilterStyle from './hooks/useSharedFilterStyle'

export function DeleteTextAdornment(props: {
  disabled?: boolean
  onClick: (ev: MouseEvent) => void
  'data-testid'?: string
}) {
  const globalClasses = useSharedFilterStyle()

  return (
    <InputAdornment position="end">
      <Clear
        className={
          props.disabled ? globalClasses.clearFilterIconDisabled : globalClasses.clearFilterIcon
        }
        fontSize="small"
        onClick={props.disabled ? undefined : props.onClick}
        data-testid={props['data-testid']}
      />
    </InputAdornment>
  )
}
