import React, { useCallback, useMemo } from 'react'
import Measure, { ContentRect } from 'react-measure'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import { DataLoadingOverlay } from 'components/DataLoadingIndicator'
import Paper from 'components/Paper'
import useInfiniteScrolling, { ListEntries, UseInfiniteScrolling } from './useInfiniteScrolling'

const useStyles = makeStyles({
  jumpToTopButton: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 100,
  },
})

export interface InfiniteScrollingRow<EntryType, EntryProps> {
  measureRef: (ref: Element | null) => void
  entry: EntryType
  entryProps: EntryProps
}

const InfiniteScrollingEntry: React.FC<ListChildComponentProps> = (props) => {
  const { index, style } = props
  const { entries, Component, margin, setSize, entryProps, emptyListMessage } = props.data
  const listEntry = entries[index]
  const handleResize = useCallback(
    (contentRect: ContentRect) => {
      if (contentRect.bounds?.height) {
        setSize?.(index, contentRect.bounds.height + margin, listEntry)
      }
    },
    [index, margin, listEntry, setSize]
  )
  if (listEntry === 'empty list') {
    return (
      <Paper data-testid="no-data-info-panel">
        {emptyListMessage || t`infinite.scrolling.empty.list`}
      </Paper>
    )
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <div style={{ ...style, height: Number(style.height) - margin }}>
          <Component measureRef={measureRef} entry={listEntry} entryProps={entryProps} />
        </div>
      )}
    </Measure>
  )
}

interface InfiniteScrollingProps<EntryType, EntryProps, ReactComponent>
  extends UseInfiniteScrolling<EntryType, EntryProps, ReactComponent> {
  className?: string
  showJumpToTop?: boolean
}

function InfiniteScrolling<EntryType, EntryProps, ReactComponent>(
  props: InfiniteScrollingProps<EntryType, EntryProps, ReactComponent>
) {
  const [listRef, itemData, getSize, handleItemsRendered, jumpToTop] = useInfiniteScrolling<
    EntryType,
    EntryProps,
    ReactComponent
  >(props)
  const { itemKey } = props
  const getItemKey = useMemo(() => {
    return (index: number, data: ListEntries<EntryType, EntryProps, ReactComponent>) => {
      const entry = data.entries[index]
      return entry === 'empty list' ? entry : itemKey(entry)
    }
  }, [itemKey])
  const classes = useStyles()

  return (
    <div className={props.className} data-testid="scrollable-list-container">
      <DataLoadingOverlay open={Boolean(props.requestInProgress)} />
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            ref={listRef}
            width={width}
            height={height}
            itemData={itemData}
            itemCount={itemData.entries.length}
            itemSize={getSize}
            onItemsRendered={handleItemsRendered}
            itemKey={getItemKey}
          >
            {InfiniteScrollingEntry}
          </VariableSizeList>
        )}
      </AutoSizer>
      {props.showJumpToTop ? (
        <div className={classes.jumpToTopButton} onClick={jumpToTop}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowUpwardIcon />}
            data-testid="button-to-top"
          >
            {t`feedback.dashboard.button.go_to_top`}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default InfiniteScrolling
