import { Fragment, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Grid } from '@material-ui/core'

import { actions, selectors } from 'store/imageUpload'
import ExportCsvButton from './ExportCsvPanel'
import FileUploadPanel from './FileUploadPanel'
import UploadQueueContainer from './UploadQueueContainer'
import { FilenameHelpText } from './uploadSteps/FilenameHelpText'
import SummaryContainer from './uploadSteps/SummaryContainer'
import TenantSelectionContainer from './uploadSteps/TenantSelectionContainer'
import UploadInProgressBlocker from './uploadSteps/UploadInProgressBlocker'
import UploadStepCard from './uploadSteps/UploadStepCard'

interface ImageUploadContainerProps {
  colWidth1: 'auto' | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  colWidth2: 'auto' | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

export default function ImageUploadContainer(props: ImageUploadContainerProps): ReactElement {
  const dispatch = useDispatch()
  const isUploadInProgress = useSelector(selectors.isUploadInProgress)
  const handleAddFiles = (newFiles: File[]) => dispatch(actions.addImages(newFiles))

  return (
    <Fragment>
      <Grid item container xs={props.colWidth1} spacing={3}>
        {isUploadInProgress ? (
          <Grid item container spacing={1} xs={12}>
            <UploadInProgressBlocker />
          </Grid>
        ) : (
          <Grid item container spacing={1} xs={12}>
            <UploadStepCard
              stepNumber={1}
              title={t`upload.step.selectshops.headline_1`}
              subtitle={t`upload.step.selectshops.headline_2`}
              dataTestid="upload-step-select-tenant"
            >
              <TenantSelectionContainer />
            </UploadStepCard>

            <UploadStepCard
              stepNumber={2}
              title={t`upload.step.selectfiles.headline_1`}
              subtitle={t`upload.step.selectfiles.headline_2`}
              dataTestid="upload-step-select-files"
            >
              <FileUploadPanel acceptFilter="image/*" onAddSelectedFile={handleAddFiles} />
            </UploadStepCard>

            <UploadStepCard
              stepNumber={3}
              title={t`upload.step.start.headline_1`}
              dataTestid="upload-step-start"
            >
              <SummaryContainer />
            </UploadStepCard>
          </Grid>
        )}

        <Grid item container spacing={1} xs={12}>
          <Grid item style={{ flexGrow: 1 }}>
            <UploadQueueContainer />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={props.colWidth2} alignContent="flex-start" direction="column">
        <FilenameHelpText />
        {!isUploadInProgress && <ExportCsvButton />}
      </Grid>
    </Fragment>
  )
}
