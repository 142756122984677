import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Box } from '@material-ui/core'
import classnames from 'classnames'

import classes from './LegalHintLinks.module.scss'

export default function LegalHintLinks(): ReactElement {
  return (
    <Box display="flex" className={classes.legalHints}>
      <div
        data-testid={'legal-hints-legal-note'}
        className={classnames(`${classes.legalHintLink}`, `${classes.legalHintLink_LegalNote}`)}
      >
        <a href="/legal-note.html" target="_blank">
          <Trans id="footer.label.legal_note">Legal Note</Trans>
        </a>
      </div>
      |
      <div
        data-testid={'legal-hints-data-protection'}
        className={classnames(
          `${classes.legalHintLink}`,
          `${classes.legalHintLink_DataProtection}`
        )}
      >
        <a href="/data-protection.html" target="_blank">
          <Trans id="footer.label.data_protection">Data Protection</Trans>
        </a>
      </div>
    </Box>
  )
}
