import React from 'react'
import { plural, t } from '@lingui/macro'
import { AccordionSummary, Checkbox, Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import moment from 'moment/moment'

import StockStatusIndicator from 'components/StockStatusIndicator'
import TenantFlag from 'components/TenantFlag'
import useImageCount from 'hooks/useImageCount'
import { TenantGallery } from 'types'
import DateUtils from 'utils/converter/DateUtils'
import { isGlobalTenant, tenantLabel } from 'utils/tenants'
import { ArticleImageGalleryMode } from './ArticleImageGallery'
import classes from './TenantAccordionSummary.module.scss'

interface TenantAccordionHeaderProps {
  tenant: TenantGallery
  mode: ArticleImageGalleryMode
  onToggleSelection?: () => void
  canUpdateGallery: boolean
}

const TenantAccordionSummary: React.FC<TenantAccordionHeaderProps> = (props) => {
  const { tenant, mode, canUpdateGallery } = props
  const imageCount = useImageCount(tenant)
  const handleCheckboxClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation()
    props.onToggleSelection?.()
  }

  return (
    <AccordionSummary
      classes={{ content: classes.root }}
      expandIcon={<ExpandMoreIcon />}
      data-testid="tenantAccordionSummary"
    >
      <Grid container>
        <Grid item xs={canUpdateGallery ? 12 : 10} className={classes.tenantDescription}>
          {mode === 'imageReview' ? (
            <Checkbox
              color="primary"
              className={classes.selectionCheckbox}
              checked={tenant.isSelected}
              onClick={handleCheckboxClick}
              data-testid="tenant-selection"
            />
          ) : null}
          <TenantFlag
            tenantId={tenant.tenantId}
            className={clsx(classes.tenantFlag, { [classes.noImages]: imageCount.total === 0 })}
            shopName={tenantLabel(tenant.tenantId)}
          />
          <div className={classes.galleryName}>
            {t({ id: `general.galleryname.${tenant.tenantId}` })}
          </div>
          {tenant.ean ? <div className={classes.tenantDetails}>EAN: {tenant.ean}</div> : null}
          <div className={classes.tenantDetails}>
            <StockStatusIndicator
              isInStock={tenant.isInStock}
              useSpecialText={isGlobalTenant(tenant)}
            />
          </div>

          {tenant.onCounterDate ? (
            <div className={classes.tenantDetails}>
              <span
                className={classes.goLiveDateLabel}
              >{t`image.gallery.tenant.label.go_live`}</span>
              <span>{DateUtils.toHumanString(moment(tenant.onCounterDate))}</span>
            </div>
          ) : null}
          <div
            className={clsx(classes.tenantDetails, { [classes.noImages]: imageCount.total === 0 })}
            data-testid="tenantAccordionSummary.imageCount"
          >
            {imageCount.total > 0
              ? t({
                  id: 'image.gallery.tenant.image_count',
                  message: plural(String(imageCount.total), {
                    one: '# image',
                    other: '# images',
                  }),
                })
              : t`image.gallery.tenant.no_images`}
          </div>
          {imageCount.suggested > 0 ? (
            <div className={classes.tenantDetails}>{t`variantdetails.imagereviews.pending`}</div>
          ) : null}
        </Grid>

        {!canUpdateGallery ? (
          <Grid item xs={2} className={classes.tenantDescription}>
            <div className={classes.noPermissionInfo}>
              <span data-testid="no-permission-for-tenant">{t`variantdetails.no.edit.permission.for.tenant`}</span>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </AccordionSummary>
  )
}

export default TenantAccordionSummary
