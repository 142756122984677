import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

import { selectors } from 'store/imageUpload'
import classes from './ExportCsvPanel.module.scss'

const FailureHint: React.FC<{ failureCount: number }> = (props) => (
  <span className={classes.errorInfoLabel}>
    <Trans id="upload.export.hint.any_failed_uploads">
      {props.failureCount} image uploads not successful.
    </Trans>
  </span>
)

const SuccessHint: React.FC = () => (
  <span className={classes.successInfoLabel}>
    <Trans id="upload.export.hint.all_success">All image uploads successful.</Trans>
  </span>
)

const ExportCsvPanel: React.FC = () => {
  const invalidProcessedImages = useSelector(selectors.invalidProcessedImages)
  const uploadReportLink = useSelector(selectors.uploadReportLink)
  const targetFilename = `prima-upload-status-export-${new Date().toISOString()}.csv`

  return uploadReportLink ? (
    <div className={classes.resultDownloadPanel} data-testid={'upload-result-export-panel'}>
      {invalidProcessedImages.length > 0 ? (
        <FailureHint failureCount={invalidProcessedImages.length} />
      ) : (
        <SuccessHint />
      )}
      <Button href={uploadReportLink} download={targetFilename} className={classes.linkButton}>
        <GetAppIcon fontSize="small" className={classes.linkIcon} />
        <span>
          <Trans id="upload.export.button.download">Download report</Trans>
        </span>
      </Button>
    </div>
  ) : null
}

export default ExportCsvPanel
