import React from 'react'
import Lightbox from 'react-image-lightbox'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from 'store/general'

const ImageLightbox: React.FC<unknown> = () => {
  const dispatch = useDispatch()
  const zoomImageUrl = useSelector(selectors.zoomImageUrl)
  const handleCloseRequest = () => dispatch(actions.setZoomImageUrl(null))

  return zoomImageUrl ? (
    <Lightbox
      mainSrc={zoomImageUrl}
      onCloseRequest={handleCloseRequest}
      reactModalStyle={{ overlay: { zIndex: 2000 } }}
    />
  ) : null
}

export default ImageLightbox
