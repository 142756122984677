import { useSelector } from 'react-redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'

import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/bulkCopyGallery'
import classes from './DotNavigation.module.scss'

export interface DotNavigationOptionProps {
  option: string | null
}

export const DotNavigationOption: React.FC<DotNavigationOptionProps> = ({ option }) => {
  const selectedArticleNumber = useSelector(selectors.selectedArticleNumber)
  const srcTenantWarnings = useSelector(selectors.srcTenantWarnings)
  const dstTenantWarnings = useSelector(selectors.dstTenantWarnings)
  const disabledArticles = useSelector(selectors.disabledArticles)
  const boundActions = useBoundActions(actions)

  if (!option) {
    return <div className={classes.emptyOption} data-testid="dotNavigation.option.empty" />
  }

  const isSelected = option === selectedArticleNumber
  const isDisabled = disabledArticles.has(option)
  const srcStatus = srcTenantWarnings.get(option)?.severity || 'normal'
  const dstStatus = dstTenantWarnings.get(option)?.severity || 'normal'

  return (
    <div
      className={clsx(classes.option, {
        [classes.selected]: isSelected,
        [classes.disabled]: isDisabled,
      })}
      onClick={() => boundActions.setSelectedArticleNumber(option)}
      data-testid="dotNavigation.option"
      data-selected={isSelected}
      data-disabled={isDisabled}
    >
      <div
        className={clsx(classes.optionBubble, classes[srcStatus])}
        data-testid="dotNavigation.option.source"
        data-status={srcStatus}
      >
        {srcStatus === 'error' ? <ErrorIcon className={classes.error} /> : null}
        {srcStatus === 'warning' ? <WarningIcon className={classes.warning} /> : null}
        {srcStatus === 'normal' ? <CheckCircleIcon className={classes.normal} /> : null}
      </div>
      <div
        className={clsx(classes.optionBubble, classes[dstStatus])}
        data-testid="dotNavigation.option.destination"
        data-status={dstStatus}
      >
        {dstStatus === 'error' ? <ErrorIcon className={classes.error} /> : null}
        {dstStatus === 'warning' ? <WarningIcon className={classes.warning} /> : null}
        {dstStatus === 'normal' ? <CheckCircleIcon className={classes.normal} /> : null}
      </div>
    </div>
  )
}
