import type * as Payloads from './actions'
import type { VariantDetailState } from './initialState'

import { __ } from 'ramda'

import { createReducer } from 'store/utils'
import { ArticleGalleries, TenantGallery } from 'types'
import articleGalleries from 'utils/articleGalleries'
import * as types from './actionTypes'
import initialState from './initialState'

function updateTenant<T extends TenantGallery>(
  tenants: T[],
  tenantId: string,
  updater: (tenant: T) => T
) {
  return tenants.map((current) => (current.tenantId === tenantId ? updater(current) : current))
}

function updateImageSuggestions(
  state: VariantDetailState,
  updater: (suggestions: ArticleGalleries) => ArticleGalleries
) {
  return state.imageSuggestions
    ? { ...state, imageSuggestions: updater(state.imageSuggestions) }
    : state
}

type Handler<T> = (state: VariantDetailState, payload: T) => VariantDetailState
// eslint-disable-next-line @typescript-eslint/ban-types
type HandlerWithoutPayload = Handler<{}>

const handlers = {
  [types.SET_VARIANT_DETAILS]: ((state, payload): VariantDetailState => {
    return {
      ...state,
      ...payload,
      errors: [],
      paperbin: [],
      changesMade: false,
    }
  }) as Handler<ArticleGalleries>,

  [types.RESET_VARIANT_DETAILS]: ((state): VariantDetailState => ({
    ...state,
    articleNumber: '',
    tenants: [],
    imageSuggestions: null,
    changesMade: false,
  })) as HandlerWithoutPayload,

  [types.ADD_ERROR]: ((state, error): VariantDetailState => ({
    ...state,
    errors: [...state.errors, error],
  })) as Handler<string>,

  [types.SET_ERRORS]: ((state, errors): VariantDetailState => ({
    ...state,
    errors,
    articleNumber: '',
    tenants: [],
    imageSuggestions: null,
  })) as Handler<string[]>,

  [types.SET_INFO_MESSAGE]: ((state, infoMessage): VariantDetailState => ({
    ...state,
    infoMessage,
  })) as Handler<string | null>,

  [types.CLEAN_NOTIFICATIONS]: ((state): VariantDetailState => ({
    ...state,
    errors: [],
    infoMessage: null,
  })) as HandlerWithoutPayload,

  [types.SHOW_SUBMIT_NOTIFICATION]: ((state): VariantDetailState => ({
    ...state,
    showSubmitNotification: true,
  })) as HandlerWithoutPayload,

  [types.CLOSE_SUBMIT_NOTIFICATION]: ((state): VariantDetailState => ({
    ...state,
    showSubmitNotification: false,
  })) as HandlerWithoutPayload,

  [types.REMOVE_IMAGE_SUGGESTION]: ((state, tenantId): VariantDetailState =>
    updateImageSuggestions(state, articleGalleries.removeTenant(__, tenantId))) as Handler<string>,

  [types.UPDATE_IMAGE_SUGGESTION]: ((state, { tenantId, update }): VariantDetailState =>
    updateImageSuggestions(
      state,
      articleGalleries.updateTenant(__, tenantId, update)
    )) as Handler<Payloads.UpdateImageSuggestionPayload>,

  [types.ADD_TO_PAPERBIN]: ((state, { tenantId, image, galleries }): VariantDetailState => ({
    ...state,
    changesMade: true,
    paperbin: [...(state.paperbin || []), image],
    tenants: updateTenant(state.tenants, tenantId, (match) => ({
      ...match,
      galleries,
    })),
  })) as Handler<Payloads.AddToPaperbinPayload>,

  [types.REMOVE_FROM_PAPERBIN]: ((state, { image }): VariantDetailState => ({
    ...state,
    paperbin: (state.paperbin || []).filter((current) => current !== image),
  })) as Handler<Payloads.RemoveFromPaperbinPayload>,

  [types.ADD_TO_SUGGESTION_PAPERBIN]: ((
    state,
    { tenantId, image, galleries }
  ): VariantDetailState =>
    updateImageSuggestions(
      state,
      articleGalleries.addToPaperBin(__, tenantId, image, galleries)
    )) as Handler<Payloads.AddToSuggestionPaperBinPayload>,

  [types.REMOVE_FROM_SUGGESTION_PAPERBIN]: ((state, { tenantId, image }): VariantDetailState =>
    updateImageSuggestions(
      state,
      articleGalleries.removeFromPaperBin(__, tenantId, image)
    )) as Handler<Payloads.RemoveFromSuggestionPaperBinPayload>,

  [types.SET_IMAGE_GALLERY]: ((state, { tenantId, galleries }): VariantDetailState => ({
    ...state,
    tenants: state.tenants.map((current) =>
      current.tenantId === tenantId ? { ...current, galleries } : current
    ),
    changesMade: true,
  })) as Handler<Payloads.SetImageGalleryPayload>,

  [types.REPLACE_WITH_MEDIATHEK_IMAGE]: ((
    state,
    { tenantId, image, imagePosition }
  ): VariantDetailState => ({
    ...state,
    changesMade: true,
    tenants: updateTenant(state.tenants, tenantId, (match) => ({
      ...match,
      galleries: {
        ...match.galleries,
        approved: {
          ...match.galleries.approved,
          [imagePosition]: image,
        },
      },
    })),
  })) as Handler<Payloads.ReplaceWithMediathekImagePayload>,

  [types.COPY_TENANT_GALLERY]: ((state, { srcTenantId, dstTenantId }): VariantDetailState => {
    const srcTenant = state.tenants.find((t) => t.tenantId === srcTenantId)
    return srcTenant
      ? {
          ...state,
          changesMade: true,
          tenants: updateTenant(state.tenants, dstTenantId, (match) => ({
            ...match,
            galleries: {
              ...match.galleries,
              approved: srcTenant.galleries.approved,
            },
          })),
        }
      : state
  }) as Handler<Payloads.CopyTenantGalleryPayload>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type VariantDetailAction = Actions[keyof Actions]

export default createReducer<VariantDetailState, VariantDetailAction>(initialState, handlers)
