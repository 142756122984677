import React from 'react'
import { i18n } from '@lingui/core'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divider: { margin: theme.spacing(2, 0, 1, 0) },
  label: { fontVariant: 'small-caps', textAlign: 'center' },
  item: { padding: 0 },
}))

interface DashboardDividerProps {
  messageId?: string
}

const DashboardDivider: React.FC<DashboardDividerProps> = ({ messageId }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.divider} data-testid="dashboard-divider">
      <Typography variant="h6" gutterBottom className={classes.label}>
        {messageId ? i18n._(messageId) : null}
      </Typography>
      <Divider />
    </Grid>
  )
}

export default DashboardDivider
