import { ReactElement } from 'react'
import { t, Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

import ImageGuidelineLink from './ImageGuidelineLink'
import classes from './HelpText.module.scss'

export function FilenameHelpText(): ReactElement {
  return (
    <div className={classes.helperBox}>
      <Typography variant="h5" className={classes.headline}>
        {t`upload.instructions.requirements.headline`}
      </Typography>

      <Typography variant="body1">
        <Trans id="upload.instructions.requirements.text.intro">
          <strong />
        </Trans>
      </Typography>

      <ul className={classes.hintList}>
        <li>
          <Trans id="upload.instructions.requirements.listitem.filename">
            <strong />
            <br />
          </Trans>
        </li>
        <li>
          <Trans id="upload.instructions.requirements.listitem.image">
            <strong />
            <br />
            <br />
            <br />
            <br />
          </Trans>
        </li>
        <li>
          <Trans id="upload.instructions.requirements.listitem.blob">
            <strong />
            <br />
            <br />
            <br />
          </Trans>
        </li>
      </ul>
      <ImageGuidelineLink />
    </div>
  )
}
