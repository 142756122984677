import { AnyAction, createAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { translateErrorResponse } from 'api/errors'
import { ImageDto, RawImageGalleryEntry } from 'api/types'
import api from 'api/variantDetail'
import { actions as galleryPageActions } from 'store/galleryPage'
import { RootState } from 'store/reducers'
import { ArticleGalleries } from 'types/index'
import articleGalleries from 'utils/articleGalleries'
import { GlobalTenantId } from 'utils/tenants'
import selectors from './selectors'

const SET_SRC_TENANT_ID = 'bulkCopyGallery/SET_SRC_TENANT_ID'
const SET_DST_TENANT_ID = 'bulkCopyGallery/SET_DST_TENANT_ID'
const SET_SHOW_DIALOG = 'bulkCopyGallery/SET_SHOW_DIALOG'
const OPEN_DIALOG = 'bulkCopyGallery/OPEN_DIALOG'
const SET_SELECTED_ARTICLE_NUMBER = 'bulkCopyGallery/SET_SELECTED_ARTICLE_NUMBER'
const SET_CURRENT_PAGE = 'bulkCopyGallery/SET_CURRENT_PAGE'
const TOGGLE_DISABLED_ARTICLE = 'bulkCopyGallery/TOGGLE_DISABLED_ARTICLE'
const SET_COPY_IN_PROGRESS = 'bulkCopyGallery/SET_COPY_IN_PROGRESS'
const SET_COPIED_GALLERIES = 'bulkCopyGallery/SET_COPIED_GALLERIES'
const START_GALLERY_COPY = 'bulkCopyGallery/START_GALLERY_COPY'
const RESET_DIALOG = 'bulkCopyGallery/RESET_DIALOG'
const SET_COPY_ERRORS = 'bulkCopyGallery/SET_COPY_ERRORS'
const SINGLE_GALLERY_COPY = 'bulkCopyGallery/SINGLE_GALLERY_COPY'

export const setDstTenantId = createAction<string>(SET_DST_TENANT_ID)
export const setShowDialog = createAction<boolean>(SET_SHOW_DIALOG)
export const toggleDisabledArticle = createAction<string>(TOGGLE_DISABLED_ARTICLE)
export const setCopyInProgress = createAction<boolean>(SET_COPY_IN_PROGRESS)
export const setCopiedGalleries = createAction<number>(SET_COPIED_GALLERIES)
export const resetDialog = createAction(RESET_DIALOG)
export const setCopyErrors = createAction<string[] | null>(SET_COPY_ERRORS)

export const setSrcTenantId = createAsyncThunk(SET_SRC_TENANT_ID, (tenantId: string, thunkApi) => {
  const { getState, dispatch } = thunkApi

  const state = getState() as RootState
  const dstTenantId = selectors.dstTenantId(state)
  const availableSrcTenants = selectors.availableSrcTenants(state)

  if (!dstTenantId || tenantId === dstTenantId) {
    for (const newDstTenantId of availableSrcTenants) {
      if (newDstTenantId !== tenantId) {
        dispatch(setDstTenantId(newDstTenantId))
        break
      }
    }
  }

  return tenantId
})

export const setSelectedArticleNumber = createAsyncThunk(
  SET_SELECTED_ARTICLE_NUMBER,
  (articleNumber: string, thunkApi) => {
    const { getState } = thunkApi

    const state = getState() as RootState
    const indexOfArticle = selectors
      .selectedArticles(state)
      .findIndex((article) => article.articleNumber === articleNumber)
    const articlesPerPage = selectors.articlesPerPage(state)
    const newPage = Math.floor(indexOfArticle / articlesPerPage)

    return { articleNumber, newPage }
  }
)

export const openDialog = createAsyncThunk(OPEN_DIALOG, (_, thunkApi) => {
  const { getState, dispatch } = thunkApi

  const state = getState() as RootState
  const selectedArticles = selectors.selectedArticles(state)
  const tenantIdsOfSelectedArticles = selectors.tenantIdsOfSelectedArticles(state)

  if (selectedArticles.length && tenantIdsOfSelectedArticles.length > 1) {
    dispatch(resetDialog())
    dispatch(setSelectedArticleNumber(selectedArticles[0].articleNumber))
    dispatch(setSrcTenantId(tenantIdsOfSelectedArticles[1]))
    dispatch(setShowDialog(true))
  }
})

export const closeDialog = () => setShowDialog(false)

export const setCurrentPage = createAsyncThunk(SET_CURRENT_PAGE, (delta: number, thunkApi) => {
  const state = thunkApi.getState() as RootState
  const maxPage = selectors.maxPage(state)
  const currentPage = selectors.currentPage(state)
  const selectedArticles = selectors.selectedArticles(state)
  const articlesPerPage = selectors.articlesPerPage(state)
  const newPage = Math.max(0, Math.min(maxPage, currentPage + delta))
  const selectedArticleNumber = selectedArticles[newPage * articlesPerPage].articleNumber

  return { currentPage: newPage, selectedArticleNumber }
})

export const nextPage = () => setCurrentPage(1)
export const prevPage = () => setCurrentPage(-1)

const copiedGalleryPayload = (
  article: ArticleGalleries,
  srcTenantId: string,
  dstTenantId: string
) => {
  const srcTenant = articleGalleries.findTenant(article, srcTenantId)
  const dstTenant = articleGalleries.findTenant(article, dstTenantId)

  if (!srcTenant || !dstTenant) {
    return []
  }

  const approvals: RawImageGalleryEntry[] = Object.values(srcTenant.galleries?.approved ?? [])
    .filter((image: ImageDto) => image.position !== 'blob')
    .map((image) => ({
      tenantId: dstTenantId,
      imageId: image.imageId,
      position: image.position,
    }))

  if (dstTenantId === GlobalTenantId) {
    const globalTenant = articleGalleries.findTenant(article, GlobalTenantId)
    const globalBlob = globalTenant?.galleries.approved?.blob

    if (globalBlob) {
      approvals.push({
        tenantId: dstTenantId,
        imageId: globalBlob.imageId,
        position: 'blob',
      })
    }
  }

  return approvals
}

export const startGalleryCopy = createAsyncThunk(START_GALLERY_COPY, async (_, thunkApi) => {
  const { dispatch, getState } = thunkApi
  const state = getState() as RootState
  const srcTenantId = selectors.srcTenantId(state)
  const dstTenantId = selectors.dstTenantId(state)
  const enabledSelectedArticles = selectors.enabledSelectedArticles(state)

  dispatch(closeDialog())
  if (!srcTenantId || !dstTenantId) {
    return
  }

  dispatch(setCopyErrors(null))
  dispatch(setCopyInProgress(true))

  let copiedArticles = 0
  let totalErrorMessages: string[] = []
  for (const article of enabledSelectedArticles) {
    dispatch(setCopiedGalleries(++copiedArticles))

    const payload = copiedGalleryPayload(article, srcTenantId, dstTenantId)
    try {
      await api.updateImageGallery(article.articleNumber, payload)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        let errorMessages: string[] = [error.message]
        const errors = translateErrorResponse(error)
        if (errors.length) {
          errorMessages = errors
        }
        totalErrorMessages = totalErrorMessages.concat(errorMessages)
      }
    }

    dispatch(
      galleryPageActions.reloadArticle(article.articleNumber) as ThunkAction<
        void,
        unknown,
        unknown,
        AnyAction
      >
    )
  }

  if (totalErrorMessages.length) {
    dispatch(setCopyErrors(totalErrorMessages))
  } else {
    dispatch(setCopyInProgress(false))
    dispatch(galleryPageActions.resetSelectedArticles())
  }
})

export const singleGalleryCopy = createAsyncThunk(
  SINGLE_GALLERY_COPY,
  async (articleNumber: string, thunkApi) => {
    const { dispatch, getState } = thunkApi
    const state = getState() as RootState
    const selectedArticles = selectors.selectedArticles(state)
    const srcTenantId = selectors.srcTenantId(state)
    const dstTenantId = selectors.dstTenantId(state)

    const article = selectedArticles.find((article) => article.articleNumber === articleNumber)
    if (article && srcTenantId && dstTenantId) {
      const payload = copiedGalleryPayload(article, srcTenantId, dstTenantId)
      await api.updateImageGallery(article.articleNumber, payload)
      dispatch(
        galleryPageActions.reloadArticle(article.articleNumber) as ThunkAction<
          void,
          unknown,
          unknown,
          AnyAction
        >
      )
    }

    return articleNumber
  }
)
