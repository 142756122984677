import type { BrandDto, SupplierDto, UserPermission } from 'api/types'
import type { FeatureToggles } from 'types'

import { Role } from 'api/types'
import { BrandSubscriptionsDto } from 'api/userProfile'

export interface UserProfileState {
  isLoaded: boolean
  username: string
  tenants: string[]
  permissions: Set<UserPermission>
  role: Role
  enabledFeatures: Set<FeatureToggles>
  firstName: string
  lastName: string
  email: string
  defaultRoute: string
  brandRestrictions: string[]
  brandSubscriptions: BrandSubscriptionsDto[]
  subscribedUsers: string[]
  allBrands: BrandDto[]
  suppliers: SupplierDto[]
  errorResponse: string[] | null
}

const initialState: UserProfileState = {
  isLoaded: false,
  username: '',
  tenants: [],
  permissions: new Set([]),
  role: Role.ANONYMOUS,
  enabledFeatures: new Set([]),
  firstName: '',
  lastName: '',
  email: '',
  defaultRoute: '/not-enough-permission',
  brandRestrictions: [],
  brandSubscriptions: [],
  subscribedUsers: [],
  allBrands: [],
  suppliers: [],
  errorResponse: null,
}

export default initialState
