import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'

import { useItemTransferListState } from './hooks'
import ItemTransferList from './ItemTransferList'
import classes from './ItemTransferList.module.scss'

export interface TransferItem {
  id: string
  label: string
  detail?: string
}

export interface ItemTransferListWithStateProps {
  selected: string[]
  available: TransferItem[]
  inputLabel: string
  onOk?: (selected: string[]) => void
  onCancel?: () => void
}

const ItemTransferListWithState: React.FC<ItemTransferListWithStateProps> = (props) => {
  const { available, selected } = props
  const state = useItemTransferListState(selected, available)
  const handleOk = () => props.onOk?.(state.selected)

  return (
    <div>
      <ItemTransferList state={state} inputLabel={props.inputLabel} />
      <div className={classes.actions}>
        <div className={classes.actionSpace} />
        <Button variant="outlined" color="primary" onClick={props.onCancel}>
          {t`general.cancel`}
        </Button>
        <Button variant="contained" color="primary" onClick={handleOk}>
          {t`general.apply`}
        </Button>
      </div>
    </div>
  )
}

export default ItemTransferListWithState
