import type { TextFieldProps } from '@material-ui/core'
import type { CreateAdminDto } from 'api/adminUser'
import type { ValidationErrors } from 'final-form'

import React from 'react'
import { Form } from 'react-final-form'
import { usePromiseTracker } from 'react-promise-tracker'
import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import { TextField } from 'mui-rff'

import adminUserApi from 'api/adminUser'
import AlertMessageList from 'components/AlertMessageList/AlertMessageList'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import { useMessageTextCollection } from 'hooks'
import logger from 'utils/logger'
import { EmailTextField, FullNameTextFields } from './TextField'

interface AdminFormValues {
  email: string
  firstName: string
  lastName: string
  username: string
}

const initialValues: AdminFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  username: '',
}

interface CreateAdminDialogProps {
  open: boolean
  onClose: () => void
  onCommit: () => void
}

function validateFormValues(values: AdminFormValues): Record<string, string> | null {
  const result: Record<string, string> = {}

  if (!values.username.length) {
    result.username = t`admin.create.account.admin.error.missing_login_name`
  }
  if (!/.+@.+\..{2,}/.test(values.email)) {
    result.email = t`admin.create.account.admin.error.invalid_mail`
  }
  if (!/\D+/.test(values.firstName)) {
    result.firstName = t`admin.create.account.admin.error.missing_first_name`
  }
  if (!/\D+/.test(values.lastName)) {
    result.lastName = t`admin.create.account.admin.error.missing_last_name`
  }

  return Object.keys(result).length ? result : null
}

const CreateAdminDialog: React.FC<CreateAdminDialogProps> = (props) => {
  const { promiseInProgress } = usePromiseTracker({ area: 'saveAdminProgress' })
  const [alertMessages, addErrors, , cleanAlertMessages] = useMessageTextCollection()

  const handleSubmit = (values: AdminFormValues): ValidationErrors | undefined => {
    cleanAlertMessages()
    const errors = validateFormValues(values)
    if (errors) {
      return errors
    }
    adminUserApi.createAdmin(values, 'saveAdminProgress').then(
      (response) => {
        if (response.data?.errorMessages) {
          addErrors(response.data.errorMessages)
        } else {
          props.onCommit()
        }
      },
      (error: AxiosError<CreateAdminDto>) => {
        logger.error('createRemoteAdmin returned an error: %O', error)
        if (error.response) {
          const { status, data } = error.response
          if (status === 403) {
            addErrors(t`admin.create.account.admin.error.admin_creation_forbidden`)
          } else if (status === 400 && data.errorMessages) {
            addErrors(data.errorMessages)
          } else {
            addErrors(error.message)
          }
        }
      }
    )
  }

  const handleClose = () => {
    cleanAlertMessages()
    props.onClose()
  }

  const commonTextFieldProps: TextFieldProps = {
    margin: 'dense',
    variant: 'outlined',
    required: true,
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">{t`admin.create.account.admin.dialog.title`}</DialogTitle>
      <DialogContent dividers>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} id="user-form" data-testid="user-form">
              <DialogContentText>{t`admin.create.account.admin.intro.text`}</DialogContentText>
              <AlertMessageList messages={alertMessages} />
              {promiseInProgress && <DataLoadingIndicator />}
              <FullNameTextFields {...commonTextFieldProps} />
              <TextField
                {...commonTextFieldProps}
                fullWidth
                type="text"
                name="username"
                label={t`admin.create.account.admin.label.login_name`}
                data-testid="username"
              />
              <EmailTextField {...commonTextFieldProps} />
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t`admin.create.account.admin.button.cancel`}
        </Button>
        <Button color="primary" type="submit" form="user-form" data-testid="user-form-submit">
          {t`admin.create.account.admin.button.create`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateAdminDialog
