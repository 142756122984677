export const byMimeType = (mimeType: string): string => {
  if (mimeType === 'image/jpeg') {
    return 'jpg'
  } else if (mimeType === 'image/png') {
    return 'png'
  }
  return 'tiff'
}

export default {
  byMimeType,
}
