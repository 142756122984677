import { useLocation } from 'react-router-dom'

type PageType =
  | 'dashboard'
  | 'bulkupload'
  | 'useradmin'
  | 'serviceadmin'
  | 'variantdetail'
  | 'articleoverview'
  | 'imagereview'
  | 'supplierfeedback'
  | 'galleryoverview'
  | 'unauthorized'

export default function (): PageType {
  const location = useLocation()
  const pathname = location?.pathname || ''

  if (pathname.match(/^\/dashboard/)) {
    return 'dashboard'
  }
  if (pathname.match(/^\/bulkupload/)) {
    return 'bulkupload'
  }
  if (pathname.match(/^\/useradmin/)) {
    return 'useradmin'
  }
  if (pathname.match(/^\/serviceadmin/)) {
    return 'serviceadmin'
  }
  if (pathname.match(/^\/variant/)) {
    return 'variantdetail'
  }
  if (pathname.match(/^\/overview/)) {
    return 'articleoverview'
  }
  if (pathname.match(/^\/images/)) {
    return 'imagereview'
  }
  if (pathname.match(/^\/feedback\/images/)) {
    return 'supplierfeedback'
  }
  if (pathname.match(/^\/galleries/)) {
    return 'galleryoverview'
  }
  if (pathname.match(/^\/err\/unauthorized/)) {
    return 'unauthorized'
  }

  return 'bulkupload'
}
