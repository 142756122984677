import backendRequest from 'api/backendRequest'
import { byMimeType } from 'utils/file/FileExtensionMapper'

export const downloadImage = (imageId: string, filename: string) => {
  return backendRequest('blob')
    .get(`/image/download?imageId=${imageId}&desiredImageName=${imageId}`)
    .then((response) => {
      const contentType = response.headers['content-type']
      const fileExtension = byMimeType(contentType)

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${filename}.${fileExtension}`) //any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
