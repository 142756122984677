import type * as Payloads from './actions'

import { __ } from 'ramda'

import { ArticleIdentifier } from 'api/types'
import { createReducer } from 'store/utils'
import { ArticleGalleries } from 'types'
import articleGalleries from 'utils/articleGalleries'
import { isGlobalTenant } from 'utils/tenants'
import * as types from './actionTypes'
import initialState, { GalleryPageFilter, GalleryPageState, initialFilter } from './initialState'

function updateArticle(
  state: GalleryPageState,
  articleNumber: string,
  updater: (article: ArticleGalleries) => Partial<ArticleGalleries>
): GalleryPageState {
  return {
    ...state,
    articles: state.articles.map((current) =>
      current.articleNumber === articleNumber ? { ...current, ...updater(current) } : current
    ),
  }
}

function updateArticleTenant(
  state: GalleryPageState,
  article: ArticleIdentifier,
  updater: (article: ArticleGalleries, tenantId: string) => ArticleGalleries
): GalleryPageState {
  const { articleNumber, tenantId } = article

  return {
    ...state,
    articles: state.articles.map((current) => {
      if (current.articleNumber !== articleNumber) {
        return current
      }

      const updatedArticle = updater(current, tenantId)

      return isGlobalTenant(tenantId)
        ? articleGalleries.addGlobalGallery(updatedArticle)
        : updatedArticle
    }),
  }
}

type Handler<T = void> = (state: GalleryPageState, payload: T) => GalleryPageState

const handlers = {
  [types.SET_ARTICLES_AND_FILTER]: ((state, payload): GalleryPageState => ({
    ...state,
    ...payload,
    requestInProgress: false,
  })) as Handler<Payloads.SetArticlesAndFilterPayload>,

  [types.ADD_ARTICLES]: ((state, { articles, isLastRequest }): GalleryPageState => ({
    ...state,
    isLastRequest,
    requestInProgress: false,
    articles: state.articles.concat(articles),
  })) as Handler<Payloads.AddArticlesPayload>,

  [types.INITIATED_REQUEST]: ((state, fetchMore): GalleryPageState => ({
    ...state,
    errorNotification: null,
    requestInProgress: true,
    page: fetchMore ? state.page + 1 : 0,
    visibleStartIndex: fetchMore ? state.visibleStartIndex : 0,
  })) as Handler<boolean>,

  [types.FINISHED_REQUEST]: ((state, errorNotification): GalleryPageState => ({
    ...state,
    errorNotification,
    requestInProgress: false,
  })) as Handler<string[] | null>,

  [types.SET_FILTER]: ((state, filter): GalleryPageState => ({
    ...state,
    ignoreFilterWarning: false,
    filter: {
      ...state.filter,
      ...filter,
    },
  })) as Handler<Partial<GalleryPageFilter>>,

  [types.RESET_FILTER]: ((state, { subscribedBrandIds }): GalleryPageState => ({
    ...state,
    ignoreFilterWarning: false,
    filter: { ...initialFilter, brandIds: subscribedBrandIds },
  })) as Handler<Payloads.ResetAllFiltersPayload>,

  [types.SET_VISIBLE_START_INDEX]: ((state, visibleStartIndex): GalleryPageState => ({
    ...state,
    visibleStartIndex,
  })) as Handler<number>,

  [types.TOGGLE_SELECTED_ARTICLE]: ((state, articleNumber): GalleryPageState =>
    updateArticle(state, articleNumber, (article) => ({
      isSelected: !article.isSelected,
    }))) as Handler<string>,

  [types.TOGGLE_ALL_SELECTED_ARTICLES]: ((state, isSelected): GalleryPageState => ({
    ...state,
    articles: state.articles.map((article) => ({ ...article, isSelected })),
  })) as Handler<boolean>,

  [types.REPLACE_ARTICLE]: ((state, article): GalleryPageState =>
    updateArticle(state, article.articleNumber, (prevArticle) => ({
      ...article,
      isSelected: prevArticle.isSelected,
    }))) as Handler<ArticleGalleries>,

  [types.IGNORE_FILTER_WARNING]: ((state, ignoreFilterWarning): GalleryPageState => ({
    ...state,
    ignoreFilterWarning,
  })) as Handler<boolean>,

  [types.UPDATE_TENANT]: ((state, { article, update }) =>
    updateArticleTenant(
      state,
      article,
      articleGalleries.updateTenant(__, __, update)
    )) as Handler<Payloads.UpdateArticleTenantPayload>,

  [types.ADD_TO_PAPERBIN]: ((state, { article, image, galleries }): GalleryPageState =>
    updateArticleTenant(
      state,
      article,
      articleGalleries.addToPaperBin(__, __, image, galleries)
    )) as Handler<Payloads.AddToPaperBinPayload>,

  [types.REMOVE_FROM_PAPERBIN]: ((state, { article, image }): GalleryPageState =>
    updateArticleTenant(
      state,
      article,
      articleGalleries.removeFromPaperBin(__, __, image)
    )) as Handler<Payloads.RemoveFromPaperBinPayload>,
}

type Actions = {
  [T in keyof typeof handlers]: {
    type: T
    payload: Parameters<typeof handlers[T]>[1]
  }
}
export type GalleryPageAction = Actions[keyof Actions]

export default createReducer<GalleryPageState, GalleryPageAction>(initialState, handlers)
