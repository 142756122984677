import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ItemTransferList from 'components/ItemTransferList'
import { ItemTransferListState } from 'components/ItemTransferList/hooks'
import { selectors } from 'store/userProfile'
import textEnumeration from 'utils/textEnumeration'
import classes from './BrandSubscriptions.module.scss'

interface BrandAccordionProps extends AccordionProps {
  headline: string
  secondHeadline: string
}

const BrandAccordion: React.FC<BrandAccordionProps> = ({ headline, secondHeadline, ...props }) => {
  return (
    <Accordion className={classes.brandSubscriptions} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
        <div className={classes.accordionHeader}>{headline}</div>
        <div className={classes.accordionSecondHeader}>{secondHeadline}</div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{props.children}</AccordionDetails>
    </Accordion>
  )
}

interface BrandSubscriptionsProps {
  subscribedBrandsState: ItemTransferListState
  subscribedUsersState: ItemTransferListState
}

function useSecondHeadline(
  ids: string[],
  selector: typeof selectors.usernameLabels | typeof selectors.brandLabels,
  visibleFirstElements = 2
) {
  const labelMap = useSelector(selector)
  return useMemo(
    () =>
      textEnumeration(
        ids
          .map((id) => labelMap.get(id) ?? '')
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b)),
        visibleFirstElements
      ),
    [ids, labelMap, visibleFirstElements]
  )
}

const BrandSubscriptions: React.FC<BrandSubscriptionsProps> = (props) => {
  const { subscribedBrandsState, subscribedUsersState } = props
  const [expanded, setExpanded] = useState<string | false>('brands')
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  const brandsSecondHeadline = useSecondHeadline(
    subscribedBrandsState.selected,
    selectors.brandLabels,
    2
  )
  const usersSecondHeadline = useSecondHeadline(
    subscribedUsersState.selected,
    selectors.usernameLabels,
    1
  )

  return (
    <div className={classes.root}>
      <BrandAccordion
        headline={t`user.profile.subscribed_brands`}
        secondHeadline={brandsSecondHeadline}
        expanded={expanded === 'brands'}
        onChange={handleChange('brands')}
        data-testid="subscribed-brands-accordion"
      >
        <ItemTransferList
          state={subscribedBrandsState}
          inputLabel={t`image.review.filter.label.brands`}
          data-testid="subscribed-brands"
        />
        <div className={classes.localStorageNotification}>
          {t`user.profile.brand_subscription_info`}
        </div>
      </BrandAccordion>
      <BrandAccordion
        headline={t`user.profile.subscribed_colleagues`}
        secondHeadline={usersSecondHeadline}
        expanded={expanded === 'users'}
        onChange={handleChange('users')}
        data-testid="subscribed-users-accordion"
      >
        <ItemTransferList
          state={subscribedUsersState}
          inputLabel={t`image.review.filter.label.brands`}
          data-testid="subscribed-users"
        />
        <div className={classes.localStorageNotification}>
          {t`user.profile.colleague_subscription_info`}
        </div>
      </BrandAccordion>
    </div>
  )
}

export default BrandSubscriptions
