import type { AxiosError } from 'axios'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { ErrorCodeDto, ErrorDto, isErrorCodeDto, isMessageDto, MessageDto } from 'api/types'
import ApiError from './ApiError'

export function translateErrorMessage(error: ErrorCodeDto | MessageDto, fallback = ''): string {
  let key: string | null = null
  let attributes: string[] | undefined = []
  if (isErrorCodeDto(error)) {
    key = error.code
    attributes = error.attributes
  } else if (isMessageDto(error)) {
    key = error.messageId
    attributes = error.messageValues
  } else {
    return fallback
  }

  const id = 'messageId.' + key
  const values =
    attributes && attributes.length > 0
      ? attributes.reduce((prev, current, idx) => ({ ...prev, [idx]: current }), {})
      : undefined

  const translation = i18n._({ id, values })
  return !translation || translation === id
    ? `error key: ${key} (no translation text known for error key ${key})`
    : translation
}

const existsErrorList = (errorObject: ApiError) => {
  return Array.isArray(errorObject.errorList) && errorObject.errorList.length
}

const buildErrorList = (errorObject: ApiError) => {
  return errorObject.errorList.map((e) => translateErrorMessage(e))
}

export function translateErrorResponse(error: AxiosError<ErrorDto>): string[] {
  if (!error) {
    return []
  }

  if (error.code === 'ECONNABORTED') {
    return [t`general.error.api.timeout`]
  }

  if (error.response) {
    if (typeof error.response.data === 'string') {
      return [error.response.data]
    }

    const errorObject = new ApiError(error)

    if (errorObject.internalStatusCode === 401 || errorObject.internalStatusCode === 403) {
      if (existsErrorList(errorObject)) {
        return buildErrorList(errorObject)
      }
      return [
        t({
          id: 'general.error.api.forbidden',
          values: {
            0: errorObject.errorMessage,
          },
        }),
      ]
    }
    if (errorObject.internalStatusCode === 500) {
      return [t`general.error.api.internal_error`]
    }
    if (existsErrorList(errorObject)) {
      return buildErrorList(errorObject)
    }
    if (errorObject.errorMessage) {
      return [errorObject.errorMessage]
    }

    return [
      t({
        id: 'general.error.api.common_error',
        values: {
          0: errorObject.internalStatusCode,
          1: errorObject.internalStatusText ?? errorObject.errorMessage,
        },
      }),
    ]
  }

  if (error.request) {
    return [
      t({
        id: 'general.error.api.connect_error',
        values: {
          0: error.request.status ?? '(unknown status)',
        },
      }),
    ]
  }

  if (error.message.length > 0) {
    return [
      t({
        id: 'general.error.api.create_error',
        values: { 0: error.message },
      }),
    ]
  }

  return []
}
