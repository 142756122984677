import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'

import { ImagePosition, UserPermission } from 'api/types'
import GalleryPaperBin from 'components/GalleryPaperBin'
import { MediathekImage } from 'store/mediathek/initialState'
import { selectors as profileSelectors } from 'store/userProfile'
import { actions, selectors } from 'store/variantDetail'
import { TenantGallery } from 'types'
import logger from 'utils/logger'
import MediathekDialog from 'views/MediathekDialog'
import VariantTenantDetails from './VariantTenantDetails'
import classes from '../VariantDetailPage.module.scss'

interface MediathekContext {
  tenantGallery: TenantGallery
  imagePosition: ImagePosition
}

export default function GalleriesOfTenants() {
  const [mediathekContext, setMediathekContext] = useState<MediathekContext | null>(null)
  const deletedImages = useSelector(selectors.paperbin)
  const articleNumber = useSelector(selectors.articleNumber)
  const tenantGalleries = useSelector(selectors.tenantsWithGlobalGallery)
  const hasChanged = useSelector(selectors.changesMade)
  const permissions = useSelector(profileSelectors.permissions)
  const tenants = useSelector(profileSelectors.tenants)

  const dispatch = useDispatch()
  const canUpdateGallery = permissions.has(UserPermission.UPDATE_IMAGE_GALLERY)

  const onSaveImageChanges = () => {
    dispatch(actions.cleanNotifications())

    if (!tenantGalleries) {
      logger.error('action "save" not valid since variant-data is not set')
      return
    }

    if (canUpdateGallery) {
      dispatch(actions.submitGalleryApproval(tenants))
    } else {
      logger.error('unexpected: user has no save permission')
      dispatch(actions.addError(t`variantdetails.images.error.changes_forbidden`))
    }
  }
  const handleMediathekClick = (tenantGallery: TenantGallery, imagePosition: ImagePosition) => {
    setMediathekContext({ tenantGallery, imagePosition })
  }
  const handleMediathekCardReplace = (image: MediathekImage) => {
    if (mediathekContext) {
      const { tenantGallery, imagePosition } = mediathekContext
      dispatch(actions.replaceWithMediathekImage(tenantGallery.tenantId, imagePosition, image))
      setMediathekContext(null)
    }
  }

  if (tenantGalleries?.length) {
    return (
      <div className={classes.dataContainer} data-testid="galleriesOfTenants">
        <GalleryPaperBin
          draggableType="draggable-image-thumbnail"
          galleryName="paperbin"
          paperbin={deletedImages}
          articleNumber={articleNumber}
        />

        {canUpdateGallery && (
          <div className={classes.saveButtonContainer}>
            <Button
              variant="contained"
              title={t`variantdetails.images.button.title.save`}
              onClick={onSaveImageChanges}
              style={{ visibility: hasChanged ? 'visible' : 'hidden' }}
              color="primary"
            >
              <SaveIcon />
              {t`variantdetails.images.button.save`}
            </Button>
          </div>
        )}
        {tenantGalleries.map((t) => (
          <VariantTenantDetails
            key={t.tenantId}
            tenantGallery={t}
            onMediathekClick={handleMediathekClick}
          />
        ))}
        {mediathekContext ? (
          <MediathekDialog
            isOpen={true}
            {...mediathekContext}
            onClose={() => setMediathekContext(null)}
            onCardReplace={handleMediathekCardReplace}
          />
        ) : null}
      </div>
    )
  } else {
    // no tenants - no galleries
    return null
  }
}
