export interface UploadInfo {
  uploadedForTenantId: string
  uploadDate: string
  uploadedByUserId: string
}

export interface MediathekImage {
  imageId: string
  urlTemplate: string
  uploadedInfo: UploadInfo[]
  isOrphan: boolean | null
}

export interface MediathekState {
  articleNumber: string
  images: MediathekImage[]
  requestInProgress: boolean
  errors: string[]
}

const initialState: MediathekState = {
  articleNumber: '',
  images: [],
  requestInProgress: false,
  errors: [],
}

export default initialState
