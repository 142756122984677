import type { ImageGalleries, ImageGalleryEntry } from 'types/image'

import React, { Fragment, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Grid } from '@material-ui/core'
import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment/moment'

import { ImageDto, ImagePosition, UserPermission } from 'api/types'
import variantDetailApi from 'api/variantDetail'
import ImageGallery from 'components/ImageGallery'
import StockStatusIndicator from 'components/StockStatusIndicator'
import TenantFlag from 'components/TenantFlag'
import { selectors as profileSelectors } from 'store/userProfile'
import { actions, selectors } from 'store/variantDetail'
import { TenantGallery } from 'types'
import DateUtils from 'utils/converter/DateUtils'
import { canReplaceImageInPosition } from 'utils/imageGallery'
import logger from 'utils/logger'
import { isGlobalTenant, tenantLabel } from 'utils/tenants'
import TenantMenu from './TenantContextMenu'
import TenantEditBlockedIndicator from './TenantEditBlockedIndicator'
import classes from './VariantTenantDetails.module.scss'

export interface VariantTenantDetailsProps {
  tenantGallery: TenantGallery
  onMediathekClick: (tenantGallery: TenantGallery, imagePosition: ImagePosition) => void
}

const SummaryLabelValueItem = (props: {
  labelText: string
  value: string
  valueTestId: string
}) => {
  return (
    <>
      <span className={classes.formLabel}>{props.labelText}</span>
      <span data-testid={props.valueTestId}>{props.value}</span>
    </>
  )
}

export default function VariantTenantDetails(props: VariantTenantDetailsProps): ReactElement {
  const { tenantGallery } = props
  const { tenantId } = tenantGallery
  const dispatch = useDispatch()
  const articleNumber = useSelector(selectors.articleNumber)
  const tenants = useSelector(profileSelectors.tenants)
  const permissions = useSelector(profileSelectors.permissions)

  const isOneOfUserTenants = tenants.includes(tenantId)

  const canUpdateGallery =
    permissions.has(UserPermission.UPDATE_IMAGE_GALLERY) &&
    isOneOfUserTenants &&
    !tenantGallery.blocked
  const disableDownload =
    !isOneOfUserTenants && !permissions.has(UserPermission.DOWNLOAD_UNASSIGNED_IMAGES)

  const startExport = () => {
    dispatch(actions.cleanNotifications())
    variantDetailApi.exportImages(articleNumber, tenantId).then(
      (response: AxiosResponse) => {
        dispatch(
          actions.setInfoMessage(
            t({
              id: 'variantdetails.images.export.hint.successful',
              message: 'export of images successful',
            })
          )
        )
      },
      (reason: AxiosError) => {
        logger.error('export failed, %O', reason)
        const msg = reason.response?.data.message ?? reason.message
        dispatch(
          actions.addError(
            t({
              id: 'variantdetails.images.export.hint.failure',
              message: `Error while exporting: ${msg}`,
            })
          )
        )
      }
    )
  }

  const handleGalleryChange = (galleries: ImageGalleries, droppedItem?: ImageGalleryEntry) => {
    dispatch(actions.setImageGallery(tenantId, galleries))
    if (droppedItem?.galleryName === 'paperbin' && droppedItem?.image) {
      dispatch(actions.removeFromPaperbin(droppedItem.image))
    }
  }
  const handleImageDelete = (image: ImageDto, galleries: ImageGalleries) => {
    dispatch(actions.addToPaperbin(tenantId, image, galleries))
  }
  const handleMediathekClick = (imagePosition: ImagePosition) => {
    if (canUpdateGallery && canReplaceImageInPosition(imagePosition)) {
      props.onMediathekClick(tenantGallery, imagePosition)
    }
  }
  const canDrop = (
    droppedItem: ImageGalleryEntry,
    targetGallery: string,
    targetImage?: ImageDto
  ) => {
    if (!canUpdateGallery) {
      return false
    }
    const srcGallery = droppedItem.galleryName
    if (srcGallery === 'paperbin') {
      return !targetImage
    }
    return srcGallery === targetGallery
  }
  const isGlobal = isGlobalTenant(tenantGallery)

  return (
    <div className={classes.tenantContainer} data-testid={`variant-tenant-${tenantId}`}>
      <div className={classes.tenantFlagPanel}>
        <div>
          <TenantFlag tenantId={tenantId} shopName={tenantLabel(tenantId)} />
        </div>
      </div>

      <Grid container className={classes.eanDetails}>
        {isGlobal ? (
          <Grid item xs={5} className={classes.tenantDescription}>
            <span className={classes.formLabel}>
              {t`variantdetails.images.label.global_gallery`}
            </span>
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={2} className={classes.tenantDescription}>
              <SummaryLabelValueItem
                labelText="EAN:"
                value={props.tenantGallery.ean}
                valueTestId="value-ean"
              />
            </Grid>
            <Grid item xs={3} className={classes.tenantDescription}>
              <SummaryLabelValueItem
                labelText={t`variantdetails.images.label.brand`}
                value={tenantGallery.brand}
                valueTestId="value-brand"
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={2} className={classes.stockInfo}>
          <StockStatusIndicator isInStock={tenantGallery.isInStock} useSpecialText={isGlobal} />
        </Grid>

        <Grid item xs={2} className={classes.tenantDescription}>
          {!isGlobal && tenantGallery.onCounterDate ? (
            <SummaryLabelValueItem
              labelText={t`image.gallery.tenant.label.go_live`}
              value={DateUtils.toHumanString(moment(tenantGallery.onCounterDate)) ?? ''}
              valueTestId="value-golive"
            />
          ) : null}
        </Grid>

        <Grid item xs={3}>
          {canUpdateGallery ? (
            <TenantMenu onClickExport={startExport} tenantGallery={tenantGallery} />
          ) : (
            <TenantEditBlockedIndicator isBlocked={tenantGallery.blocked} />
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.galleryContainer}>
        <Grid item xs={12}>
          <ImageGallery
            mediathekEnabled
            tenantGallery={tenantGallery}
            visibleGalleries={['approved', 'global']}
            galleryName={'variantdetail_tenant_' + tenantId}
            draggableType="draggable-image-thumbnail"
            droppableType="draggable-image-thumbnail"
            {...{ disableDownload, canUpdateGallery, canDrop }}
            onGalleryChange={handleGalleryChange}
            onImageDelete={handleImageDelete}
            onMediathekClick={handleMediathekClick}
            data-testid="tenantGallery"
          />
        </Grid>
      </Grid>
    </div>
  )
}
