import { trackPromise } from 'react-promise-tracker'

import backendRequest from 'api/backendRequest'
import { AllowedUploadTenantsDto, ImageUploadStatusDto } from './imageUploadTypes'

export const fetchAllowedTenants = () => {
  return backendRequest().get<AllowedUploadTenantsDto>('/article/allowed-image-upload-tenants')
}

const calculatePercent = (event: ProgressEvent) => {
  return (event.loaded / event.total) * 100
}

export function uploadImage(
  data: FormData,
  asSuggestion: boolean,
  progressCallback?: (loaded: number) => void,
  waitingProgressArea?: string
) {
  const url = asSuggestion
    ? '/article/suggest-image-for-multiple-tenants'
    : '/article/upload-approved-image-for-multiple-tenants'
  return trackPromise(
    backendRequest().post<ImageUploadStatusDto>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const loaded = calculatePercent(progressEvent)
        if (progressCallback) {
          progressCallback(loaded)
        }
      },
    }),
    waitingProgressArea
  )
}
