import type { ArticleDto } from 'api/types'

export interface ArticleOverviewFilter {
  articleNumbersAndEans: string
  brandLines: string
  brandIds: string[]
  supplierIds: string[]
  depotNumber: string
  onlineReady: boolean | null
  inStock: boolean | null
  hasSuggestions: boolean | null
  goLiveFrom: string
  goLiveTo: string
  blocked: boolean | null
}

export type ArticleOverviewFilterName = keyof ArticleOverviewFilter

export interface ArticleDetailRow {
  ean: string
  supplierArticleNumber: string
  supplierName: string
  depotNumber: string
  tenant: string
  goLiveDate: string | null
  inStock: boolean | null
}

export interface ArticleRow extends ArticleDto {
  eans: string[]
  brandIds: string[]
  brandLines: string[]
  tenants: string[]
  details: ArticleDetailRow[]
}

export interface ArticleOverviewState {
  requestInProgress: boolean
  errorNotification: string[] | null
  articles: ArticleRow[]
  currentPage: number
  pageCount: number
  allowedBrands: string[]
  allowedSuppliers: string[]
  filter: ArticleOverviewFilter
  ignoreFilterWarning: boolean
}

export const initialFilter: ArticleOverviewFilter = {
  articleNumbersAndEans: '',
  brandLines: '',
  brandIds: [],
  supplierIds: [],
  depotNumber: '',
  onlineReady: null,
  inStock: null,
  hasSuggestions: null,
  goLiveFrom: '',
  goLiveTo: '',
  blocked: false,
}

const initialState: ArticleOverviewState = {
  allowedBrands: [],
  allowedSuppliers: [],
  requestInProgress: false,
  errorNotification: null,
  articles: [],
  currentPage: 0,
  pageCount: 0,
  filter: initialFilter,
  ignoreFilterWarning: false,
}

export default initialState
