import { t } from '@lingui/macro'
import { equals } from 'ramda'
import { createSelector } from 'reselect'

import profileSelectors from 'store/userProfile/selectors'
import { createBasicSelectors } from 'store/utils'
import { compareTenantIds } from 'utils/tenants'
import initialState, { initialFilter } from './initialState'

const selectors = createBasicSelectors(initialState, 'galleryPage')

const selectedArticles = createSelector(selectors.articles, (articles) =>
  articles.filter((article) => article.isSelected)
)

const tenantIdsOfSelectedArticles = createSelector(selectedArticles, (selectedArticles) => {
  const tenantIds = new Set(
    selectedArticles.flatMap((article) => article.tenants.map((tenant) => tenant.tenantId))
  )

  return [...tenantIds].sort(compareTenantIds)
})

const areArticlesSelected = createSelector(
  selectedArticles,
  (selectedArticles) => selectedArticles.length > 0
)

const areAllArticlesSelected = createSelector(
  selectors.articles,
  selectedArticles,
  (articles, selectedArticles) => articles.length === selectedArticles.length && articles.length > 0
)

const areSomeArticlesSelected = createSelector(
  selectors.articles,
  selectedArticles,
  (articles, selectedArticles) =>
    selectedArticles.length > 0 && selectedArticles.length < articles.length
)

const selectionText = createSelector(
  selectors.articles,
  selectedArticles,
  (articles, selectedArticles) => {
    const selectedCount = selectedArticles.length
    const totalCount = articles.length

    if (selectedCount === 0) {
      return t({
        id: 'galleryPage.bulkCopy.select.all',
        values: { 0: totalCount },
      })
    }

    if (selectedCount === totalCount) {
      return t({
        id: 'galleryPage.bulkCopy.selected.all',
        values: { 0: selectedCount },
      })
    }

    return t({
      id: 'galleryPage.bulkCopy.selected',
      values: { 0: selectedCount, 1: totalCount },
    })
  }
)

const canCopyImageGalleries = createSelector(
  selectors.allowedTenants,
  (allowedTenants) => allowedTenants?.length > 1
)

const currentFilterEqualsInitialFilter = createSelector(
  selectors.filter,
  profileSelectors.totalSubscribedBrands,
  (filter, brandIds) =>
    equals(filter, initialFilter) ||
    (brandIds.length ? equals(filter, { ...initialFilter, brandIds }) : false)
)

const showFilterWarning = createSelector(
  currentFilterEqualsInitialFilter,
  selectors.ignoreFilterWarning,
  (currentFilterEqualsInitialFilter, ignoreFilterWarning) =>
    !ignoreFilterWarning && currentFilterEqualsInitialFilter
)

export default {
  ...selectors,
  selectedArticles,
  tenantIdsOfSelectedArticles,
  areArticlesSelected,
  areAllArticlesSelected,
  areSomeArticlesSelected,
  selectionText,
  canCopyImageGalleries,
  currentFilterEqualsInitialFilter,
  showFilterWarning,
}
