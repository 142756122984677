import React, { ReactElement, useMemo } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { TableCell, TableRow } from '@material-ui/core'
import classnames from 'classnames'

import { UserDto } from 'api/adminUser'
import { selectors } from 'store/adminUsers'
import { tenantLabel } from 'utils/tenants'
import UserMenu, { UserMenuOption } from '../components/UserMenu'
import useStyles from './useStyles'

function joinArray<T>(array: T[] | undefined, getter: (x: T) => string, emptyMsg: string, max = 2) {
  if (!array) {
    return emptyMsg
  }

  if (array.length > max) {
    const shortenedArray = array.slice(0, max).map(getter)
    return [...shortenedArray, '...'].join(', ')
  }

  return array.map(getter).join(', ')
}

const joinBrands = (user: UserDto, brandMap: Map<string, string>) =>
  joinArray(
    user.brandIds,
    (brandId) => brandMap.get(brandId) ?? '-',
    t`admin.accounts.list.hint.no_user_brands`
  )

const joinTenants = (user: UserDto) =>
  joinArray(user.tenantIds, tenantLabel, t`admin.accounts.list.hint.no_user_tenants`)

interface Props {
  user: UserDto
  currentUsername: string
  searchWords: string[]
  onClick: (userId: string) => void
  onClickOptionMenu: (user: UserDto, menuOption: UserMenuOption) => void
}

export default function UserTableRow(props: Props): ReactElement {
  const classes = useStyles()
  const { user, searchWords } = props
  const brands = useSelector(selectors.brands)
  const brandNames = useMemo(() => {
    return new Map(brands.map((brand) => [brand.id, brand.name]))
  }, [brands])
  const tableCellClasses = classnames(classes.cell, {
    [classes.deactivated]: !user.enabled,
  })
  const isUserClickable = user.role.name !== 'ADMIN'
  const handleCellClick = () => {
    if (isUserClickable) {
      props.onClick(props.user.userId)
    }
  }

  return (
    <TableRow
      className={classnames(classes.row, {
        [classes.clickable]: props.onClick,
        [classes.deactivated]: !user.enabled,
      })}
      data-username={user.username}
    >
      <TableCell
        className={tableCellClasses}
        component="th"
        scope="row"
        onClick={handleCellClick}
        data-testid="user-list-username"
      >
        <Highlighter textToHighlight={user.username} searchWords={searchWords} />{' '}
        {!user.enabled ? t`admin.account.deactivated` : ''}
      </TableCell>
      <TableCell className={tableCellClasses} align="left" onClick={handleCellClick}>
        <Highlighter textToHighlight={user.email} searchWords={searchWords} autoEscape />
      </TableCell>
      <TableCell className={tableCellClasses} align="left" onClick={handleCellClick}>
        <Highlighter
          textToHighlight={`${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()}
          searchWords={searchWords}
          autoEscape
        />
      </TableCell>
      <TableCell className={tableCellClasses} align="left" onClick={handleCellClick}>
        {user.role.name}
      </TableCell>
      <TableCell className={tableCellClasses} align="left" onClick={handleCellClick}>
        {user.hasNoBrandRestriction
          ? t`admin.accounts.list.hint.all_brands`
          : joinBrands(user, brandNames)}
      </TableCell>
      <TableCell className={tableCellClasses} align="left" onClick={handleCellClick}>
        {joinTenants(user)}
      </TableCell>
      <TableCell className={tableCellClasses} align="left">
        <UserMenu
          user={user}
          currentUsername={props.currentUsername}
          onMenuClick={props.onClickOptionMenu}
        />
      </TableCell>
    </TableRow>
  )
}
