import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import { UserPermission } from 'api/types'
import DataLoadingIndicator from 'components/DataLoadingIndicator'
import SingleTextTableRow from 'components/SingleTextTableRow/SingleTextTableRow'
import { actions, selectors } from 'store/articleOverview'
import { selectors as profileSelectors } from 'store/userProfile'
import ArticleTableRow from './ArticleTableRow'
import classes from './ArticleOverviewTable.module.scss'

const ArticleOverviewTableBody = () => {
  const articles = useSelector(selectors.articles)

  if (!articles || articles.length === 0) {
    return <SingleTextTableRow message={t`variantlist.hint.empty_list`} columns={5} />
  }

  return (
    <React.Fragment>
      {articles.map((article, index) => (
        <ArticleTableRow key={index} article={article} />
      ))}
    </React.Fragment>
  )
}

const ArticleOverviewTable: React.FC = () => {
  const dispatch = useDispatch()
  const pageCount = useSelector(selectors.pageCount)
  const currentPage = useSelector(selectors.currentPage)
  const requestInProgress = useSelector(selectors.requestInProgress)
  const articles = useSelector(selectors.articles)
  const blockedArticles = useSelector(selectors.blockedArticles)
  const permissions = useSelector(profileSelectors.permissions)

  const loadNewPage = (_event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(actions.fetchArticleOverview(page - 1))
  }
  const handleBlockToggleSelectedAllArticles = () => {
    dispatch(actions.toggleBlockForVisibleArticles())
  }

  return (
    <div>
      <div className={classes.pagination}>
        <Pagination
          showFirstButton
          showLastButton
          count={pageCount}
          page={currentPage + 1}
          onChange={loadNewPage}
        />
      </div>
      {requestInProgress ? (
        <DataLoadingIndicator />
      ) : (
        <React.Fragment>
          <TableContainer>
            <Table
              aria-label="collapsible table"
              className={classes.table}
              stickyHeader
              size="small"
            >
              <TableHead className={classes.header}>
                <TableRow>
                  <TableCell width="5%" />
                  <TableCell width="10%">{t`variantlist.header.article_number`}</TableCell>
                  <TableCell width="10%" align="left">
                    {t`variantlist.header.variant_eans`}
                  </TableCell>
                  <TableCell width="20%" align="left">
                    {t`variantlist.header.variant_brands`}
                  </TableCell>
                  <TableCell width="20%" align="left">
                    {t`variantlist.header.variant_brand_lines`}
                  </TableCell>
                  <TableCell width="15%" align="left">
                    {t`variantlist.header.variant_shops`}
                  </TableCell>
                  {permissions.has(UserPermission.BLOCK_ARTICLE) && (
                    <TableCell width="10%" align="center">
                      <div>{t`variantlist.header.article_blocked`}</div>
                      <Checkbox
                        color="primary"
                        className={classes.checkbox}
                        checked={blockedArticles.length > 0}
                        indeterminate={
                          blockedArticles.length > 0 && blockedArticles.length !== articles.length
                        }
                        onChange={handleBlockToggleSelectedAllArticles}
                      />
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <ArticleOverviewTableBody />
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.pagination}>
            <Pagination
              showFirstButton
              showLastButton
              count={pageCount}
              page={currentPage + 1}
              onChange={loadNewPage}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ArticleOverviewTable
