import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import { ImageReviewsDto } from 'api/imageReviews'
import { fetchImageReviews } from 'api/imageReviews/imageReviewsApi'
import { ImageReviewsFilter } from 'store/imageReviews/initialState'
import { DashboardTileProps } from '../Dashboard'
import DashboardTile from './DashboardTile'

const getResponseCount = (response: ImageReviewsDto) => String(response.totalElements)

export default function PendingReviewsTile({ subscribedBrandIds }: DashboardTileProps) {
  const history = useHistory()
  const filter = useMemo(
    (): ImageReviewsFilter => ({
      articleNumbers: '',
      brandIds: subscribedBrandIds,
      tenantIds: [],
      supplierIds: [],
      uploadStart: '',
      uploadEnd: '',
    }),
    [subscribedBrandIds]
  )
  const fetchStatus = useCallback(() => fetchImageReviews(filter), [filter])
  const handleClick = () => {
    history.push('/images', { fromDashboard: true, filter })
  }

  return (
    <DashboardTile
      subtitle={t`dashboard.tile.subtitle.pending_image_review`}
      caption={t`dashboard.tile.caption.pending_image_review`}
      onClick={handleClick}
      fetchStatus={fetchStatus}
      getResponseCount={getResponseCount}
      data-tile-type="imageReviews"
    />
  )
}
