export default function <T>(arr1: T[], arr2: T[]): boolean {
  const comparableArray1 = arr1 ?? []
  const comparableArray2 = arr2 ?? []

  if (comparableArray1.length !== comparableArray2.length) {
    return false
  }

  const arr1test = comparableArray1.slice().sort()
  const arr2test = comparableArray2.slice().sort()
  return !arr1test.some((val, idx) => val !== arr2test[idx])
}
