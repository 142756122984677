import React, { useCallback } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import { areEqual } from 'react-window'
import { t } from '@lingui/macro'
import { Divider, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { pick } from 'ramda'

import { translatedImageFeedback } from 'api/imageReviews'
import { imagePositions, ImageState } from 'api/types'
import BlockingLayer from 'components/BlockingLayer'
import ImageSlot from 'components/ImageSlot'
import { InfiniteScrollingRow } from 'components/InifiniteScrolling'
import useCloseAnimation from 'hooks/useCloseAnimation'
import { SupplierFeedbackData } from 'store/supplierFeedback'
import { resolveSupplierFeedback } from 'store/supplierFeedback/actions'
import { getImageInPositionOfFeedbackGallery } from 'utils/imageGallery'
import FeedbackHint from '../FeedbackHint'
import { FeedbackElement, isFeedbackElement } from '../FeedbackHint/FeedbackHint'
import ArticleProductDataSummary from './ArticleProductDataSummary'
import FeedbackImageCard from './FeedbackImageCard'
import classes from './FeedbackList.module.scss'

export interface SingleFeedbackRowData {
  isInProgressIndicator: string
  onResolveItem?: (feedbackId: string) => void
}

export const FeedbackListItem = React.memo<
  InfiniteScrollingRow<SupplierFeedbackData, SingleFeedbackRowData>
>((props) => {
  const { measureRef, entry, entryProps } = props
  const { onResolveItem } = entryProps
  const dispatch = useDispatch()

  const feedbackGalleryName = entry.id + '-feedback'
  const listEntryId = entry.id
  const handleCloseAnimationFinished = useCallback(() => {
    if (onResolveItem) {
      onResolveItem(listEntryId)
    }
  }, [onResolveItem, listEntryId])
  const [isClosing, initiateClosing, closingElementProps] = useCloseAnimation(
    handleCloseAnimationFinished
  )
  const { promiseInProgress: actionOfFeedbackInProgress } = usePromiseTracker({
    area: 'feedbackActionRunningIndicator',
  })

  let anyImageSubstitutionRequired = false
  const imagePositionsOfRejectedImages = []
  for (const position of imagePositions) {
    const feedback = entry.gallery[position]

    if (feedback) {
      if (feedback.newImageRequested) {
        anyImageSubstitutionRequired = true
      }
      if (feedback.state === ImageState.REJECTED) {
        imagePositionsOfRejectedImages.push(position === 0 ? 'Main' : String(position))
      }
    }
  }

  const requireAnySubstitutionUploads =
    entry.replacementImagesRequired || anyImageSubstitutionRequired

  const articleProps = {
    ...pick(
      [
        'id',
        'isSelected',
        'articleNumber',
        'ean',
        'onCounterDate',
        'tenantId',
        'tenantLabel',
        'isInStock',
        'brand',
      ],
      entry
    ),
    blocked: false,
  }

  const resolveFeedback = () => {
    dispatch(resolveSupplierFeedback(entry.id, initiateClosing, entryProps.isInProgressIndicator))
  }

  const mergedFeedback = () => {
    const galleryFeedback: (string | FeedbackElement)[] =
      entry.galleryFeedbackKeys.map((reason) => {
        return translatedImageFeedback(reason)
      }) || []

    const imageFeedback = imagePositions
      .map((position) => {
        const feedback = entry.gallery[position]?.imageFeedbackSet || []

        return feedback.length
          ? {
              positionLabel: position === 0 ? 'Main' : String(position),
              feedback: feedback.map(translatedImageFeedback).join(', '),
            }
          : null
      })
      .filter(isFeedbackElement)

    return galleryFeedback.concat(imageFeedback)
  }

  return (
    <div
      className={isClosing ? classes.closedListItemRoot : classes.listItemRoot}
      data-testid={`feedback-item-${articleProps.articleNumber}-${articleProps.tenantId}`}
      {...closingElementProps}
    >
      {actionOfFeedbackInProgress ? <BlockingLayer /> : null}
      <div ref={measureRef} className={classes.listItemContainer} data-testid="feedback-entry">
        <Grid container>
          <Grid item xs={8}>
            <ArticleProductDataSummary article={articleProps} />
          </Grid>
          <Grid item container xs={4} direction="column" alignItems="flex-end">
            {entryProps.onResolveItem && (
              <Button
                size="large"
                variant="contained"
                title={t`feedback.dashboard.item.button.title.close`}
                onClick={resolveFeedback}
                color="primary"
                className={classes.closeButton}
                data-testid="feedback-gallery-close"
              >
                {t`feedback.dashboard.item.button.label`}
              </Button>
            )}
          </Grid>

          <div className={classes.divider}>
            <Divider />
          </div>
          <Grid item xs={12} className={classes.galleryFeedbackSummaryContainer}>
            <FeedbackHint
              reupload={requireAnySubstitutionUploads}
              rejectedAll={entry.replacementImagesRequired}
              rejectedImages={imagePositionsOfRejectedImages}
              feedback={mergedFeedback()}
            />
          </Grid>

          <div className={classes.divider}>
            <Divider />
          </div>
          <Grid item xs={12}>
            <div className={classes.feedbackImageGallery} data-testid="feedback-entry-gallery">
              {imagePositions.map((imagePosition) => (
                <ImageSlot key={imagePosition} imagePosition={imagePosition}>
                  <FeedbackImageCard
                    articleNumber={articleProps.articleNumber}
                    galleryName={feedbackGalleryName}
                    image={getImageInPositionOfFeedbackGallery(imagePosition, entry.gallery)}
                    imagePosition={imagePosition}
                  />
                </ImageSlot>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}, areEqual)

FeedbackListItem.displayName = 'MemoizedFeedbackListItem'

export default FeedbackListItem
