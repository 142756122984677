import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'

import useBoundActions from 'hooks/useBoundActions'
import { actions as bulkCopyActions } from 'store/bulkCopyGallery'
import { actions, selectors } from 'store/galleryPage'
import { selectors as userProfileSelectors } from 'store/userProfile'
import { BulkCopyGalleryDialog } from './BulkCopyGalleryDialog'
import { BulkCopyProgress } from './BulkCopyProgress'
import classes from './BulkCopyGallery.module.scss'

export const BulkCopyGallery: React.FC = () => {
  const articles = useSelector(selectors.articles)
  const areArticlesSelected = useSelector(selectors.areArticlesSelected)
  const areSomeArticlesSelected = useSelector(selectors.areSomeArticlesSelected)
  const tenantIdsOfSelectedArticles = useSelector(selectors.tenantIdsOfSelectedArticles)
  const selectionText = useSelector(selectors.selectionText)
  const boundActions = useBoundActions(actions)
  const boundBulkCopyActions = useBoundActions(bulkCopyActions)

  return articles.length > 0 ? (
    <div className={classes.articleSelection} data-testid="bulkCopy">
      <FormControlLabel
        control={
          <Checkbox
            checked={areArticlesSelected}
            indeterminate={areSomeArticlesSelected}
            color={areArticlesSelected ? 'primary' : 'secondary'}
            onChange={boundActions.toggleAllSelectedArticles}
            data-testid="bulkCopy.globalSelection"
          />
        }
        data-testid="bulkCopy.label"
        label={selectionText}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={tenantIdsOfSelectedArticles.length < 2}
        onClick={boundBulkCopyActions.openDialog}
        data-testid="bulkCopy.button"
      >
        {t`galleryPage.bulkCopy.copy`}
      </Button>
      <BulkCopyGalleryDialog />
      <BulkCopyProgress />
    </div>
  ) : null
}
