import { t } from '@lingui/macro'
import { createSelector } from 'reselect'

import { selectors as galleryPageSelectors } from 'store/galleryPage'
import { createBasicSelectors } from 'store/utils'
import { ImageGalleries } from 'types/image'
import articleGalleries from 'utils/articleGalleries'
import tenantGallery from 'utils/tenantGallery'
import { compareTenantIds, GlobalTenantId } from 'utils/tenants'
import initialState from './initialState'

const selectors = createBasicSelectors(initialState, 'bulkCopyGallery')

const selectedArticles = galleryPageSelectors.selectedArticles

const tenantIdsOfSelectedArticles = galleryPageSelectors.tenantIdsOfSelectedArticles

const disabledArticles = createSelector(
  selectors.disabledArticles,
  (disabledArticles) => new Set(disabledArticles)
)

export const manuallyCopiedArticles = createSelector(
  selectors.manuallyCopiedArticles,
  (manuallyCopiedArticles) => new Set(manuallyCopiedArticles)
)

const selectedArticle = createSelector(
  selectedArticles,
  selectors.selectedArticleNumber,
  (selectedArticles, selectedArticleNumber) =>
    selectedArticles.find((article) => article.articleNumber === selectedArticleNumber)
)

const availableSrcTenants = createSelector(selectedArticles, (selectedArticles) => {
  const tenantIds = new Set(
    selectedArticles.flatMap((article) => article.tenants.map((tenant) => tenant.tenantId))
  )
  return [...tenantIds].sort(compareTenantIds)
})

const availableDstTenants = createSelector(
  availableSrcTenants,
  selectors.srcTenantId,
  (tenantIds, srcTenantId) => tenantIds.filter((tenantId) => tenantId !== srcTenantId)
)

const srcTenant = createSelector(
  selectedArticle,
  selectors.srcTenantId,
  (selectedArticle, srcTenantId) =>
    selectedArticle?.tenants.find((tenant) => tenant.tenantId === srcTenantId)
)

const dstTenant = createSelector(
  selectedArticle,
  selectors.dstTenantId,
  (selectedArticle, dstTenantId) =>
    selectedArticle?.tenants.find((tenant) => tenant.tenantId === dstTenantId)
)

const paginationStartIndex = createSelector(
  selectors.currentPage,
  selectors.articlesPerPage,
  (currentPage, articlesPerPage) => currentPage * articlesPerPage
)

const paginationEndIndex = createSelector(
  selectors.currentPage,
  selectors.articlesPerPage,
  (currentPage, articlesPerPage) => (currentPage + 1) * articlesPerPage
)

const visibleArticles = createSelector(
  selectedArticles,
  paginationStartIndex,
  paginationEndIndex,
  selectors.articlesPerPage,
  (selectedArticles, startIndex, endIndex, articlesPerPage) => {
    const visibleArticles: (string | null)[] = selectedArticles
      .slice(startIndex, endIndex)
      .map((article) => article.articleNumber)

    while (visibleArticles.length < articlesPerPage) {
      visibleArticles.push(null)
    }

    return visibleArticles
  }
)

const maxPage = createSelector(
  selectedArticles,
  selectors.articlesPerPage,
  (selectedArticles, articlesPerPage) => Math.floor((selectedArticles.length - 1) / articlesPerPage)
)

export type TenantState = 'error' | 'warning' | null

interface TenantErrors {
  errors?: string[] | null
  severity?: TenantState
}

const srcTenantWarnings = createSelector(
  selectedArticles,
  selectors.srcTenantId,
  selectors.dstTenantId,
  manuallyCopiedArticles,
  (selectedArticles, srcTenantId, dstTenantId, manuallyCopiedArticles) => {
    const result = new Map<string | null, TenantErrors | null>()
    const galleryTypes: (keyof ImageGalleries)[] =
      dstTenantId === GlobalTenantId ? ['approved'] : ['approved', 'global']

    for (const article of selectedArticles) {
      const errors = []
      let severity: TenantState = null

      if (!manuallyCopiedArticles.has(article.articleNumber)) {
        const srcTenantGallery = articleGalleries.findTenant(article, srcTenantId)

        if (!tenantGallery.hasApprovedImages(srcTenantGallery)) {
          errors.push(t`galleryPage.bulkCopy.no_source_gallery_for_selected_tenant`)
          severity = 'error'
        } else if (!tenantGallery.hasMainImage(srcTenantGallery, galleryTypes)) {
          errors.push(t`galleryPage.bulkCopy.no_main_image`)
          severity = 'error'
        }
      }

      result.set(article.articleNumber, errors.length ? { errors, severity } : null)
    }

    return result
  }
)

const dstTenantWarnings = createSelector(
  selectedArticles,
  selectors.dstTenantId,
  manuallyCopiedArticles,
  (selectedArticles, dstTenantId, manuallyCopiedArticles) => {
    const result = new Map<string | null, TenantErrors | null>()

    for (const article of selectedArticles) {
      const errors = []
      let severity: TenantState = null

      if (!manuallyCopiedArticles.has(article.articleNumber)) {
        const dstTenantGallery = articleGalleries.findTenant(article, dstTenantId)

        if (tenantGallery.hasApprovedImagesExceptBlob(dstTenantGallery)) {
          errors.push(t`galleryPage.bulkCopy.existing_destination_gallery`)
          severity = 'warning'
        }
        if (tenantGallery.hasSuggestedImages(dstTenantGallery)) {
          errors.push(t`galleryPage.bulkCopy.destination_has_pending_suggestions`)
          severity = 'error'
        }
        if (!dstTenantGallery) {
          errors.push(t`galleryPage.bulkCopy.tenant_does_not_exist`)
          severity = 'error'
        }
      }

      result.set(article.articleNumber, errors.length ? { errors, severity } : null)
    }

    return result
  }
)

const getArticleState = (tenantA: TenantState, tenantB: TenantState): TenantState => {
  if (tenantA === 'error' || tenantB === 'error') {
    return 'error'
  }
  if (tenantA === 'warning' || tenantB === 'warning') {
    return 'warning'
  }
  return null
}

const selectedArticlesState = createSelector(
  selectedArticles,
  disabledArticles,
  srcTenantWarnings,
  dstTenantWarnings,
  (articles, disabledArticles, srcTenantWarnings, dstTenantWarnings): TenantState[] =>
    articles.map((article) =>
      disabledArticles.has(article.articleNumber)
        ? null
        : getArticleState(
            srcTenantWarnings.get(article.articleNumber)?.severity || null,
            dstTenantWarnings.get(article.articleNumber)?.severity || null
          )
    )
)

const reducedStateOfArticles = (articleStates: TenantState[]): TenantState =>
  articleStates.reduce(getArticleState, null)

const stateBeforeCurrentPage = createSelector(
  selectedArticlesState,
  paginationStartIndex,
  (stateOfSelectedArticles, startIndex) =>
    reducedStateOfArticles(stateOfSelectedArticles.slice(0, startIndex))
)

const stateAfterCurrentPage = createSelector(
  selectedArticlesState,
  paginationEndIndex,
  (stateOfSelectedArticles, endIndex) =>
    reducedStateOfArticles(stateOfSelectedArticles.slice(endIndex))
)

const stateOfSelectedArticles = createSelector(selectedArticlesState, (articles) =>
  reducedStateOfArticles(articles)
)

const enabledSelectedArticles = createSelector(
  selectedArticles,
  disabledArticles,
  (articles, disabledArticles) =>
    articles.filter((article) => !disabledArticles.has(article.articleNumber))
)

const articleNumbersWithConflicts = createSelector(
  selectors.selectedArticleNumber,
  selectedArticles,
  selectedArticlesState,
  (selectedArticleNumber, articles, articlesState) => {
    const prev = []
    const next = []
    const totalErrors = []

    let passedSelected = false
    for (let idx = 0; idx < articles.length; idx++) {
      const currentArticle = articles[idx]
      const currentArticleState = articlesState[idx]

      if (currentArticleState === 'error') {
        totalErrors.push(currentArticle.articleNumber)
      }

      if (currentArticle.articleNumber === selectedArticleNumber) {
        passedSelected = true
        continue
      }

      if (currentArticleState !== null) {
        if (passedSelected) {
          next.push(currentArticle.articleNumber)
        } else {
          prev.unshift(currentArticle.articleNumber)
        }
      }
    }

    return { prev, next, totalErrors }
  }
)

export default {
  ...selectors,
  tenantIdsOfSelectedArticles,
  disabledArticles,
  selectedArticles,
  selectedArticle,
  availableSrcTenants,
  availableDstTenants,
  srcTenant,
  dstTenant,
  paginationStartIndex,
  paginationEndIndex,
  visibleArticles,
  maxPage,
  srcTenantWarnings,
  dstTenantWarnings,
  stateOfSelectedArticles,
  stateBeforeCurrentPage,
  stateAfterCurrentPage,
  enabledSelectedArticles,
  articleNumbersWithConflicts,
  selectedArticlesState,
}
