import React from 'react'
import { ConnectDropTarget } from 'react-dnd'
import clsx from 'clsx'

import { ImagePosition } from 'api/types'
import classes from './ImageSlot.module.scss'

export function getSlotLabel(imagePosition: ImagePosition, withOrdinaryNumber = false): string {
  switch (imagePosition) {
    case 0:
      return 'Main'
    case 8:
      return 'Modell / Hover'
    case 'blob':
      return 'Blob'
  }

  return withOrdinaryNumber ? imagePosition.toString() : ''
}

export interface ImageSlotProps {
  imagePosition: ImagePosition
  imageId?: string
  disableMenu?: boolean
  connectDropTarget?: ConnectDropTarget
}

const ImageSlot: React.FC<ImageSlotProps> = (props) => {
  return (
    <div
      ref={props.connectDropTarget}
      data-testid={`image-slot-${props.imagePosition}`}
      data-imageid={props.imageId ?? 'no-image'}
      className={classes.imageSlot}
    >
      <div className={clsx(classes.slotLabel, { [classes.small]: props.disableMenu })}>
        {getSlotLabel(props.imagePosition, true) || <div>&nbsp;</div>}
      </div>
      {props.children}
    </div>
  )
}

export default ImageSlot
