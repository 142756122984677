import React from 'react'
import { useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import { Tooltip, withStyles } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'

import { ImageDto, ImagePosition, ImageState } from 'api/types'
import { actions as generalActions } from 'store/general'
import { getImageUrlInSize } from 'utils/image'
import ImageThumbnail from './ImageThumbnail'
import ReviewIcons from './ReviewIcons'
import classes from './ImageGallery.module.scss'

const getZoomImageUrl = getImageUrlInSize(900)

const InheritedTooltip = withStyles({
  tooltip: {
    fontSize: '1.1em',
    padding: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
})(Tooltip)

interface ArticleImageProps {
  image?: ImageDto
  imagePosition: ImagePosition
  galleryName: string
  draggableType?: string
  rejectAllSuggestions?: boolean
  canUpdateGallery?: boolean
  showInheritedTooltip?: boolean
  onToggleReviewState?: () => void
}

const ArticleImage: React.FC<ArticleImageProps> = (props) => {
  const { image } = props

  const dispatch = useDispatch()
  const handleImageClick = () => {
    if (image) {
      dispatch(generalActions.setZoomImageUrl(getZoomImageUrl(image)))
    }
  }

  return (
    <div
      className={clsx(classes.imageContainer, { [classes.empty]: !image })}
      onClick={handleImageClick}
    >
      {image ? (
        <React.Fragment>
          <div className={classes.imageWrapper}>
            <ImageThumbnail
              image={image}
              imagePosition={props.imagePosition}
              galleryName={props.galleryName}
              draggableType={props.draggableType}
              rejectAllSuggestions={props.rejectAllSuggestions}
            />
          </div>
          {props.canUpdateGallery ? (
            <ReviewIcons
              image={image}
              rejectAllSuggestions={props.rejectAllSuggestions}
              onToggleReviewState={props.onToggleReviewState}
            />
          ) : null}
          <div className={classes.imageZoom}>
            <SearchIcon className={classes.zoomIcon} />
          </div>
          {props.showInheritedTooltip && image.state === ImageState.INHERITED ? (
            <InheritedTooltip title={t`image.slot.dnd.hint.is_inherited`} placement="top">
              <div className={classes.inheritedPopover}>
                <InfoOutlinedIcon className={classes.inheritedIcon} />
              </div>
            </InheritedTooltip>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default ArticleImage
