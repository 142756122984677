import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'

import { ErrorMessageList } from 'components/AlertMessageList'
import { selectors } from 'store/imageReviews'
import BulkApproval from './components/BulkApproval'
import ImageReviewList from './components/ImageReviewList'
import ImageReviewsFilter from './components/ImageReviewsFilter'
import TotalCountLabel from './components/TotalCountLabel'
import { useInitialRequest } from './hooks'
import 'react-image-lightbox/style.css'
import classes from './ImageReviews.module.scss'

export default function ImagesPage(): ReactElement {
  const [showSuggestionsAsRejected, setShowSuggestionsAsRejected] = useState(false)
  const errorNotification = useSelector(selectors.errorNotification)
  const requestInProgress = useSelector(selectors.requestInProgress)

  useInitialRequest()

  return (
    <div className={classes.rootContainer} data-testid="image-reviews">
      {errorNotification && <ErrorMessageList errors={errorNotification} />}
      <ImageReviewsFilter disabled={requestInProgress} />
      <div className={classes.listTopContainer}>
        <BulkApproval
          onMouseEnterReject={() => setShowSuggestionsAsRejected(true)}
          onMouseLeaveReject={() => setShowSuggestionsAsRejected(false)}
        />
        <TotalCountLabel />
      </div>
      <ImageReviewList showSuggestionsAsRejected={showSuggestionsAsRejected} />
    </div>
  )
}
