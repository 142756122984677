import type { ArticleOverviewDto } from 'api/articleOverview/articleOverviewTypes'
import type { ThunkAction } from 'redux-thunk'
import type { RootState } from 'store/reducers'
import type { ArticleOverviewAction } from './reducer'

import moment from 'moment/moment'
import { any } from 'ramda'

import articleOverviewApi from 'api/articleOverview'
import { translateErrorResponse } from 'api/errors'
import { selectors as profileSelectors } from 'store/userProfile'
import DateUtils from 'utils/converter/DateUtils'
import { compareTenantIds } from 'utils/tenants'
import * as types from './actionTypes'
import { ArticleDetailRow, ArticleOverviewFilter, ArticleRow } from './initialState'
import selectors from './selectors'

type ArticleOverviewThunkAction<T = void> = ThunkAction<T, RootState, null, ArticleOverviewAction>

export interface ResetAllFiltersPayload {
  subscribedBrandIds: string[]
}

export interface SetArticleOverviewPayload {
  articleOverview: ArticleOverviewDto
  articles: ArticleRow[]
}

export function filterAndFetchArticleOverview(
  filters: Partial<ArticleOverviewFilter>
): ArticleOverviewThunkAction {
  return (dispatch) => {
    dispatch(setFilter(filters))
    dispatch(fetchArticleOverview(0))
  }
}

const initiateFetchRequest = (): ArticleOverviewAction => ({
  type: types.INITIATE_REQUEST,
  payload: {},
})

export interface SetAvailableFilterPayload {
  allowedBrands: string[]
  allowedSuppliers: string[]
}

export const checkAvailableFilter = (): ArticleOverviewThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const filtersFetched = any(
      (value) => value.length > 0,
      [selectors.allowedBrands(state), selectors.allowedSuppliers(state)]
    )

    if (!filtersFetched) {
      articleOverviewApi.fetchArticleOverviewFilters().then(
        (filtersResponse) => {
          const { brands, suppliers } = filtersResponse.data
          dispatch({
            type: types.SET_AVAILABLE_FILTER,
            payload: {
              allowedBrands: brands,
              allowedSuppliers: suppliers,
            },
          })
        },
        (error) => {
          dispatch({
            type: types.SET_ERROR_MESSAGE,
            payload: translateErrorResponse(error),
          })
        }
      )
    }
  }
}

export function fetchArticleOverview(page?: number): ArticleOverviewThunkAction {
  return (dispatch, getState) => {
    dispatch(checkAvailableFilter())

    const showFilterWarning = selectors.showFilterWarning(getState())
    if (showFilterWarning) {
      return
    }

    dispatch(initiateFetchRequest())

    const filter = selectors.filter(getState())
    const currentPage = selectors.currentPage(getState())
    const supplierLabels = profileSelectors.supplierLabels(getState())
    articleOverviewApi.fetchArticleOverview(filter, page ?? currentPage).then(
      (response) => {
        const articleRows = response.data.searchResult.content.map((article): ArticleRow => {
          const tenants = Object.keys(article.tenantSpecificAttributes).sort(compareTenantIds)
          const tenantAttributeValues = tenants.map((key) => article.tenantSpecificAttributes[key])
          const eans = new Set(tenantAttributeValues.map((t) => t.ean))
          const brandIds = new Set(tenantAttributeValues.map((t) => t.brandId))
          const brandLines = new Set(tenantAttributeValues.map((t) => t.brandLine))
          const details = tenants.map((key): ArticleDetailRow => {
            const attributes = article.tenantSpecificAttributes[key]
            return {
              ean: attributes.ean,
              supplierArticleNumber: attributes.supplierArticleNumber,
              supplierName: supplierLabels.get(attributes.supplierId) ?? attributes.supplierId,
              depotNumber: attributes.depotNumber,
              tenant: key,
              goLiveDate: DateUtils.toHumanString(moment(attributes.onCounterDate)),
              inStock: attributes.inStock,
            }
          })
          return {
            ...article,
            tenants,
            details,
            eans: [...eans],
            brandIds: [...brandIds],
            brandLines: [...brandLines],
          }
        })
        dispatch({
          type: types.SET_ARTICLE_OVERVIEW,
          payload: {
            articleOverview: response.data,
            articles: articleRows,
          },
        })
      },
      (error) => {
        dispatch({
          type: types.SET_ERROR_MESSAGE,
          payload: translateErrorResponse(error),
        })
      }
    )
  }
}

export const resetFilter = (subscribedBrands: string[]): ArticleOverviewAction => ({
  type: types.RESET_FILTER,
  payload: { subscribedBrandIds: subscribedBrands },
})

export const setFilter = (filter: Partial<ArticleOverviewFilter>): ArticleOverviewAction => ({
  type: types.SET_FILTER,
  payload: filter,
})

export const ignoreFilterWarning = (ignore: boolean): ArticleOverviewAction => ({
  type: types.IGNORE_FILTER_WARNING,
  payload: ignore,
})

export interface ArticleBlockedPayload {
  articleNumbers: Set<string>
  isBlocked: boolean
}
export const setArticleBlocked = (
  articleNumbers: Set<string>,
  isBlocked: boolean
): ArticleOverviewAction => ({
  type: types.SET_ARTICLE_BLOCKED,
  payload: { articleNumbers, isBlocked },
})

export const updateArticleBlocked = (
  articleNumber: string,
  isBlocked: boolean
): ArticleOverviewThunkAction => {
  return (dispatch, getState) => {
    const article = selectors.articles(getState()).find((a) => a.articleNumber === articleNumber)
    if (article && article.blocked !== isBlocked) {
      articleOverviewApi.setArticleBlocked(articleNumber, isBlocked).then(() => {
        dispatch(setArticleBlocked(new Set([articleNumber]), isBlocked))
      })
    }
  }
}

export const toggleBlockForVisibleArticles = (): ArticleOverviewThunkAction => {
  return (dispatch, getState) => {
    const articles = selectors.articles(getState())
    const blockedArticles = selectors.blockedArticles(getState())
    const isActive = articles.length !== blockedArticles.length

    if (articles.length > 0) {
      const articleNumbers = articles.map((article) => article.articleNumber)
      articleOverviewApi.setArticleBlockedBulk(articleNumbers, isActive).then((response) => {
        const successfulArticleNumbers = new Set(articleNumbers)
        for (const articleNumber of Object.keys(response.data?.errorsByArticleNumber)) {
          successfulArticleNumbers.delete(articleNumber)
        }
        dispatch(setArticleBlocked(successfulArticleNumbers, isActive))
      })
    }
  }
}
