import { ImagePosition } from 'api/types'
import { getImageUrlInSize } from 'utils/image'

export function getSlotLabel(imagePosition: ImagePosition, withOrdinaryNumber = false): string {
  switch (imagePosition) {
    case 0:
      return 'Main'
    case 8:
      return 'Modell / Hover'
    case 'blob':
      return 'Blob'
  }

  return withOrdinaryNumber ? imagePosition.toString() : ''
}

export const getImageUrl = getImageUrlInSize(110)
