import { ArticleGalleries } from 'types'

export interface GalleryPageFilter {
  articleNumbersAndEans: string
  brandIds: string[]
  tenantIds: string[]
  supplierIds: string[]
}

export type GalleryPageFilterName = keyof GalleryPageFilter

export interface GalleryPageState {
  articles: ArticleGalleries[]
  allowedBrands: string[]
  allowedSuppliers: string[]
  allowedTenants: string[]
  filter: GalleryPageFilter
  page: number
  totalElements: number
  visibleStartIndex: number
  isLastRequest: boolean
  requestInProgress: boolean
  errorNotification: string[] | null
  ignoreFilterWarning: boolean
}

export const initialFilter: GalleryPageFilter = {
  articleNumbersAndEans: '',
  brandIds: [],
  tenantIds: [],
  supplierIds: [],
}

const initialState: GalleryPageState = {
  articles: [],
  allowedBrands: [],
  allowedSuppliers: [],
  allowedTenants: [],
  filter: initialFilter,
  page: 0,
  totalElements: 0,
  visibleStartIndex: 0,
  isLastRequest: false,
  requestInProgress: false,
  errorNotification: null,
  ignoreFilterWarning: false,
}

export default initialState
