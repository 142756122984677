import { ReactElement } from 'react'
import { Grid, Typography } from '@material-ui/core'

import classes from './UploadStepCard.module.scss'

interface Props {
  stepNumber: number
  title?: string
  subtitle?: string
  children?: React.ReactNode
  dataTestid?: string
}

export default function UploadStepCard(props: Props): ReactElement {
  return (
    <Grid item style={{ flexGrow: 1, flexBasis: 0 }} data-testid={props.dataTestid}>
      <div className={classes.cardBox}>
        <Typography variant="h2" className={classes.stepNumberText}>
          {props.stepNumber}
        </Typography>
        <Typography variant="h3" className={classes.headline}>
          {props.title}
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>
          {props.subtitle}
        </Typography>
        <div className={classes.stepContent}>{props.children}</div>
      </div>
    </Grid>
  )
}
