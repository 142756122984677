import { useMemo } from 'react'

import { TenantGallery } from 'types'
import tenantGallery, { ImageCountResult } from 'utils/tenantGallery'

export default function useImageCount(tenant: TenantGallery): ImageCountResult {
  return useMemo(() => {
    return tenantGallery.getImageCount(tenant)
  }, [tenant])
}
