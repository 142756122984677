import clsx from 'clsx'

import TenantFlag from 'components/TenantFlag'
import { tenantLabel } from 'utils/tenants'
import classes from './TenantLabel.module.scss'

interface TenantLabelProps {
  tenantId: string
  disabled?: boolean
  className?: string
}

export const TenantLabel: React.FC<TenantLabelProps> = ({ tenantId, disabled, className }) => {
  return (
    <div
      className={clsx(classes.tenantLabel, className, {
        [classes.disabled]: disabled,
      })}
      data-testid="tenantLabel"
    >
      <TenantFlag tenantId={tenantId} className={classes.tenantFlag} />
      {tenantLabel(tenantId)}
    </div>
  )
}
