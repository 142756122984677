import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'

import { ArticleOverviewDto } from 'api/articleOverview'
import { fetchArticleOverview } from 'api/articleOverview/articleOverviewApi'
import useInitialFilter, {
  ArticleOverviewUrlParameter,
} from 'views/ArticleOverview/useInitialFilter'
import { DashboardTileProps } from '../Dashboard'
import DashboardTile from './DashboardTile'

interface ArticleOverviewTileProps extends DashboardTileProps {
  urlParameter: ArticleOverviewUrlParameter
  hideFilterInCaption?: string[]
  'data-testid'?: string
}

const createUrlParams = (p: ArticleOverviewUrlParameter): string =>
  Object.entries(p)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([k, v]) => k + '=' + v)
    .join('&')

defineMessage({ id: 'dashboard.tile.filter.prioGoLive.true' })
defineMessage({ id: 'dashboard.tile.filter.inStock.true' })
defineMessage({ id: 'dashboard.tile.filter.inStock.false' })
defineMessage({ id: 'dashboard.tile.filter.hasSuggestions.true' })
defineMessage({ id: 'dashboard.tile.filter.hasSuggestions.false' })
const paramNames: (keyof ArticleOverviewUrlParameter)[] = [
  'prioGoLive',
  'inStock',
  'hasSuggestions',
]

const createTileCaption = (
  params: ArticleOverviewUrlParameter,
  ignoreFilter?: string[]
): string => {
  return paramNames
    .map((name) => {
      if (ignoreFilter && ignoreFilter.includes(name)) {
        return null
      }
      const param = params[name]
      return typeof param === 'boolean' ? i18n._(`dashboard.tile.filter.${name}.${param}`) : null
    })
    .filter(Boolean)
    .join(', ')
}

const getResponseCount = (res: ArticleOverviewDto) => String(res.searchResult.totalElements)

export default function ArticleOverviewTile(props: ArticleOverviewTileProps) {
  const history = useHistory()
  const initialFilter = useInitialFilter(props.subscribedBrandIds, props.urlParameter)
  const fetchStatus = useCallback(() => fetchArticleOverview(initialFilter), [initialFilter])

  const urlParameter = createUrlParams(props.urlParameter)
  const handleClick = () => {
    history.push('/overview?' + urlParameter, { fromDashboard: true })
  }

  return (
    <DashboardTile
      subtitle={t`dashboard.tile.subtitle.articles`}
      caption={createTileCaption(props.urlParameter, props.hideFilterInCaption)}
      onClick={handleClick}
      fetchStatus={fetchStatus}
      getResponseCount={getResponseCount}
      data-tile-type={`articleOverview[${urlParameter}]`}
    />
  )
}
