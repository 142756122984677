import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'

import { UserPermission } from 'api/types'
import Paper from 'components/Paper'
import { ComponentCommonProps } from 'components/types'
import {
  BlockedFilter,
  BrandFilter,
  DateFilter,
  EanArticleNumbersFilter,
  HasSuggestionsFilter,
  InStockFilter,
  OnlineReadyFilter,
  PrioGoLiveFilter,
  ResetAllFilterButton,
  SupplierFilter,
  TextFilter,
  VerticalFilterDivider,
} from 'components/VariantFilter'
import { actions, ArticleOverviewFilterName, selectors } from 'store/articleOverview'
import { selectors as profileSelectors } from 'store/userProfile'
import { isDepotNumber } from 'utils/validation'
import classes from './ArticleOverviewFilter.module.scss'

function validateDepotNumber(value: string) {
  if (!isDepotNumber(value)) {
    return t`filter.error.invalid_depot_number`
  }
}

const ArticleOverviewFilter: React.FC<ComponentCommonProps> = ({
  'data-testid': dataTest = 'articleOverviewFilter',
}) => {
  const dispatch = useDispatch()
  const requestInProgress = useSelector(selectors.requestInProgress)
  const filter = useSelector(selectors.filter)

  const currentFilterEqualsInitialFilter = useSelector(selectors.currentFilterEqualsInitialFilter)
  const allowedBrands = useSelector(selectors.allowedBrands)
  const allowedSuppliers = useSelector(selectors.allowedSuppliers)
  const brandLabels = useSelector(profileSelectors.brandLabels)
  const supplierLabels = useSelector(profileSelectors.supplierLabels)
  const userPermissions = useSelector(profileSelectors.permissions)
  const totalSubscribedBrands = useSelector(profileSelectors.totalSubscribedBrands)

  const handleFilterChange = (name: ArticleOverviewFilterName) => (value: unknown) => {
    dispatch(actions.setFilter({ [name]: value }))
  }
  const handleFilterChangeAndUpdate = (name: ArticleOverviewFilterName) => (value: unknown) => {
    dispatch(actions.filterAndFetchArticleOverview({ [name]: value }))
  }
  const handlePrioGoLiveChange = (goLiveFrom: string, goLiveTo: string) => {
    dispatch(actions.filterAndFetchArticleOverview({ goLiveFrom, goLiveTo }))
  }
  const handleResetAll = () => {
    dispatch(actions.resetFilter(totalSubscribedBrands))
    dispatch(actions.fetchArticleOverview(0))
  }
  const handleSubmit = () => {
    dispatch(actions.fetchArticleOverview(0))
  }

  return (
    <Paper className={classes.root} data-testid={dataTest}>
      <EanArticleNumbersFilter
        disabled={requestInProgress}
        value={filter.articleNumbersAndEans}
        onChange={handleFilterChange('articleNumbersAndEans')}
        onSubmit={handleSubmit}
      />
      <VerticalFilterDivider />
      {userPermissions.has(UserPermission.QUERY_BRANDS) && (
        <Fragment>
          <BrandFilter
            disabled={requestInProgress}
            brandIds={filter.brandIds}
            brandLabels={brandLabels}
            className={classes.filter}
            allowedBrands={allowedBrands}
            onChange={handleFilterChangeAndUpdate('brandIds')}
          />
          <VerticalFilterDivider />
        </Fragment>
      )}
      {userPermissions.has(UserPermission.QUERY_SUPPLIERS) && (
        <Fragment>
          <SupplierFilter
            disabled={requestInProgress}
            supplier={filter.supplierIds}
            allowedSuppliers={allowedSuppliers}
            supplierLabels={supplierLabels}
            onChange={handleFilterChangeAndUpdate('supplierIds')}
          />
          <VerticalFilterDivider />
        </Fragment>
      )}
      <TextFilter
        disabled={requestInProgress}
        label={t`variantlist.header.variant_brand_lines`}
        value={filter.brandLines}
        onChange={handleFilterChange('brandLines')}
        onSubmit={handleSubmit}
      />
      <VerticalFilterDivider />
      <TextFilter
        disabled={requestInProgress}
        label={t`filter.label.depot_number`}
        value={filter.depotNumber}
        validateInput={validateDepotNumber}
        onChange={handleFilterChange('depotNumber')}
        onSubmit={handleSubmit}
      />
      <VerticalFilterDivider />
      <OnlineReadyFilter
        disabled={requestInProgress}
        value={filter.onlineReady}
        onChange={handleFilterChangeAndUpdate('onlineReady')}
      />
      <VerticalFilterDivider />
      <InStockFilter
        disabled={requestInProgress}
        value={filter.inStock}
        onChange={handleFilterChangeAndUpdate('inStock')}
      />
      <VerticalFilterDivider />
      <HasSuggestionsFilter
        disabled={requestInProgress}
        value={filter.hasSuggestions}
        onChange={handleFilterChangeAndUpdate('hasSuggestions')}
      />
      <VerticalFilterDivider />
      {userPermissions.has(UserPermission.BLOCK_ARTICLE) && (
        <>
          <BlockedFilter
            disabled={requestInProgress}
            value={filter.blocked}
            onChange={handleFilterChangeAndUpdate('blocked')}
            data-testid={`${dataTest}.blocked`}
          />
          <VerticalFilterDivider />
        </>
      )}
      <div className={classes.goLiveDateFilters}>
        <div className={classes.dateFilterWrapper}>
          <DateFilter
            disabled={requestInProgress}
            value={filter.goLiveFrom}
            label={t`filter.label.variant_golive_from`}
            onChange={handleFilterChange('goLiveFrom')}
            onSubmit={handleSubmit}
          />
        </div>
        <VerticalFilterDivider noColor />
        <div className={classes.dateFilterWrapper}>
          <DateFilter
            disabled={requestInProgress}
            value={filter.goLiveTo}
            label={t`filter.label.variant_golive_to`}
            onChange={handleFilterChange('goLiveTo')}
            onSubmit={handleSubmit}
          />
        </div>
        <VerticalFilterDivider noColor />
        <PrioGoLiveFilter
          disabled={requestInProgress}
          goLiveFrom={filter.goLiveFrom}
          goLiveTo={filter.goLiveTo}
          onChange={handlePrioGoLiveChange}
        />
      </div>
      <VerticalFilterDivider />
      <ResetAllFilterButton
        hidden={currentFilterEqualsInitialFilter}
        onResetAll={handleResetAll}
        data-testid={`${dataTest}.reset`}
      />
    </Paper>
  )
}

export default ArticleOverviewFilter
