import type { IdNameTuple, KeyLabelTuple } from 'types'

import { ImageFeedback } from './imageReviews/imageReviewsTypes'

export type RoleId =
  | 'ADMIN'
  | 'DOUGLAS'
  | 'DOUGLAS_COM'
  | 'CONTACT_PERSON'
  | 'SUPPLIER_SERVICE_ACCOUNT'

export interface RoleDto {
  id: RoleId
  name: string
}

export type BrandDto = IdNameTuple
export type TenantDto = KeyLabelTuple
export type SupplierDto = IdNameTuple

export type TenantMap<T> = { [id: string]: T }

export interface TenantSpecificAttributeDto {
  uploadedBy: string
  uploadedTimestamp: string
  state: ImageState
}

export interface TenantSpecificArticleAttributeDto {
  ean: string
  creationDate: string
  brandId: string
  supplierArticleNumber: string
  supplierId: string
  productGroup: string
  brandLine: string
  depotNumber: string
  color: string
  oneShot: string
  productType: string
  weeeNumber: string
  price: string
  onCounterDate: string
  esbExportTime: string
  inStock: boolean
}

export interface ImageDto {
  imageId: string
  position: string
  state: ImageState
  type: ImageType
  createdDate: string
  urlTemplate: string
  feedback?: ImageFeedback[]
  requestNewImage?: boolean
}

export interface ArticleIdentifier {
  articleNumber: string
  tenantId: string
}

export type ImageGallery = ImagePositionMap<ImageDto>

export function convertSingleImageFeedbackDataToImageDto(
  i: SingleImageFeedbackData | undefined,
  pos: ImagePosition
): ImageDto | undefined {
  return i
    ? ({
        imageId: i.imageId,
        position: pos,
        urlTemplate: i.urlTemplate,
        state: i.state,
        type: i.type,
        createdDate: '',
        feedback: i.imageFeedbackSet,
        requestNewImage: i.newImageRequested,
      } as ImageDto)
    : undefined
}

export interface SingleImageFeedbackData {
  imageId: string
  position: string
  urlTemplate: string
  state: ImageState
  type: ImageType
  imageFeedbackSet: ImageFeedback[]
  newImageRequested: boolean
}

export const imagePositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 'blob'] as const
export type ImagePosition = typeof imagePositions[number]
export type ImagePositionMap<T> = Partial<Record<ImagePosition, T>>

export interface ImageGalleryDto {
  images: ImageGallery
  blocked: boolean
}

export interface ImageGalleriesDto {
  suggestedGalleries: TenantMap<ImageGalleryDto>
  approvedGalleries: TenantMap<ImageGalleryDto>
}

export interface VariantImageDto {
  imageId: string
  urlTemplate: string
  orphan: boolean
  tenantSpecificAttributes: TenantMap<TenantSpecificAttributeDto>
}

export interface VariantImagesDto extends MessageDto {
  images: VariantImageDto[]
}

export interface ArticleDto {
  articleNumber: string
  sample?: boolean
  blocked?: boolean
  tenantSpecificAttributes: TenantMap<TenantSpecificArticleAttributeDto>
  imageGalleries: ImageGalleriesDto
  articleImages: VariantImageDto[]
}

export interface MessageDto {
  message: string
  messageId: string
  messageValues?: string[]
}

export interface ErrorCodeDto {
  code: string
  attributes: string[]
}

export interface ErrorDto {
  error: string
  status: number
  message: string
  exception: string
  errors: ErrorCodeDto[]
}

export interface RawImageGalleryEntry {
  tenantId: string
  imageId: string
  position?: string
}

interface Sorting {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

interface Pageable {
  sort: Sorting
  pageNumber: number
  pageSize: number
  offset: number
  paged: boolean
  unpaged: boolean
}

export interface Page<T> {
  content: T[]
  pageable: Pageable
  first: boolean
  last: boolean
  number: number
  totalPages: number
  totalElements: number
  numberOfElements: number
  sort: Sorting
  size: number
  empty: boolean
}

export enum UserPermission {
  UPDATE_IMAGE_GALLERY = 'UPDATE_IMAGE_GALLERY',
  UPDATE_IMAGE_POSITION = 'UPDATE_IMAGE_POSITION',
  SHOW_ARTICLE_OVERVIEW = 'SHOW_ARTICLE_OVERVIEW',
  SHOW_CONTACT_PERSON_DASHBOARD = 'SHOW_CONTACT_PERSON_DASHBOARD',
  SHOW_IMAGE_REVIEW = 'SHOW_IMAGE_OVERVIEW',
  SHOW_IMAGE_UPLOAD = 'SHOW_IMAGE_UPLOAD',
  SHOW_VARIANT_DETAILS = 'SHOW_VARIANT_DETAILS',
  SHOW_ADMIN_OVERVIEW = 'SHOW_ADMIN_OVERVIEW',
  SHOW_PRODUCT_IMPORT = 'SHOW_PRODUCT_IMPORT',
  SHOW_ARTICLE_GALLERY_OVERVIEW = 'SHOW_ARTICLE_GALLERY_OVERVIEW',
  SHOW_IMAGE_GALLERY_FEEDBACK = 'SHOW_IMAGE_GALLERY_FEEDBACK',
  SHOW_MDM_DASHBOARD = 'SHOW_MDM_DASHBOARD',
  RESOLVE_IMAGE_GALLERY_FEEDBACK = 'RESOLVE_IMAGE_GALLERY_FEEDBACK',
  APPROVE_IMAGE = 'APPROVE_IMAGE',
  SUGGEST_IMAGE = 'SUGGEST_IMAGE',
  DOWNLOAD_UNASSIGNED_IMAGES = 'DOWNLOAD_UNASSIGNED_IMAGES',
  QUERY_BRANDS = 'QUERY_BRANDS',
  QUERY_TENANTS = 'QUERY_TENANTS',
  QUERY_SUPPLIERS = 'QUERY_SUPPLIERS',
  EXPORT_GALLERY = 'EXPORT_GALLERY',
  BLOCK_ARTICLE = 'BLOCK_ARTICLE',
  UNDEFINED = 'UNDEFINED',
}

export enum Role {
  ANONYMOUS = 'ANONYMOUS',
  DOUGLAS = 'DOUGLAS',
  CONTACT_PERSON = 'CONTACT_PERSON',
  ADMIN = 'ADMIN',
  DOUGLAS_COM = 'DOUGLAS_COM',
}

export enum ImageState {
  NEW = 'NEW',
  SUGGESTED = 'SUGGESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  TRANSMITTED = 'IMAGE_TRANSMITTED',
  INHERITED = 'APPROVED_FROM_PARENT',
  NOTHING = 'EMPTY',
}

export enum ImageType {
  GLOBAL_IMAGE = 'GLOBAL_IMAGE',
  TENANT_IMAGE = 'TENANT_IMAGE',
  NEW_IMAGE = 'NEW_IMAGE',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorDto(error: any): error is ErrorDto {
  return (
    error &&
    error.status !== undefined &&
    error.message !== undefined &&
    error.exception !== undefined
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorCodeDto(errorCode: any): errorCode is ErrorCodeDto {
  return errorCode && errorCode.code !== undefined && errorCode.attributes !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMessageDto(message: any): message is MessageDto {
  return message && message.messageId !== undefined
}
