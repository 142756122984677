import type { Action } from 'redux'
import type { RootState } from './reducers'

type BasicSelectors<S> = {
  [T in keyof S]: (state: RootState) => S[T]
}

export function createBasicSelectors<S extends RootState[keyof RootState]>(
  initialState: S,
  sliceName: keyof RootState
): {
  [T in keyof S]: (state: RootState) => S[T]
} {
  const result: BasicSelectors<S> = {} as BasicSelectors<S>

  for (const attr in initialState) {
    result[attr] = (state: RootState): S[Extract<keyof S, string>] => (state[sliceName] as S)[attr]
  }

  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createReducer<S, A extends Action<any>>(
  initialState: S,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlers: Record<string, any>
) {
  return (state: S = initialState, action: A): S => {
    return Object.prototype.hasOwnProperty.call(handlers, action.type)
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handlers[action.type](state, (action as any).payload)
      : state
  }
}
