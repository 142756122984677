import React from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Clear } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import classnames from 'classnames'

import useBoundActions from 'hooks/useBoundActions'
import { actions, selectors } from 'store/adminUsers'

const useStyles = makeStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
    '&.withSearchTerm': {
      marginTop: '2rem',
    },
  },
  searchTerm: {
    width: '20rem',
  },
  clearIcon: {
    cursor: 'pointer',
  },
})

interface UserTableControlsProps {
  showSearchTerm?: boolean
}

const UserTableControls: React.FC<UserTableControlsProps> = ({ showSearchTerm }) => {
  const classes = useStyles()
  const boundActions = useBoundActions(actions)
  const page = useSelector(selectors.page)
  const totalPages = useSelector(selectors.totalPages)
  const searchTerm = useSelector(selectors.searchTerm)
  const handlePageChange = (ev: unknown, newPageNumber: number) => {
    boundActions.changePage(newPageNumber)
  }
  const handleSearchTermChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    boundActions.setSearchTerm(ev.target.value)
  }

  return (
    <div className={classnames(classes.pagination, { withSearchTerm: showSearchTerm })}>
      {showSearchTerm ? (
        <TextField
          className={classes.searchTerm}
          label={t`admin.accounts.searchTerm`}
          size="small"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchTermChange}
          InputProps={{
            endAdornment: searchTerm ? (
              <InputAdornment position="end">
                <Clear
                  className={classes.clearIcon}
                  data-testid="clear-user-search"
                  onClick={() => boundActions.setSearchTerm('')}
                />
              </InputAdornment>
            ) : null,
          }}
          inputProps={{
            'data-testid': 'user-search',
          }}
        />
      ) : (
        <div />
      )}
      <Pagination
        showFirstButton
        showLastButton
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />
    </div>
  )
}

export default UserTableControls
