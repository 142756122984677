import type { ImageUploadStatusDto } from 'api/imageUpload'
import type { AxiosError } from 'axios'
import type { ThunkAction } from 'redux-thunk'
import type { RootState } from 'store/reducers'
import type { ImageUploadAction } from './reducer'

import imageUploadApi from 'api/imageUpload'
import { TenantDto } from 'api/types'
import logger from 'utils/logger'
import { compareTenantDto, isGlobalTenant } from 'utils/tenants'
import * as types from './actionTypes'
import { UploadStatus } from './initialState'
import selectors from './selectors'
import { validateImage } from './utils'

type ImageUploadThunkAction<T = void> = ThunkAction<T, RootState, null, ImageUploadAction>

export interface AddImageDataUrlPayload {
  imageId: string
  dataUrl: string
}

export const addImages = (files: File[]): ImageUploadThunkAction => {
  return (dispatch, getState): void => {
    const existingKeys = new Set(selectors.images(getState()).map((image) => image.id))
    const images = files.map(validateImage).filter((image) => !existingKeys.has(image.id))
    dispatch({ type: types.ADD_UPLOADED_IMAGES, payload: images })
    images.forEach((image) => {
      if (image.uploadStatus !== UploadStatus.INVALID && image.file.type !== 'image/tiff') {
        const imgReader = new FileReader()
        imgReader.addEventListener(
          'loadend',
          () => {
            if (typeof imgReader.result === 'string') {
              // convert image file to base64 string
              dispatch({
                type: types.SET_IMAGE_DATA_URL,
                payload: { imageId: image.id, dataUrl: imgReader.result },
              })
            }
          },
          true
        )
        imgReader.readAsDataURL(image.file)
      }
    })
  }
}

const setAllowedTenants = (tenants: TenantDto[]): ImageUploadAction => ({
  type: types.SET_ALLOWED_TENANTS,
  payload: tenants,
})

export const setSelectedTenants = (tenants: TenantDto[]): ImageUploadAction => ({
  type: types.SET_SELECTED_TENANTS,
  payload: tenants,
})

export const fetchAvailableTenants = (): ImageUploadThunkAction => {
  return (dispatch) => {
    imageUploadApi.fetchAllowedTenants().then(
      (response) => {
        const { data } = response
        if (data?.allowedTenants?.length > 0) {
          dispatch(setAllowedTenants(data.allowedTenants.sort(compareTenantDto)))
          const globalTenant = data.allowedTenants.find((tenant) => isGlobalTenant(tenant.key))
          if (globalTenant) {
            dispatch(setSelectedTenants([globalTenant]))
          } else if (data.allowedTenants.length === 1) {
            dispatch(setSelectedTenants(data.allowedTenants))
          }
        }
      },
      (error: unknown) => logger.error('error while requesting allowed tenants', error)
    )
  }
}

export interface SetUploadResultPayload {
  imageId: string
  response: ImageUploadStatusDto
}

export interface SetUploadErrorPayload {
  imageId: string
  error: AxiosError
}

export interface SetUploadProgressPayload {
  imageId: string
  percent: number
}

export const startBulkUpload = (asSuggestion: boolean): ImageUploadThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const images = selectors.unprocessedImages(getState())
    const tenants = selectors.selectedTenants(getState()).map((tenant) => tenant.key)

    dispatch({
      type: types.BULK_UPLOAD_STARTED,
      payload: images.filter((image) => image.uploadStatus === UploadStatus.READY).length,
    })
    for (const image of images) {
      const imageId = image.id
      dispatch({ type: types.START_FILE_UPLOAD, payload: imageId })

      if (image.uploadStatus !== UploadStatus.READY) {
        continue
      }

      const data = new FormData()
      data.append('file', image.file)
      data.append('properties', JSON.stringify({ tenants }))
      await imageUploadApi
        .uploadImage(
          data,
          asSuggestion,
          (percent) => dispatch({ type: types.SET_UPLOAD_PROGRESS, payload: { percent, imageId } }),
          'uploadImagesProgress'
        )
        .then(
          (success) => {
            dispatch({
              type: types.SET_UPLOAD_RESULT,
              payload: { imageId, response: success.data },
            })
          },
          (error: AxiosError) => {
            dispatch({ type: types.SET_UPLOAD_ERROR, payload: { imageId, error } })
          }
        )
    }
  }
}

export const removeImage = (imageId: string): ImageUploadAction => ({
  type: types.REMOVE_IMAGE,
  payload: imageId,
})

export const removeAllImages = (): ImageUploadAction => ({
  type: types.REMOVE_ALL_IMAGES,
  payload: {},
})

export const toggleSelectedTenant = (tenantId: string): ImageUploadAction => ({
  type: types.TOGGLE_SELECTED_TENANT,
  payload: tenantId,
})
