import React from 'react'
import { t } from '@lingui/macro'

import { BooleanFilter, BooleanOptionKey, BooleanValue } from './BooleanFilter'

export interface OnlineReadyFilterProps {
  value: BooleanValue
  disabled?: boolean
  onChange: (newValue: boolean | null) => void
  'data-testid'?: string
}

export function OnlineReadyFilter(props: OnlineReadyFilterProps) {
  const optionLabels = {
    optionNotSet: t`filter.options.online_ready_unfiltered`,
    optionTrue: t`filter.options.is_online_ready`,
    optionFalse: t`filter.options.not_online_ready`,
  } as { [K in BooleanOptionKey]: string }

  return (
    <BooleanFilter {...props} label={t`filter.label.online_ready`} optionLabels={optionLabels} />
  )
}
