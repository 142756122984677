import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { equals } from 'ramda'

import { selectors as profileSelectors } from 'store/userProfile'

export const useInitialFiltersChangedListener = <T>(currentFilter: T, initialFilter: T) => {
  const brandIds = useSelector(profileSelectors.totalSubscribedBrands)

  return useMemo(
    () => equals(currentFilter, { ...initialFilter, brandIds }),
    [currentFilter, initialFilter, brandIds]
  )
}
