import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { FormControl, FormLabel } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { UserPermission } from 'api/types'
import userProfileApi from 'api/userProfile'
import { useItemTransferListState } from 'components/ItemTransferList/hooks'
import ModalDialog from 'components/ModalDialog/ModalDialog'
import ReadonlyDataField from 'components/ReadonlyDataField/ReadonlyDataField'
import { actions as profileActions, selectors as profileSelectors } from 'store/userProfile'
import arrayCompare from 'utils/arrayCompare'
import { getApplicationLocale, loadAndActivateLocale } from 'utils/localizations/i18nUtil'
import BrandSubscriptions from './BrandSubscriptions'
import LanguageSwitcher from './LanguageSwitcher'
import classes from './UserProfileDialog.module.scss'

export interface UserProfileDialogProps {
  open: boolean
  onClose: () => void
}

export default function UserProfileDialog(props: UserProfileDialogProps) {
  const dispatch = useDispatch()
  const email = useSelector(profileSelectors.email)
  const fullname = useSelector(profileSelectors.fullName)
  const [locale, setLocale] = useState(getApplicationLocale())
  const brandRestrictions = useSelector(profileSelectors.brandRestrictions)
  const subscribedUsersAvailable = useSelector(profileSelectors.subscribedUsersAvailable)
  const ownSubscribedBrands = useSelector(profileSelectors.ownSubscribedBrands)
  const initialSubscribedBrandsRef = useRef(ownSubscribedBrands)
  const subscribedUsers = useSelector(profileSelectors.subscribedUsers)
  const initialSubscribedUsersRef = useRef(subscribedUsers)
  const permissions = useSelector(profileSelectors.permissions)
  const brandLabels = useSelector(profileSelectors.brandLabels)
  const availableBrands = useMemo(
    () =>
      brandRestrictions.map((brandId) => ({
        id: brandId,
        label: brandLabels.get(brandId) ?? brandId,
      })),
    [brandRestrictions, brandLabels]
  )
  const subscribedBrandsState = useItemTransferListState(ownSubscribedBrands, availableBrands)
  const subscribedUsersState = useItemTransferListState(subscribedUsers, subscribedUsersAvailable)
  const hasImageReviewPermission = permissions.has(UserPermission.SHOW_IMAGE_REVIEW)

  const submitProfileDialog = () => {
    loadAndActivateLocale(locale)

    userProfileApi.setUserLanguage(getApplicationLocale())
    if (hasImageReviewPermission) {
      const brandsChanged = !arrayCompare(
        initialSubscribedBrandsRef.current,
        subscribedBrandsState.selected
      )
      const usersChanged = !arrayCompare(
        initialSubscribedUsersRef.current,
        subscribedUsersState.selected
      )

      if (brandsChanged) {
        initialSubscribedBrandsRef.current = subscribedBrandsState.selected
      }
      if (usersChanged) {
        initialSubscribedUsersRef.current = subscribedUsersState.selected
      }

      dispatch(
        profileActions.updateBrandSubscriptions(
          brandsChanged ? subscribedBrandsState.selected : undefined,
          usersChanged ? subscribedUsersState.selected : undefined
        )
      )
    }
    props.onClose()
  }

  const handleClose = () => {
    setLocale(getApplicationLocale())
    props.onClose()
  }

  return (
    <ModalDialog
      id="profileDialog"
      open={props.open}
      onClose={handleClose}
      onApply={submitProfileDialog}
      title={t({ id: 'user.profile.dialog.title', message: 'Profile' })}
      testId="profile-dialog"
      width="xl"
    >
      <Grid container className={classes.dataGrid}>
        <Grid item xs={hasImageReviewPermission ? 4 : 6}>
          <ReadonlyDataField
            label={t({ id: 'user.profile.dialog.label.full_name', message: 'Name' })}
            value={fullname}
          />
        </Grid>
        <Grid item xs={hasImageReviewPermission ? 4 : 6}>
          <ReadonlyDataField
            label={t({ id: 'user.profile.dialog.label.email', message: 'E-Mail' })}
            value={email}
          />
        </Grid>
        <Grid item xs={hasImageReviewPermission ? 4 : 12}>
          <FormControl>
            <FormLabel id="languageSelectionLabel" className={classes.dataLabel}>
              <Trans id={'user.profile.dialog.label.language'}>PRIMA Language: </Trans>
            </FormLabel>
            <LanguageSwitcher initialSelectedKey={locale} onSelectLanguage={setLocale} />
          </FormControl>
        </Grid>
      </Grid>
      {hasImageReviewPermission ? (
        <BrandSubscriptions
          subscribedBrandsState={subscribedBrandsState}
          subscribedUsersState={subscribedUsersState}
        />
      ) : null}
    </ModalDialog>
  )
}
