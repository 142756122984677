import React from 'react'
import { Avatar, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AvatarGroup } from '@material-ui/lab'

import { availableTenants } from 'utils/tenants'
import TenantFlag, { Sizes } from './TenantFlag'

const useStyle = makeStyles((theme) => ({
  tenantIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    background: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))

export default function TenantFlagGroup({ tenantIds }: { tenantIds: string[] }) {
  const classes = useStyle()

  return (
    <AvatarGroup max={10} spacing={4} data-testid="tenantFlagGroup">
      {availableTenants.map((t) => {
        if (tenantIds.includes(t.key)) {
          return (
            <Tooltip key={t.key} title={t.label} placement="top">
              <Avatar className={classes.tenantIcon}>
                <TenantFlag tenantId={t.key} shopName={t.label} size={Sizes.Small} />
              </Avatar>
            </Tooltip>
          )
        } else {
          return null
        }
      })}
    </AvatarGroup>
  )
}
