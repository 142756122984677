import { ImageDto } from 'api/types'
import { ArticleGalleries, TenantGallery } from 'types'

export interface VariantDetailState {
  articleNumber: string
  sample: boolean
  blocked: boolean
  tenants: TenantGallery[]
  imageSuggestions: ArticleGalleries | null
  paperbin: ImageDto[]
  errors: string[]
  infoMessage: string | null
  changesMade: boolean
  showSubmitNotification: boolean
}

const initialState: VariantDetailState = {
  articleNumber: '',
  sample: false,
  blocked: false,
  tenants: [],
  imageSuggestions: null,
  paperbin: [],
  errors: [],
  infoMessage: null,
  changesMade: false,
  showSubmitNotification: false,
}

export default initialState
