import type { MediathekImage } from 'store/mediathek'

import React from 'react'
import { t } from '@lingui/macro'
import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import { LayersClear } from '@material-ui/icons'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment/moment'

import MeatballsMenu from 'components/MeatballsMenu'
import TenantFlag from 'components/TenantFlag'
import DateUtils from 'utils/converter/DateUtils'
import { getImageUrlInSize } from 'utils/image'
import classes from './Mediathek.module.scss'

interface CardProps {
  image: MediathekImage
  hideMenu?: boolean
  onCardReplace?: (image: MediathekImage) => void
  onCardDelete?: (image: MediathekImage) => void
}

const getImageUrl = getImageUrlInSize(180)

export default function MediathekImageCard(props: CardProps) {
  const handleClickReplace = () => props.onCardReplace?.(props.image)
  // const handleClickDelete = () => props.onCardDelete?.(props.image)

  return (
    <Card className={classes.imageCard}>
      <CardActionArea onClick={handleClickReplace}>
        <CardMedia
          component="img"
          image={getImageUrl(props.image.urlTemplate)}
          title="article image in mediathek"
          className={classes.image}
        />
        <CardContent className={classes.imageSubtext}>
          <AvatarGroup className={classes.originalShopMarker}>
            {props.image.uploadedInfo.map((i) => (
              <Avatar key={i.uploadedForTenantId}>
                <TenantFlag
                  tenantId={i.uploadedForTenantId}
                  shopName={i.uploadedForTenantId}
                  tooltipText={`uploaded for ${i.uploadedForTenantId} by ${
                    i.uploadedByUserId
                  } at ${DateUtils.toDateString(moment(i.uploadDate))}`}
                  useMuiTooltip
                />
              </Avatar>
            ))}
          </AvatarGroup>
          {props.image.isOrphan && (
            <Tooltip title={t`mediathek.card.image_in_no_gallery`} placement="top">
              <Avatar alt="in no gallery" className={classes.imageUsedByNoneState}>
                <LayersClear />
              </Avatar>
            </Tooltip>
          )}
        </CardContent>
      </CardActionArea>
      {!props.hideMenu ? (
        <CardActions className={classes.imageActionsContainer}>
          <MeatballsMenu>
            <MenuItem onClick={handleClickReplace}>{t`mediathek.card.replace_image`}</MenuItem>
            {/* <MenuItem
              onClick={handleClickDelete}
            >{t`mediathek.card.delete_image_permanently`}</MenuItem> */}
          </MeatballsMenu>
        </CardActions>
      ) : null}
    </Card>
  )
}
