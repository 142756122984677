import React, { useRef, useState } from 'react'
import { Popover } from '@material-ui/core'
import clsx from 'clsx'

import { ImageFeedback, translatedImageFeedback } from 'api/imageReviews/imageReviewsTypes'
import { ImageDto, ImageState } from 'api/types'
import ImageContextMenu from 'components/ImageContextMenu'
import { deleteImageInGalleries, setReviewFeedback, toggleReviewState } from 'utils/imageGallery'
import ArticleImage from './ArticleImage'
import { DraggableImageSlotProps } from './DraggableImageSlot'
import { useImagePermissions, useImageStateClasses } from './hooks'
import classes from './ImageGallery.module.scss'

interface ImageCardProps extends DraggableImageSlotProps {
  image?: ImageDto
  isOver?: boolean
  isDropAllowed?: boolean
}

const ImageCard = React.forwardRef<HTMLDivElement, ImageCardProps>((props, ref) => {
  const { image, imagePosition, tenantGallery } = props
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null)
  const [popoverContent, setPopoverContent] = useState<string[] | null>(null)
  const imageCardClasses = useImageStateClasses(
    image,
    props.rejectAllSuggestions,
    classes.imageCard
  )
  const { canRemove, canReplace } = useImagePermissions(
    tenantGallery,
    imagePosition,
    props.canUpdateGallery
  )
  const handleToggleReviewState = () => {
    if (props.onGalleryChange) {
      props.onGalleryChange(toggleReviewState(tenantGallery.galleries, imagePosition))
    }
  }
  const handleSetReviewFeedback = (feedback?: ImageFeedback[], requestNewImage?: boolean) => {
    if (props.onGalleryChange) {
      props.onGalleryChange(
        setReviewFeedback(tenantGallery.galleries, imagePosition, feedback, requestNewImage)
      )
    }
  }
  const handleImageDelete = () => {
    if (props.onImageDelete && image && canRemove) {
      props.onImageDelete(
        image,
        deleteImageInGalleries(image, imagePosition, tenantGallery.galleries)
      )
    }
  }
  const handleMediathekClick = () => props.onMediathekClick?.(imagePosition)
  const handleMouseEnter = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (image?.feedback?.length && image?.state === ImageState.REJECTED) {
      setPopoverAnchorEl(ev.currentTarget)
      setPopoverContent(image.feedback.map(translatedImageFeedback))
    }
  }
  const triggeredInheritedInfo = useRef(false)
  const handleMouseLeave = () => {
    triggeredInheritedInfo.current = false
    setPopoverAnchorEl(null)
  }

  return (
    <div
      ref={ref}
      className={imageCardClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="dragcontainer"
    >
      <div
        className={clsx(classes.dropIndicator, {
          [classes.isOver]: props.isOver,
          [classes.canDrop]: props.isDropAllowed,
        })}
        data-testid="dropindicator"
      />
      <ArticleImage
        image={image}
        imagePosition={imagePosition}
        galleryName={props.galleryName}
        draggableType={props.draggableType}
        rejectAllSuggestions={props.rejectAllSuggestions}
        canUpdateGallery={props.canUpdateGallery}
        onToggleReviewState={handleToggleReviewState}
        showInheritedTooltip
      />
      {props.disableMenu ? null : (
        <ImageContextMenu
          image={image}
          articleNumber={tenantGallery.articleNumber}
          imagePosition={props.imagePosition}
          canRemove={canRemove}
          canReplace={canReplace}
          hideDownloadEntry={props.disableDownload}
          mediathekEnabled={props.mediathekEnabled}
          onToggleReviewState={handleToggleReviewState}
          onSetReviewFeedback={handleSetReviewFeedback}
          onImageDelete={handleImageDelete}
          onMediathekClick={handleMediathekClick}
        />
      )}
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleMouseLeave}
        disableRestoreFocus
        className={classes.popover}
        classes={{ paper: classes.paper }}
      >
        {popoverContent
          ? popoverContent.map((feedback, idx) => <div key={idx}>{feedback}</div>)
          : null}
      </Popover>
    </div>
  )
})

ImageCard.displayName = 'ImageCard'

export default ImageCard
