import { ImageState, TenantMap, TenantSpecificAttributeDto, VariantImageDto } from 'api/types'
import { getTenantPosition } from './tenants'

function getMinimumPosition(tenantSpecificAttributes: TenantMap<TenantSpecificAttributeDto>) {
  const tenantPositions = Object.keys(tenantSpecificAttributes)
    .filter((tenantId) => tenantSpecificAttributes[tenantId].state === ImageState.APPROVED)
    .map((tenantId) => ({
      tenantId,
      position: getTenantPosition(tenantId),
    }))
  const minimumPosition = tenantPositions.reduce(
    (prev, current) => (current.position >= 0 && current.position < prev.position ? current : prev),
    { tenantId: '', position: Infinity }
  )

  return minimumPosition.tenantId ? minimumPosition : null
}

export function compareMediathekImage(a: VariantImageDto, b: VariantImageDto) {
  const minTenantA = getMinimumPosition(a.tenantSpecificAttributes)
  const minTenantB = getMinimumPosition(b.tenantSpecificAttributes)

  if (minTenantA && minTenantB) {
    if (minTenantA.position === minTenantB.position) {
      const tenantAUploadDate = a.tenantSpecificAttributes[minTenantA.tenantId].uploadedTimestamp
      const tenantBUploadDate = b.tenantSpecificAttributes[minTenantB.tenantId].uploadedTimestamp

      return new Date(tenantAUploadDate).getTime() - new Date(tenantBUploadDate).getTime()
    }

    return minTenantA.position - minTenantB.position
  }

  if (minTenantA) {
    return -1
  }
  if (minTenantB) {
    return 1
  }

  return 0
}
