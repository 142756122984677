import React, { Fragment, ReactElement, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Box, Button, Dialog, IconButton, Link, Typography, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

import { UserPermission } from 'api/types'
import { actions, selectors } from 'store/imageUpload'
import { selectors as profileSelectors } from 'store/userProfile'
import classes from './SummaryContainer.module.scss'

interface DialogTitleProps {
  children: ReactNode
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography {...other}>
      <div>
        <Typography variant="h6">{children}</Typography>
        <IconButton aria-label="close" onClick={onClose} className={classes.closeDialogIcon}>
          <CloseIcon />
        </IconButton>
      </div>
    </MuiDialogTitle>
  )
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const isClickOutsideDialogOrEscapeKey = (reason?: string) =>
  reason === 'backdropClick' || reason === 'escapeKeyDown'

export default function SummaryContainer(): ReactElement {
  const dispatch = useDispatch()
  const unprocessedImages = useSelector(selectors.unprocessedImages)
  const validUnprocessedImages = useSelector(selectors.validUnprocessedImages)
  const isUploadAllowed = useSelector(selectors.isUploadAllowed)
  const userProfile = useSelector(profileSelectors.permissions)

  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = (event: never, reason?: string) =>
    !isClickOutsideDialogOrEscapeKey(reason) && setOpenDialog(false)

  const handleUploadStart = () =>
    dispatch(actions.startBulkUpload(!userProfile.has(UserPermission.APPROVE_IMAGE)))

  return (
    <Fragment>
      <Typography className={classes.summaryCountText} data-testid="bulkupload-count">
        {validUnprocessedImages.length} / {unprocessedImages.length}
      </Typography>
      <Typography className={classes.summaryLabel}>
        {t`upload.progress.pre.summary.label.counter`}
      </Typography>
      <Box className={classes.spacer} />
      <Typography className={classes.summaryLegal}>
        <Trans id="upload.progress.pre.summary.text.legal_hint">
          <span onClick={handleOpenDialog} className={classes.summaryLink} />
        </Trans>
      </Typography>

      <Button
        disabled={!isUploadAllowed}
        variant="contained"
        color="primary"
        onClick={handleUploadStart}
        data-testid={'start-upload-button'}
      >
        {t`upload.progress.button.upload`}
      </Button>

      <Dialog
        disableEscapeKeyDown
        onClose={handleCloseDialog}
        aria-labelledby="upload-legal-dialog"
        open={openDialog}
      >
        <DialogTitle onClose={handleCloseDialog}>
          <div id="upload-legal-dialog">{t`upload.legalinfo.headline`}</div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_intro`}</Typography>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_1`}</Typography>
          <Typography gutterBottom>
            <Trans id="upload.legalinfo.text.part_2">
              <Link
                href={'https://ecom-data.douglas.de/'}
                target="_blank"
                rel="noopener"
                className={classes.summaryLink}
              />
            </Trans>
          </Typography>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_3`}</Typography>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_4`}</Typography>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_5`}</Typography>
          <Typography gutterBottom>{t`upload.legalinfo.text.part_6`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant={'contained'} color={'primary'}>
            {t`upload.legalinfo.button.close`}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
