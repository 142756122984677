import type { ImageFile } from 'store/imageUpload'

import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'

import { actions } from 'store/imageUpload'
import { UploadStatus } from 'store/imageUpload/initialState'
import { ImageIcon, TiffPreviewIcon } from 'style/icons'
import UploadProgressStateError from './resultStates/UploadProgressStateError'
import UploadProgressStateReady from './resultStates/UploadProgressStateReady'
import UploadProgressStateSuccess from './resultStates/UploadProgressStateSuccess'
import UploadProgressStateUploading from './resultStates/UploadProgressStateUploading'
import classes from './UploadQueueContainer.module.scss'

export interface QueueDetailElementProps {
  image: ImageFile
}

const statusTextClass = (image: ImageFile) => {
  switch (image.uploadStatus) {
    case UploadStatus.UPLOADED_OK:
      return classes.statusTextSuccess
    case UploadStatus.READY:
    case UploadStatus.UPLOADING:
      return classes.statusTextDefault
  }

  return classes.statusTextError
}

const cleanUpStatusMessage = (msg: string) => {
  msg = msg.trim()
  return msg.length === 0 ? '' : msg + ' '
}

const UploadStateIndicator = (props: { image: ImageFile }) => {
  const { image } = props
  const defaultIconSize = 50

  if (image.uploadStatus === UploadStatus.INVALID) {
    // invalid or error
    return <UploadProgressStateError size={defaultIconSize} />
  }
  if (image.uploadStatus === UploadStatus.READY) {
    // upload not yet started
    return <UploadProgressStateReady size={defaultIconSize} />
  }

  if (image.percent === 0) {
    // upload not yet started
    return <UploadProgressStateReady size={defaultIconSize} />
  }

  if (image.percent < 100 || image.uploadStatus === UploadStatus.UPLOADING) {
    // not yet 100% or result state not yet clear after upload finished
    return <UploadProgressStateUploading value={image.percent} size={defaultIconSize} />
  }

  if (image.uploadStatus === UploadStatus.UPLOADED_OK) {
    // final state clear and successful: 200
    return <UploadProgressStateSuccess size={defaultIconSize} />
  }

  return <UploadProgressStateError size={defaultIconSize} /> // final state clear and failure
}

const MaskedImageIcon = (props: { link: string; clipId: string }) => (
  <image width={56} height={56} xlinkHref={props.link} clipPath={`url(#${props.clipId})`} />
)

const ImagePreviewIcon = (props: { image: ImageFile; clipId: string }) => {
  const { image } = props

  if (image.url) {
    return <MaskedImageIcon link={image.url.replace('{WIDTH}', '56')} clipId={props.clipId} />
  }
  if (image.file.type === 'image/tiff') {
    return <TiffPreviewIcon />
  }
  if (image.dataUrl) {
    return <MaskedImageIcon link={image.dataUrl} clipId={props.clipId} />
  }

  return <ImageIcon />
}

const ImagePreview = (props: { image: ImageFile }) => {
  const { image } = props
  const clipId = `circleClip${image.id}`

  return image.uploadStatus === UploadStatus.INVALID ? (
    <Warning className={classes.statusIconError} />
  ) : (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id={clipId}>
          <circle cx="28" cy="28" r="28" fill="#FFFFFF" />
        </clipPath>
      </defs>
      <ImagePreviewIcon image={image} clipId={clipId} />
    </svg>
  )
}

const QueueDetailElement: React.FC<QueueDetailElementProps> = (props) => {
  const { image } = props
  const dispatch = useDispatch()
  const handleRemove = () => dispatch(actions.removeImage(image.id))

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.closeIconContainer} data-testid="queueDetailElement">
        <IconButton
          aria-label="delete"
          onClick={handleRemove}
          className={classes.iconButton}
          disabled={image.uploadStatus === UploadStatus.UPLOADED_OK}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      </Grid>
      <Grid
        item
        container
        xs={11}
        className={classes.detailsContent}
        data-testid="status-detail"
        data-filename={image.file.name}
        data-status={image.uploadStatus}
      >
        <Grid item xs={1}>
          <ImagePreview image={image} />
        </Grid>
        <Grid item xs={11} className={classes.detailsInfoText}>
          <Typography className={classes.filenameText}>{image.file.name}</Typography>
          <Typography className={statusTextClass(image)} data-testid="status-detail-text">
            {(image.messages || []).map((value, idx) => (
              <span key={idx}>{cleanUpStatusMessage(value)}</span>
            ))}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={1} className={classes.statusContent}>
        <div className={classes.processingIcon}>
          <UploadStateIndicator image={image} />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.divider} />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(QueueDetailElement)
