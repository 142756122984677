import type { ImageFeedback, ImageReviewsDto } from 'api/imageReviews'
import type { ThunkAction } from 'redux-thunk'
import type { RootState } from 'store/reducers'
import type { ImageReviewsFilter } from './initialState'
import type { ImageReviewsAction } from './reducer'

import { __, any } from 'ramda'

import { translateErrorResponse } from 'api/errors'
import imageReviewsApi from 'api/imageReviews'
import { ArticleIdentifier, ImageDto } from 'api/types'
import { selectors as profileSelectors } from 'store/userProfile'
import { ArticleGalleries, FetchState, TenantGallery } from 'types'
import { ImageGalleries } from 'types/image'
import articleGalleries from 'utils/articleGalleries'
import tenantGallery from 'utils/tenantGallery'
import { compareTenantIds } from 'utils/tenants'
import * as types from './actionTypes'
import selectors from './selectors'

type ImageReviewsThunkAction<T = void> = ThunkAction<T, RootState, null, ImageReviewsAction>

const initiatedRequest = (fetchMore: boolean): ImageReviewsAction => ({
  type: types.INITIATED_REQUEST,
  payload: fetchMore,
})

const finishedRequest = (errorNotification: string[] | null): ImageReviewsAction => ({
  type: types.FINISHED_REQUEST,
  payload: errorNotification,
})

export interface ResetAllFiltersPayload {
  subscribedBrandIds: string[]
}

export interface ImageReviewsPayload {
  articles: ArticleGalleries[]
  isLastRequest: boolean
  totalElements: number
}

export interface SetAvailableFilterPayload {
  allowedBrands: string[]
  allowedSuppliers: string[]
  allowedTenants: string[]
}

const articlesPayload = (
  reviews: ImageReviewsDto,
  getState: () => RootState
): ImageReviewsPayload => {
  const brandLabels = profileSelectors.brandLabels(getState())
  const mapArticle = articleGalleries.fromArticleDto(__, brandLabels, ['suggested'])

  return {
    articles: reviews.articles.map(mapArticle).filter((article) => article.tenants.length > 0),
    isLastRequest: reviews.last,
    totalElements: reviews.totalElements,
  }
}

export const checkAvailableFilter = (): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const filtersFetched = any(
      (value) => value.length > 0,
      [
        selectors.allowedBrands(state),
        selectors.allowedSuppliers(state),
        selectors.allowedTenants(state),
      ]
    )

    if (!filtersFetched) {
      imageReviewsApi.fetchImageReviewFilters().then(
        (filtersResponse) => {
          const { brands, suppliers, tenants } = filtersResponse.data
          dispatch({
            type: types.SET_AVAILABLE_FILTER,
            payload: {
              allowedBrands: brands,
              allowedSuppliers: suppliers,
              allowedTenants: tenants.sort(compareTenantIds),
            },
          })
        },
        (error) =>
          dispatch({ type: types.ADD_ERROR_NOTIFICATION, payload: translateErrorResponse(error) })
      )
    }
  }
}

export const fetchImageReviews = (fetchMore = false): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    dispatch(initiatedRequest(fetchMore))
    dispatch(checkAvailableFilter())

    const state = getState()
    const filter = selectors.filter(state)
    const page = selectors.page(state)
    imageReviewsApi.fetchImageReviews(filter, page).then(
      (response) => {
        dispatch({
          type: fetchMore ? types.ADD_IMAGES_TO_REVIEW : types.SET_IMAGE_REVIEWS,
          payload: articlesPayload(response.data, getState),
        })
      },
      (error) => dispatch(finishedRequest(translateErrorResponse(error)))
    )
  }
}

export const fetchMoreImageReviews = (): ImageReviewsThunkAction => {
  return fetchImageReviews(true)
}

export const setImageReviews = (
  filter: ImageReviewsFilter,
  response: ImageReviewsDto
): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    dispatch(checkAvailableFilter())
    dispatch(setFilter(filter))
    dispatch({ type: types.SET_IMAGE_REVIEWS, payload: articlesPayload(response, getState) })
  }
}

export interface UpdateTenantPayload {
  article: ArticleIdentifier
  update: Partial<TenantGallery>
}

export const updateTenant = (
  article: ArticleIdentifier,
  update: Partial<TenantGallery>
): ImageReviewsAction => ({
  type: types.UPDATE_TENANT,
  payload: { article, update },
})

export const updateImageGallery = (article: ArticleIdentifier, galleries: ImageGalleries) =>
  updateTenant(article, { galleries, hasChanged: true })

export const setFetchState = (article: ArticleIdentifier, fetchState: FetchState) =>
  updateTenant(article, { fetchState })

export const setTenantError = (article: ArticleIdentifier, errors?: string[]) =>
  updateTenant(article, { error: errors && errors.length ? errors.join(', ') : undefined })

export interface AddToPaperBinPayload {
  article: ArticleIdentifier
  image: ImageDto
  galleries: ImageGalleries
}

export const addToPaperBin = (
  article: ArticleIdentifier,
  image: ImageDto,
  galleries: ImageGalleries
): ImageReviewsAction => ({
  type: types.ADD_TO_PAPERBIN,
  payload: { article, image, galleries },
})

export interface RemoveFromPaperBinPayload {
  article: ArticleIdentifier
  image: ImageDto
}

export const removeFromPaperBin = (
  article: ArticleIdentifier,
  image: ImageDto
): ImageReviewsAction => ({
  type: types.REMOVE_FROM_PAPERBIN,
  payload: { article, image },
})

export const setFilter = (filter: Partial<ImageReviewsFilter>): ImageReviewsAction => ({
  type: types.SET_FILTER,
  payload: filter,
})

export const toggleSelectedTenant = (article: ArticleIdentifier): ImageReviewsAction => ({
  type: types.TOGGLE_SELECTED_GALLERY,
  payload: article,
})

export const toggleAllSelectedTenants = (): ImageReviewsThunkAction => {
  return (dispatch, getState): void => {
    const areAllTenantsSelected = selectors.areAllTenantsSelected(getState())

    dispatch({ type: types.TOGGLE_ALL_SELECTED_TENANTS, payload: !areAllTenantsSelected })
  }
}

export const resetFilter = (subscribedBrands: string[]): ImageReviewsAction => ({
  type: types.RESET_FILTER,
  payload: { subscribedBrandIds: subscribedBrands },
})

const findTenant = (articles: ArticleGalleries[], identifier: ArticleIdentifier) =>
  articles
    .find((article) => article.articleNumber === identifier.articleNumber)
    ?.tenants.find((tenant) => tenant.tenantId === identifier.tenantId)

const saveImageSuggestions = (
  article: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean,
  rejectAllSuggestions: boolean,
  isBulk: boolean
): ImageReviewsThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const articles = selectors.articles(getState())
    const tenant = findTenant(articles, article)

    if (tenant) {
      dispatch(setFetchState(article, 'FETCHING'))
      const entries = tenantGallery.toReviewImageGalleryEntryForm(tenant, rejectAllSuggestions)
      return imageReviewsApi
        .updateReviewImageGallery(article.articleNumber, article.tenantId, {
          entries,
          feedback,
          newGalleryRequested,
        })
        .then(
          () => {
            dispatch({ type: types.REMOVE_TENANT, payload: article })
            if (isBulk) {
              dispatch({ type: types.INCREASE_BULK_COUNT, payload: {} })
            }
          },
          (reason) => {
            dispatch(setTenantError(article, translateErrorResponse(reason)))
            dispatch(setFetchState(article, 'ERROR'))
            if (isBulk) {
              dispatch({ type: types.INCREASE_BULK_COUNT, payload: {} })
            }
          }
        )
    }

    if (isBulk) {
      dispatch({ type: types.INCREASE_BULK_COUNT, payload: {} })
    }
    return Promise.resolve()
  }
}

export const rejectGallery = (
  article: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean
): ImageReviewsThunkAction<void> => {
  return saveImageSuggestions(article, feedback, newGalleryRequested, true, false)
}

export const approveGallery = (article: ArticleIdentifier): ImageReviewsThunkAction<void> => {
  return saveImageSuggestions(article, [], false, false, false)
}

export interface BulkApprovalStartedPayload {
  totalCount: number
  rejectSuggestions: boolean
}

const bulkApproval = (
  rejectSuggestions: boolean,
  feedback?: ImageFeedback[],
  newGalleryRequested?: boolean
): ImageReviewsThunkAction<Promise<void>> => {
  return async (dispatch, getState): Promise<void> => {
    const selectedTenants = selectors.selectedTenants(getState())
    dispatch({
      type: types.BULK_APPROVAL_STARTED,
      payload: { totalCount: selectedTenants.length, rejectSuggestions },
    })

    for (const tenant of selectedTenants) {
      await dispatch(
        saveImageSuggestions(
          tenant,
          feedback ?? [],
          Boolean(newGalleryRequested),
          rejectSuggestions,
          true
        )
      )
    }
    dispatch({ type: types.BULK_APPROVAL_FINISHED, payload: {} })
  }
}

export const startBulkApproval = () => bulkApproval(false)

export const startBulkRejections = (feedback?: ImageFeedback[], requestNewImage?: boolean) =>
  bulkApproval(true, feedback, requestNewImage)

export const removeArticle = (articleNumber: string): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_ARTICLE, payload: articleNumber })

    const bulkApproval = selectors.bulkApproval(getState())
    if (!bulkApproval.inProgress) {
      dispatch(refetchArticles())
    }
  }
}

const defaultPageSize = 10
export const refetchArticles = (): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const filter = selectors.filter(state)
    const articles = selectors.articles(state)
    const size = Math.max(
      defaultPageSize,
      Math.ceil(articles.length / defaultPageSize) * defaultPageSize
    )

    imageReviewsApi.fetchImageReviews(filter, 0, size).then(
      (response) => {
        dispatch({ type: types.MERGE_ARTICLES, payload: articlesPayload(response.data, getState) })
      },
      (error) => dispatch(finishedRequest(translateErrorResponse(error)))
    )
  }
}

export const setVisibleStartIndex = (index: number): ImageReviewsThunkAction<void> => {
  return (dispatch, getState) => {
    const visibleStartIndex = selectors.visibleStartIndex(getState())
    if (index !== visibleStartIndex) {
      dispatch({
        type: types.SET_VISIBLE_START_INDEX,
        payload: index,
      })
    }
  }
}

export const removeTenantFromArticle = (article: ArticleIdentifier): ImageReviewsAction => ({
  type: types.REMOVE_TENANT_FROM_ARTICLE,
  payload: article,
})
