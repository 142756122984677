import type { MediathekImage } from 'store/mediathek'
import type { RootState } from 'store/reducers'
import type { VariantDetailAction } from './reducer'

import { t } from '@lingui/macro'
import { AxiosError } from 'axios'
import { ThunkAction } from 'redux-thunk'

import { translateErrorMessage, translateErrorResponse } from 'api/errors'
import imageReviewsApi from 'api/imageReviews'
import { ImageFeedback } from 'api/imageReviews/imageReviewsTypes'
import {
  ArticleIdentifier,
  ImageDto,
  ImageGallery,
  ImagePosition,
  ImageState,
  RawImageGalleryEntry,
} from 'api/types'
import variantDetailApi from 'api/variantDetail'
import { actions as imageReviewsActions } from 'store/imageReviews'
import { ImageReviewsAction } from 'store/imageReviews/reducer'
import { selectors as profileSelectors } from 'store/userProfile'
import { ArticleGalleries, FetchState, TenantGallery } from 'types'
import { ImageGalleries } from 'types/image'
import articleGalleries from 'utils/articleGalleries'
import tenantGallery from 'utils/tenantGallery'
import * as types from './actionTypes'
import selectors from './selectors'

type VariantDetailThunkAction<T = void> = ThunkAction<
  T,
  RootState,
  unknown,
  VariantDetailAction | ImageReviewsAction
>

export interface SetVariantDetailsPayload {
  articleNumber: string
  tenants: TenantGallery[]
  imageSuggestions?: ArticleGalleries
}

export const fetchVariantDetails =
  (articleNumber: string, waitingProgressArea?: string): VariantDetailThunkAction =>
  (dispatch, getState) => {
    dispatch(resetVariantDetails())
    variantDetailApi.fetchVariantDetails(articleNumber, waitingProgressArea).then(
      (response) => {
        const articleDetail = response.data
        const brandLabels = profileSelectors.brandLabels(getState())

        dispatch({
          type: types.SET_VARIANT_DETAILS,
          payload: {
            ...articleGalleries.fromArticleDetailDto(articleDetail, brandLabels),
            imageSuggestions: articleGalleries.fromArticleDto(articleDetail.article, brandLabels, [
              'suggested',
            ]),
          },
        })
      },
      (error: AxiosError) => {
        let errorMessages: string[] = [error.message]
        const errors = translateErrorResponse(error)
        if (errors.length) {
          errorMessages = errors
        }
        dispatch({ type: types.SET_ERRORS, payload: errorMessages })
      }
    )
  }

export const resetVariantDetails = (): VariantDetailAction => ({
  type: types.RESET_VARIANT_DETAILS,
  payload: {},
})

export const addError = (error: string): VariantDetailAction => ({
  type: types.ADD_ERROR,
  payload: error,
})

export const setInfoMessage = (msg: string | null): VariantDetailAction => ({
  type: types.SET_INFO_MESSAGE,
  payload: msg,
})

export const cleanNotifications = (): VariantDetailAction => ({
  type: types.CLEAN_NOTIFICATIONS,
  payload: {},
})

export interface SetImageGalleryPayload {
  tenantId: string
  galleries: ImageGalleries
}

export const setImageGallery = (
  tenantId: string,
  galleries: ImageGalleries
): VariantDetailAction => ({
  type: types.SET_IMAGE_GALLERY,
  payload: { tenantId, galleries },
})

export interface AddToPaperbinPayload {
  tenantId: string
  image: ImageDto
  galleries: ImageGalleries
}

export const addToPaperbin = (
  tenantId: string,
  image: ImageDto,
  galleries: ImageGalleries
): VariantDetailAction => ({
  type: types.ADD_TO_PAPERBIN,
  payload: { tenantId, image, galleries },
})

export interface RemoveFromPaperbinPayload {
  image: ImageDto
}

export const removeFromPaperbin = (image: ImageDto): VariantDetailAction => ({
  type: types.REMOVE_FROM_PAPERBIN,
  payload: { image },
})

export interface AddToSuggestionPaperBinPayload {
  tenantId: string
  image: ImageDto
  galleries: ImageGalleries
}

export const addToSuggestionPaperBin = (
  article: ArticleIdentifier,
  image: ImageDto,
  galleries: ImageGalleries
) => ({
  type: types.ADD_TO_SUGGESTION_PAPERBIN,
  payload: { tenantId: article.tenantId, image, galleries },
})

export interface RemoveFromSuggestionPaperBinPayload {
  tenantId: string
  image: ImageDto
}

export const removeFromSuggestionPaperBin = (article: ArticleIdentifier, image: ImageDto) => ({
  type: types.REMOVE_FROM_SUGGESTION_PAPERBIN,
  payload: { tenantId: article.tenantId, image },
})

export const showSubmitNotification = (): VariantDetailAction => ({
  type: types.SHOW_SUBMIT_NOTIFICATION,
  payload: {},
})

export const closeSubmitNotification = (): VariantDetailAction => ({
  type: types.CLOSE_SUBMIT_NOTIFICATION,
  payload: {},
})

export interface UpdateImageSuggestionPayload {
  tenantId: string
  update: Partial<TenantGallery>
}

export const updateImageSuggestion = (
  article: ArticleIdentifier,
  update: Partial<TenantGallery>
): VariantDetailAction => ({
  type: types.UPDATE_IMAGE_SUGGESTION,
  payload: { tenantId: article.tenantId, update },
})

export const updateSuggestionGallery = (article: ArticleIdentifier, galleries: ImageGalleries) =>
  updateImageSuggestion(article, { galleries, hasChanged: true })

export const setSuggestionFetchState = (article: ArticleIdentifier, fetchState: FetchState) =>
  updateImageSuggestion(article, { fetchState })

export const setSuggestionError = (article: ArticleIdentifier, errors?: string[]) =>
  updateImageSuggestion(article, { error: errors && errors.length ? errors.join(', ') : undefined })

type FilterPredicate = (args: [string, ImageDto | undefined]) => boolean
const getGalleryEntries = (
  tenantId: string,
  gallery: ImageGallery,
  predicate: FilterPredicate
): RawImageGalleryEntry[] => {
  return (
    Object.entries(gallery)
      .filter(predicate)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map(([position, image]) => ({ tenantId, position, imageId: image!.imageId }))
  )
}

export const submitGalleryApproval = (
  allowedTenants: string[]
): ThunkAction<void, RootState, null, VariantDetailAction> => {
  return (dispatch, getState): void => {
    const state = getState()
    const articleNumber = selectors.articleNumber(state)
    const tenants = selectors.tenants(state)
    const approvalsOfTenants: RawImageGalleryEntry[] = []
    tenants.forEach((t) => {
      const tenantId = t.tenantId
      if (!allowedTenants.includes(tenantId)) {
        return
      }

      const approvedGallery = t.galleries.approved || {}
      const approvedGalleryOfTenant = getGalleryEntries(
        tenantId,
        approvedGallery,
        ([position, image]) => Boolean(image)
      )

      if (approvedGalleryOfTenant.length === 0) {
        approvalsOfTenants.push({ tenantId: tenantId } as RawImageGalleryEntry)
      } else {
        approvalsOfTenants.push(...approvedGalleryOfTenant)
      }
    })

    if (approvalsOfTenants.length === 0) {
      dispatch(setInfoMessage(t`variantdetails.images.hint.nothing_to_submit`))
      return
    }

    variantDetailApi.updateImageGallery(articleNumber, approvalsOfTenants).then(
      (value) => {
        dispatch(showSubmitNotification())
        dispatch(setInfoMessage(translateErrorMessage(value.data)))
        dispatch(fetchVariantDetails(articleNumber))
      },
      (error: AxiosError) => {
        const errors = translateErrorResponse(error)
        if (errors.length) {
          dispatch({ type: types.ADD_ERROR, payload: errors[0] })
        } else {
          dispatch(
            addError(
              t({
                id: 'variantdetails.images.error.approve_submit_failed',
                message: `Could not save changes on product image gallery, error: ${error.message}`,
              })
            )
          )
        }
      }
    )
  }
}

export interface ReplaceWithMediathekImagePayload {
  tenantId: string
  imagePosition: ImagePosition
  image: ImageDto
}

export const replaceWithMediathekImage = (
  tenantId: string,
  imagePosition: ImagePosition,
  image: MediathekImage
) => ({
  type: types.REPLACE_WITH_MEDIATHEK_IMAGE,
  payload: {
    tenantId,
    imagePosition,
    image: {
      imageId: image.imageId,
      position: imagePosition,
      urlTemplate: image.urlTemplate,
      state: ImageState.APPROVED,
    },
  },
})

export interface CopyTenantGalleryPayload {
  srcTenantId: string
  dstTenantId: string
}

export const copyTenantGallery = (
  srcTenantId: string,
  dstTenantId: string
): VariantDetailAction => ({
  type: types.COPY_TENANT_GALLERY,
  payload: { srcTenantId, dstTenantId },
})

export const saveImageSuggestions = (
  identifier: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean,
  rejectAllSuggestions: boolean
): VariantDetailThunkAction<void> => {
  return (dispatch, getState) => {
    const imageSuggestions = selectors.imageSuggestions(getState())
    const tenant = imageSuggestions?.tenants?.find(
      (tenant) => tenant.tenantId === identifier.tenantId
    )

    if (tenant) {
      dispatch(setSuggestionFetchState(identifier, 'FETCHING'))
      const entries = tenantGallery.toReviewImageGalleryEntryForm(tenant, rejectAllSuggestions)
      imageReviewsApi
        .updateReviewImageGallery(identifier.articleNumber, identifier.tenantId, {
          entries,
          feedback,
          newGalleryRequested,
        })
        .then(
          () => {
            dispatch({ type: types.REMOVE_IMAGE_SUGGESTION, payload: identifier.tenantId })
            dispatch(imageReviewsActions.removeTenantFromArticle(identifier))
            dispatch(fetchVariantDetails(identifier.articleNumber))
          },
          (reason) => dispatch(setSuggestionError(identifier, translateErrorResponse(reason)))
        )
    }
  }
}

export const rejectSuggestionGallery = (
  article: ArticleIdentifier,
  feedback: ImageFeedback[],
  newGalleryRequested: boolean
): VariantDetailThunkAction<void> => {
  return saveImageSuggestions(article, feedback, newGalleryRequested, true)
}

export const approveSuggestionGallery = (
  article: ArticleIdentifier
): VariantDetailThunkAction<void> => {
  return saveImageSuggestions(article, [], false, false)
}
