export interface BulkCopyState {
  showDialog: boolean
  srcTenantId: string | null
  dstTenantId: string | null
  selectedArticleNumber: string | null
  currentPage: number
  articlesPerPage: number
  disabledArticles: string[]
  copyInProgress: boolean
  copiedGalleries: number
  copyErrors: string[] | null
  manuallyCopiedArticles: string[]
}

const initialState: BulkCopyState = {
  showDialog: false,
  srcTenantId: null,
  dstTenantId: null,
  selectedArticleNumber: null,
  currentPage: 0,
  articlesPerPage: 10,
  disabledArticles: [],
  copyInProgress: false,
  copiedGalleries: 0,
  copyErrors: null,
  manuallyCopiedArticles: [],
}

export default initialState
