import type { ItemTransferListState } from './hooks'

import { useCallback, useRef } from 'react'
import { t } from '@lingui/macro'

import ItemTransferButtons from './ItemTransferButtons'
import ItemTransferPanel from './ItemTransferPanel'
import classes from './ItemTransferList.module.scss'

interface ItemTransferListProps {
  state: ItemTransferListState
  inputLabel: string
  'data-testid'?: string
}

const ItemTransferList: React.FC<ItemTransferListProps> = ({ state, inputLabel, ...props }) => {
  const filteredItemsLeft = useRef<string[]>([])
  const filteredItemsRight = useRef<string[]>([])
  const handleLeftClick = () => {
    state.transferLeft(filteredItemsRight.current)
  }
  const handleRightClick = () => {
    state.transferRight(filteredItemsLeft.current)
  }
  const handleFilteredItemsLeftChange = useCallback((items: string[]) => {
    filteredItemsLeft.current = items
  }, [])
  const handleFilteredItemsRightChange = useCallback((items: string[]) => {
    filteredItemsRight.current = items
  }, [])

  return (
    <div className={classes.panels} data-testid={props['data-testid']}>
      <ItemTransferPanel
        title={t`general.selected`}
        items={state.selected}
        itemMap={state.itemMap}
        inputLabel={inputLabel}
        data-testid="selected"
        onClick={state.handleUnselect}
        onChange={handleFilteredItemsLeftChange}
      />
      <ItemTransferButtons
        onTransferLeftClick={handleLeftClick}
        onTransferRightClick={handleRightClick}
      />
      <ItemTransferPanel
        autoFocus
        title={t`general.available`}
        items={state.unselected}
        itemMap={state.itemMap}
        inputLabel={inputLabel}
        data-testid="available"
        onClick={state.handleSelect}
        onChange={handleFilteredItemsRightChange}
      />
    </div>
  )
}

export default ItemTransferList
