import type { ArticleGalleries } from 'types'

export interface ImageReviewsFilter {
  articleNumbers: string
  brandIds: string[]
  tenantIds: string[]
  supplierIds: string[]
  uploadStart: string
  uploadEnd: string
}

export type ImageReviewsFilterName = keyof ImageReviewsFilter

export interface BulkApprovalState {
  count: number
  totalCount: number
  rejectSuggestions: boolean
  inProgress: boolean
}

export interface ImageReviewsState {
  errorNotification: string[] | null
  allowedBrands: string[]
  allowedSuppliers: string[]
  allowedTenants: string[]
  articles: ArticleGalleries[]
  filter: ImageReviewsFilter
  bulkApproval: BulkApprovalState
  page: number
  requestInProgress: boolean
  isLastRequest: boolean
  totalElements: number
  visibleStartIndex: number
}

export const initialFilters: ImageReviewsFilter = {
  articleNumbers: '',
  brandIds: [],
  tenantIds: [],
  supplierIds: [],
  uploadStart: '',
  uploadEnd: '',
}

const initialState: ImageReviewsState = {
  errorNotification: null,
  allowedBrands: [],
  allowedSuppliers: [],
  allowedTenants: [],
  articles: [],
  filter: initialFilters,
  bulkApproval: {
    count: 0,
    totalCount: 0,
    rejectSuggestions: false,
    inProgress: false,
  },
  page: 0,
  requestInProgress: false,
  isLastRequest: false,
  totalElements: 0,
  visibleStartIndex: 0,
}

export default initialState
