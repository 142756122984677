import { ReactElement } from 'react'
import { Box, CircularProgressProps, SvgIcon } from '@material-ui/core'

import classes from './UploadProgressState.module.scss'

//const mainColor = '#E6373C';

export default function UploadProgressStateError(props: CircularProgressProps): ReactElement {
  return (
    <Box
      position="relative"
      display="inline-flex"
      style={{ width: props.size, height: props.size }}
    >
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.error}
      >
        <SvgIcon
          className="MuiCircularProgress-svg"
          viewBox="22 22 44 44"
          style={{ width: props.size, height: props.size }}
        >
          <circle
            cx="44"
            cy="44"
            r="18"
            fill="none"
            strokeWidth="4.5"
            stroke="currentColor"
          ></circle>
        </SvgIcon>
      </Box>

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.375 3.12476L9.6875 12.4998H10.3125L10.625 3.12476H9.375Z"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M10.625 15.6248H9.375V16.8748H10.625V15.6248Z"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      </Box>
    </Box>
  )
}
